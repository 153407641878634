import styled from '@emotion/styled';
import { Group, Paper, PaperProps, Stack, Text } from '@mantine/core';

interface StatsViewerProps {
    isHover: boolean;
    areaId: string | number;
    grade: string;
    percentFailure: number;
}

export function StatsViewer({ isHover, areaId, grade, percentFailure }: StatsViewerProps) {
    const content = (
        <Stack spacing="0.25rem">
            <Text variant="body2">{`Stats - Area ${areaId}`}</Text>
            <Group position="left" spacing="0.25rem">
                <Text variant="body2light">Score:</Text>
                <Text variant="body2">{grade}</Text>
            </Group>
            <Group position="left" spacing="0.25rem">
                <Text variant="body2light">Failure:</Text>
                <Text variant="body2">{(percentFailure * 100).toFixed(4)}%</Text>
            </Group>
        </Stack>
    );

    return isHover ? <StatsContainer>{content}</StatsContainer> : null;
}

const StatsContainer = styled(Paper)<PaperProps>({
    boxShadow: '2px 2px 20px 0px rgba(0, 0, 0, 0.10)',
    backgroundColor: 'var(--color-white)',
    borderRadius: '5px',
    padding: '0.5rem',
});
