import { useMemo } from 'react';

import { Layer, Rectangle } from 'recharts';
import stc from 'string-to-color';

const SankeyNode = ({ x, y, width, height, index, payload, containerWidth, setBackwardLinks }: any) => {
    const isOut = x + width + 6 > containerWidth;

    const nodeColor = useMemo(() => (payload.color ? `rgb(${payload.color})` : stc(payload.name)), [payload]);

    if (payload.sourceNodes.length === 0 && payload.targetNodes.length === 0) {
        return null;
    }

    return (
        <Layer key={`CustomNode${index}`}>
            <Rectangle
                x={x}
                y={y}
                width={width}
                height={height}
                fill={nodeColor}
                fillOpacity="1"
                onMouseEnter={() => {
                    setBackwardLinks(payload.sourceLinks);
                }}
                onMouseLeave={() => {
                    setBackwardLinks([]);
                }}
            />
            <text
                data-cy="sankey-node"
                textAnchor={isOut ? 'end' : 'start'}
                x={isOut ? x - 6 : x + width + 6}
                y={y + height / 2}
                fontSize="14"
            >
                {payload.name}
            </text>
        </Layer>
    );
};

export default SankeyNode;
