import { useRef } from 'react';

import { Loader, Pagination } from '@dronebase/shared-ui-core';
import styled from '@emotion/styled';
import { Button, Group, Table } from '@mantine/core';
import { flexRender } from '@tanstack/react-table';
import { useNavigationType } from 'react-router-dom';

import { TabularTableFiltersDrawer } from 'components/filters/anomaliesTabularTable/TabularTableFiltersDrawer';
import Error from 'components/Layout/Error';
import { useAnomalyData, useTabularTable } from 'lib/hooks';
import useIsHorizontallyScrolling from 'lib/hooks/useIsHorizontallyScrolling';

import CustomizeColumns from './filters/tabular/CustomizeColumns';
import { FilterPill } from './filters/tabular/FilterPill';
import { OverflowContainer } from './TabularTable.styled';

const TabularTable = () => {
    const { loading: loadingAnomalyData, error } = useAnomalyData();
    const overflowContainerRef = useRef<HTMLDivElement>(null);
    const isScrollingHorizontally = useIsHorizontallyScrolling(overflowContainerRef);
    const navigationType = useNavigationType();
    const {
        table,
        loading: loadingTabularTable,
        getCommonPinningStyles,
        renderHeader,
        clearFilters,
        applyFilters,
        cancelFilters,
        tabularFilters,
        setTabularFilters,
        resetToDefaultColumnVisibilityState,
        columnVisibilityState,
    } = useTabularTable(isScrollingHorizontally, navigationType);

    const { rows } = table.getRowModel();

    if (loadingTabularTable || loadingAnomalyData || !table) {
        return <Loader />;
    }

    if (error) {
        return <Error showRedirect={false} label={error} />;
    }

    return (
        <>
            <Group position="apart" h="2.5rem">
                <Group position="left" align="center">
                    <TabularTableFiltersDrawer apply={applyFilters} clear={clearFilters} cancel={cancelFilters} />
                    <Button size="md" disabled={tabularFilters.length === 0} variant="text" onClick={clearFilters}>
                        Clear Filters
                    </Button>
                </Group>

                <Group position="right" align="center">
                    <CustomizeColumns
                        table={table}
                        resetToDefaultColumnVisibilityState={resetToDefaultColumnVisibilityState}
                        columnVisibilityState={columnVisibilityState}
                    />

                    <Pagination
                        itemsPerPage={table.getState().pagination.pageSize}
                        currentPage={table.getState().pagination.pageIndex + 1}
                        onItemsPerPage={(ipp) => () => table.setPageSize(ipp)}
                        onNextPage={() => table.nextPage()}
                        onPreviousPage={() => table.previousPage()}
                        onLastPage={() => table.setPageIndex(table.getPageCount() - 1)}
                        onFirstPage={() => table.setPageIndex(0)}
                        totalPages={table.getPageCount()}
                        totalItems={table.getPrePaginationRowModel().rows.length || 0}
                        withTotal
                    />
                </Group>
            </Group>
            {tabularFilters.length > 0 && (
                <Group py="md" spacing="var(--spacing-100)">
                    {tabularFilters.map((filter) => (
                        <FilterPill key={filter.id} filter={filter} setTabularFilters={setTabularFilters} />
                    ))}
                </Group>
            )}
            <OverflowContainer ref={overflowContainerRef}>
                <Table>
                    <TableHead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id}>{headerGroup.headers.map((header) => renderHeader(header))}</tr>
                        ))}
                    </TableHead>
                    <tbody>
                        {rows.map((row) => (
                            <tr key={row.id}>
                                {row.getVisibleCells().map((cell) => {
                                    const { column } = cell;

                                    return (
                                        <td
                                            key={cell.id}
                                            style={{
                                                ...getCommonPinningStyles(column),
                                                padding: '0.75rem',
                                            }}
                                        >
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </OverflowContainer>
        </>
    );
};

export default TabularTable;

const TableHead = styled.thead({
    position: 'sticky',
    top: 0,
    zIndex: 200,
    borderBottom: '1px solid var(--color-grey-300) !important',
});
