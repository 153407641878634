import styled from '@emotion/styled';
import { Outlet } from 'react-router-dom';

import zeitviewLogo from 'components/common/images/zeitview-logo.svg';
import { useDocumentTitle } from 'lib/hooks/useDocumentTitle';

import { AcquisitionBanner } from './AcquisitionBanner';

export const AuthPagesLayout = () => {
    useDocumentTitle('Zeitview | Solar Insights');

    return (
        <PageLayoutContainer>
            <AcquisitionBanner />
            <PageContentContainer>
                <img src={zeitviewLogo} />
                <CardLayoutContainer>
                    <Outlet />
                </CardLayoutContainer>
            </PageContentContainer>
        </PageLayoutContainer>
    );
};

const PageLayoutContainer = styled.div({
    height: '100vh',
    width: '100vw',
    backgroundImage: "url('./login-page-bg.webp')",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    flexDirection: 'column',
});

const PageContentContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingTop: '7.25rem',
    paddingBottom: '7.25rem',
    height: '100%',
    alignItems: 'center',
    gap: '2.5rem',
    overflow: 'auto',
});

export const CardContentContainer = styled.div({
    display: 'flex',
    width: '27.5rem',
    minWidth: '27.5rem',
    maxWidth: '41.25rem',
    padding: '1.5rem',
    flexDirection: 'column',
    justifyContent: 'center',
    alignSelf: 'center',
    gap: '1.5rem',
    borderRadius: '0.25rem',
    background: 'var(--color-white-100, #FFF)',
    boxShadow: '0px 4px 48px 0px rgba(141, 149, 162, 0.20)',
});

const CardLayoutContainer = styled.div({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
    height: '100%',
});
