import { useMemo } from 'react';

import styled from '@emotion/styled';
import { Divider, Paper, PaperProps, Stack, Text } from '@mantine/core';

import { REPORT_TYPE_CTA_BUTTON_COLOR } from 'lib/constants';

import UpgradeOptionCTA, { UpgradeOptionsCardProps } from './UpgradeOptionCTA';
import RecoverableEnergyBreakdown from '../RecoverableEnergyBreakdown';

interface CardContent {
    [key: string]: {
        upgradeCost: number;
        accentColor: string;
        copy: JSX.Element;
    };
}

const UpgradeOptionsCard = ({ type, upgradeCosts }: UpgradeOptionsCardProps) => {
    const content: CardContent = useMemo(
        () => ({
            string_level: {
                upgradeCost: upgradeCosts.string_level,
                accentColor: REPORT_TYPE_CTA_BUTTON_COLOR.string,
                copy: (
                    <WhatsIncludedContainer>
                        <ul>
                            <li>
                                <Text variant="body3light" color="gray.9">
                                    Inverted Outages
                                </Text>
                            </li>
                            <li>
                                <Text variant="body3light" color="gray.9">
                                    Sectional Outages
                                </Text>
                            </li>
                            <li>
                                <Text variant="body3light" color="gray.9">
                                    All string level anomalies
                                </Text>
                            </li>
                        </ul>
                    </WhatsIncludedContainer>
                ),
            },
            module_basic: {
                upgradeCost: upgradeCosts.module_basic,
                accentColor: REPORT_TYPE_CTA_BUTTON_COLOR.basic,
                copy: (
                    <WhatsIncludedContainer>
                        <Text variant="body3light" color="gray.9">
                            Everything in String Level, plus:
                        </Text>
                        <ul>
                            <li>
                                <Text
                                    variant="body3light"
                                    color="gray.9"
                                >{`Module faults with energy loss affecting >=33% of a module`}</Text>
                            </li>
                            <li>
                                <Text
                                    variant="body3light"
                                    color="gray.9"
                                >{`Sub-module faults with energy loss affecting >=33% of a module`}</Text>
                            </li>
                            <li>
                                <Text
                                    variant="body3light"
                                    color="gray.9"
                                >{`Hot-spots with >=10°C temperature difference`}</Text>
                            </li>
                        </ul>
                    </WhatsIncludedContainer>
                ),
            },
            module_advanced: {
                upgradeCost: upgradeCosts.module_advanced,
                accentColor: REPORT_TYPE_CTA_BUTTON_COLOR.advanced,
                copy: (
                    <WhatsIncludedContainer>
                        <Text variant="body3light" color="gray.9">
                            Everything in Module Basic, plus:
                        </Text>
                        <ul>
                            <li>
                                <Text variant="body3light" color="gray.9">
                                    All module faults
                                </Text>
                            </li>
                            <li>
                                <Text variant="body3light" color="gray.9">
                                    All sub-module faults
                                </Text>
                            </li>
                            <li>
                                <Text
                                    variant="body3light"
                                    color="gray.9"
                                >{`All hot-spots with >1°C temperature difference`}</Text>
                            </li>
                            <li>
                                <Text variant="body3light" color="gray.9">
                                    Other site issues: junction box heating, missing modules, acute soiling (bird
                                    droppings, debris), PID, major racking shifts, misaligned trackers, systemic shading
                                </Text>
                            </li>
                        </ul>
                    </WhatsIncludedContainer>
                ),
            },
        }),
        [upgradeCosts.module_advanced, upgradeCosts.module_basic, upgradeCosts.string_level],
    );

    return (
        <CardContainer>
            <UpgradeOptionCTA type={type} upgradeCosts={upgradeCosts} content={content} />
            <Stack spacing={0}>
                <Text variant="body3light" color="gray.4">
                    Est. Recoverable Energy Savings
                </Text>
                <RecoverableEnergyBreakdown reportType={type} />
            </Stack>
            <Divider my="0.5rem" color="gray.1" />
            <Text variant="body3light" color="gray.9">
                {content[type].copy}
            </Text>
        </CardContainer>
    );
};

export default UpgradeOptionsCard;

const CardContainer = styled(Paper)<PaperProps>({
    width: '20.8rem',
    backgroundColor: 'var(--color-white)',
    boxShadow: '0px 4px 48px 0px rgba(141, 149, 162, 0.20)',
    borderRadius: '10px',
    textAlign: 'center',
    border: `2px solid var(--color-grey-100)`,
    height: 'auto',
    padding: '2.5rem 1.5rem',
});

const WhatsIncludedContainer = styled.div({
    paddingTop: '0.25rem',
    textAlign: 'left',
    ul: {
        listStyleType: 'disc',
        paddingLeft: '1.25rem',
        margin: 0,
    },
});
