import { useCallback, useMemo } from 'react';

import { PickingInfo } from 'deck.gl/typed';
import { useSetAtom } from 'jotai';

import { getCenterPointFromGeometry } from 'lib/helpers';
import { Asset } from 'lib/types/inspections/anomaliesMap';
import { hoveringAnomaliesAtom, hoveringAssetAtom, selectedAnomaliesAtom } from 'state/inspections/anomaliesMap';

import { useAnomalyMapData } from './useAnomalyMapData';
import { useHighlightedAssets } from './useHighlightedAssets';

const useSelectedAsset = () => {
    const setSelectedAnomalies = useSetAtom(selectedAnomaliesAtom);
    const { activeMapAnomalies } = useAnomalyMapData();
    const setHoveringAsset = useSetAtom(hoveringAssetAtom);
    const setHoveringAnomalies = useSetAtom(hoveringAnomaliesAtom);
    const { setHighlightedModuleUuid } = useHighlightedAssets();

    const clearSelectedAsset = useCallback(() => {
        setSelectedAnomalies(undefined);
        setHighlightedModuleUuid(undefined);
    }, [setHighlightedModuleUuid, setSelectedAnomalies]);

    const getAssetAndAnomalies = useCallback(
        ({ assetRegionUuid, inverterId, combinerId, positionId, stack, coordinates }: Asset) => {
            const asset = {
                assetRegionUuid,
                inverterId,
                combinerId,
                positionId,
                stack,
                coordinates,
            };

            if (activeMapAnomalies[assetRegionUuid]?.species.length > 0) {
                const anomalies = activeMapAnomalies[assetRegionUuid];

                return { anomalies, asset };
            } else {
                return { asset };
            }
        },
        [activeMapAnomalies],
    );

    const setAssetAndAnomalies = useCallback(
        (result: any, assetRegionUuid: string) => {
            setSelectedAnomalies(result.anomalies);
            setHighlightedModuleUuid(assetRegionUuid);
        },
        [setHighlightedModuleUuid, setSelectedAnomalies],
    );

    const handleAssetInfo = useCallback(
        (info: PickingInfo) => {
            if (info?.object?.properties?.uuid && info.coordinate) {
                const {
                    geometry,
                    properties: {
                        uuid: assetRegionUuid,
                        nom_inverter: inverterId,
                        nom_combiner: combinerId,
                        position_id: positionId,
                        nom_stack: stack,
                    },
                } = info.object;

                const centroidCoordinates = getCenterPointFromGeometry(geometry);
                const coordinates = [
                    Number(centroidCoordinates[1].toFixed(6)),
                    Number(centroidCoordinates[0].toFixed(6)),
                ] as [number, number];

                return getAssetAndAnomalies({
                    assetRegionUuid,
                    inverterId,
                    combinerId,
                    positionId,
                    stack,
                    coordinates,
                });
            } else {
                return null;
            }
        },
        [getAssetAndAnomalies],
    );

    const onAssetHover = useCallback(
        (info: PickingInfo) => {
            const result = handleAssetInfo(info);

            if (result) {
                setHoveringAnomalies(result.anomalies);
                setHoveringAsset(result.asset);
            }
        },
        [handleAssetInfo, setHoveringAnomalies, setHoveringAsset],
    );

    const value = useMemo(
        () => ({
            clearSelectedAsset,
            onAssetHover,
            getAssetAndAnomalies,
            setAssetAndAnomalies,
        }),
        [clearSelectedAsset, onAssetHover, getAssetAndAnomalies, setAssetAndAnomalies],
    );

    return value;
};

export { useSelectedAsset };
