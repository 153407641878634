import { SelectItem } from '@mantine/core';
import { forEach, get, mapValues } from 'lodash';

export type TabularFiltersInfo = {
    anomalyStatusData: SelectItem[];
    priorityOptions: { label: string; value: string }[];
    stackIdData: SelectItem[];
    irSignalData: SelectItem[];
    rgbSignalData: SelectItem[];
    subsystemData: SelectItem[];
    combinerIdData: SelectItem[];
    inverterIdData: SelectItem[];
    hasSliderData: { [key: string]: boolean }; // Indicates if range slider data exists for each column
};

export const generateFilterOptions = (rawData: any[]): TabularFiltersInfo => {
    // Mapping of selection options for MultiSelect components
    const dropdownFieldMappings: Record<keyof Omit<TabularFiltersInfo, 'hasSliderData'>, string> = {
        anomalyStatusData: 'Anomaly Status',
        priorityOptions: 'Priority',
        stackIdData: 'Stack ID',
        irSignalData: 'IR Signal',
        rgbSignalData: 'RGB Signal',
        subsystemData: 'Subsystem',
        combinerIdData: 'Combiner ID',
        inverterIdData: 'Inverter ID',
    };

    // Fields requiring range value existence checks
    const rangeFields = [
        'Energy Loss Weight',
        'Power Loss (kW)',
        'Base Temperature',
        'Anomaly Temperature',
        'Temperature Delta',
    ];

    // Initialize sets for dropdown values and existence check for range fields
    const uniqueDropdownValues: Record<keyof Omit<TabularFiltersInfo, 'hasData'>, Set<string>> = mapValues(
        dropdownFieldMappings,
        () => new Set<string>(),
    ) as Record<keyof Omit<TabularFiltersInfo, 'hasData'>, Set<string>>;
    const rangeFieldDataExistence: Record<string, boolean> = Object.fromEntries(
        rangeFields.map((field) => [field, false]),
    );

    // Process through raw data once to populate uniqueDropdownValues and rangeFieldDataExistence
    forEach(rawData, (record) => {
        forEach(dropdownFieldMappings, (fieldName, optionKey) => {
            const value = get(record, fieldName) as string | undefined;

            if (value) {
                uniqueDropdownValues[optionKey as keyof typeof uniqueDropdownValues].add(value);
            }
        });

        forEach(rangeFields, (field) => {
            if (record[field] !== null && record[field] !== undefined && record[field] !== '') {
                rangeFieldDataExistence[field] = true;
            }
        });
    });

    // Convert uniqueDropdownValues to arrays of TabularFilter objects
    const filterOptions: TabularFiltersInfo = {
        hasSliderData: rangeFieldDataExistence,
        ...mapValues(dropdownFieldMappings, (fieldName, optionKey) =>
            Array.from(uniqueDropdownValues[optionKey as keyof typeof uniqueDropdownValues]).map((value) => ({
                label: value,
                value,
            })),
        ),
    } as TabularFiltersInfo;

    return filterOptions;
};
