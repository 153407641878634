import { useState, useRef, useEffect, ReactNode, isValidElement } from 'react';

import { Tooltip } from '@mantine/core';

import { StyledTableData } from './TableElements.styled';

const TableDataWithTooltip = ({ children }: { children: ReactNode }) => {
    const [isOverflowing, setIsOverflowing] = useState(false);
    const ref = useRef<HTMLTableCellElement>(null);

    useEffect(() => {
        const checkOverflow = () => {
            if (ref.current) {
                setIsOverflowing(ref.current.offsetWidth < ref.current.scrollWidth);
            }
        };

        checkOverflow();
        window.addEventListener('resize', checkOverflow);

        return () => window.removeEventListener('resize', checkOverflow);
    }, [children]);

    /** 
        Get text value from table cell, if it's a Mantine Cell component,
        else use the children as the result
    */
    const childText =
        isValidElement(children) && children?.props?.cell?.getValue()?.props?.children
            ? children.props.cell.getValue().props.children
            : children;

    return (
        <Tooltip
            mt="1rem"
            position="top-start"
            disabled={!isOverflowing || typeof childText !== 'string'}
            transitionProps={{ transition: 'pop', duration: 300 }}
            label={childText}
            withArrow
        >
            <StyledTableData ref={ref}>{children}</StyledTableData>
        </Tooltip>
    );
};

export default TableDataWithTooltip;
