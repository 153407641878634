import { notifications } from '@dronebase/shared-ui-core';

import { SITE_INSPECTION_API_URL } from 'lib/constants';
import { fetchData } from 'lib/hooks';

export const changeSerialNumber = async (siteId: string, assetRegionUuid: string, serialNumber: string) => {
    const { error } = await fetchData({
        url: `${SITE_INSPECTION_API_URL}/sites/${siteId}/assets/update`,
        method: 'POST',
        body: {
            updates: [
                {
                    filter: {
                        asset_uuid: assetRegionUuid,
                    },
                    data: {
                        serial_number: serialNumber,
                    },
                },
            ],
        },
    });

    if (error) {
        notifications.error({
            title: 'Something went wrong. Please try again later.',
            withCloseButton: true,
            withIcon: true,
        });
    } else {
        notifications.success({
            title: 'Serial number has been updated',
            withCloseButton: true,
            withIcon: true,
        });
    }
};
