import { WritableAtom, atom, useSetAtom } from 'jotai';
import { RESET, atomWithDefault, useResetAtom } from 'jotai/utils';

import { APPLY, atomWithValidation, useApplyAtom } from 'state/utils/atomWithValidation';

import { flatColumnFiltersAtom } from './pivotFilters';

export const stackIdFilterDefaultAtom = atom<string[]>([]);
export const stackIdFilterAtom = atomWithDefault((get) => get(stackIdFilterDefaultAtom));

export const anomalyStatusFilterDefaultAtom = atom<string[]>([]);
export const anomalyStatusFilterAtom = atomWithDefault((get) => get(anomalyStatusFilterDefaultAtom));

export const statusUpdatedDateFilterDefaultAtom = atom<string[]>([]);
export const statusUpdatedDateFilterAtom = atomWithDefault((get) => get(statusUpdatedDateFilterDefaultAtom));

export const irSignalFilterDefaultAtom = atom<string[]>([]);
export const irSignalFilterAtom = atomWithDefault((get) => get(irSignalFilterDefaultAtom));

export const rgbSignalFilterDefaultAtom = atom<string[]>([]);
export const rgbSignalFilterAtom = atomWithDefault((get) => get(rgbSignalFilterDefaultAtom));

export const subsystemFilterDefaultAtom = atom<string[]>([]);
export const subsystemFilterAtom = atomWithDefault((get) => get(subsystemFilterDefaultAtom));

export const priorityFilterDefaultAtom = atom<string[]>([]);
export const priorityFilterAtom = atomWithDefault((get) => get(priorityFilterDefaultAtom));

export const energyLossWeightFilterDefaultAtom = atom<[number, number]>([0, 1]);
export const energyLossWeightFilterAtom = atomWithDefault((get) => get(energyLossWeightFilterDefaultAtom));

export const powerLossFilterDefaultAtom = atom<[number, number]>([0, 100]);
export const powerLossFilterAtom = atomWithDefault((get) => get(powerLossFilterDefaultAtom));

export const baseTemperatureFilterDefaultAtom = atom<[number, number]>([0, 100]);
export const baseTemperatureFilterAtom = atomWithDefault((get) => get(baseTemperatureFilterDefaultAtom));

export const anomalyTemperatureFilterDefaultAtom = atom<[number, number]>([0, 100]);
export const anomalyTemperatureFilterAtom = atomWithDefault((get) => get(anomalyTemperatureFilterDefaultAtom));

export const temperatureDeltaFilterDefaultAtom = atom<[number, number]>([0, 100]);
export const temperatureDeltaFilterAtom = atomWithDefault((get) => get(temperatureDeltaFilterDefaultAtom));

export const combinerIdFilterDefaultAtom = atom<string[]>([]);
export const combinerIdFilterAtom = atomWithDefault((get) => get(combinerIdFilterDefaultAtom));

export const inverterIdFilterDefaultAtom = atom<string[]>([]);
export const inverterIdFilterAtom = atomWithDefault((get) => get(inverterIdFilterDefaultAtom));

export const tabularTableFilterAtoms = {
    'Stack ID': {
        atom: atomWithValidation(stackIdFilterAtom),
        defaultAtom: stackIdFilterDefaultAtom,
    },
    'Anomaly Status': {
        atom: atomWithValidation(anomalyStatusFilterAtom),
        defaultAtom: anomalyStatusFilterDefaultAtom,
    },
    'Status Updated Date': {
        atom: atomWithValidation(statusUpdatedDateFilterAtom),
        defaultAtom: statusUpdatedDateFilterDefaultAtom,
    },
    'IR Signal': {
        atom: atomWithValidation(irSignalFilterAtom),
        defaultAtom: irSignalFilterDefaultAtom,
    },
    'RGB Signal': {
        atom: atomWithValidation(rgbSignalFilterAtom),
        defaultAtom: rgbSignalFilterDefaultAtom,
    },
    'Subsystem': {
        atom: atomWithValidation(subsystemFilterAtom),
        defaultAtom: subsystemFilterDefaultAtom,
    },
    'Priority': {
        atom: atomWithValidation(priorityFilterAtom),
        defaultAtom: priorityFilterDefaultAtom,
    },
    'Energy Loss Weight': {
        atom: atomWithValidation(energyLossWeightFilterAtom),
        defaultAtom: energyLossWeightFilterDefaultAtom,
    },
    'Power Loss (kW)': {
        atom: atomWithValidation(powerLossFilterAtom),
        defaultAtom: powerLossFilterDefaultAtom,
    },
    'Base Temperature': {
        atom: atomWithValidation(baseTemperatureFilterAtom),
        defaultAtom: baseTemperatureFilterDefaultAtom,
    },
    'Anomaly Temperature': {
        atom: atomWithValidation(anomalyTemperatureFilterAtom),
        defaultAtom: anomalyTemperatureFilterDefaultAtom,
    },
    'Temperature Delta': {
        atom: atomWithValidation(temperatureDeltaFilterAtom),
        defaultAtom: temperatureDeltaFilterDefaultAtom,
    },
    'Combiner ID': {
        atom: atomWithValidation(combinerIdFilterAtom),
        defaultAtom: combinerIdFilterDefaultAtom,
    },
    'Inverter ID': {
        atom: atomWithValidation(inverterIdFilterAtom),
        defaultAtom: inverterIdFilterDefaultAtom,
    },
} as const;

const allTabularTableAtoms = Object.values(tabularTableFilterAtoms).map(({ atom }) => atom) as WritableAtom<
    unknown,
    [typeof RESET | typeof APPLY],
    void
>[];

export const translatedTabularTableFiltersAtom = atom(
    (get) =>
        Object.keys(tabularTableFilterAtoms)
            .filter((key) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const defaultValue: string[] = get(tabularTableFilterAtoms[key].defaultAtom);
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const currentValue: string[] = get(tabularTableFilterAtoms[key].atom);

                if (defaultValue.length === 0 && currentValue.length === 0) {
                    return false;
                }

                return currentValue !== defaultValue;
            })
            .map((key) => ({
                id: key,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                value: get(tabularTableFilterAtoms[key].atom),
            })),
    (_, set) => {
        set(setAllTabularTableFiltersAtom, APPLY);
    },
);

/**
 * An atom that when we modify its value, it dispatches the change to
 * every atom of the Tabular Table Filter Drawer.
 *
 * In other word, RESETing or APPLYing this atom RESETs or APPLYs
 * every atoms of `tabularTableFilterAtoms`
 */
const setAllTabularTableFiltersAtom = atom<null, [typeof RESET | typeof APPLY], void>(null, (_, set, update) => {
    allTabularTableAtoms.forEach((atom) => set(atom, update));
});

const clearAllTabularTableFiltersAtom = atom<null, unknown[], void>(null, (get, set) => {
    Object.values(tabularTableFilterAtoms).forEach(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ({ atom, defaultAtom }) => set(atom, get(defaultAtom)),
    );
});

const populateTabularTableFiltersFromPivotTableAtom = atom<null, unknown[], void>(null, (get, set) => {
    const pivotFilters = get(flatColumnFiltersAtom);

    pivotFilters?.forEach((filter) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        set(tabularTableFilterAtoms[filter.id as keyof typeof tabularTableFilterAtoms].atom, filter.value);
        // Need to APPLY the changes in order for it to take effect
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        set(tabularTableFilterAtoms[filter.id as keyof typeof tabularTableFilterAtoms].atom, APPLY);
    });
});

export const useClearTabularTableFilter = () => useSetAtom(clearAllTabularTableFiltersAtom);

export const useResetTabularTableFilter = () => useResetAtom(setAllTabularTableFiltersAtom);

export const useApplyTabularTableFilter = () => useApplyAtom(setAllTabularTableFiltersAtom);

export const useRemoveTabularTableFilter = (filterId: string) => {
    const { atom } = tabularTableFilterAtoms[filterId as keyof typeof tabularTableFilterAtoms];

    return useApplyAtom(atom);
};

export const usePopulateTabularTableFiltersFromPivotTable = () =>
    useSetAtom(populateTabularTableFiltersFromPivotTableAtom);
