import { useMemo, useState } from 'react';

import styled from '@emotion/styled';
import { Stack, Text, SimpleGrid, NumberInput, TextProps } from '@mantine/core';

import { DEFAULT_SITE_LOSS_VALUES } from 'lib/constants';
import { calculateFinancialLoss } from 'lib/helpers';

import ModalSection from '../shared/ModalSection';
import ModalTitle from '../shared/ModalTitle';
import { PowerLossBreakdown } from '../types';

interface CalculatorProps {
    powerLossBreakdown: PowerLossBreakdown;
}

const Calculator = ({ powerLossBreakdown }: CalculatorProps) => {
    const [apr, setAPR] = useState<number>(DEFAULT_SITE_LOSS_VALUES.APR);
    const [ppaRate, setPPARate] = useState<number>(DEFAULT_SITE_LOSS_VALUES.PPA_RATE);
    const [hurdleRate, setHurdleRate] = useState<number>(DEFAULT_SITE_LOSS_VALUES.HURDLE_FACTOR);
    const [npvDuration, setNPVDuration] = useState<number>(DEFAULT_SITE_LOSS_VALUES.NPV_DURATION);

    const financialLoss = useMemo(
        () =>
            calculateFinancialLoss({
                powerLossBreakdown,
                apr,
                ppaRate,
                hurdleRate,
                npvDuration,
            }),
        [apr, ppaRate, hurdleRate, npvDuration, powerLossBreakdown],
    );

    return (
        <SimpleGrid cols={2} verticalSpacing="lg">
            <NumberInput
                label="Annual Production Ratio"
                value={apr}
                min={0}
                onChange={(value) => setAPR(Number(value))}
                rightSection={<RightSectionText>kWh/kWp</RightSectionText>}
            />
            <NumberInput
                label="Power Purchase Agreement (PPA) Rate"
                value={ppaRate}
                precision={2}
                min={0}
                onChange={(value) => setPPARate(Number(value))}
                rightSection={<RightSectionText>$/kWh</RightSectionText>}
            />
            <NumberInput
                label="Hurdle Rate"
                value={hurdleRate}
                min={0}
                onChange={(value) => setHurdleRate(Number(value))}
                rightSection={<RightSectionText>%</RightSectionText>}
            />
            <NumberInput
                label="Net Present Value (NPV) Duration"
                value={npvDuration}
                min={0}
                onChange={(value) => setNPVDuration(Number(value))}
                rightSection={<RightSectionText>years</RightSectionText>}
            />
            <Stack spacing={0}>
                <Text>Financial loss over lifetime of project:</Text>
                <Text fz="1.25rem" c="var(--color-gray-900, #181A1C)">
                    {financialLoss.totalFinancialLoss}
                </Text>
            </Stack>
        </SimpleGrid>
    );
};

const RightSectionText = styled(Text)<TextProps>({
    transform: 'translateX(-50%)',
});

const FinancialLossCalculator = ({ powerLossBreakdown }: CalculatorProps) => (
    <>
        <ModalTitle title="Financial Loss Calculator" />
        <ModalSection>
            <Text span>
                This calculator enables you to modify the default input parameter values that Zeitview uses to calculate
                financial loss. Details of the calculation are available within the information dialog for the DC LOSSES
                AT SITE.
            </Text>
            <Text span fw={700}>
                {' '}
                Please note that modifications to the input values are not saved to the inspection.
            </Text>
        </ModalSection>

        <Calculator powerLossBreakdown={powerLossBreakdown} />
    </>
);

export default FinancialLossCalculator;
