export const CustomFileIcon = () => (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.75 5.13333C8.75 4.23127 9.46821 3.5 10.3542 3.5H32.8125C33.238 3.5 33.646 3.67208 33.9468 3.97839L46.7802 17.0451C47.081 17.3514 47.25 17.7668 47.25 18.2V50.8667C47.25 51.7687 46.5318 52.5 45.6458 52.5H10.3542C9.46821 52.5 8.75 51.7687 8.75 50.8667V5.13333ZM11.9583 6.76667V49.2333H44.0417V19.8333H32.8125C31.9265 19.8333 31.2083 19.1021 31.2083 18.2V6.76667H11.9583ZM34.4167 9.07655L41.773 16.5667H34.4167V9.07655ZM18.375 21.4667C18.375 20.5646 19.0932 19.8333 19.9792 19.8333H26.3958C27.2818 19.8333 28 20.5646 28 21.4667C28 22.3687 27.2818 23.1 26.3958 23.1H19.9792C19.0932 23.1 18.375 22.3687 18.375 21.4667ZM18.375 31.2667C18.375 30.3646 19.0932 29.6333 19.9792 29.6333H36.0208C36.9068 29.6333 37.625 30.3646 37.625 31.2667C37.625 32.1687 36.9068 32.9 36.0208 32.9H19.9792C19.0932 32.9 18.375 32.1687 18.375 31.2667ZM18.375 41.0667C18.375 40.1646 19.0932 39.4333 19.9792 39.4333H36.0208C36.9068 39.4333 37.625 40.1646 37.625 41.0667C37.625 41.9687 36.9068 42.7 36.0208 42.7H19.9792C19.0932 42.7 18.375 41.9687 18.375 41.0667Z"
            fill="#8D95A2"
        />
    </svg>
);
