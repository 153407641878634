import { FormEvent, useEffect, useState } from 'react';

import { notifications } from '@dronebase/shared-ui-core';
import { Hide, Show } from '@dronebase/shared-ui-icons';
import styled from '@emotion/styled';
import { PasswordInput, Stack, Text, Button, Title } from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';

import { Config } from 'config';
import { MIN_PASSWORD_LENGTH } from 'lib/constants';
import { ROUTES } from 'lib/constants/routes';
import { debounce, validateNewPassword } from 'lib/helpers';
import { fetchData } from 'lib/hooks';

const AUTH_API_URL = Config.get('AUTH_API_URL');

interface FormErrors {
    [key: string]: string | undefined;
}

const UpdatePassword = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [, setSavingPassword] = useState<boolean>(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [formErrors, setFormErrors] = useState<FormErrors>({});

    useEffect(() => {
        if (!params?.resetId) {
            navigate(ROUTES.auth.index);
        }

        const validateResetToken = async () => {
            setSavingPassword(true);
            const { error } = await fetchData({
                url: `${AUTH_API_URL}/password-reset?reset_uuid=${params?.resetId}`,
                method: 'GET',
                body: {},
            });

            setSavingPassword(false);

            if (error) {
                notifications.error({
                    title: 'Your reset token is invalid or expired.',
                    withCloseButton: true,
                    withIcon: true,
                });
                navigate(ROUTES.auth.index);
            }
        };

        validateResetToken();
    }, [navigate, params?.resetId]);

    const setFormError = debounce((key: string, value: string | undefined) => {
        setFormErrors((prev: FormErrors) => ({
            ...prev,
            [key]: value,
        }));
    }, 1000);

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();

        try {
            setSavingPassword(true);
            const { error } = await fetchData({
                url: `${AUTH_API_URL}/password-reset/set-password`,
                method: 'POST',
                body: {
                    reset_uuid: params?.resetId,
                    password,
                },
            });

            setSavingPassword(false);

            if (error) {
                throw error;
            }

            notifications.success({
                title: 'Your password has been reset successfully.',
                withCloseButton: true,
                withIcon: true,
            });
            navigate(ROUTES.auth.index, { replace: true });
        } catch (error) {
            notifications.error({
                title: 'Something went wrong. Please try again later.',
                withCloseButton: true,
                withIcon: true,
            });
        }
    };

    useEffect(() => {
        const passwordError = validateNewPassword(password, confirmPassword);
        const confirmPasswordError = validateNewPassword(confirmPassword, password);

        if (password) {
            setFormError('password', passwordError);
        }

        if (confirmPassword) {
            setFormError('confirmPassword', confirmPasswordError);
        }
    }, [password, confirmPassword, setFormError]);

    return (
        <Stack>
            <Stack spacing="sm" align="center" justify="center">
                <Title order={6} ta="center">
                    Update Password
                </Title>
                <Text variant="body2" c="gray.4">
                    Enter your new password below
                </Text>
            </Stack>
            <StyledForm onSubmit={onSubmit}>
                <Stack>
                    <PasswordInput
                        w="24.5rem"
                        label="Password"
                        name="password"
                        visibilityToggleIcon={({ reveal }) => (reveal ? <Hide /> : <Show />)}
                        error={formErrors.password}
                        value={password}
                        minLength={MIN_PASSWORD_LENGTH}
                        onChange={(e) => setPassword(e.target.value)}
                        autoFocus
                    />
                    <PasswordInput
                        w="24.5rem"
                        label="Confirm Password"
                        name="confirmPassword"
                        visibilityToggleIcon={({ reveal }) => (reveal ? <Hide /> : <Show />)}
                        error={formErrors.confirmPassword}
                        value={confirmPassword}
                        minLength={MIN_PASSWORD_LENGTH}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                </Stack>

                <Button
                    type="submit"
                    variant="filled"
                    size="xl"
                    disabled={!password || !confirmPassword || !!validateNewPassword(password, confirmPassword)}
                >
                    Update Password
                </Button>
            </StyledForm>
        </Stack>
    );
};

export default UpdatePassword;

const StyledForm = styled.form({
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
});
