import { useMemo } from 'react';

import { Info } from '@dronebase/shared-ui-icons';
import styled from '@emotion/styled';
import { Badge, Box, BoxProps, Button, Group, Stack, Text, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import DifferentialsModal from 'components/inspections/AnomalyMap/DifferentialsModal';
import { BackForth } from 'core/Icons/BackForth';
import { PowerIcon } from 'core/Icons/PowerIcon';
import { shortFormatDate } from 'lib/helpers';
import { PowerChangeDirection, useDifferentialMode } from 'lib/hooks/useDifferentialMode';

const DifferentialHeader = () => {
    const [opened, { open, close }] = useDisclosure(false);
    const {
        currentInspectionDate,
        currentReportType,
        previousInspectionDate,
        previousReportType,
        powerChangeDirection,
        percentagePowerLossChange,
    } = useDifferentialMode();

    const percentagePowerChange = useMemo(() => {
        if (powerChangeDirection === PowerChangeDirection.DECREASE) {
            return { text: `- ${percentagePowerLossChange} %`, color: 'red.5' };
        } else if (powerChangeDirection === PowerChangeDirection.INCREASE) {
            return { text: `+ ${percentagePowerLossChange} %`, color: 'green.7' };
        }

        return { text: `${percentagePowerLossChange} %`, color: 'orange.5' };
    }, [powerChangeDirection, percentagePowerLossChange]);

    return (
        <>
            <Group spacing="xs">
                <Text variant="body2" color="gray.1">
                    {shortFormatDate(previousInspectionDate)}
                </Text>
                <Tooltip label={previousReportType} withArrow>
                    <StyledBox>
                        <Info color="var(--color-grey-300)" />
                    </StyledBox>
                </Tooltip>

                <BackForth />
                <Text variant="body2" color="gray.1">
                    {shortFormatDate(currentInspectionDate)}
                </Text>
                <Tooltip label={currentReportType} withArrow>
                    <StyledBox>
                        <Info color="var(--color-grey-300)" />
                    </StyledBox>
                </Tooltip>
            </Group>
            <Stack spacing={0} align="end" pt="0.5rem">
                <Badge
                    color={percentagePowerChange.color}
                    leftSection={<PowerIcon />}
                    variant="filled"
                    fz="0.85rem"
                    size="md"
                    radius="sm"
                >
                    {percentagePowerChange.text}
                </Badge>
                <Button p={0} variant="text" size="md" onClick={open}>
                    See Breakdown
                </Button>
            </Stack>
            <DifferentialsModal isOpen={opened} open={open} close={close} />
        </>
    );
};

export default DifferentialHeader;

const StyledBox = styled(Box)<BoxProps>({
    svg: {
        ':hover': {
            color: 'var(--color-purple-300)',
        },
    },
    display: 'flex',
    width: '1.25rem',
});
