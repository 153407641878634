import { Stack, Text } from '@mantine/core';

import ModalSection from '../../inspections/shared/ModalSection';
import ModalTitle from '../../inspections/shared/ModalTitle';

const StringLevelInfoModal = () => (
    <>
        <ModalTitle title="What's Included In The String Level Inspection?" />
        <ModalSection title="Description">
            <Stack spacing="0.5rem">
                <Text variant="body2">
                    Identification of power losses due to balance of system (BoS) faults. It includes the location of
                    all BoS faults for efficient remediation.
                </Text>
                <Text variant="body2">
                    Using our proprietary HL Insight engine, we capture a high-resolution scan of the site using a
                    combination of IR and RGB visual imagery. It allows us to precisely identify the location, category,
                    and severity of all BoS faults impacting the site.
                </Text>
            </Stack>
        </ModalSection>
        <ModalSection title="Scope of Inspection Service">
            <Stack spacing="0.5rem">
                <Text variant="body2">
                    <ul style={{ listStyleType: 'disc', margin: '0 20px' }}>
                        <li>
                            Summary of power losses and key observations of the site attributed to balance of system
                            (BoS) faults
                        </li>
                        <li>Industry benchmarking of power losses due to BoS faults</li>
                        <li>Identification of the following anomalies:</li>
                        <ul style={{ listStyleType: 'disc', margin: '0 20px' }}>
                            <li>Inverter outages</li>
                            <li>Sectional outages</li>
                            <li>All string-level anomalies</li>
                        </ul>
                        <li>List of modules impacted by anomalies</li>
                        <li>Site schematic with locations of anomalies</li>
                    </ul>
                </Text>
            </Stack>
        </ModalSection>
    </>
);

export default StringLevelInfoModal;
