import { useCallback } from 'react';

import { ArrowDown, ArrowUp } from '@dronebase/shared-ui-icons';
import { ColumnSort, Header, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';

import { SortedBy } from 'components/Sites/types';
import { SORT_DIRECTION } from 'lib/constants';

import TableDataWithTooltip from './TableDataWithTooltip';
import {
    StyledDataRow,
    StyledHeaderGroup,
    StyledHeaderRow,
    StyledTable,
    StyledTableContainer,
    StyledTableHead,
    StyledTableHeader,
} from './TableElements.styled';

type Sort = {
    id: string;
    desc: boolean;
};

export interface TableProps {
    data: unknown[];
    columns: any[];
    handleSort?: (key: string, direction: SortedBy['direction']) => void;
    sort?: Sort | null;
    hasBorder?: boolean;
}

const Table = ({ data, columns, handleSort, sort = null, hasBorder = true }: TableProps) => {
    const table = useReactTable({
        data,
        columns,
        state: {
            sorting: sort ? [sort as ColumnSort] : undefined,
        },
        enableColumnResizing: true,
        columnResizeMode: 'onChange',
        getCoreRowModel: getCoreRowModel(),
    });

    const handleColumnHeaderClick = useCallback(
        (header: Header<unknown, unknown>) => {
            if (header.column.getCanSort()) {
                if (sort) {
                    const isDesc = sort.id === header.id ? !sort.desc : sort.desc;

                    handleSort?.(header.id, isDesc ? SORT_DIRECTION.DESC : SORT_DIRECTION.ASC);
                }
            }
        },
        [sort, handleSort],
    );

    const renderHeader = useCallback(
        (header: Header<unknown, unknown>) => {
            // If column isn't sorted this returns `false`
            const sortDirection = header.column.getIsSorted();
            const columnIsSortable = header.column.getCanSort();
            const headerBackgroundColor = sortDirection ? 'var(--color-grey-50)' : 'var(--color-white)';

            return (
                <StyledTableHeader key={header.id} colSpan={header.colSpan}>
                    {!header.isPlaceholder && (
                        <StyledHeaderGroup
                            onClick={() => handleColumnHeaderClick(header)}
                            style={{
                                color: sortDirection ? 'var(--color-grey-400)' : 'var(--color-grey-300)',
                                background: headerBackgroundColor,
                                cursor: columnIsSortable ? 'pointer' : 'default',
                            }}
                        >
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {columnIsSortable && sortDirection ? (
                                {
                                    asc: <ArrowUp width="0.75rem" />,
                                    desc: <ArrowDown width="0.75rem" />,
                                }[sortDirection]
                            ) : columnIsSortable ? (
                                <ArrowDown width="0.75rem" style={{ visibility: 'hidden' }} />
                            ) : null}
                        </StyledHeaderGroup>
                    )}
                </StyledTableHeader>
            );
        },
        [handleColumnHeaderClick],
    );

    if (!data.length) {
        return null;
    }

    return (
        <StyledTableContainer hasBorder={hasBorder}>
            <StyledTable>
                <StyledTableHead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <StyledHeaderRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => renderHeader(header))}
                        </StyledHeaderRow>
                    ))}
                </StyledTableHead>

                <tbody>
                    {table.getRowModel().rows.map((row) => (
                        <StyledDataRow key={row.id}>
                            {row.getVisibleCells().map((cell) => (
                                <TableDataWithTooltip key={cell.id}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </TableDataWithTooltip>
                            ))}
                        </StyledDataRow>
                    ))}
                </tbody>
            </StyledTable>
        </StyledTableContainer>
    );
};

export default Table;
