import { atom } from 'jotai';

import { InspectionMapViewState, HoverInfo, SelectedAnomalies, Asset } from 'lib/types/inspections/anomaliesMap';

/** Hover Info Anomalies Map */
export const hoverInfoAtom = atom<HoverInfo | undefined>(undefined);

export const hoverInfoAssetIdAtom = atom((get) => {
    const hoverInfo = get(hoverInfoAtom);

    return hoverInfo?.assetId;
});

/** Inspection ID From Params */
export const inspectionIdAtom = atom<string | undefined>(undefined);

/** Anomalies Map ViewState */
const DEFAULT_INSPECTION_MAP_VIEW_STATE: InspectionMapViewState = {
    longitude: 0,
    latitude: 0,
    zoom: 16,
};

export const viewStateAtom = atom<InspectionMapViewState>(DEFAULT_INSPECTION_MAP_VIEW_STATE);

/** Selected/Hovered Asset/Anomalies - Anomalies Map */
export const isAssetSelectedAtom = atom<boolean>(false);

export const selectedAnomaliesAtom = atom<SelectedAnomalies | undefined>(undefined);

export const hoveringAssetAtom = atom<Asset | undefined>(undefined);

export const hoveringAnomaliesAtom = atom<SelectedAnomalies | undefined>(undefined);

export const anomalyTabDropdownInspectionIdAtom = atom<string | null>(null);
