import { Flex } from '@mantine/core';

import { InspectionUpgradeOptions } from 'lib/constants';

import UpgradeOptionsCard from './UpgradeOptionsCard';
import UpgradeOptionsInfoModal from './UpgradeOptionsInfoModal';
import PopoverCard from '../../shared/PopoverCard';
import { UpgradeCosts } from '../../types';

interface UpgradeOptionsProps {
    summary: any;
    upgradeCosts: UpgradeCosts;
}

const UpgradeOptions = ({ upgradeCosts }: UpgradeOptionsProps) => (
    <PopoverCard title="Inspection Upgrade Options" modalContent={<UpgradeOptionsInfoModal />} hasBorder={false}>
        <Flex justify="space-around">
            <UpgradeOptionsCard type={InspectionUpgradeOptions.StringLevel} upgradeCosts={upgradeCosts} />
            <UpgradeOptionsCard type={InspectionUpgradeOptions.ModuleBasic} upgradeCosts={upgradeCosts} />
            <UpgradeOptionsCard type={InspectionUpgradeOptions.ModuleAdvanced} upgradeCosts={upgradeCosts} />
        </Flex>
    </PopoverCard>
);

export default UpgradeOptions;
