import { FormEvent, useEffect, useState } from 'react';

import { Hide, Show } from '@dronebase/shared-ui-icons';
import styled from '@emotion/styled';
import { Button, PasswordInput, Stack, Text, Title } from '@mantine/core';

import { SingleSignOn } from 'components/Auth/SingleSignOn';
import { HorizontalDividerWithLabel } from 'components/common/Divider/HorizontalLabelDivider';
import { MIN_PASSWORD_LENGTH } from 'lib/constants';
import { validateNewPassword } from 'lib/helpers';

const InviteAcceptForm = ({ onSubmit }: { onSubmit: (password?: string | null) => Promise<void> }) => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordFieldError, setPasswordFieldError] = useState('');
    const [confirmPasswordFieldError, setConfirmPasswordFieldError] = useState('');

    useEffect(() => {
        setTimeout(() => {
            const passwordError = validateNewPassword(password, confirmPassword);
            const confirmPasswordError = validateNewPassword(confirmPassword, password);

            if (password && passwordError) {
                setPasswordFieldError(passwordError);
            } else {
                setPasswordFieldError('');
            }

            if (confirmPassword && confirmPasswordError) {
                setConfirmPasswordFieldError(confirmPasswordError);
            } else {
                setConfirmPasswordFieldError('');
            }
        }, 1000);
    }, [password, confirmPassword]);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmit(password);
    };

    return (
        <>
            <Stack align="center">
                <Title order={6} ta="center">
                    Welcome to Zeitview Solar Insights
                </Title>
                <Text variant="body2" ta="center" color="gray.4">
                    Please create and confirm your password.
                </Text>
            </Stack>

            <StyledForm onSubmit={handleSubmit} noValidate>
                <PasswordInput
                    label="Password"
                    placeholder="Enter Password"
                    aria-label="Password"
                    visibilityToggleIcon={({ reveal }) => (reveal ? <Hide /> : <Show />)}
                    name="password"
                    value={password}
                    error={passwordFieldError}
                    onChange={({ target }) => setPassword(target.value)}
                    minLength={MIN_PASSWORD_LENGTH}
                    autoFocus
                />
                <PasswordInput
                    label="Confirm Password"
                    placeholder="Enter Password"
                    aria-label="Confirm Password"
                    visibilityToggleIcon={({ reveal }) => (reveal ? <Hide /> : <Show />)}
                    name="password"
                    value={confirmPassword}
                    error={confirmPasswordFieldError}
                    onChange={({ target }) => setConfirmPassword(target.value)}
                    minLength={MIN_PASSWORD_LENGTH}
                />
                <Button
                    type="submit"
                    variant="filled"
                    size="xl"
                    disabled={!password || !confirmPassword || !!validateNewPassword(password, confirmPassword)}
                >
                    Set Password
                </Button>
            </StyledForm>

            <HorizontalDividerWithLabel label="or" color="gray.1" labelColor="gray.4" />

            <SingleSignOn onSuccessCallback={onSubmit} />
        </>
    );
};

export default InviteAcceptForm;

const StyledForm = styled.form({
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
});
