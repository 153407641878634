import { Flex, Text } from '@mantine/core';

export const Footer = () => (
    <Flex align="center" justify="space-between">
        <Text style={{ fontSize: 'var(--typography-fz-md)', fontWeight: 'var(--typography-fw-bold)' }}>IR Signals</Text>
        <Text style={{ fontSize: 'var(--typography-fz-md)', fontWeight: 'var(--typography-fw-bold)' }}>
            RGB Signals
        </Text>
        <Text style={{ fontSize: 'var(--typography-fz-md)', fontWeight: 'var(--typography-fw-bold)' }}>
            Actions To Take
        </Text>
    </Flex>
);

export default Footer;
