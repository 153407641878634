import styled from '@emotion/styled';
import { Stack } from '@mantine/core';

export const KeyObservationsFiltersContainer = styled(Stack)({
    ':not(:last-child)': {
        borderBottom: `1px solid var(--color-grey-500)`,
    },
});

export const KeyObservationsFiltersSectionContainer = styled.div({
    'paddingBottom': '1rem',
    ':not(:last-child)': {
        borderBottom: `1px solid var(--color-grey-500)`,
    },
});

export const TooltipContentWrapper = styled.div({ display: 'inline-block' });
