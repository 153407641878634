import { MVTLayer } from '@deck.gl/geo-layers/typed';
import { PolygonLayer, TextLayer } from '@deck.gl/layers/typed';
import { ScatterplotLayer } from 'deck.gl/typed';

import { Config } from 'config';
import {
    ASSET_DEFAULT_COLOR,
    HEX_QUANTILE_COLORS,
    HEXMAP_FILL_ALPHA,
    HEXMAP_LINE_ALPHA,
    MAIN_MAP_SITE_FILL_COLOR,
    MAIN_MAP_SITE_OUTLINE_COLOR,
    MINIMAP_SITE_FILL_COLOR,
    MINIMAP_SITE_OUTLINE_COLOR,
    TRANSPARENT_FILL_COLOR,
    VIEWPORT_PATH_COLOR,
    SET_LAYER_OUTLINE_COLOR,
} from 'lib/constants';
import { getCenterPointFromGeometry } from 'lib/helpers';

export const ViewportBackgroundLayer = (viewportBounds, visible = true) =>
    new PolygonLayer({
        id: 'viewport-bounds-1',
        data: viewportBounds,
        pickable: false,
        stroked: true,
        filled: true,
        visible,
        lineWidthMinPixels: 2,
        positionFormat: 'XY',
        getPolygon: (d) => [...d, d[0]],
        getLineColor: VIEWPORT_PATH_COLOR,
        getFillColor: [91, 72, 251, 50],
        getLineWidth: 2,
        widthUnits: 'pixels',
    });

export const MinimapZoomOutline = (viewportBounds, visible = true) =>
    new PolygonLayer({
        id: 'viewport-bounds-2',
        data: viewportBounds,
        pickable: false,
        stroked: true,
        filled: true,
        visible,
        lineWidthMinPixels: 2,
        positionFormat: 'XY',
        getPolygon: (d) => [...d, d[0]],
        getLineColor: VIEWPORT_PATH_COLOR,
        getFillColor: [91, 72, 251, 0],
        getLineWidth: 2,
        widthUnits: 'pixels',
        updateTriggers: {
            getFillColor: visible,
        },
    });

export const ViewportShadeLayer = (viewportBounds, visible = true) =>
    new PolygonLayer({
        id: 'viewport-shade',
        data: viewportBounds,
        pickable: false,
        stroked: false,
        filled: true,
        visible,
        positionFormat: 'XY',
        getPolygon: (d) => [...d, d[0]],
        getFillColor: [0, 0, 0, 0],
        updateTriggers: {
            getFillColor: visible,
        },
    });

export const getAssetMVTURL = (draftVersionUUID, assetTypeUUID) =>
    `${Config.get(
        'DV_TILESERVE_URL',
    )}?filter_draft_version_uuid=${draftVersionUUID}&filter_asset_region_type_uuid=${assetTypeUUID}`;

const getAnomalyColor = (species, irSignalColors, rgbSignalColors) => {
    if (irSignalColors && species?.irFilter !== undefined) {
        return irSignalColors[species.irFilter] ?? ASSET_DEFAULT_COLOR;
    } else if (rgbSignalColors && species?.rgbFilter !== undefined) {
        return rgbSignalColors[species.rgbFilter] ?? ASSET_DEFAULT_COLOR;
    } else {
        return ASSET_DEFAULT_COLOR;
    }
};

export const SetLayer = ({ layerID, draftVersionUUID, assetTypeUUID }) =>
    new MVTLayer({
        id: layerID,
        data: getAssetMVTURL(draftVersionUUID, assetTypeUUID),
        maxRequests: 12,
        minZoom: 17,
        maxZoom: 20,
        pickable: false,
        getFillColor: TRANSPARENT_FILL_COLOR,
        getLineColor: SET_LAYER_OUTLINE_COLOR,
        getLineWidth: 0.25,
        loadOptions: {
            mvt: {
                layerProperty: null,
            },
        },
    });

export const AssetLayer = ({
    layerID,
    draftVersionUUID,
    assetTypeUUID,
    mapAnomalies,
    irSignalColors,
    rgbSignalColors,
    highlightedModuleUuid,
    hoverInfoAssetId,
}) =>
    new MVTLayer({
        id: layerID,
        data: getAssetMVTURL(draftVersionUUID, assetTypeUUID),
        maxRequests: 12,
        minZoom: 17,
        maxZoom: 20,
        pickable: true,
        getFillColor: (d) =>
            getAnomalyColor(mapAnomalies[d.properties.uuid]?.species?.[0], irSignalColors, rgbSignalColors),
        getLineWidth: (d) =>
            d.properties.uuid === highlightedModuleUuid || d.properties.uuid === hoverInfoAssetId ? 0.25 : 0.1,
        updateTriggers: {
            getFillColor: [mapAnomalies, irSignalColors, rgbSignalColors, highlightedModuleUuid],
            getLineWidth: [highlightedModuleUuid, hoverInfoAssetId],
        },
        loadOptions: {
            mvt: {
                layerProperty: null,
            },
        },
    });

export const AnomalyScatterplotLayer = (data, irSignalColors, rgbSignalColors, zoomLevel) =>
    new ScatterplotLayer({
        id: 'scatter-plot-layer',
        data,
        getPosition: (d) => d.position,
        getRadius: () => 1 + (17 - Math.min(17, zoomLevel)) * (2 / Math.log(data.length / 2 + 1.05)),
        getLineColor: [0, 0, 0, 0],
        getLineWidth: 0,
        radiusMinPixels: 3,
        radiusMaxPixels: 6,
        radiusUnits: 'pixels',
        minZoom: 0,
        maxZoom: 20,
        visible: zoomLevel <= 18,
        getFillColor: (d) => getAnomalyColor(d.species[0], irSignalColors, rgbSignalColors),
        updateTriggers: {
            getFillColor: [irSignalColors, rgbSignalColors],
            getRadius: [data, zoomLevel],
        },
    });

export function SiteOutlineLayer(data, id, minimap = true, visible = true) {
    return new PolygonLayer({
        id,
        data,
        pickable: false,
        stroked: true,
        filled: true,
        visible,
        lineWidthMinPixels: 2,
        positionFormat: 'XY',
        getPolygon: (d) => d ?? [],
        getFillColor: minimap ? MINIMAP_SITE_FILL_COLOR : MAIN_MAP_SITE_FILL_COLOR,
        getLineColor: minimap ? MINIMAP_SITE_OUTLINE_COLOR : MAIN_MAP_SITE_OUTLINE_COLOR,
        updateTriggers: {
            getFillColor: minimap,
            getLineColor: minimap,
        },
        getLineWidth: 1,
    });
}

export function HexagonLayer(data, setHoverData) {
    return new PolygonLayer({
        id: 'hex-layer',
        data,
        pickable: true,
        stroked: true,
        filled: true,
        lineWidthMinPixels: 2,
        positionFormat: 'XY',
        getPolygon: (d) => d.aggregationGeometry.geometry.coordinates[0],
        getFillColor: getHexFillColor,
        getLineColor: getHexLineColor,
        onHover: (d) => setHoverData(d),
        updateTriggers: {
            getFillColor: data,
            getLineColor: data,
        },
        getLineWidth: 1,
    });
}

export function HexLabelLayer(data) {
    return new TextLayer({
        id: 'text-layer',
        data,
        getPosition: (d) =>
            getCenterPointFromGeometry({
                type: 'Polygon',
                coordinates: d.aggregationGeometry.geometry.coordinates[0],
            }),
        getText: (d) => String(d.aggregationGeometry.diagnosticId),
        getColor: getHexLineColor,
        getSize: 32,
        getAngle: 0,
        getTextAnchor: 'middle',
        getAlignmentBaseline: 'center',
        updateTriggers: {
            getPosition: data,
            getText: data,
            getColor: data,
        },
    });
}

const getHexLineColor = (d) => [...HEX_QUANTILE_COLORS[d.performanceQuartile], HEXMAP_LINE_ALPHA];
const getHexFillColor = (d) => [...HEX_QUANTILE_COLORS[d.performanceQuartile], HEXMAP_FILL_ALPHA];
