import { AggregatedAnomaly, SankeyLinkData } from 'components/inspections/types';
import { EMPTY_SIGNAL_OR_MODIFIER, NO_IR_SIGNAL, NO_RGB_SIGNAL } from 'lib/constants';
import {
    DEFAULT_FALLBACK_NODE_COLOR,
    DEFAULT_OTHER_NODE_COLOR,
    DEFAULT_REMEDIATION_LEVEL_COLORS,
    SANKEY_AGGREGATION,
} from 'lib/constants/sankey';

export const getSankeyTooltipPowerLoss = (
    aggregation: string,
    payload: any,
    isNode: boolean,
    powerLossMapping: { [key: string]: number },
    powerLossPerModule: number,
) => {
    if (aggregation === SANKEY_AGGREGATION.NUMBER_OF_MODULES) {
        const nodeName = isNode ? payload?.name : payload?.payload?.payload?.name?.split(' - ')[0];
        const powerLossMid = powerLossMapping[nodeName];

        return powerLossMid ? powerLossMid * powerLossPerModule * payload.value : null;
    } else {
        return payload.value;
    }
};

export const getSankeyTooltipModuleCount = (
    aggregation: string,
    payload: any,
    isNode: boolean,
    linkData: SankeyLinkData,
) => {
    if (aggregation === SANKEY_AGGREGATION.NUMBER_OF_MODULES) {
        return payload.value;
    } else {
        return !isNode && getCountFromSankeyLinks(linkData.numberOfModules, payload?.name);
    }
};

export const isIROnly = (anomaly: AggregatedAnomaly) => anomaly.isIRAnomaly && !anomaly.isRGBAnomaly;
export const isRGBOnly = (anomaly: AggregatedAnomaly) => anomaly.isRGBAnomaly && !anomaly.isIRAnomaly;
export const isIRAndRGB = (anomaly: AggregatedAnomaly) => anomaly.isIRAnomaly && anomaly.isRGBAnomaly;

export const generateSankeyNodes = (anomalies: AggregatedAnomaly[], signalColors: any) => {
    const uniqueNodes: any = new Map();

    uniqueNodes.set(NO_RGB_SIGNAL, {
        filter: {
            rgb: [EMPTY_SIGNAL_OR_MODIFIER],
        },
        color: signalColors.rgb[EMPTY_SIGNAL_OR_MODIFIER],
    });

    uniqueNodes.set(NO_IR_SIGNAL, {
        filter: {
            ir: [EMPTY_SIGNAL_OR_MODIFIER],
        },
        color: signalColors.ir[EMPTY_SIGNAL_OR_MODIFIER],
    });

    anomalies.forEach((anomaly: AggregatedAnomaly) => {
        if (anomaly.showAsOther) {
            if (anomaly.isRGBAnomaly) {
                uniqueNodes.set(anomaly.rgbAnomalyName, {
                    filter: {
                        rgb: [anomaly.rgbFilter],
                    },
                    color: signalColors.rgb[anomaly.rgbFilter],
                });
            }

            uniqueNodes.set('Other', {
                filter: {
                    ir: [...(uniqueNodes.get('Other')?.filter?.ir ?? []), anomaly.irFilter],
                },
                color: DEFAULT_OTHER_NODE_COLOR,
            });
        } else if (isIROnly(anomaly)) {
            uniqueNodes.set(anomaly.definitionName, {
                powerLoss: anomaly.powerLoss,
                filter: {
                    ir: [anomaly.irFilter],
                },
                color: signalColors.ir[anomaly.irFilter],
            });
        } else if (isRGBOnly(anomaly)) {
            uniqueNodes.set(anomaly.definitionName, {
                powerLoss: anomaly.powerLoss,
                filter: {
                    rgb: [anomaly.rgbFilter],
                },
                color: signalColors.rgb[anomaly.rgbSignal],
            });
        } else if (isIRAndRGB(anomaly)) {
            uniqueNodes.set(anomaly.irAnomalyName, {
                powerLoss: anomaly.powerLoss,
                filter: {
                    ir: [anomaly.irFilter],
                },
                color: signalColors.ir[anomaly.irFilter],
            });
            uniqueNodes.set(anomaly.rgbAnomalyName, {
                filter: {
                    rgb: [anomaly.rgbFilter],
                },
                color: signalColors.rgb[anomaly.rgbFilter],
            });
        }

        uniqueNodes.set(anomaly.remediationCategory, {
            filter: {
                remediationFilters: [anomaly.priorityLevel.toString()],
            },
            color: DEFAULT_REMEDIATION_LEVEL_COLORS[anomaly.priorityLevel] || DEFAULT_FALLBACK_NODE_COLOR,
        });
    });

    return Array.from(uniqueNodes.keys()).map((node: any) => ({
        name: node,
        powerLoss: uniqueNodes.get(node)?.powerLoss,
        filter: uniqueNodes.get(node)?.filter,
        color: uniqueNodes.get(node)?.color,
    }));
};

export const generatePowerLossMapping = (nodes: any) =>
    nodes.reduce((acc: any, node: any) => {
        acc[node.name] = node.powerLoss;

        return acc;
    }, {});

export const updateRgbPriorityMapping = (
    rgbPriorityMapping: any,
    rgbAnomalyIndex: number,
    remediationCategoryIndex: number,
    anomalyCount: any,
) => {
    const count =
        (rgbPriorityMapping && rgbPriorityMapping[rgbAnomalyIndex]
            ? rgbPriorityMapping[rgbAnomalyIndex][remediationCategoryIndex] || 0
            : 0) + anomalyCount;

    return {
        ...rgbPriorityMapping,
        [rgbAnomalyIndex]: {
            ...rgbPriorityMapping[rgbAnomalyIndex],
            [remediationCategoryIndex]: count,
        },
    };
};

export const getCountFromSankeyLinks = (links: any, name: any) =>
    links.find((link: any) => link.name === name)?.value || 0;

export const updateLinkTotalMapping = (mapping: any, targetNode: number, anomalyCount: any) => {
    const count = (mapping[targetNode] || 0) + anomalyCount;

    return {
        ...mapping,
        [targetNode]: count,
    };
};

export const generateSankeyData = (anomalies: AggregatedAnomaly[], powerLossPerModule: number, signalColors: any) => {
    if (!anomalies || !signalColors) {
        return {
            nodes: [],
            linkData: {
                numberOfModules: [],
                powerLoss: [],
            },
            powerLossMapping: [],
        };
    }

    const linkData: SankeyLinkData = {
        numberOfModules: [],
        powerLoss: [],
    };

    const nodes = generateSankeyNodes(anomalies, signalColors);

    const powerLossMapping = generatePowerLossMapping(nodes);

    const noRgbSignalNodeIndex = nodes.findIndex((node: any) => node.name === NO_RGB_SIGNAL);
    const noIrSignalNodeIndex = nodes.findIndex((node: any) => node.name === NO_IR_SIGNAL);

    const addSankeyLink = (source: number, target: number, name: string, anomaly: AggregatedAnomaly) => {
        linkData.numberOfModules.push({
            source,
            target,
            value: anomaly.modulesImpacted,
            name,
        });

        // Including links that have zero power loss creates divide by zero errors
        if (anomaly.powerLoss > 0) {
            linkData.powerLoss.push({
                source,
                target,
                value: anomaly.powerLoss * powerLossPerModule * anomaly.modulesImpacted,
                name,
            });
        }
    };

    anomalies.forEach((anomaly) => {
        const anomalyNodeIndex = nodes.findIndex((node: any) => node.name === anomaly.definitionName);
        const remediationCategoryIndex = nodes.findIndex((node: any) => node.name === anomaly.remediationCategory);

        if (anomaly.showAsOther) {
            const otherNodeIndex = nodes.findIndex((node: any) => node.name === 'Other');

            if (anomaly.isRGBAnomaly) {
                const rgbAnomalyIndex = nodes.findIndex((node: any) => node.name === anomaly.rgbAnomalyName);
                const name = `Other - ${anomaly.rgbAnomalyName} - ${anomaly.remediationCategory}`;

                addSankeyLink(otherNodeIndex, rgbAnomalyIndex, name, anomaly);
                addSankeyLink(rgbAnomalyIndex, remediationCategoryIndex, name, anomaly);
            } else {
                const name = `Other - ${NO_RGB_SIGNAL} - ${anomaly.remediationCategory}`;

                addSankeyLink(otherNodeIndex, noRgbSignalNodeIndex, name, anomaly);
                addSankeyLink(noRgbSignalNodeIndex, remediationCategoryIndex, name, anomaly);
            }
        } else if (isIROnly(anomaly)) {
            const name = `${anomaly.definitionName} - ${NO_RGB_SIGNAL} - ${anomaly.remediationCategory}`;

            addSankeyLink(anomalyNodeIndex, noRgbSignalNodeIndex, name, anomaly);
            addSankeyLink(noRgbSignalNodeIndex, remediationCategoryIndex, name, anomaly);
        } else if (isRGBOnly(anomaly)) {
            const name = `${NO_IR_SIGNAL} - ${anomaly.definitionName} - ${anomaly.remediationCategory}`;

            addSankeyLink(noIrSignalNodeIndex, anomalyNodeIndex, name, anomaly);
            addSankeyLink(anomalyNodeIndex, remediationCategoryIndex, name, anomaly);
        } else if (isIRAndRGB(anomaly)) {
            const rgbAnomalyIndex = nodes.findIndex((node: any) => node.name === anomaly.rgbAnomalyName);
            const irAnomalyIndex = nodes.findIndex((node: any) => node.name === anomaly.irAnomalyName);
            const name = `${anomaly.irAnomalyName} - ${anomaly.rgbAnomalyName} - ${anomaly.remediationCategory}`;

            addSankeyLink(irAnomalyIndex, rgbAnomalyIndex, name, anomaly);
            addSankeyLink(rgbAnomalyIndex, remediationCategoryIndex, name, anomaly);
        }
    });

    return { nodes, linkData, powerLossMapping };
};

export const getConnectedNodes = (sankeyData: any, linkIndex: number | null) => {
    if (linkIndex === null || !sankeyData) {
        return {};
    }

    const { links, nodes } = sankeyData;

    const connectedLinkIndex = linkIndex % 2 === 0 ? linkIndex + 1 : linkIndex - 1;
    const currentSourceNodeIndex = links[linkIndex]?.source;
    const connectedSourceNodeIndex = links[connectedLinkIndex]?.source;
    const currentSourceNode = nodes[currentSourceNodeIndex];
    const connectedSourceNode = nodes[connectedSourceNodeIndex];

    return {
        currentSourceNode,
        connectedSourceNode,
    };
};
