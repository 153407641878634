import { GET_SITE_NAME } from 'lib/queries';
import { client } from 'lib/urqlClient';

export interface SiteLayoutLoaderData {
    id: string;
    name: string;
    capacity: number;
}

export const siteLayoutLoader = async ({
    params,
}: {
    params: Record<string, string | undefined>;
}): Promise<SiteLayoutLoaderData> => {
    const {
        data: { site },
    } = await client.query(GET_SITE_NAME, { siteId: params.siteId }).toPromise();

    return { id: site.uuid, name: site.name, capacity: site.capacity };
};
