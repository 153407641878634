import styled from '@emotion/styled';
import { Button, Text, TextProps } from '@mantine/core';

export const AcquisitionBanner = () => (
    <BannerContainer>
        <StyledText>Heliolytics is now Zeitview.</StyledText>
        <Button
            variant="link"
            size="medium"
            pt={0}
            pb={0}
            style={{ color: 'var(--color-grey-100)' }}
            onClick={() =>
                window.open(
                    'https://www.businesswire.com/news/home/20230906378784/en/Zeitview-Acquires-Heliolytics-Becoming-the-Market-Leader-in-Solar-Aerial-Inspections',
                )
            }
        >
            Read Press Release
        </Button>
    </BannerContainer>
);

const BannerContainer = styled.div({
    height: '3.5rem',
    padding: '1rem 0.5rem 1rem 1.5rem',
    justifyContent: 'center',
    backgroundColor: 'var(--color-purple-400)',
    display: 'flex',
    gap: '0.25rem',
});

const StyledText = styled(Text)<TextProps>({
    fontSize: '1rem',
    fontWeight: '600',
    color: 'white',
    lineHeight: '150%',
});
