export const GRADE_COLORS: { [grade: string]: string } = {
    A: '#8acb23',
    B: '#f2cb2c',
    C: '#ff7f00',
    D: '#de0000',
};

export const QUARTILE_RANGE: { [grade: string]: string } = {
    A: '0 - 25%',
    B: '25 - 50%',
    C: '50 - 75%',
    D: '75 - 100%',
};

export const PERFORMANCE_RANGE_TABLE_COLUMNS = ['quartile', 'grade', 'bos', 'major', 'minor'];
