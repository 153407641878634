import { bbox, bboxPolygon } from '@turf/turf';
// eslint-disable-next-line import/no-unresolved
import { BBox, Geometry } from 'geojson';
import { AttributionControl, Layer, LngLatBoundsLike, Map, Source } from 'react-map-gl';

import { MAPBOX_STATIC_BASE_URL, MAPBOX_TOKEN, SAT_MAP_STYLE, SITE_CARD_DISPLAY_PROPERTIES } from 'lib/constants';

function roundTo(value: number, precision: number) {
    return Math.round(value * 10 ** precision) / 10 ** precision;
}

function expandBoundingBox(boundingBox: BBox, expansionAmount: number): LngLatBoundsLike {
    const extentX = boundingBox[2] - boundingBox[0];
    const extentY = boundingBox[3] - boundingBox[1];

    return [
        boundingBox[0] - extentX * expansionAmount,
        boundingBox[1] - extentY * expansionAmount,
        boundingBox[2] + extentX * expansionAmount,
        boundingBox[3] + extentY * expansionAmount,
    ];
}

export function getStaticMapboxImageURL(siteOutline: any, sizeX: number, sizeY: number) {
    const siteBoundingBox = bbox(siteOutline);
    const imageBounds: any = expandBoundingBox(siteBoundingBox, 0.05);
    // We do rounding to save on URL length and increas our chance of being able to show a polygon
    const roundedBox = imageBounds.map((value: number) => roundTo(value, 6));
    const roundedCoordinates = siteOutline.coordinates.map((polygon: any) => [
        polygon[0].map((coordinate: number[]) => [roundTo(coordinate[0], 6), roundTo(coordinate[1], 6)]),
    ]);
    const siteGeoJSON = {
        type: 'Feature',
        properties: SITE_CARD_DISPLAY_PROPERTIES,
        geometry: {
            type: 'MultiPolygon',
            coordinates: roundedCoordinates,
        },
    };
    const urlSuffix = `[${roundedBox}]/${sizeX}x${sizeY}?access_token=${MAPBOX_TOKEN}`;

    let url = `${MAPBOX_STATIC_BASE_URL}/geojson(${encodeURIComponent(JSON.stringify(siteGeoJSON))})/${urlSuffix}`;

    if (url.length > 8192) {
        // https://docs.mapbox.com/api/overview/#url-length-limits
        // Fallback to a box around the site when the URL exceeds the max length due to complexity
        // We want to try to render the full multi-polygon as it better shows the site contours
        // The bounding box will simply be a box around the site and should only be used as a last resort
        const boundingGeometry = bboxPolygon(siteBoundingBox);

        boundingGeometry.properties = SITE_CARD_DISPLAY_PROPERTIES;
        url = `${MAPBOX_STATIC_BASE_URL}/geojson(${encodeURIComponent(JSON.stringify(boundingGeometry))})/${urlSuffix}`;
    }

    return url;
}

export const SiteMap = ({ siteOutline }: { siteOutline: Geometry }) => (
    <Map
        initialViewState={{
            bounds: expandBoundingBox(bbox(siteOutline), 0.05),
        }}
        style={{ borderRadius: '10px' }}
        mapStyle={SAT_MAP_STYLE}
        attributionControl={false}
        interactive={false}
        onRender={(event) => event.target.resize()}
        maxZoom={18}
        mapboxAccessToken={MAPBOX_TOKEN}
    >
        <Source
            id="my-data"
            type="geojson"
            data={{
                type: 'Feature',
                geometry: siteOutline,
                properties: {},
            }}
        >
            <Layer
                id="point"
                type="line"
                paint={{
                    'line-width': 1,
                    'line-color': '#F30709',
                }}
            />
        </Source>
        <AttributionControl
            compact={true}
            style={{
                backgroundColor: 'rgba(255,255,255,0.5)',
                backdropFilter: 'blur(4px)',
            }}
        />
    </Map>
);
