import { useEffect, useMemo, useState } from 'react';

import { useDisclosure } from '@mantine/hooks';
import { useRouteLoaderData } from 'react-router-dom';

import { InspectionLoaderData } from 'components/inspections/types';
import { SiteLayoutLoaderData } from 'components/Layout/SiteLayout';
import { DIFFERENTIAL_MODES, REPORT_TYPES } from 'lib/constants';
import { buildModuleSummary, buildPowerSummary } from 'lib/helpers/differentials';
import createCustomHook from 'lib/helpers/hookFactory';

export enum PowerChangeDirection {
    INCREASE,
    DECREASE,
    EQUAL,
}

const ValueProvider = () => {
    const [mode, setMode] = useState(DIFFERENTIAL_MODES.PRESENT);
    const [active, { close: deactivateDifferentialMode, open: activateDifferentialMode }] = useDisclosure(false);

    const currentInspectionData = useRouteLoaderData('inspection') as InspectionLoaderData;
    const [differentialModeInspectionId, setDifferentialModeInspectionId] = useState<string | null>(null);
    const siteDetails = useRouteLoaderData('site-details') as SiteLayoutLoaderData;

    const currentInspectionDate = currentInspectionData?.inspectionDate;
    const currentReportType = currentInspectionData?.reportType;
    const currentInspectionSummary = currentInspectionData?.summary;
    const previousInspectionDate = currentInspectionData?.previousInspection?.publishDate;
    const previousReportType = currentInspectionData?.previousInspection?.reportType;
    const previousInspectionSummary = currentInspectionData?.previousInspection;
    const previousReportTier = currentInspectionData?.previousInspectionReportTier;

    const hasDifferentialReports =
        currentInspectionData &&
        currentInspectionData.hasDifferentialApplication &&
        currentInspectionData.reportTier === 3 &&
        currentInspectionData.reportType !== REPORT_TYPES.DIAGNOSTIC_REPORT &&
        currentInspectionData.reportType !== REPORT_TYPES.GLASS_CRACK &&
        previousInspectionDate &&
        previousReportType !== REPORT_TYPES.DIAGNOSTIC_REPORT &&
        previousReportType !== REPORT_TYPES.GLASS_CRACK &&
        previousReportTier === 3;

    const powerSummaryData = useMemo(
        () => buildPowerSummary(currentInspectionData, siteDetails?.capacity),
        [currentInspectionData, siteDetails],
    );
    const modulesSummaryData = useMemo(() => buildModuleSummary(currentInspectionData), [currentInspectionData]);
    const { previousInspection, currentInspection } = powerSummaryData;

    const percentagePowerLossChange = useMemo(
        () =>
            ((Math.abs(previousInspection.powerLossPercent - currentInspection.powerLossPercent) * 100) / 100).toFixed(
                2,
            ),
        [previousInspection, currentInspection],
    );

    const powerChangeDirection = useMemo(() => {
        if (
            powerSummaryData?.previousInspection.powerLossPercent > powerSummaryData?.currentInspection.powerLossPercent
        ) {
            return PowerChangeDirection.INCREASE;
        } else if (
            powerSummaryData?.previousInspection.powerLossPercent < powerSummaryData?.currentInspection.powerLossPercent
        ) {
            return PowerChangeDirection.DECREASE;
        } else {
            return PowerChangeDirection.EQUAL;
        }
    }, [powerSummaryData]);

    useEffect(() => {
        if (!active) {
            setMode(DIFFERENTIAL_MODES.PRESENT);
        }
    }, [active]);

    const value = useMemo(
        () => ({
            mode,
            active,
            powerChangeDirection,
            percentagePowerLossChange,
            currentInspectionDate,
            currentInspectionSummary,
            currentReportType,
            previousInspectionDate,
            previousReportType,
            previousInspectionSummary,
            hasDifferentialReports,
            powerSummaryData,
            modulesSummaryData,
            setMode,
            activateDifferentialMode,
            deactivateDifferentialMode,
            differentialModeInspectionId,
            setDifferentialModeInspectionId,
        }),
        [
            mode,
            active,
            powerChangeDirection,
            percentagePowerLossChange,
            currentInspectionDate,
            currentInspectionSummary,
            currentReportType,
            previousInspectionDate,
            previousReportType,
            previousInspectionSummary,
            hasDifferentialReports,
            powerSummaryData,
            modulesSummaryData,
            setMode,
            activateDifferentialMode,
            deactivateDifferentialMode,
            differentialModeInspectionId,
            setDifferentialModeInspectionId,
        ],
    );

    return value;
};

const { Provider: DifferentialModeProvider, useCustomHook: useDifferentialMode } = createCustomHook<any>({
    name: 'useDifferentialMode',
    callback: ValueProvider,
});

export { DifferentialModeProvider, useDifferentialMode };
