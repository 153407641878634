import { useState } from 'react';

import { ChevronDown, ChevronUp } from '@dronebase/shared-ui-icons';
import styled from '@emotion/styled';
import { ActionIcon, Text } from '@mantine/core';

import KeyObservationGallery from './KeyObservationImageGallery';
import { Observation } from '../types';

interface KeyObservationRowProps {
    observation: Observation;
    onClick: (e: string) => void;
}

const KeyObservationRow = ({ observation, onClick }: KeyObservationRowProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const { images } = observation;

    return (
        <>
            <StyledTableRow>
                <td>
                    <Text
                        style={{ cursor: 'pointer' }}
                        color="var(--color-purple-400)"
                        onClick={() => onClick(observation.id)}
                    >
                        {observation.id}
                    </Text>
                </td>
                <td>
                    <Text style={{ textWrap: 'wrap', whiteSpace: 'break-spaces' }}>{observation.description}</Text>
                </td>
                <td>
                    <ActionIcon onClick={() => setIsOpen(!isOpen)} style={{ padding: '0', minHeight: '1rem' }}>
                        {isOpen ? <ChevronUp /> : <ChevronDown />}
                    </ActionIcon>
                </td>
            </StyledTableRow>
            {isOpen && images && <KeyObservationGallery images={images} />}
        </>
    );
};

const StyledTableRow = styled.tr({
    verticalAlign: 'top',
});

export default KeyObservationRow;
