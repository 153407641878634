import { Button } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import InviteUserDrawer from 'components/Users/WorkspaceUsers/InviteUserDrawer';

import { PageHeader } from './PageHeader';

export const UsersPageHeader = () => {
    const [inviteUsersDrawerOpen, { open, close }] = useDisclosure(false);

    const InviteUserButton = () => (
        <Button variant="filled" size="md" onClick={open}>
            Invite User
        </Button>
    );

    return (
        <>
            <PageHeader title="Users" flexEndContent={<InviteUserButton />} />
            <InviteUserDrawer isOpen={inviteUsersDrawerOpen} close={close} />
        </>
    );
};
