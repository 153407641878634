import { ReactNode, createContext, useContext } from 'react';

import { PaginationProps } from '@dronebase/shared-ui-core';
import { useLoaderData } from 'react-router-dom';

import { useManualPagination } from 'lib/hooks/useManualPagination';
import { WorkspaceInvitesData, useWorkspaceInvites } from 'lib/hooks/useWorkspaceInvites';
import useWorkspaceUsers, { WorkspaceUsersData } from 'lib/hooks/useWorkspaceUsers';
import { WorkspaceUsersLoader } from 'lib/loaders/usersLoader';
import { UserPermission, WorkspaceApp, WorkspaceInvite } from 'lib/types';

export type User = {
    uuid: string;
    email: string;
    createdAt: string;
    lastModified: string;
    permissions: UserPermission[];
};

export interface UsersContextProps extends WorkspaceUsersData, WorkspaceInvitesData {
    workspaceApps: WorkspaceApp[];
    defaultPermissionIDs: string[];
    sortedFilteredPaginatedUsers: User[];
    paginatedInvites: WorkspaceInvite[];
    userPaginationProps: PaginationProps;
    invitesPaginationProps: PaginationProps;
}

export const UsersContext = createContext<UsersContextProps | null>(null);

export const UsersProvider = ({ children }: { children: ReactNode }) => {
    const { workspaceUsers, workspaceApps, defaultPermissionIDs } = useLoaderData() as WorkspaceUsersLoader;
    const workspaceUserData = useWorkspaceUsers({ workspaceUsers });
    const workspaceInvitesData = useWorkspaceInvites();

    const { paginatedData: sortedFilteredPaginatedUsers, paginationProps: userPaginationProps } = useManualPagination(
        workspaceUserData.sortedFilteredUsers,
    );
    const { paginatedData: paginatedInvites, paginationProps: invitesPaginationProps } = useManualPagination(
        workspaceInvitesData.invites,
    );

    return (
        <UsersContext.Provider
            value={{
                workspaceApps,
                defaultPermissionIDs,
                userPaginationProps,
                invitesPaginationProps,
                sortedFilteredPaginatedUsers,
                paginatedInvites,
                ...workspaceUserData,
                ...workspaceInvitesData,
            }}
        >
            {children}
        </UsersContext.Provider>
    );
};

export const useUsersContext = () => {
    const context = useContext(UsersContext);

    if (!context) {
        throw new Error('useUsersContext must be used within a UsersProvider');
    }

    return context;
};
