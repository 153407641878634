import { useCallback } from 'react';

import { notifications } from '@dronebase/shared-ui-core';
import fileDownload from 'js-file-download';

const useDownloadImage = () => {
    const download = useCallback(async (url: string) => {
        try {
            const response = await fetch(url);

            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }

            const blob = await response.blob();

            const fileName = url.split('/').pop();
            const fileExtension = url.split('.').pop();

            fileDownload(blob, fileName ?? `image.${fileExtension ?? 'jpg'}`);
        } catch (error) {
            notifications.error({
                title: 'Download Failed',
                message: 'Failed to download image',
                withCloseButton: true,
                withIcon: true,
            });
        }
    }, []);

    return download;
};

export default useDownloadImage;
