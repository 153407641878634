import { useMemo, useState } from 'react';

import { Info } from '@dronebase/shared-ui-icons';
import { Popover, ActionIcon, Text } from '@mantine/core';

import { getOrdinal, getPositionIdLocationInstructions } from 'lib/inspections/positionId';
import { DarkThemeProvider } from 'providers/DarkThemeProvider';
import { LightThemeProvider } from 'providers/LightThemeProvider';

import { StyledTable, StyledRow, StyledOrdinalCell, StyledPositionDescriptionCell } from './PositionIdPopover.styled';

export const PositionIDPopover = ({ positionId }: { positionId: string }) => {
    const positionIdLocationInstructions = useMemo(() => getPositionIdLocationInstructions(positionId), [positionId]);
    const [opened, setOpened] = useState(false);

    return (
        <LightThemeProvider>
            <Popover
                position="bottom-end"
                opened={opened}
                onChange={setOpened}
                styles={{ dropdown: { border: 'none' } }}
            >
                <DarkThemeProvider>
                    <Popover.Target>
                        <ActionIcon
                            size="sm"
                            variant="filled"
                            p={0}
                            aria-pressed={opened}
                            onClick={() => setOpened((o) => !o)}
                        >
                            <Info color="var(--color-grey-300)" />
                        </ActionIcon>
                    </Popover.Target>
                </DarkThemeProvider>
                <Popover.Dropdown p={0}>
                    <StyledTable>
                        <tbody>
                            {positionIdLocationInstructions.map((instruction) => (
                                <StyledRow key={instruction.direction}>
                                    <StyledOrdinalCell>
                                        <Text variant="caption1" c="gray.9" ta="right">
                                            {instruction.value}
                                            <sup>{getOrdinal(instruction.value)}</sup>
                                        </Text>
                                    </StyledOrdinalCell>
                                    <StyledPositionDescriptionCell>
                                        <Text variant="caption1" c="gray.4">
                                            {instruction.direction}
                                        </Text>
                                    </StyledPositionDescriptionCell>
                                </StyledRow>
                            ))}
                        </tbody>
                    </StyledTable>
                </Popover.Dropdown>
            </Popover>
        </LightThemeProvider>
    );
};
