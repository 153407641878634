import { useEffect, useState } from 'react';

import { Stack, Text } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

import { LinkButton } from 'components/common/Buttons/LinkButton';
import NoResultsIcon from 'components/common/Icons/NoResultsIcon';
import { SITES_ROLE } from 'core/constants';
import { ROUTES } from 'lib/constants/routes';
import { getCookie } from 'lib/cookies';
import { getAppPermissions } from 'lib/helpers';
import useFirebaseAuth from 'lib/hooks/useFirebaseAuth';

const ERROR_TEXT_MAP = {
    404: "Sorry, that page doesn't exist!",
    ERROR: 'An error occurred!',
    ERROR_NO_PERMISSION: 'An error occurred, please contact your workspace admin!',
};

interface ErrorProps {
    type?: 'ERROR' | '404';
    label?: string;
    showRedirect?: boolean;
    checkAuth?: boolean; // Optional prop for authentication check
}

const Error = ({ type = 'ERROR', label = '', showRedirect = true, checkAuth = false }: ErrorProps) => {
    const navigate = useNavigate();
    const [displayText, setDisplayText] = useState('');
    const { authUser, loading } = useFirebaseAuth();

    useEffect(() => {
        if (checkAuth && !authUser && !loading) {
            navigate(ROUTES.auth.index);

            return;
        }

        if (label) {
            setDisplayText(label);
        } else if (type === 'ERROR') {
            const hasAppPermission = getAppPermissions(SITES_ROLE, false);
            const workspace = getCookie('helio-workspace');

            if (hasAppPermission && workspace) {
                setDisplayText(ERROR_TEXT_MAP.ERROR);
            } else if (workspace) {
                setDisplayText(ERROR_TEXT_MAP.ERROR_NO_PERMISSION);
            } else {
                navigate(ROUTES.auth.select_workspace);

                return;
            }
        } else {
            setDisplayText(ERROR_TEXT_MAP[404]);
        }
    }, [authUser, label, navigate, type, checkAuth, loading]);

    if (!displayText) {
        return null;
    }

    return (
        <Stack h="100%" spacing="0.25rem" align="center" justify="center">
            <NoResultsIcon />
            <Text variant="subtitle1" c="gray.9">
                {displayText}
            </Text>
            {showRedirect && (
                <LinkButton to="/sites" size="xl">
                    Return to viewing all sites
                </LinkButton>
            )}
        </Stack>
    );
};

export default Error;
