import { useEffect, useState } from 'react';

import { Loader, MultiSelect } from '@dronebase/shared-ui-core';
import styled from '@emotion/styled';
import { Group, Text } from '@mantine/core';
import { useRouteLoaderData } from 'react-router-dom';

import { InspectionLoaderData } from 'components/inspections/types';
import {
    ALL_REMEDIATION_LEVELS,
    REMEDIATION_LEVEL_1,
    REMEDIATION_LEVEL_2,
    REMEDIATION_LEVEL_3,
    REPORT_TYPES,
} from 'lib/constants/inspections';
import { useAnomalyOverviewFilters } from 'lib/hooks';

import AggregationFilter from './AggregationFilter';

const REMEDIATION_CATEGORY_OPTIONS = [
    { value: REMEDIATION_LEVEL_1, label: REMEDIATION_LEVEL_1 },
    { value: REMEDIATION_LEVEL_2, label: REMEDIATION_LEVEL_2 },
    { value: REMEDIATION_LEVEL_3, label: REMEDIATION_LEVEL_3 },
];

const SankeyFilters = () => {
    const { fetching, remediationCategoryFilter, setRemediationCategoryFilter } = useAnomalyOverviewFilters();
    const { reportType } = useRouteLoaderData('inspection') as InspectionLoaderData;
    const [remediationCategoryDisplayValue, setRemediationCategoryDisplayValue] = useState<string>('All');

    useEffect(() => {
        if (ALL_REMEDIATION_LEVELS.every((category: string) => remediationCategoryFilter.includes(category))) {
            setRemediationCategoryDisplayValue('All');
        } else {
            setRemediationCategoryDisplayValue(remediationCategoryFilter.join(', '));
        }
    }, [remediationCategoryFilter]);

    if (fetching) {
        return <Loader />;
    }

    return (
        <StyledFilterGroup>
            <AggregationFilter />
            <StyledRemediationGroup>
                <Text fz="0.75rem">Priority</Text>
                <MultiSelect
                    w="15rem"
                    maxVisibleItems={0}
                    placeholder={remediationCategoryDisplayValue}
                    onChange={setRemediationCategoryFilter}
                    data={REMEDIATION_CATEGORY_OPTIONS}
                    value={remediationCategoryFilter}
                    disabled={reportType === REPORT_TYPES.FSX}
                />
            </StyledRemediationGroup>
        </StyledFilterGroup>
    );
};

const StyledRemediationGroup = styled(Group)({
    justifyContent: 'center',
    alignContent: 'center',
    gap: '0.625rem',
    flexGrow: 1,
});

const StyledFilterGroup = styled(Group)({
    justifyContent: 'center',
});

export default SankeyFilters;
