import { useCallback, useEffect, useMemo, useState } from 'react';

import { LinearInterpolator, MapView, WebMercatorViewport } from '@deck.gl/core';
import DeckGL from '@deck.gl/react';
import styled from '@emotion/styled';
import { Button, Paper, Stack, Title, Radio, Text } from '@mantine/core';
import { Map } from 'react-map-gl';
import { useQuery } from 'urql';

import { MAPBOX_TOKEN, SAT_MAP_STYLE } from 'lib/constants';
import { GET_HEX_DATA_BY_SUPER_REPORT_TASK, GET_SITE_GEOMETRY } from 'lib/queries';

import { StatsViewer } from './DiagnosticReportMapStatsViewer';
import { HexagonLayer, HexLabelLayer, SiteOutlineLayer } from '../../AnomalyMap/deckglLayers';

const anomTypeOptions = [
    { key: 'Balance of System', text: 'BOS' },
    { key: 'Major', text: 'Major Module' },
    { key: 'Minor', text: 'Minor Module' },
];

// Starting point at the center of NA, zooms to the current site geometry when query completes
const siteLocation = { longitude: -105.2551, latitude: 54.526 };
const INITIAL_VIEW_STATE = {
    longitude: siteLocation.longitude,
    latitude: siteLocation.latitude,
    zoom: 16,
    pitch: 0,
    bearing: 0,
};

function DiagnosticHexMap(props) {
    // This should be replaced with a query once the honeybird backend is up and running
    const [viewState, setViewState] = useState(INITIAL_VIEW_STATE);
    const [hoverData, setHoverData] = useState();
    const [selectedAnomType, setSelectedAnomType] = useState('Balance of System');
    const [siteBounds, setSiteBounds] = useState();

    const mainView = useMemo(() => new MapView({ id: 'main', controller: true }), []);

    const [siteOutlineResults] = useQuery({
        query: GET_SITE_GEOMETRY,
        variables: {
            siteUuid: props.siteUuid,
        },
    });

    const [hexDataResult] = useQuery({
        query: GET_HEX_DATA_BY_SUPER_REPORT_TASK,
        variables: { superReportTaskUuid: props.superReportTaskUuid },
    });

    const hexData = useMemo(() => {
        if (hexDataResult.data) {
            return hexDataResult.data.diagnosticAnomalyAggregations.filter(
                (aggResult) => aggResult.definition.globalDefinition.nameExternal === selectedAnomType,
            );
        }
    }, [hexDataResult.data, selectedAnomType]);

    const siteGeometry = useMemo(() => siteOutlineResults.data?.site.geometry, [siteOutlineResults]);

    const handleViewStateChange = ({ viewState: newViewState }) => {
        if (siteBounds) {
            newViewState.longitude = Math.min(siteBounds.maxLon, Math.max(siteBounds.minLon, newViewState.longitude));
            newViewState.latitude = Math.min(siteBounds.maxLat, Math.max(siteBounds.minLat, newViewState.latitude));
        }

        setViewState((prev) => ({
            longitude: prev.longitude,
            latitude: prev.latitude,
            zoom: prev.zoom,
            height: prev.height,
            width: prev.width,
            minZoom: prev.minZoom,
            maxZoom: prev.maxZoom,
            ...newViewState,
        }));
    };

    useEffect(() => {
        if (viewState.height && viewState.width && siteBounds) {
            handleResetViewportClick();
        }
    }, [viewState.height && viewState.width && siteBounds]);

    const handleResetViewportClick = useCallback(() => {
        const viewport = new WebMercatorViewport(viewState);
        const fullSiteView = viewport.fitBounds(
            [
                [siteBounds.minLon, siteBounds.minLat],
                [siteBounds.maxLon, siteBounds.maxLat],
            ],
            { padding: 60 },
        );

        handleViewStateChange({
            viewState: {
                longitude: fullSiteView.longitude,
                latitude: fullSiteView.latitude,
                zoom: fullSiteView.zoom,
                pitch: 0,
                bearing: 0,
                minZoom: 10,
                transitionDuration: 500,
                transitionInterpolator: new LinearInterpolator(),
            },
        });
    }, [siteBounds, viewState.height, viewState.width]);

    useEffect(() => {
        if (siteGeometry) {
            if (siteGeometry.coordinates && siteGeometry.coordinates[0]) {
                setSiteBounds(
                    siteGeometry.coordinates.flat(2).reduce(
                        (bounds, latLon) => ({
                            minLon: Math.min(latLon[0], bounds.minLon),
                            maxLon: Math.max(latLon[0], bounds.maxLon),
                            minLat: Math.min(latLon[1], bounds.minLat),
                            maxLat: Math.max(latLon[1], bounds.maxLat),
                        }),
                        {
                            minLon: 180,
                            maxLon: -180,
                            minLat: 180,
                            maxLat: -180,
                        },
                    ),
                );
            }
        }
    }, [siteGeometry]);

    return (
        <MapContainer>
            {siteBounds && (
                <DeckGL
                    controller={{
                        doubleClickZoom: false,
                        touchRotate: false,
                        touchZoom: true,
                        dragRotate: false,
                    }}
                    viewState={viewState}
                    layers={[
                        HexagonLayer(hexData, (obj) => setHoverData(obj?.object ?? {})),
                        HexLabelLayer(hexData),
                        SiteOutlineLayer(siteGeometry?.coordinates ?? [], 'main-site-outline', false),
                    ]}
                    style={{ zIndex: 1 }}
                    onViewStateChange={handleViewStateChange}
                    views={[mainView]}
                    parameters={{ depthTest: false }}
                >
                    <MapView id="main" />
                    <AnomalyTypeSelectionContainer>
                        <Stack align="end" justify="end">
                            <Button
                                bg="var(--color-white)"
                                onClick={handleResetViewportClick}
                                disabled={!siteGeometry}
                                variant="outline"
                                w="max-content"
                                size="lg"
                            >
                                Reset View
                            </Button>
                            <ChoiceGroupContainer>
                                <Stack spacing="0.5rem">
                                    <Title order={6}>Anomaly Type</Title>
                                    <Radio.Group
                                        value={selectedAnomType}
                                        onChange={setSelectedAnomType}
                                        label={
                                            <Text pb="0.5rem" variant="body2light" color="gray.9">
                                                Hover over a hexagon for more info.
                                            </Text>
                                        }
                                        orientation="vertical"
                                    >
                                        {anomTypeOptions.map((option) => (
                                            <Radio
                                                py="0.35rem"
                                                key={option.key}
                                                value={option.key}
                                                label={option.text}
                                                styles={{
                                                    label: { ':hover': { cursor: 'pointer' } },
                                                    radio: { ':hover': { cursor: 'pointer' } },
                                                }}
                                            />
                                        ))}
                                    </Radio.Group>
                                </Stack>
                            </ChoiceGroupContainer>
                            <StatsViewer
                                isHover={!!hoverData?.uuid}
                                areaId={hoverData?.aggregationGeometry?.diagnosticId}
                                grade={String(hoverData?.performanceQuartile)}
                                percentFailure={
                                    hoverData?.affectedModuleCount && hoverData?.modulesAnalyzed > 0
                                        ? hoverData?.affectedModuleCount / hoverData?.modulesAnalyzed
                                        : 0
                                }
                            />
                        </Stack>
                    </AnomalyTypeSelectionContainer>
                    <Map mapStyle={SAT_MAP_STYLE} mapboxAccessToken={MAPBOX_TOKEN} />
                </DeckGL>
            )}
        </MapContainer>
    );
}

export default DiagnosticHexMap;

const AnomalyTypeSelectionContainer = styled.div({
    padding: '0.75rem',
    gap: '1.5rem',
    position: 'absolute',
    background: 'transparent',
    right: '0.5rem',
});

const ChoiceGroupContainer = styled(Paper)({
    boxShadow: '2px 2px 20px 0px rgba(0, 0, 0, 0.10)',
    backgroundColor: 'var(--color-white)',
    borderRadius: '5px',
    padding: '0.75rem',
    width: '12rem',
});

const MapContainer = styled.div({
    'position': 'relative',
    'width': '100%',
    'height': '40rem',
    'margin': 'auto',
    '.mapboxgl-map': {
        borderRadius: '0.5rem',
    },
    'canvas': {
        borderRadius: '0.5rem',
    },
});
