import { Group, Text } from '@mantine/core';

import { CustomFileIcon } from 'components/common/Icons/CustomFileIcon';
import { ExcelFileIcon } from 'components/common/Icons/ExcelFileIcon';
import { ImageFileIcon } from 'components/common/Icons/ImageFileIcon';
import { PDFFileIcon } from 'components/common/Icons/PDFFileIcon';

export type FileDownload = {
    name: string;
    type: string;
    url: string;
};

interface DownloadItemProps {
    file: FileDownload;
}

const FILE_ICONS = [
    { type: ['pdf'], icon: <PDFFileIcon /> },
    { type: ['xls', 'xlsx', 'csv'], icon: <ExcelFileIcon /> },
    { type: ['jpg', 'jpeg', 'png'], icon: <ImageFileIcon /> },
];

const FileItem = ({ file }: DownloadItemProps) => (
    <Group ta="center" position="center" w="9rem">
        <a href={file.url} target="_blank" rel="noreferrer noopener" download>
            {FILE_ICONS.find((fileIcons) => fileIcons.type.includes(file.type.toLowerCase()))?.icon ?? (
                <CustomFileIcon />
            )}
            <Text fz="0.875rem">{file.name}</Text>
        </a>
    </Group>
);

export default FileItem;
