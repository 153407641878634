import { Dispatch, useMemo, useState } from 'react';

import createCustomHook from 'lib/helpers/hookFactory';

export type HighlightedAssetUuid = string | undefined;

export interface HighlightedAssetsContextType {
    highlightedModuleUuid: HighlightedAssetUuid;
    highlightedInverterUuid: HighlightedAssetUuid;
    highlightedCombinerUuid: HighlightedAssetUuid;
    highlightedStackLayerUuid: HighlightedAssetUuid;
    setHighlightedModuleUuid: Dispatch<React.SetStateAction<HighlightedAssetUuid>>;
    setHighlightedInverterUuid: Dispatch<React.SetStateAction<HighlightedAssetUuid>>;
    setHighlightedCombinerUuid: Dispatch<React.SetStateAction<HighlightedAssetUuid>>;
    setHighlightedStackLayerUuid: Dispatch<React.SetStateAction<HighlightedAssetUuid>>;
}

const ValueProvider = () => {
    const [highlightedModuleUuid, setHighlightedModuleUuid] = useState<HighlightedAssetUuid>();
    const [highlightedInverterUuid, setHighlightedInverterUuid] = useState<HighlightedAssetUuid>();
    const [highlightedCombinerUuid, setHighlightedCombinerUuid] = useState<HighlightedAssetUuid>();
    const [highlightedStackLayerUuid, setHighlightedStackLayerUuid] = useState<HighlightedAssetUuid>();

    const value = useMemo(
        () => ({
            highlightedModuleUuid,
            highlightedInverterUuid,
            highlightedCombinerUuid,
            highlightedStackLayerUuid,
            setHighlightedModuleUuid,
            setHighlightedInverterUuid,
            setHighlightedCombinerUuid,
            setHighlightedStackLayerUuid,
        }),
        [
            highlightedModuleUuid,
            highlightedInverterUuid,
            highlightedCombinerUuid,
            highlightedStackLayerUuid,
            setHighlightedModuleUuid,
            setHighlightedInverterUuid,
            setHighlightedCombinerUuid,
            setHighlightedStackLayerUuid,
        ],
    );

    return value;
};

const { Provider: HighlightedAssetsProvider, useCustomHook: useHighlightedAssets } = createCustomHook<
    HighlightedAssetsContextType | undefined
>({
    name: 'useHighlightedAssets',
    callback: ValueProvider,
});

export { HighlightedAssetsProvider, useHighlightedAssets };
