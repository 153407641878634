import { Text } from '@mantine/core';
import { useRouteLoaderData } from 'react-router-dom';

import FinancialLossCalculation from 'assets/images/financial-loss-calculation.png';
import PowerLossBos from 'assets/images/power-loss-bos.png';
import PowerLossCalculation from 'assets/images/power-loss-calculation.png';
import PowerLossMajor from 'assets/images/power-loss-major.png';
import PowerLossMinor from 'assets/images/power-loss-minor.png';
import PowerLossReferenceValues from 'assets/images/power-loss-reference-values.png';
import { REPORT_TYPES } from 'lib/constants';

import { CalculationContainer, VariablesContainer, VariableReferenceContainer } from './SiteLossInfoModal.styled';
import ModalSection from '../shared/ModalSection';
import ModalTitle from '../shared/ModalTitle';
import { InspectionLoaderData } from '../types';

const SiteLossInfoModal = () => {
    const { reportType } = useRouteLoaderData('inspection') as InspectionLoaderData;

    return (
        <>
            <ModalTitle title="DC Losses At Site" />

            <ModalSection title="Power loss">
                <Text variant="body2">
                    {reportType === REPORT_TYPES.DIAGNOSTIC_REPORT
                        ? SITE_LOSS_DESCRIPTION_MAP.DIAGNOSTIC
                        : SITE_LOSS_DESCRIPTION_MAP.DEFAULT}
                </Text>
                <CalculationContainer>
                    <img src={PowerLossCalculation} alt="Power Loss Calculation" width="700" />
                </CalculationContainer>
                <VariablesContainer>
                    <VariableReferenceContainer>
                        <img src={PowerLossBos} alt="Power Loss BOS" />
                        <Text variant="body2">Power loss attributed to BoS faults.</Text>
                    </VariableReferenceContainer>
                    <VariableReferenceContainer>
                        <img src={PowerLossMajor} alt="Power Loss Major" />
                        <Text variant="body2">
                            {reportType === REPORT_TYPES.DIAGNOSTIC_REPORT
                                ? MAJOR_LOSS_DESCRIPTION_MAP.DIAGNOSTIC
                                : MAJOR_LOSS_DESCRIPTION_MAP.DEFAULT}
                        </Text>
                    </VariableReferenceContainer>
                    <VariableReferenceContainer>
                        <img src={PowerLossMinor} alt="Power Loss Minor" />
                        <Text variant="body2">
                            {reportType === REPORT_TYPES.DIAGNOSTIC_REPORT
                                ? MINOR_LOSS_DESCRIPTION_MAP.DIAGNOSTIC
                                : MINOR_LOSS_DESCRIPTION_MAP.DEFAULT}
                        </Text>
                    </VariableReferenceContainer>
                </VariablesContainer>
            </ModalSection>

            <ModalSection title="Financial loss">
                <Text variant="body2">
                    The estimated net present value (NPV) of the financial loss based on the power losses at the site.
                </Text>
                <CalculationContainer>
                    <img src={FinancialLossCalculation} alt="Financial Loss Calculation" width="700" />
                </CalculationContainer>
                <CalculationContainer>
                    <img src={PowerLossReferenceValues} alt="Power Loss Reference Values" width="700" />
                </CalculationContainer>
            </ModalSection>
        </>
    );
};

export default SiteLossInfoModal;

const SITE_LOSS_DESCRIPTION_MAP = {
    DIAGNOSTIC:
        'Power loss for the site is calculated based on anomalies with IR signals. Diagnostic Inspections estimate power loss as the sum of power loss due to balance of system (BoS) faults, plus the estimates of power losses attributed to Major Module and Minor Module anomalies.',
    DEFAULT:
        'Power loss for the site is calculated based on anomalies with IR signals. Only Module Basic and Module Advanced inspections will include power losses due to module-level anomalies; however, String Level inspections only quantify power loss due to balance of system (BoS) faults.',
};

const MAJOR_LOSS_DESCRIPTION_MAP = {
    DIAGNOSTIC:
        'Power loss extrapolated from a 20% sub-sampling of modules with either power loss >= 33%, a module sub-component failure, or temperature delta greater than or equal to 10°C/50°F.',
    DEFAULT:
        'Power loss attributed to modules with either power loss >= 33%, a module sub-component failure, or temperature delta greater than or equal to 10°C/50°F.',
};

const MINOR_LOSS_DESCRIPTION_MAP = {
    DIAGNOSTIC:
        'Power loss extrapolated from a 20% sub-sampling of modules with power loss < 33%, or temperature delta less than 10°C/50°F.',
    DEFAULT: 'Power loss attributed to modules with power loss < 33%, or temperature delta less than 10°C/50°F.',
};
