import styled from '@emotion/styled';
import { Box, Text } from '@mantine/core';

interface ModalSectionProps {
    title?: string;
    children: React.ReactNode;
}

const ModalSection = ({ title, children }: ModalSectionProps) => (
    <ModalSectionContainer>
        {title && (
            <Text color="var(--color-grey-900)" fz="1rem">
                {title}
            </Text>
        )}
        <Box>{children}</Box>
    </ModalSectionContainer>
);

const ModalSectionContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.625rem',
});

export default ModalSection;
