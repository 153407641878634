export const BackForth = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.52433 1.45945C7.26194 1.1858 6.83029 1.17947 6.56019 1.4453L2.70673 5.23798C2.57457 5.36806 2.5 5.5468 2.5 5.73348C2.5 5.92016 2.57458 6.0989 2.70675 6.22897L6.56021 10.0214C6.83031 10.2873 7.26197 10.2809 7.52434 10.0072C7.78672 9.73359 7.78045 9.29626 7.51035 9.03043L4.86226 6.42426H15.8442C16.2207 6.42426 16.526 6.11498 16.526 5.73347C16.526 5.35196 16.2207 5.04268 15.8442 5.04268H4.86219L7.51036 2.43628C7.78046 2.17044 7.78671 1.7331 7.52433 1.45945Z"
            fill="#8D95A2"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.15584 13.576C3.77929 13.576 3.47403 13.8852 3.47403 14.2668C3.47403 14.6483 3.77929 14.9575 4.15584 14.9575H15.1377L12.4897 17.5637C12.2196 17.8295 12.2133 18.2669 12.4757 18.5405C12.738 18.8142 13.1697 18.8205 13.4398 18.5547L17.2933 14.7622C17.4254 14.6322 17.5 14.4534 17.5 14.2668C17.5 14.0801 17.4254 13.9013 17.2933 13.7713L13.4398 9.97859C13.1697 9.71275 12.7381 9.71909 12.4757 9.99273C12.2133 10.2664 12.2195 10.7037 12.4896 10.9696L15.1378 13.576H4.15584Z"
            fill="#8D95A2"
        />
    </svg>
);
