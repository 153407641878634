import styled from '@emotion/styled';
import { Group } from '@mantine/core';

import { PAGE_HEADER_HEIGHT } from 'lib/constants/layout';

export const TitleSection = styled.div({
    display: 'flex',
    gap: '1rem',
});

export const SegmentedTabsSection = styled.div({
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center',
});

export const FlexEndContentSection = styled(Group)({
    display: 'flex',
    justifyContent: 'end',
    flexGrow: 1,
});

export const DualPageTitles = styled.div({
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
});

export const HeaderContainer = styled.header({
    position: 'relative', // needed to get box shadow to show over elements
    zIndex: 10,
    height: PAGE_HEADER_HEIGHT,
    minHeight: PAGE_HEADER_HEIGHT,
    padding: '0 1.5rem',
    borderBottom: '1px solid var(--color-grey-700)',
    background: 'var(--color-grey-700)',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
});
