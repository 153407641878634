import React from 'react';

import Chameleon from '@chamaeleonidae/chmln';
import ReactDOM from 'react-dom/client';

import 'mapbox-gl/dist/mapbox-gl.css';
import './App.css';

import App from './App';
import { Config } from './config';

const CHAMELEON_PROJECT = String(Config.get('REACT_APP_CHAMELEON_PROJECT'));

if (CHAMELEON_PROJECT) {
    Chameleon.init(CHAMELEON_PROJECT, { fastUrl: 'https://fast.chameleon.io/', forceOverride: false });
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
);
