import { Title } from '@mantine/core';
import { redirect, useNavigate } from 'react-router-dom';

import { HorizontalDividerWithLabel } from 'components/common/Divider/HorizontalLabelDivider';
import { CardContentContainer } from 'components/Layout/AuthPagesLayout';
import { ROUTES } from 'lib/constants/routes';
import { getCookie } from 'lib/cookies';
import { checkLoggedIn } from 'lib/helpers';

import LoginForm from './LoginForm';
import { SingleSignOn } from './SingleSignOn';

const LoginPage = () => {
    const navigate = useNavigate();

    return (
        <CardContentContainer>
            <Title order={6} ta="center">
                Log in
            </Title>

            <LoginForm />

            <HorizontalDividerWithLabel label="or" color="gray.1" labelColor="gray.4" />

            <SingleSignOn onSuccessCallback={() => navigate(ROUTES.auth.select_workspace)} />
        </CardContentContainer>
    );
};

export default LoginPage;

export const loginLoader = async () => {
    if (await checkLoggedIn()) {
        const workspaceID = getCookie('helio-workspace')?.id;
        const userId = getCookie('helio-userId');

        if (workspaceID) {
            const url = `${window.location.origin}/sites`;

            window.location.replace(url);

            return redirect(url);
        } else if (userId) {
            return redirect(ROUTES.auth.select_workspace);
        }
    }

    return null;
};
