import { gql } from 'urql';

export const ACTIVE_USER_INVITES = gql`
    query ActiveUserInvites($workspaceId: uuid!) {
        workspaceInvites: auth_invites(
            where: {
                workspace_uuid: { _eq: $workspaceId }
                accepted_at: { _is_null: true }
                deleted_at: { _is_null: true }
            }
            order_by: { expires_at: desc }
        ) {
            uuid
            emailSent: email_sent
            email
            updatedAt: updated_at
            expiresAt: expires_at
            permissions
        }
        permissions: auth_permissions {
            name
            uuid
            description
        }
    }
`;

const workspaceAppsFragment = `
    apps: auth_applications(
        where: {
            workspace_applications: {
                workspace_uuid: { _eq: $workspaceId }
                is_active_start_at: { _lte: "now()" }
                _and: { _or: [{ is_active_end_at: { _is_null: true } }, { is_active_end_at: { _gt: "now()" } }] }
            }
        }
        order_by: { name: asc }
    ) {
        uuid
        name
        permissions {
            uuid
            name
            description
        }
    }
`;

export const GET_WORKSPACE_APPS = gql`
    query WorkspaceApps($workspaceId: uuid!, $permissionNames: [String!]!) {
        ${workspaceAppsFragment}
        permissions: auth_permissions(
            where: {
                name: { _in: $permissionNames }
                application: { workspace_applications: { workspace_uuid: { _eq: $workspaceId } } }
            }
        ) {
            uuid
        }
    }
`;

export const GET_WORKSPACE_USERS = gql`
    query WorkspaceUsers($workspaceId: uuid!, $portalEmail: String) {
        workspaceUsers: auth_workspace_users(
            where: { workspace_uuid: { _eq: $workspaceId }, user: { email: { _neq: $portalEmail } } }
        ) {
            user {
                uuid
                createdAt: created_at
                email
            }
            lastModified: workspace_user_permissions_aggregate {
                aggregate {
                    max {
                        createdAt: created_at
                    }
                }
            }
            userPermissions: workspace_user_permissions {
                permission {
                    uuid
                    name
                    description
                    application {
                        uuid
                        name
                        description
                    }
                }
            }
        }
    }
`;

export const GET_WORKSPACE_PERMISSIONS_BY_NAME = gql`
    query GetWorkspacePermissionsByName($workspaceId: uuid!, $permissionNames: [String!]!) {
        auth_permissions(
            where: {
                name: { _in: $permissionNames }
                application: { workspace_applications: { workspace_uuid: { _eq: $workspaceId } } }
            }
        ) {
            uuid
        }
    }
`;
