import { Stack, Text } from '@mantine/core';

import ModalSection from '../shared/ModalSection';
import ModalTitle from '../shared/ModalTitle';

const DiagnosticResultsInfoModal = () => (
    <>
        <ModalTitle title="Diagnostic Results" />
        <ModalSection title="Description">
            <Stack spacing="0.5rem">
                <Text variant="body2">
                    Diagnostic Results provide a high-level overview of the impact of balance of system (BoS), Major
                    Module and Minor Module anomalies.
                </Text>

                <Text variant="body2">
                    Major Module anomalies are module-level anomalies with either power loss &gt;&#61;33&#37;, a module
                    sub-component failure, or temperature delta greater than or equal to 10&#176;C&#47;50&#176;F. Minor
                    Module anomalies are module-level anomalies with power loss &lt;33&#37;.
                </Text>

                <Text variant="body2">
                    The site is sub-divided into hexagonal regions of roughly 20 MW, where each region is assigned a
                    grade according to the industry benchmark used in the Performance Scorecard. This view is useful for
                    determining which areas of the site are performing poorly, and whether additional analysis is
                    required for the site. In the event the entire site is less than 20 MW, one region is used for the
                    entire site.
                </Text>
            </Stack>
        </ModalSection>
    </>
);

export default DiagnosticResultsInfoModal;
