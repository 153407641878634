import { useEffect, useMemo, useState } from 'react';

import { LOCAL_STORAGE_KEYS } from 'lib/constants/storage';
import createCustomHook from 'lib/helpers/hookFactory';
import { get, set } from 'lib/helpers/localStorage';

const ValueProvider = () => {
    const [mapSignalColors, setMapSignalColors] = useState<any>();
    const [irSignalColors, setIRSignalColors] = useState();
    const [rgbSignalColors, setRGBSignalColors] = useState();
    const [colorPalette, setColorPalette] = useState(get(LOCAL_STORAGE_KEYS.COLOR_PALETTE) ?? 'IR');

    useEffect(() => {
        setIRSignalColors(colorPalette === 'IR' ? mapSignalColors?.ir : null);
        setRGBSignalColors(colorPalette === 'RGB' ? mapSignalColors?.rgb : null);
    }, [colorPalette, mapSignalColors]);

    useEffect(() => {
        set(LOCAL_STORAGE_KEYS.COLOR_PALETTE, colorPalette);
    }, [colorPalette]);

    const value = useMemo(
        () => ({
            mapSignalColors,
            colorPalette,
            irSignalColors,
            rgbSignalColors,
            setMapSignalColors,
            setIRSignalColors,
            setRGBSignalColors,
            setColorPalette,
        }),
        [
            mapSignalColors,
            colorPalette,
            irSignalColors,
            rgbSignalColors,
            setMapSignalColors,
            setIRSignalColors,
            setRGBSignalColors,
            setColorPalette,
        ],
    );

    return value;
};

const { Provider: MapSignalColorsProvider, useCustomHook: useMapSignalColors } = createCustomHook<any>({
    name: 'useMapSignalColors',
    callback: ValueProvider,
});

export { MapSignalColorsProvider, useMapSignalColors };
