import { useCallback, useState, useEffect, ChangeEvent } from 'react';

import styled from '@emotion/styled';
import { Group, Radio, Stack, Text } from '@mantine/core';

import { DIFFERENTIAL_MODES, DIFFERENTIAL_MODES_FILTER_OPTIONS } from 'lib/constants';
import { shortFormatDate } from 'lib/helpers';
import { useDifferentialMode } from 'lib/hooks/useDifferentialMode';

import DifferentialFilterOption from './DifferentialFilterOption';

const DifferentialFilterSection = () => {
    const { mode, setMode, currentInspectionDate, previousInspectionDate } = useDifferentialMode();

    const currentInspectionDateText = shortFormatDate(currentInspectionDate);
    const previousInspectionDateText = shortFormatDate(previousInspectionDate);

    const [inspectionFilter, setInspectionFilter] = useState(currentInspectionDateText);

    useEffect(() => {
        if ([DIFFERENTIAL_MODES.CHANGED_CURRENT, DIFFERENTIAL_MODES.CHANGED_PREVIOUS].includes(mode)) {
            setInspectionFilter(
                mode === DIFFERENTIAL_MODES.CHANGED_CURRENT ? currentInspectionDateText : previousInspectionDateText,
            );
        }
    }, [mode, currentInspectionDateText, previousInspectionDateText]);

    const handleInspectionDateChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const selectedDate = event.currentTarget.value;

            setInspectionFilter(selectedDate);
            const newMode =
                selectedDate === currentInspectionDateText
                    ? DIFFERENTIAL_MODES.CHANGED_CURRENT
                    : DIFFERENTIAL_MODES.CHANGED_PREVIOUS;

            setMode(newMode);
        },
        [currentInspectionDateText, setMode],
    );

    return (
        <Stack pt="0.5rem" spacing="0">
            <FilterSectionContainer spacing="0.25rem">
                {Object.entries(DIFFERENTIAL_MODES_FILTER_OPTIONS).map(([key, value]) => (
                    <DifferentialFilterOption key={key} optionKey={key} optionValue={value} />
                ))}
            </FilterSectionContainer>
            {(mode === DIFFERENTIAL_MODES.CHANGED_CURRENT || mode === DIFFERENTIAL_MODES.CHANGED_PREVIOUS) && (
                <FilterSectionContainer spacing="0.25rem">
                    <Text variant="body1" c="gray.1">
                        Filter by
                    </Text>
                    {[currentInspectionDateText, previousInspectionDateText].map((option) => (
                        <Group key={option} align="center">
                            <Radio
                                key={option}
                                value={option}
                                checked={inspectionFilter === option}
                                onChange={handleInspectionDateChange}
                                styles={{ radio: { '&:hover': { cursor: 'pointer' } } }}
                            />
                            <Text variant="body1" c="gray.1">
                                {option}
                            </Text>
                        </Group>
                    ))}
                </FilterSectionContainer>
            )}
        </Stack>
    );
};

export default DifferentialFilterSection;

const FilterSectionContainer = styled(Stack)({
    borderBottom: '1px solid var(--color-grey-500)',
    padding: '1rem 0',
});
