import styled from '@emotion/styled';
import { Stack } from '@mantine/core';
import { useRouteLoaderData } from 'react-router-dom';

import { SiteLayoutLoaderData } from 'components/Layout/SiteLayout';

import AnomalyOverviewInfoModal from './AnomalyOverviewInfoModal';
import SankeyChart from './Sankey';
import Footer from './Sankey/Footer';
import SankeyFilters from './Sankey/SankeyFilters';
import PopoverCard from '../shared/PopoverCard';
import { InspectionLoaderData } from '../types';

const AnomalyOverview = () => {
    const { capacity } = useRouteLoaderData('site-details') as SiteLayoutLoaderData;
    const { summary } = useRouteLoaderData('inspection') as InspectionLoaderData;

    const powerLossPerModule = (1000 * capacity) / summary.activeModules;

    return (
        <PopoverCard title="Overview of Anomalies" modalContent={<AnomalyOverviewInfoModal />}>
            <StyledOverviewContainer>
                <StyledSankeyContainer>
                    <SankeyFilters />

                    <SankeyChart
                        totalModules={summary.activeModules}
                        siteCapacity={capacity}
                        powerLossPerModule={powerLossPerModule}
                    />
                </StyledSankeyContainer>
                <Footer />
            </StyledOverviewContainer>
        </PopoverCard>
    );
};

const StyledOverviewContainer = styled(Stack)({
    marginLeft: '3.375rem',
    marginRight: '3.375rem',
    gap: '1.25rem',
});

const StyledSankeyContainer = styled(Stack)({
    gap: '1.25rem',
});

export default AnomalyOverview;
