import { Text } from '@mantine/core';

import ModalSection from '../../shared/ModalSection';
import ModalTitle from '../../shared/ModalTitle';

const UpgradeOptionsInfoModal = () => (
    <>
        <ModalTitle title="Inspection Upgrade Options" />
        <ModalSection title="Description">
            <Text variant="body2">
                Zeitview Diagnostic inspections collect data at the same high resolution as other inspection tiers,
                giving you the option to upgrade to a more comprehensive service tier as you deem necessary.
            </Text>
        </ModalSection>
    </>
);

export default UpgradeOptionsInfoModal;
