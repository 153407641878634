import { useRef, useEffect, useState, ReactNode } from 'react';

import styled from '@emotion/styled';
import { Tooltip, Text } from '@mantine/core';

interface TdOverflowTooltipProps {
    children: ReactNode;
    tooltipLabel: string;
}

const TdOverflowTooltip = ({ children, tooltipLabel }: TdOverflowTooltipProps) => {
    const textElementRef = useRef<HTMLDivElement>(null);
    const [isOverflowing, setIsOverflowing] = useState(false);

    useEffect(() => {
        if (textElementRef.current) {
            const isOverflowing = textElementRef.current.scrollWidth > textElementRef.current.clientWidth;

            setIsOverflowing(isOverflowing);
        }
    }, []);

    return (
        <Tooltip
            label={tooltipLabel}
            disabled={typeof children === 'string' ? !isOverflowing : false}
            transitionProps={{ transition: 'pop', duration: 300 }}
            position="top-start"
        >
            {typeof children === 'string' ? (
                <Text
                    ref={textElementRef}
                    variant="body2light"
                    style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                    maw="min-content"
                >
                    {children}
                </Text>
            ) : (
                <TdContentContainer>{children}</TdContentContainer>
            )}
        </Tooltip>
    );
};

export default TdOverflowTooltip;

const TdContentContainer = styled.div({
    maxWidth: 'min-content', // contain tooltip hover area to the content
});
