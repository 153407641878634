import { ChevronRight } from '@dronebase/shared-ui-icons';
import styled from '@emotion/styled';
import { Box, Group, Text, Avatar } from '@mantine/core';

import { GET_LOGO_URL } from 'lib/constants';
import { getInitials } from 'lib/helpers';
import { Workspace } from 'lib/types';

const WorkspaceRow = ({ workspace, onClick }: { workspace: Workspace; onClick: () => void }) => (
    <WorkspaceRowContainer key={workspace.uuid} onClick={onClick}>
        <Group position="apart">
            <Group>
                <Avatar
                    radius="sm"
                    src={GET_LOGO_URL(workspace.uuid)}
                    styles={{
                        placeholder: {
                            backgroundColor: 'var(--color-white)',
                            color: 'var(--color-grey-300)',
                        },
                    }}
                >
                    {getInitials(workspace.name)}
                </Avatar>
                <Box>
                    <Text variant="body1" color="var(--color-grey-900)">
                        {workspace.name}
                    </Text>
                    <Text variant="body2" color="var(--color-grey-300)">
                        {`${workspace.sites_aggregate.aggregate.count} sites`}
                    </Text>
                </Box>
            </Group>
            <ChevronRight color="var(--color-grey-300)" />
        </Group>
    </WorkspaceRowContainer>
);

export default WorkspaceRow;

const WorkspaceRowContainer = styled.div({
    'padding': '0.5rem',
    'cursor': 'pointer',
    'borderRadius': '0.1875rem',
    ':hover': {
        backgroundColor: 'var(--color-grey-50)',
    },
});
