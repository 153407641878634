const StackIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.99995 3.83334H3.33328V14.9445H9.99995V3.83334ZM3.33328 2.72223C2.71963 2.72223 2.22217 3.21969 2.22217 3.83334V14.9445C2.22217 15.5581 2.71963 16.0556 3.33328 16.0556H9.99995C10.6136 16.0556 11.1111 15.5581 11.1111 14.9445V3.83334C11.1111 3.21969 10.6136 2.72223 9.99995 2.72223H3.33328ZM7.77772 4.66666C7.93114 4.66666 8.0555 4.79103 8.0555 4.94444V5.77778H8.88882C9.04223 5.77778 9.1666 5.90215 9.1666 6.05556C9.1666 6.20898 9.04223 6.33334 8.88882 6.33334H8.0555V8.00001H8.88883C9.04225 8.00001 9.16661 8.12437 9.16661 8.27778C9.16661 8.4312 9.04225 8.55556 8.88883 8.55556H8.0555V10.2222H8.88882C9.04223 10.2222 9.1666 10.3466 9.1666 10.5C9.1666 10.6534 9.04223 10.7778 8.88882 10.7778H8.0555V12.4445H8.88884C9.04225 12.4445 9.16662 12.5688 9.16662 12.7222C9.16662 12.8756 9.04225 13 8.88884 13H8.0555V13.8333C8.0555 13.9867 7.93114 14.1111 7.77772 14.1111C7.62431 14.1111 7.49995 13.9867 7.49995 13.8333V13H5.83328V13.8334C5.83328 13.9868 5.70891 14.1111 5.5555 14.1111C5.40209 14.1111 5.27772 13.9868 5.27772 13.8334V13H4.4444C4.29098 13 4.16662 12.8756 4.16662 12.7222C4.16662 12.5688 4.29098 12.4445 4.4444 12.4445H5.27772V10.7778H4.44438C4.29097 10.7778 4.1666 10.6534 4.1666 10.5C4.1666 10.3466 4.29097 10.2222 4.44438 10.2222H5.27772V8.55556H4.44439C4.29098 8.55556 4.16661 8.4312 4.16661 8.27778C4.16661 8.12437 4.29098 8.00001 4.44439 8.00001H5.27772V6.33334H4.44438C4.29096 6.33334 4.1666 6.20898 4.1666 6.05556C4.1666 5.90215 4.29096 5.77778 4.44438 5.77778H5.27772V4.94446C5.27772 4.79105 5.40209 4.66669 5.5555 4.66669C5.70891 4.66669 5.83328 4.79105 5.83328 4.94446V5.77778H7.49995V4.94444C7.49995 4.79103 7.62431 4.66666 7.77772 4.66666ZM5.83328 12.4445H7.49995V10.7778H5.83328V12.4445ZM7.49995 8.55556V10.2222H5.83328V8.55556H7.49995ZM5.83328 8.00001H7.49995V6.33334H5.83328V8.00001Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.70415 16.6111C5.89627 16.9432 6.25534 17.1667 6.66661 17.1667H13.3333C13.9469 17.1667 14.4444 16.6692 14.4444 16.0556V4.94445C14.4444 4.3308 13.9469 3.83334 13.3333 3.83334H11.6666V4.94445H13.3333V16.0556H11.2422C10.937 16.3965 10.4936 16.6111 9.99995 16.6111H5.70415ZM11.6666 14.1111H12.2222C12.3756 14.1111 12.4999 13.9868 12.4999 13.8333C12.4999 13.6799 12.3756 13.5556 12.2222 13.5556H11.6666V14.1111ZM11.6666 11.8889H12.2222C12.3756 11.8889 12.4999 11.7645 12.4999 11.6111C12.4999 11.4577 12.3756 11.3333 12.2222 11.3333H11.6666V11.8889ZM11.6666 9.66667H12.2222C12.3756 9.66667 12.4999 9.5423 12.4999 9.38889C12.4999 9.23548 12.3756 9.11111 12.2222 9.11111H11.6666V9.66667ZM11.6666 7.44446H12.2222C12.3756 7.44446 12.4999 7.3201 12.4999 7.16668C12.4999 7.01327 12.3756 6.88891 12.2222 6.88891H11.6666V7.44446Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.03748 17.7222C9.2296 18.0543 9.58868 18.2778 9.99995 18.2778H16.6666C17.2803 18.2778 17.7777 17.7803 17.7777 17.1667V6.05556C17.7777 5.44191 17.2803 4.94445 16.6666 4.94445H14.9999V6.05556H16.6666V17.1667H14.5756C14.2704 17.5076 13.8269 17.7222 13.3333 17.7222H9.03748ZM14.9999 15.2222H15.5555C15.7089 15.2222 15.8333 15.0979 15.8333 14.9445C15.8333 14.791 15.7089 14.6667 15.5555 14.6667H14.9999V15.2222ZM14.9999 13H15.5555C15.7089 13 15.8333 12.8756 15.8333 12.7222C15.8333 12.5688 15.7089 12.4445 15.5555 12.4445H14.9999V13ZM14.9999 10.7778H15.5555C15.7089 10.7778 15.8333 10.6534 15.8333 10.5C15.8333 10.3466 15.7089 10.2222 15.5555 10.2222H14.9999V10.7778ZM14.9999 8.55556H15.5555C15.7089 8.55556 15.8333 8.4312 15.8333 8.27778C15.8333 8.12437 15.7089 8.00001 15.5555 8.00001H14.9999V8.55556Z"
            fill="currentColor"
        />
    </svg>
);

export default StackIcon;
