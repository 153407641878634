import { useEffect, useMemo, useState } from 'react';

import { useAtomValue } from 'jotai';

import {
    SiteOutlineLayer,
    ViewportBackgroundLayer,
    MinimapZoomOutline,
    ViewportShadeLayer,
} from 'components/inspections/AnomalyMap/deckglLayers';
import { getViewportBounds } from 'lib/helpers';
import createCustomHook from 'lib/helpers/hookFactory';
import { viewStateAtom } from 'state/inspections/anomaliesMap';

import { useSiteGeometry } from './useSiteGeometry';

const ValueProvider = () => {
    const [viewportShadeLayer, setViewportShadeLayer] = useState<any>(null);
    const [viewportBackgroundLayer, setViewportBackgroundLayer] = useState<any>(null);
    const [minimapSiteOutlineLayer, setMinimapSiteOutlineLayer] = useState<any>(null);
    const [minimapZoomOutlineTopLayer, setMinimapZoomOutlineTopLayer] = useState<any>(null);
    const [minimapVisible, setMinimapVisible] = useState(true);

    const viewState = useAtomValue(viewStateAtom);
    const { siteGeometry } = useSiteGeometry();

    useEffect(() => {
        setViewportShadeLayer(ViewportShadeLayer(getViewportBounds(viewState), minimapVisible));
    }, [viewState, minimapVisible]);

    useEffect(() => {
        setViewportBackgroundLayer(ViewportBackgroundLayer(getViewportBounds(viewState), minimapVisible));
        setMinimapZoomOutlineTopLayer(MinimapZoomOutline(getViewportBounds(viewState), minimapVisible));
    }, [viewState, minimapVisible]);

    useEffect(() => {
        setMinimapSiteOutlineLayer(
            SiteOutlineLayer(siteGeometry?.coordinates ?? [], 'minimap-site-outline', true, minimapVisible),
        );
    }, [siteGeometry, minimapVisible]);

    const value = useMemo(
        () => ({
            minimapLayers: [
                viewportShadeLayer,
                viewportBackgroundLayer,
                minimapSiteOutlineLayer,
                minimapZoomOutlineTopLayer,
            ],
            minimapVisible,
            setMinimapVisible,
        }),
        [
            viewportShadeLayer,
            viewportBackgroundLayer,
            minimapSiteOutlineLayer,
            minimapZoomOutlineTopLayer,
            minimapVisible,
            setMinimapVisible,
        ],
    );

    return value;
};

const { Provider: MiniMapLayersProvider, useCustomHook: useMiniMapLayers } = createCustomHook<any>({
    name: 'useMiniMapLayers',
    callback: ValueProvider,
});

export { MiniMapLayersProvider, useMiniMapLayers };
