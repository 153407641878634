import { useEffect } from 'react';

import { InfoModal, StatusAlert, StatusAlertType } from '@dronebase/shared-ui-core';
import { Divider, Group, Text } from '@mantine/core';

import { DecreaseIcon } from 'components/common/Icons/DecreaseIcon';
import { EvenIcon } from 'components/common/Icons/EvenIcon';
import { IncreaseIcon } from 'components/common/Icons/IncreaseIcon';
import { PowerChangeDirection, useDifferentialMode } from 'lib/hooks';
import { LightThemeProvider } from 'providers/LightThemeProvider';

import { ModuleSummary, PowerSummary } from './DifferentialsModalContent';

const PowerChangedMessage: Record<PowerChangeDirection, { message: string; icon: JSX.Element }> = {
    [PowerChangeDirection.INCREASE]: {
        message: 'more',
        icon: <IncreaseIcon />,
    },
    [PowerChangeDirection.DECREASE]: {
        message: 'less',
        icon: <DecreaseIcon />,
    },
    [PowerChangeDirection.EQUAL]: {
        message: 'equal',
        icon: <EvenIcon />,
    },
};

const DifferentialsModal = ({ isOpen, open, close }: { isOpen: boolean; open: () => void; close: () => void }) => {
    const {
        active: isDifferentialModeActive,
        powerSummaryData,
        powerChangeDirection,
        percentagePowerLossChange,
        currentReportType,
        previousReportType,
    } = useDifferentialMode();

    const { previousInspection, currentInspection } = powerSummaryData;

    useEffect(() => {
        if (isDifferentialModeActive) {
            open();
        }
    }, [isDifferentialModeActive, open]);

    const ModalTitle = () => (
        <Group>
            {PowerChangedMessage[powerChangeDirection as PowerChangeDirection].icon}
            <Text>
                {previousInspection.powerLossPercent !== currentInspection.powerLossPercent &&
                    `${percentagePowerLossChange}% `}
                {PowerChangedMessage[powerChangeDirection as PowerChangeDirection].message} power production
            </Text>
        </Group>
    );

    return (
        <LightThemeProvider>
            <InfoModal title={<ModalTitle />} opened={isOpen} onClose={close} styles={{ body: { overflow: 'hidden' } }}>
                <Text variant="body2">since the last inspection</Text>
                {currentReportType !== previousReportType && (
                    <StatusAlert status={StatusAlertType.WARNING} withCloseButton={false}>
                        You&apos;re comparing two different inspection types. Anomaly percentage and count changes may
                        appear higher or lower than what was actually present due to the difference in depth of analysis
                        between each inspection.
                    </StatusAlert>
                )}
                <PowerSummary
                    previousInspection={powerSummaryData.previousInspection}
                    currentInspection={powerSummaryData.currentInspection}
                    currentReportType={currentReportType}
                    previousReportType={previousReportType}
                />
                <Divider size="xs" color="gray.1" />
                <ModuleSummary />
            </InfoModal>
        </LightThemeProvider>
    );
};

export default DifferentialsModal;
