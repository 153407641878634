import { useCallback } from 'react';

import { Loader } from '@dronebase/shared-ui-core';
import { Table, Text } from '@mantine/core';

import { REPORT_TABS } from 'lib/constants';
import { useAnomalyMapFilters, useAnomalyOverviewFilters, useInspectionTabs } from 'lib/hooks';

import KeyObservationRow from './KeyObservationRow';
import KeyObservationsInfoModal from './KeyObservationsInfoModal';
import PopoverCard from '../shared/PopoverCard';
import { Observation } from '../types';

const KeyObservationsTable = () => {
    const {
        filter: currentFilters,
        setAnomalyMapFilters,
        setShowObservationsPanel: setShowKeyObservationsFilters,
    } = useAnomalyMapFilters();
    const { observations, fetchingObservations } = useAnomalyOverviewFilters();
    const { setAndNavigateToTab } = useInspectionTabs();

    const handleKeyObservationClick = useCallback(
        (observationId: string) => {
            setAnomalyMapFilters({
                ...currentFilters,
                observationIds: [observationId],
            });
            setShowKeyObservationsFilters(true);
            setAndNavigateToTab(REPORT_TABS.MAP);
        },
        [setAndNavigateToTab, currentFilters, setAnomalyMapFilters, setShowKeyObservationsFilters],
    );

    if (!observations.length) {
        return null;
    }

    return (
        <PopoverCard title="Key Observations" modalContent={<KeyObservationsInfoModal />} useUnpaddedContainer={true}>
            {fetchingObservations ? (
                <Loader />
            ) : (
                <Table style={{ width: 'unset', minWidth: '100%' }}>
                    <thead style={{ zIndex: '101', position: 'relative' }}>
                        <tr>
                            <th>
                                <Text>ID</Text>
                            </th>
                            <th>
                                <Text>Key Observation</Text>
                            </th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {observations.map((observation: Observation) => (
                            <KeyObservationRow
                                observation={observation}
                                onClick={() => handleKeyObservationClick(observation.observationID)}
                                key={`ko-${observation.observationID}`}
                            />
                        ))}
                    </tbody>
                </Table>
            )}
        </PopoverCard>
    );
};

export default KeyObservationsTable;
