import { useEffect, useMemo, useState } from 'react';

import { Layer } from 'deck.gl/typed';
import { useAtomValue } from 'jotai';
import { useRouteLoaderData } from 'react-router-dom';

import { ElectricalRegionLayer } from 'components/inspections/AnomalyMap/CustomLayers';
import {
    AssetLayer,
    AnomalyScatterplotLayer,
    SiteOutlineLayer,
    SetLayer,
} from 'components/inspections/AnomalyMap/deckglLayers';
import { InspectionLoaderData } from 'components/inspections/types';
import {
    MODULE_ASSET_TYPE_UUID,
    MODULE_LAYER_ID,
    AssetTypes,
    MAP_LAYER_ASSET_TYPES,
    DISPLAY_MAP_LAYER_KEYS,
    SET_ASSET_TYPE_UUID,
    TRACKER_TYPES_WITH_SETS,
    SET_LAYER_ID,
} from 'lib/constants';
import { buildElectricalRegionLayer } from 'lib/helpers/common';
import createCustomHook from 'lib/helpers/hookFactory';
import { hoverInfoAssetIdAtom, viewStateAtom } from 'state/inspections/anomaliesMap';

import { useAnomalyMapData } from './useAnomalyMapData';
import { useAssetLabels } from './useAssetLabels';
import { useHighlightedAssets } from './useHighlightedAssets';
import { useMapSignalColors } from './useMapSignalColors';
import { useSiteGeometry } from './useSiteGeometry';
import { useAnomalyMapFilters } from '../useAnomalyMapFilters';

const ValueProvider = () => {
    const [assetLayer, setAssetLayer] = useState<Layer | null>(null);
    const [siteOutlineLayer, setSiteOutlineLayer] = useState<Layer | null>(null);
    const [combinerLayer, setCombinerLayer] = useState<ElectricalRegionLayer | null>(null);
    const [inverterLayer, setInverterLayer] = useState<ElectricalRegionLayer | null>(null);
    const [stackLayer, setStackLayer] = useState<ElectricalRegionLayer | null>(null);
    const [setLayer, setSetLayer] = useState<Layer | null>(null);
    const [scatterplotLayer, setScatterplotLayer] = useState<Layer | null>(null);

    const viewState = useAtomValue(viewStateAtom);
    const hoverInfoAssetId = useAtomValue(hoverInfoAssetIdAtom);
    const { siteGeometry } = useSiteGeometry();
    const { filter } = useAnomalyMapFilters();
    const { combinerLabels, inverterLabels, stackLabels } = useAssetLabels();
    const { filteredMapAnomalies } = useAnomalyMapData();
    const { irSignalColors, rgbSignalColors } = useMapSignalColors();
    const { highlightedInverterUuid, highlightedCombinerUuid, highlightedStackLayerUuid, highlightedModuleUuid } =
        useHighlightedAssets();
    const {
        summary: { draftVersionUUID },
        trackerType,
    } = useRouteLoaderData('inspection') as InspectionLoaderData;

    const showSiteOutline = useMemo(
        () => filter.layerFilters.includes(DISPLAY_MAP_LAYER_KEYS.OUTLINE),
        [filter.layerFilters],
    );
    const showCombiners = useMemo(
        () => filter.layerFilters.includes(DISPLAY_MAP_LAYER_KEYS.COMBINER),
        [filter.layerFilters],
    );
    const showInverters = useMemo(
        () => filter.layerFilters.includes(DISPLAY_MAP_LAYER_KEYS.INVERTER),
        [filter.layerFilters],
    );
    const showStacks = useMemo(() => filter.layerFilters.includes(DISPLAY_MAP_LAYER_KEYS.STACK), [filter.layerFilters]);

    const showSets = useMemo(() => trackerType && TRACKER_TYPES_WITH_SETS.includes(trackerType), [trackerType]);

    // Asset layer
    useEffect(() => {
        setAssetLayer(
            AssetLayer({
                layerID: MODULE_LAYER_ID,
                draftVersionUUID,
                assetTypeUUID: MODULE_ASSET_TYPE_UUID,
                mapAnomalies: filteredMapAnomalies,
                irSignalColors,
                rgbSignalColors,
                highlightedModuleUuid,
                hoverInfoAssetId,
            }),
        );
    }, [
        filteredMapAnomalies,
        irSignalColors,
        rgbSignalColors,
        highlightedModuleUuid,
        hoverInfoAssetId,
        draftVersionUUID,
    ]);

    // Set Layer
    useEffect(() => {
        showSets &&
            setSetLayer(
                SetLayer({
                    layerID: SET_LAYER_ID,
                    draftVersionUUID,
                    assetTypeUUID: SET_ASSET_TYPE_UUID,
                }),
            );
    }, [draftVersionUUID, showSets]);

    // Site outline layer
    useEffect(() => {
        setSiteOutlineLayer(
            SiteOutlineLayer(siteGeometry?.coordinates ?? [], 'main-site-outline', false, showSiteOutline),
        );
    }, [siteGeometry, showSiteOutline]);

    const scatterplotAnomalies = useMemo(() => Object.values(filteredMapAnomalies).flat(1), [filteredMapAnomalies]);

    useEffect(() => {
        setScatterplotLayer(
            AnomalyScatterplotLayer(scatterplotAnomalies, irSignalColors, rgbSignalColors, viewState.zoom),
        );
    }, [scatterplotAnomalies, irSignalColors, rgbSignalColors, viewState.zoom]);

    // Combiner layer
    useEffect(() => {
        setCombinerLayer(
            buildElectricalRegionLayer({
                id: AssetTypes.combiner.layerId,
                data: combinerLabels,
                visible: showCombiners,
                highlightedUuid: highlightedCombinerUuid,
                draftVersionUUID,
                assetType: MAP_LAYER_ASSET_TYPES.COMBINER,
                zoom: viewState.zoom,
            }),
        );
    }, [combinerLabels, showCombiners, highlightedCombinerUuid, viewState.zoom, draftVersionUUID]);

    // Inverter layer
    useEffect(() => {
        setInverterLayer(
            buildElectricalRegionLayer({
                id: AssetTypes.inverter.layerId,
                data: inverterLabels,
                visible: showInverters,
                highlightedUuid: highlightedInverterUuid,
                draftVersionUUID,
                assetType: MAP_LAYER_ASSET_TYPES.INVERTER,
                zoom: viewState.zoom,
            }),
        );
    }, [inverterLabels, showInverters, highlightedInverterUuid, draftVersionUUID, viewState.zoom]);

    // Stack layer
    useEffect(() => {
        setStackLayer(
            buildElectricalRegionLayer({
                id: AssetTypes.stack.layerId,
                data: stackLabels,
                visible: showStacks,
                highlightedUuid: highlightedStackLayerUuid,
                draftVersionUUID,
                assetType: MAP_LAYER_ASSET_TYPES.STACK,
                zoom: viewState.zoom,
            }),
        );
    }, [stackLabels, showStacks, highlightedStackLayerUuid, draftVersionUUID, viewState.zoom]);

    const value = useMemo(
        () => ({
            assetLayer,
            setLayer,
            siteOutlineLayer,
            combinerLayer,
            inverterLayer,
            stackLayer,
            scatterplotLayer,
        }),
        [assetLayer, siteOutlineLayer, combinerLayer, inverterLayer, stackLayer, setLayer, scatterplotLayer],
    );

    return value;
};

const { Provider: MapLayersProvider, useCustomHook: useMapLayers } = createCustomHook<any>({
    name: 'useMapLayers',
    callback: ValueProvider,
});

export { MapLayersProvider, useMapLayers };
