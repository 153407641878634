import { useState } from 'react';

import { ConfirmModal, notifications } from '@dronebase/shared-ui-core';
import { Stack, Text } from '@mantine/core';

import { NoPendingInvites } from 'components/common/Icons/NoPendingInvites';
import Table from 'components/common/Table';
import { Config } from 'config';
import { generateViewInvitesColumns } from 'lib/constants';
import { useUsersContext } from 'lib/context/UsersContext';
import { getCookie } from 'lib/cookies';
import { fetchData } from 'lib/hooks';
import { WorkspaceInvite } from 'lib/types';

const AUTH_API_URL = Config.get('AUTH_API_URL');

const ViewInvites = () => {
    const [inviteToCancel, setInviteToCancel] = useState<string | null>(null);

    const { refetchInvites, paginatedInvites } = useUsersContext();

    const workspace = getCookie('helio-workspace');

    const reinviteUser = async (invite: WorkspaceInvite) => {
        const _permissions = invite.permissions.map((permission) => permission.uuid);
        const { error: createInviteError } = await fetchData({
            url: `${AUTH_API_URL}/invite/${invite.uuid}/resend`,
            method: 'POST',
            body: { email: invite.email, workspace_uuid: workspace?.id, permissions: _permissions },
        });

        if (createInviteError) {
            notifications.error({
                title: createInviteError,
            });
        } else {
            notifications.info({ title: 'Successfully re-invited user' });
            refetchInvites();
        }
    };

    const onCancelInvite = async (inviteUUID: string) => {
        const { error } = await fetchData({
            url: `${AUTH_API_URL}/invite/${inviteUUID}/delete`,
            method: 'POST',
            body: {},
        });

        if (error) {
            notifications.error({ title: `An error occurred cancelling the invite!` });
        } else {
            notifications.info({ title: `Invite successfully cancelled!` });
            setInviteToCancel(null);
            refetchInvites();
        }
    };

    return (
        <>
            {paginatedInvites?.length ? (
                <Table
                    data={paginatedInvites}
                    columns={generateViewInvitesColumns(reinviteUser, setInviteToCancel)}
                    hasBorder={false}
                />
            ) : (
                <Stack justify="center" align="center" h="100%">
                    <NoPendingInvites />
                    <Text variant="subtitle1" c="gray.9">
                        No pending Invitations
                    </Text>
                </Stack>
            )}

            <ConfirmModal
                opened={!!inviteToCancel}
                onClose={() => setInviteToCancel(null)}
                title="Confirm Invite Cancellation"
                confirmLabel="Cancel Invite"
                onConfirm={() => inviteToCancel && onCancelInvite(inviteToCancel)}
            >
                <Text>Are you sure you want to cancel this invite? This cannot be undone.</Text>
            </ConfirmModal>
        </>
    );
};

export default ViewInvites;
