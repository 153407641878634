import { useEffect } from 'react';

import { Button, Text, Title } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'lib/constants/routes';

const ResetPasswordSuccess = () => {
    const navigate = useNavigate();

    useEffect(() => {
        if (!localStorage.getItem('passwordResetSuccess')) {
            navigate(ROUTES.auth.index, { replace: true });
        }
    }, [navigate]);

    const handleBackToSignIn = () => {
        localStorage.removeItem('passwordResetSuccess');
        navigate(ROUTES.auth.index, { replace: true });
    };

    return (
        <>
            <Title order={6} ta="center">{`We've sent you an email with a link to reset your password.`}</Title>
            <Text variant="body2" ta="center" color="gray.4">
                {`Check your spam and promotions folder if it doesn't appear in your main mailbox.`}
            </Text>
            <Button variant="filled" w="100%" size="xl" onClick={handleBackToSignIn}>
                Back to Log in Screen
            </Button>
        </>
    );
};

export default ResetPasswordSuccess;
