import { Center, Checkbox, Group, Stack, Text, Tooltip } from '@mantine/core';

import NoObservationsIcon from 'components/common/Icons/NoObservationsIcon';
import { useAnomalyMapFilters, useAnomalyOverviewFilters } from 'lib/hooks';

import {
    KeyObservationsFiltersContainer,
    KeyObservationsFiltersSectionContainer,
    TooltipContentWrapper,
} from './KeyObservationsFilters.styled';
import { Observation } from '../types';

const KeyObservationsFilters = () => {
    const { observations } = useAnomalyOverviewFilters();
    const { filter, toggleFilter } = useAnomalyMapFilters();

    const tooltipText = 'This observation cannot be used to filter the anomaly map.';

    if (observations && observations.length) {
        return (
            <KeyObservationsFiltersContainer p="1.5rem">
                <Text variant="body1" c="gray.0">
                    Key Observations
                </Text>
                <Stack spacing="1rem">
                    {observations.map((observation: Observation) => (
                        <KeyObservationsFiltersSectionContainer key={observation.id}>
                            <Group align="center" position="left">
                                <Tooltip
                                    ml="-0.5rem"
                                    label={tooltipText}
                                    position="top-start"
                                    disabled={!observation.isDisabled}
                                    transitionProps={{ transition: 'pop', duration: 300 }}
                                >
                                    <TooltipContentWrapper>
                                        <Checkbox
                                            onChange={() => toggleFilter('observationIds', observation.observationID)}
                                            checked={filter?.observationIds.includes(observation.observationID)}
                                            disabled={observation.isDisabled}
                                        />
                                    </TooltipContentWrapper>
                                </Tooltip>
                                <Text variant="body2" c="gray.1">
                                    {observation.id}
                                </Text>
                            </Group>
                            <Text variant="body2light" c="gray.2" pt="0.25rem">
                                {observation.description}
                            </Text>
                        </KeyObservationsFiltersSectionContainer>
                    ))}
                </Stack>
            </KeyObservationsFiltersContainer>
        );
    } else {
        return (
            <Center pt="8rem">
                <Stack spacing="1.5rem" justify="center" align="center">
                    <NoObservationsIcon />
                    <Text variant="body2medium" c="gray.3">
                        No Key Observations for this report
                    </Text>
                </Stack>
            </Center>
        );
    }
};

export default KeyObservationsFilters;
