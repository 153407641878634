import { useAtom, WritableAtom } from 'jotai';

// This type represent the props that our HOC adds
// We also need to ensure that the original component actually need those
type AddedProps<AtomValue, AtomUpdate> = {
    value: AtomValue;
    // We match multiple function signatures here
    // As we accept component who don't fully use jotai syntax
    onChange: React.Dispatch<AtomUpdate> | React.Dispatch<AtomValue> | React.Dispatch<React.SetStateAction<AtomValue>>;
};

/**
 * An Higher Order Function that let's you control a component
 * that accepts `value` and `onChange` based on a Jotai atom
 *
 * @param atom a Jotai writable atom
 * @param Component a component to pass the atom value and updater in props
 * @returns a component with the values and setter function of the atoms passed
 * as `value` and `onChange` props
 */
export const withComponentControlledByAtom = <AtomValue, AtomUpdate, Props extends AddedProps<AtomValue, AtomUpdate>>(
    atom: WritableAtom<AtomValue, [AtomUpdate], void>,
    Component: React.FC<Props>,
) => {
    // The newly created component with `value` and `onChange`
    const ComponentWithAtom = (props: Omit<Props, 'value' | 'onChange'>) => {
        const [value, onChange] = useAtom(atom);

        // Typescript is unhappy with this by default so we cast that to Props
        // It is safe to assume that `value` and `onChange` are of the expected types
        const allProps = {
            ...props,
            value,
            onChange,
        } as Props;

        return <Component {...allProps} />;
    };

    return ComponentWithAtom;
};
