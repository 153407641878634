import { Stack, Text } from '@mantine/core';

import { colors } from 'lib/constants';
import { getSankeyTooltipModuleCount, getSankeyTooltipPowerLoss } from 'lib/helpers/sankey';

const CustomTooltip = ({
    active,
    payload,
    totalModules,
    siteCapacity,
    powerLossMapping,
    powerLossPerModule,
    aggregation,
    linkData,
}: any) => {
    const shouldShowTooltip = active && payload && payload.length;
    const isNode = !payload[0]?.payload?.payload?.source?.name;

    if (shouldShowTooltip) {
        const moduleCount = getSankeyTooltipModuleCount(aggregation, payload[0], isNode, linkData);
        const moduleCountPercentage =
            moduleCount / totalModules < 0.0001 ? '< 0.01' : `${((moduleCount / totalModules) * 100).toFixed(2)}`;
        const powerLoss = getSankeyTooltipPowerLoss(
            aggregation,
            payload[0],
            isNode,
            powerLossMapping,
            powerLossPerModule,
        );
        const powerLossPercentage =
            powerLoss / (1000 * siteCapacity) < 0.0001
                ? '< 0.01'
                : `${((powerLoss / (1000 * siteCapacity)) * 100).toFixed(2)}`;

        return (
            <Stack
                spacing="0.5rem"
                style={{
                    backgroundColor: colors.white,
                    padding: 15,
                    borderRadius: 10,
                }}
                data-cy="sankey-tooltip"
            >
                <Text style={{ fontSize: 'var(--typography-fz-sm)', fontWeight: 'var(--typography-fw-bold)' }}>
                    {payload[0]?.payload?.payload?.name}
                </Text>

                {moduleCount ? (
                    <Stack spacing="0.5rem">
                        <Text style={{ fontSize: 'var(--typography-fz-sm)' }}>
                            {moduleCount.toLocaleString()} modules impacted
                        </Text>
                        <Text style={{ fontSize: 'var(--typography-fz-sm)' }}>
                            {moduleCountPercentage}% of total modules
                        </Text>
                    </Stack>
                ) : null}
                {powerLoss ? (
                    <Stack spacing="0.5rem">
                        <Text style={{ fontSize: 'var(--typography-fz-sm)' }}>
                            {Number(powerLoss.toFixed(2)).toLocaleString()} kW power loss
                        </Text>
                        <Text style={{ fontSize: 'var(--typography-fz-sm)' }}>
                            {powerLossPercentage}% of total site power
                        </Text>
                    </Stack>
                ) : null}

                {isNode ? null : (
                    <Text style={{ fontSize: 'var(--typography-fz-xs)', fontStyle: 'italic', paddingTop: '10px' }}>
                        Click to see on map
                    </Text>
                )}
            </Stack>
        );
    }

    return null;
};

export default CustomTooltip;
