import { Loader } from '@dronebase/shared-ui-core';
import { Center, Stack, Text } from '@mantine/core';

import { useAnomalyMapSections, useDifferentialMode } from 'lib/hooks';

import AnomalyColorPalette from './AnomalyColorPalette';
import DifferentialFilterSection from './DifferentialFilterSection';
import FilterCategorySection from './FilterCategorySection';

export const AnomalyFilters = () => {
    const { sections, totalAnomalyCount, fetchingReportAnomalies } = useAnomalyMapSections();
    const { active: isDifferentialModeActive } = useDifferentialMode();

    return fetchingReportAnomalies ? (
        <Center h="100%">
            <Loader />
        </Center>
    ) : (
        <Stack p="1.5rem">
            {isDifferentialModeActive && <DifferentialFilterSection />}
            {sections ? (
                <Stack spacing="1rem">
                    {Object.keys(sections).map((filterKey) => (
                        <FilterCategorySection
                            filterKey={filterKey}
                            options={sections[filterKey].filterOptions}
                            title={sections[filterKey].name}
                            loading={sections[filterKey].loading}
                            disabledFilterKeys={
                                totalAnomalyCount === 0 ? ['priorityHigh', 'priorityMedium', 'priorityLow'] : []
                            }
                            key={filterKey}
                        />
                    ))}
                    {Object.keys(sections).length > 0 && <AnomalyColorPalette />}
                </Stack>
            ) : (
                <NoAnomaliesPlaceholder />
            )}
        </Stack>
    );
};

const NoAnomaliesPlaceholder = () => (
    <Stack mt="2rem" align="center" justify="center">
        <Text variant="body1" c="white">
            No anomalies in this category
        </Text>
    </Stack>
);
