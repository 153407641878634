import { LOCAL_STORAGE_KEYS } from 'lib/constants/storage';

export const get = (key: LOCAL_STORAGE_KEYS) => localStorage.getItem(key);

export const getAsBoolean = (key: LOCAL_STORAGE_KEYS) => get(key) === 'true';

export const getAsJSON = (key: LOCAL_STORAGE_KEYS) => {
    const value = get(key);

    return value ? JSON.parse(value) : null;
};

export const set = (key: LOCAL_STORAGE_KEYS, value: any) => {
    localStorage.setItem(key, value);
};

export const toggle = (key: LOCAL_STORAGE_KEYS) => {
    const value = get(key);

    set(key, value === 'true' ? 'false' : 'true');
};

export const remove = (key: LOCAL_STORAGE_KEYS) => {
    localStorage.removeItem(key);
};

export const resetLocalStorage = () => {
    Object.values(LOCAL_STORAGE_KEYS).map((key) => remove(key as LOCAL_STORAGE_KEYS));
};
