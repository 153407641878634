import { Flex } from '@mantine/core';
import { Popup, PointLike } from 'react-map-gl';

import { SiteMarkerInfo } from '../types';

export interface SiteMarkerPopupProps {
    siteInfo: SiteMarkerInfo;
}

const POPUP_OFFSET: PointLike = [0, -10];

export const SiteMarkerPopup = ({ siteInfo }: SiteMarkerPopupProps) => {
    if (siteInfo) {
        return (
            <Popup
                longitude={siteInfo.longitude}
                latitude={siteInfo.latitude}
                offset={POPUP_OFFSET}
                closeButton={false}
                closeOnClick={false}
            >
                <Flex align="center" justify="center">
                    {siteInfo.siteName}
                </Flex>
            </Popup>
        );
    } else {
        return null;
    }
};
