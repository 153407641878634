import { useEffect, useMemo, useState } from 'react';

import styled from '@emotion/styled';
import { Avatar, Menu, Text } from '@mantine/core';
import { User } from 'firebase/auth';
import { useOutletContext } from 'react-router-dom';

import { useSwitchWorkspaceModalContext } from 'lib/context/SwitchWorkspaceModalContext';
import { getCookie } from 'lib/cookies';

interface UserMenuData {
    firstName: string | null;
    lastName: string | null;
    photoURL: string | null | undefined;
    email: string;
    workspace: string | null;
}

interface UserMenuProps {
    onLogout?: (redirect?: boolean) => Promise<void>;
}

const UserMenu = ({ onLogout }: UserMenuProps) => {
    const authUser = useOutletContext() as User;
    const [workspace, setWorkspace] = useState(null);
    const { openModal } = useSwitchWorkspaceModalContext();

    useEffect(() => {
        const workspaceCookie = getCookie('helio-workspace');

        setWorkspace(workspaceCookie?.name);
    }, []);

    const userData: UserMenuData = useMemo(() => {
        let firstName = null;
        let lastName = null;

        if (authUser.displayName && authUser.displayName.includes(' ')) {
            [firstName, lastName] = authUser.displayName.split(' ');
        } else {
            firstName = authUser.email?.split('@')[0] || '';
        }

        return {
            firstName,
            lastName,
            email: authUser.email || '',
            photoURL: authUser.photoURL,
            workspace,
        };
    }, [authUser, workspace]);

    const initials = userData.lastName
        ? `${userData.firstName?.charAt(0).toUpperCase()}${userData.lastName?.charAt(0).toUpperCase()}`
        : userData.firstName?.charAt(0).toUpperCase();

    return (
        <UserMenuContainer>
            <Menu position="bottom-end">
                <Menu.Label>
                    <Text ta="right" variant="body2">
                        {userData.firstName} {userData.lastName || ''}{' '}
                        {userData.workspace ? ` (${userData.workspace})` : ''}
                    </Text>
                    <Text color="var(--color-grey-400)" ta="right" variant="body3">
                        {userData.email}
                    </Text>
                </Menu.Label>
                <Menu.Target>
                    <Avatar
                        radius="xl"
                        alt="Profile Icon"
                        src={userData.photoURL}
                        styles={{
                            placeholder: { color: 'var(--color-white)', backgroundColor: 'var(--color-grey-200)' },
                        }}
                    >
                        {initials}
                    </Avatar>
                </Menu.Target>
                <Menu.Dropdown>
                    <Menu.Item onClick={() => onLogout && onLogout()}>Log Out</Menu.Item>
                    <Menu.Item onClick={openModal}>Change Workspace</Menu.Item>
                </Menu.Dropdown>
            </Menu>
        </UserMenuContainer>
    );
};

export default UserMenu;

const UserMenuContainer = styled.div({
    display: 'flex',
    alignItems: 'center',
});
