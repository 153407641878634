import { useState } from 'react';

import { TextInput } from '@mantine/core';

const format = (value: string) => {
    const raw = (value || '').replace(/\D/g, '').substring(0, 10);
    const len = raw.length;

    if (len === 0) {
        return raw;
    } else if (len < 4) {
        return `(${raw}`;
    } else if (len < 7) {
        return `(${raw.substring(0, 3)}) ${raw.substring(3, 6)}`;
    } else {
        return `(${raw.substring(0, 3)}) ${raw.substring(3, 6)}-${raw.substring(6, 10)}`;
    }
};
const toValue = (formatted: string) => formatted.replace(/\D/g, '').substring(0, 10);

interface MaskedPhoneInputProps {
    onChange: (value: string) => void;
    value: string;
    placeholder?: string;
    inputName?: string;
    id?: string;
}

const MaskedPhoneInput = ({ value = '', onChange, id, inputName, placeholder }: MaskedPhoneInputProps) => {
    const [formatted, setFormatted] = useState(format(value));

    return (
        <TextInput
            id={id}
            name={inputName}
            placeholder={placeholder}
            type="tel"
            autoComplete="tel-national"
            label="Phone Number"
            maxLength={14}
            pb="1rem"
            value={formatted}
            onChange={(event) => {
                const f = format(event.currentTarget.value);

                setFormatted(f);
                onChange(toValue(f));
            }}
        />
    );
};

export default MaskedPhoneInput;
