import { Left as LeftIcon } from '@dronebase/shared-ui-icons';
import { ActionIcon } from '@mantine/core';
import { Link } from 'react-router-dom';

export const BackButton = ({ to, onClick = undefined }: { to: string; onClick?: () => void }) => (
    <Link to={to}>
        <ActionIcon p={0} onClick={onClick}>
            <LeftIcon />
        </ActionIcon>
    </Link>
);
