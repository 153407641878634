import { RefObject, useCallback, useEffect, useState } from 'react';

const useIsHorizontallyScrolling = (ref: RefObject<HTMLDivElement>) => {
    const [isScrollingHorizontally, setIsScrollingHorizontally] = useState(false);

    const handleScroll = useCallback(() => {
        const container = ref.current;

        if (container) {
            const isScrollingHorizontally = container.scrollLeft > 0;

            setIsScrollingHorizontally(isScrollingHorizontally);
        }
    }, [ref]);

    useEffect(() => {
        const container = ref.current;

        if (container) {
            container.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
        };
    }, [handleScroll, ref, ref.current?.scrollLeft]);

    return isScrollingHorizontally;
};

export default useIsHorizontallyScrolling;
