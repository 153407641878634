import styled from '@emotion/styled';
import { Group } from '@mantine/core';

export const StyledTableData = styled.td({
    'boxSizing': 'border-box',
    'padding': '0 10px',
    'textAlign': 'left',
    'maxWidth': '12.5rem',
    'minWidth': '8.75rem',
    'whiteSpace': 'nowrap',
    'overflow': 'hidden',
    'textOverflow': 'ellipsis',
    '& > a': {
        'color': 'var(--color-purple-400)',
        'textDecoration': 'none',
        ':hover': {
            textDecoration: 'underline',
            color: 'var(--color-purple-400)',
        },
    },
});

export const StyledTableHead = styled.thead({
    position: 'sticky',
    top: 0,
    backgroundColor: 'white',
    zIndex: 100,
});

export const StyledHeaderRow = styled.tr({
    borderBottom: `1px solid var(--color-grey-100)`,
});

export const StyledDataRow = styled.tr({
    ':not(:last-child)': {
        borderBottom: `1px solid var(--color-grey-100)`,
    },
    'td': {
        height: '3.125rem',
        padding: '0 22px',
        fontSize: '0.875rem',
        fontWeight: 400,
    },
});

export const StyledTableHeader = styled.th({
    boxSizing: 'border-box',
    padding: '0 10px',
    whiteSpace: 'nowrap',
    height: '2.5rem',
    maxWidth: '12.5rem',
    minWidth: '8.75rem',
});

export const StyledHeaderGroup = styled(Group)({
    gap: '0.5rem',
    alignItems: 'center',
    borderRadius: '30px',
    padding: '0 12px',
    flexWrap: 'nowrap',
    width: 'fit-content',
});

export const StyledTable = styled.table({
    borderCollapse: 'collapse',
    width: '100%',
});

export const StyledTableContainer = styled.div(({ hasBorder }: { hasBorder: boolean }) => ({
    border: hasBorder ? '1px solid var(--color-grey-100)' : 'none',
    background: 'var(--color-white)',
    overflow: 'auto',
}));
