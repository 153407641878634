import { useCallback, useEffect, useMemo, useState } from 'react';

import { isEmpty } from 'lodash';
import { useRouteLoaderData } from 'react-router-dom';

import { InspectionLoaderData } from 'components/inspections/types';
import {
    PivotViewOptions,
    REPORT_TYPES,
    DEFAULT_PIVOT_VIEW_OPTIONS,
    DISABLED_PIVOT_VIEW_OPTIONS_FOR_FSX,
    DIFFERENTIAL_PIVOT_VIEW_OPTIONS,
    REMEDIATION_LEVEL_1,
    REMEDIATION_LEVEL_3,
    REMEDIATION_LEVEL_2,
} from 'lib/constants';
import { findMatchingPivotSettings } from 'lib/helpers';
import createCustomHook from 'lib/helpers/hookFactory';

import { useDifferentialMode } from './useDifferentialMode';
import { usePivotTable } from './usePivotTable';

const ValueProvider = () => {
    const [viewOptions, setViewOptions] = useState<any>(DEFAULT_PIVOT_VIEW_OPTIONS);
    const [disabledViewOptions, setDisabledViewOptions] = useState<any>([]);
    const [loading, setLoading] = useState(true);

    const { settings, viewSettings, setView, remediationCategories } = usePivotTable();
    const { reportType } = useRouteLoaderData('inspection') as InspectionLoaderData;
    const { active: isDifferentialModeActive } = useDifferentialMode();

    const setDefaultView = useCallback(() => {
        if (disabledViewOptions.includes(PivotViewOptions.REMEDIATION_RECOMMENDED)) {
            setView(
                disabledViewOptions.includes(PivotViewOptions.MONITOR_AND_REMEDIATE)
                    ? PivotViewOptions.LONG_TERM_MONITORING
                    : PivotViewOptions.MONITOR_AND_REMEDIATE,
            );
        } else {
            setView(PivotViewOptions.REMEDIATION_RECOMMENDED);
        }
    }, [disabledViewOptions, setView]);

    useEffect(() => {
        if (reportType === REPORT_TYPES.FSX) {
            setDisabledViewOptions(DISABLED_PIVOT_VIEW_OPTIONS_FOR_FSX);
        }
    }, [reportType]);

    useEffect(() => {
        if (isDifferentialModeActive) {
            setViewOptions(DIFFERENTIAL_PIVOT_VIEW_OPTIONS);
            setView(PivotViewOptions.DIFFERENTIAL_SUMMARY);
        } else {
            setViewOptions(DEFAULT_PIVOT_VIEW_OPTIONS);
            setDefaultView();
        }
    }, [isDifferentialModeActive, setDefaultView, setView]);

    useEffect(() => {
        if (!isEmpty(remediationCategories)) {
            setDisabledViewOptions([
                !remediationCategories.includes(REMEDIATION_LEVEL_1) && PivotViewOptions.REMEDIATION_RECOMMENDED,
                !remediationCategories.includes(REMEDIATION_LEVEL_2) && PivotViewOptions.MONITOR_AND_REMEDIATE,
                !remediationCategories.includes(REMEDIATION_LEVEL_3) && PivotViewOptions.LONG_TERM_MONITORING,
            ]);

            setLoading(false);
        }
    }, [remediationCategories]);

    useEffect(() => {
        if (settings) {
            const enabledViewSettings = Object.entries(viewSettings)
                .filter(([key, _]) => !disabledViewOptions.includes(key))
                .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

            const matchingView = findMatchingPivotSettings(settings, enabledViewSettings);

            setView(matchingView ? matchingView : PivotViewOptions.CUSTOM);
        }
    }, [settings, viewSettings]);

    const value = useMemo(
        () => ({
            viewOptions,
            disabledViewOptions,
            loading,
        }),
        [viewOptions, disabledViewOptions, loading],
    );

    return value;
};

const { Provider: PivotViewOptionsProvider, useCustomHook: usePivotViewOptions } = createCustomHook<any>({
    name: 'usePivotViewOptions',
    callback: ValueProvider,
});

export { PivotViewOptionsProvider, usePivotViewOptions };
