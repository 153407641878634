import { ChevronRight, Search } from '@dronebase/shared-ui-icons';
import { ActionIcon, Group, Flex } from '@mantine/core';

interface CollapsedMapViewProps {
    handleToggling: () => void;
}

const CollapsedMapView = ({ handleToggling }: CollapsedMapViewProps) => (
    <Flex
        h="100%"
        w="52px"
        direction="column"
        justify="space-between"
        style={{ borderRight: '1px solid var(--color-grey-100)' }}
    >
        <ActionIcon m={0} p="0.25rem" onClick={handleToggling} size="md" mr="-0.1rem">
            <Group noWrap mr="-0.5rem">
                <Search
                    style={{
                        padding: 0,
                        marginRight: '-0.75rem',
                    }}
                />
                <ChevronRight
                    style={{
                        padding: 0,
                        marginLeft: '-0.6rem',
                    }}
                />
            </Group>
        </ActionIcon>
    </Flex>
);

export default CollapsedMapView;
