import { Text } from '@mantine/core';
import { useRouteLoaderData } from 'react-router-dom';

import { PageLayout } from 'components/Layout/PageLayout';

import DiagnosticResultsMap from './DiagnosticResultsMap';
import UpgradeOptions from './Upgrades/UpgradeOptions';
import SiteLoss from '../Findings/SiteLoss';
import SitePerformance from '../Findings/SitePerformance';
import InspectionContainerStyled from '../shared/InspectionContainer.styled';
import PopoverCard from '../shared/PopoverCard';
import { InspectionLoaderData } from '../types';

const DiagnosticReport = () => {
    const { summary, description, upgradeCosts } = useRouteLoaderData('inspection') as InspectionLoaderData;

    return (
        <PageLayout>
            <InspectionContainerStyled>
                {description && (
                    <PopoverCard>
                        <Text variant="body2light" c="gray.9">
                            {description}
                        </Text>
                    </PopoverCard>
                )}

                <SiteLoss />

                <SitePerformance />

                {upgradeCosts && <UpgradeOptions summary={summary} upgradeCosts={upgradeCosts} />}

                <DiagnosticResultsMap />
            </InspectionContainerStyled>
        </PageLayout>
    );
};

export default DiagnosticReport;
