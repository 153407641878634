import { ChangeEvent, useMemo, useState } from 'react';

import { notifications } from '@dronebase/shared-ui-core';
import { TextInput, Stack, Group, Text, Modal, Button } from '@mantine/core';
import { useRevalidator, useRouteLoaderData } from 'react-router-dom';

import MaskedPhoneInput from 'components/common/Input/TextInput/MaskedPhoneInput';
import { Config } from 'config';
import { REPORT_TYPE_CTA_TEXT } from 'lib/constants';
import { getCookie } from 'lib/cookies';
import { formatDollarAmount } from 'lib/helpers';
import { fetchData } from 'lib/hooks';

import { InspectionLoaderData } from '../../types';

interface UpgradeFormProps {
    type: string;
    opened: boolean;
    close: () => void;
}

const UpgradeModal = ({ type, close, opened }: UpgradeFormProps) => {
    const { upgradeCosts, summary, inspectionReportTypes } = useRouteLoaderData('inspection') as InspectionLoaderData;
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const userInfo = getCookie('helio-user');
    const revalidator = useRevalidator();

    const disableButton = useMemo(
        () => !(firstName?.length + lastName?.length > 2 && phoneNumber?.length === 10),
        [firstName?.length, lastName?.length, phoneNumber?.length],
    );

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let reportTypeUuid;
        let recommendedUpgradeReportTypeUuid;

        inspectionReportTypes.forEach((reportType) => {
            if (reportType.nameExternal === upgradeTypeExternal) {
                reportTypeUuid = reportType.uuid;
            }

            if (
                upgradeCosts &&
                reportType.nameExternal.toLowerCase().replace(/ /g, '_') === upgradeCosts.upgrade_recommended
            ) {
                recommendedUpgradeReportTypeUuid = reportType.uuid;
            }
        });

        // TODO: replace with native fetch api
        const { error } = await fetchData({
            url: `${Config.get('REPORT_PUBLISHING_URL')}/upgrades/create`,
            method: 'POST',
            body: {
                name: `${firstName} ${lastName}`,
                phone: `+1${phoneNumber}`,
                email: userInfo.email,
                upgrade_currency: upgradeCosts?.currency ?? 'USD',
                upgrade_cost: upgradeCosts?.[type] ?? 0,
                super_report_task_uuid: summary?.superReportTaskUuid,
                report_type_uuid: reportTypeUuid,
                recommended_upgrade_report_type_uuid: recommendedUpgradeReportTypeUuid,
            },
        });

        if (error) {
            notifications.error({
                title: 'Upgrade Request Failed',
                message: error,
                withIcon: true,
                withCloseButton: true,
            });
        } else {
            notifications.success({
                title: 'Upgrade Request Received',
                message: 'A Zeitview representative will contact you in 1-3 business days to confirm your request',
                withIcon: true,
                withCloseButton: true,
            });
            revalidator.revalidate();
        }

        close();
    };
    const upgradeTypeExternal = REPORT_TYPE_CTA_TEXT[type];

    return (
        <Modal size="md" opened={opened} onClose={close} title={`Request Upgrade to ${upgradeTypeExternal}`}>
            <Stack>
                <Stack justify="left" c="gray.9">
                    {upgradeCosts?.[type] && (
                        <Text variant="body1" c="gray.9">
                            Costs: {formatDollarAmount(Number(upgradeCosts[type]))}
                        </Text>
                    )}
                </Stack>
                <form onSubmit={handleSubmit}>
                    <Stack>
                        <Group align="center" spacing="1rem" noWrap grow>
                            <TextInput
                                label="First Name"
                                value={firstName}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => setFirstName(event.target.value)}
                                autoComplete="given-name"
                            />
                            <TextInput
                                label="Last Name"
                                value={lastName}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => setLastName(event.target.value)}
                                autoComplete="family-name"
                            />
                        </Group>
                        <TextInput
                            w="100%"
                            label="Email Address"
                            defaultValue={userInfo?.email}
                            disabled
                            type="email"
                            autoComplete="email"
                        />
                        <Group w="50%" pr="0.5rem" grow>
                            <MaskedPhoneInput value={phoneNumber} onChange={(value) => setPhoneNumber(value)} />
                        </Group>
                    </Stack>

                    <Group pt="0.5rem" pb="0.25rem">
                        <Button size="lg" type="submit" disabled={disableButton}>
                            Request Upgrade
                        </Button>
                        <Button variant="text" size="lg" onClick={close}>
                            Cancel
                        </Button>
                    </Group>
                </form>
            </Stack>
        </Modal>
    );
};

export default UpgradeModal;
