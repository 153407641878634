const CombinerIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.18306 13.8081C5.42714 13.564 5.82286 13.564 6.06694 13.8081L7.31694 15.0581C7.56102 15.3021 7.56102 15.6979 7.31694 15.9419L6.06694 17.1919C5.82286 17.436 5.42714 17.436 5.18306 17.1919C4.93898 16.9479 4.93898 16.5521 5.18306 16.3081L5.99112 15.5L5.18306 14.6919C4.93898 14.4479 4.93898 14.0521 5.18306 13.8081Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.18306 3.80806C5.42714 3.56398 5.82286 3.56398 6.06694 3.80806L7.31694 5.05806C7.56102 5.30214 7.56102 5.69786 7.31694 5.94194L6.06694 7.19194C5.82286 7.43602 5.42714 7.43602 5.18306 7.19194C4.93898 6.94786 4.93898 6.55214 5.18306 6.30806L5.99112 5.5L5.18306 4.69194C4.93898 4.44786 4.93898 4.05214 5.18306 3.80806Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.625 8H16.875C17.9105 8 18.75 8.83947 18.75 9.875V11.125C18.75 12.1605 17.9105 13 16.875 13H15.625C14.5895 13 13.75 12.1605 13.75 11.125V9.875C13.75 8.83947 14.5895 8 15.625 8ZM15.625 9.25C15.2798 9.25 15 9.52982 15 9.875V11.125C15 11.4702 15.2798 11.75 15.625 11.75H16.875C17.2202 11.75 17.5 11.4702 17.5 11.125V9.875C17.5 9.52982 17.2202 9.25 16.875 9.25H15.625Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.48223 4.875H2.5V6.125H4.48223L5.10723 5.5L4.48223 4.875ZM7.95789 6.125H8.75C8.9136 6.125 9.02192 6.16676 9.10837 6.22717C9.20349 6.29364 9.30371 6.40556 9.40611 6.58763C9.61911 6.96635 9.7611 7.49056 9.93435 8.1302L9.94335 8.16343C10.1073 8.76875 10.3036 9.48711 10.6499 10.0474C10.7525 10.2133 10.8708 10.3693 11.0071 10.5084C10.8749 10.6503 10.7645 10.8062 10.6711 10.9663C10.3522 11.5129 10.1778 12.2113 10.0306 12.8004L10.0187 12.8484C9.85589 13.4995 9.71852 14.0278 9.4992 14.4038C9.39634 14.5802 9.29161 14.6922 9.18532 14.7614C9.08508 14.8267 8.95164 14.875 8.75 14.875H7.95789C8.1807 15.2605 8.1807 15.7395 7.95789 16.125H8.75C9.17336 16.125 9.54773 16.0171 9.86741 15.8089C10.1811 15.6047 10.4083 15.3261 10.5789 15.0337C10.8978 14.4871 11.0722 13.7887 11.2194 13.1996L11.2313 13.1516C11.3941 12.5005 11.5315 11.9722 11.7508 11.5962C11.8537 11.4198 11.9584 11.3078 12.0647 11.2386C12.1649 11.1733 12.2984 11.125 12.5 11.125H15V9.875H12.5C12.1434 9.875 11.9192 9.72348 11.7132 9.39011C11.48 9.01289 11.3245 8.48125 11.1499 7.83657L11.1206 7.72825C10.9663 7.15622 10.7876 6.494 10.4956 5.97487C10.3344 5.68819 10.1205 5.40949 9.82432 5.20252C9.51943 4.98949 9.15913 4.875 8.75 4.875H7.95789C8.1807 5.26049 8.1807 5.73951 7.95789 6.125ZM2.5 14.875H4.48223L5.10723 15.5L4.48223 16.125H2.5V14.875Z"
            fill="currentColor"
        />
    </svg>
);

export default CombinerIcon;
