import styled from '@emotion/styled';

import { INSPECTION_TABLE_COLORS } from 'lib/constants';

export const OverflowContainer = styled.div({
    overflow: 'auto',
    height: '100%',
});

export const StyledTable = styled.table({
    border: `1px solid ${INSPECTION_TABLE_COLORS.offWhite}`,
    borderCollapse: 'collapse',
    width: '100%',
    fontFamily: 'Roboto',
    fontSize: 14,
});

export const StyledTd = styled.td({
    'border': `1px solid ${INSPECTION_TABLE_COLORS.offWhite}`,
    'padding': 8,
    ':first-of-type': {
        backgroundColor: INSPECTION_TABLE_COLORS.offWhite,
    },
});
