import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';

import DiagnosticHexMap from './DiagnosticReportMap/DiagnosticHexMap';
import DiagnosticResultsInfoModal from './DiagnosticResultsInfoModal';
import PopoverCard from '../shared/PopoverCard';

const DiagnosticResultsMap = () => {
    const { inspectionId, siteId } = useParams();

    return (
        <PopoverCard
            hasBorder={false}
            useUnpaddedContainer
            title="Diagnostic Results"
            modalContent={<DiagnosticResultsInfoModal />}
        >
            <MapContainer>
                <DiagnosticHexMap superReportTaskUuid={inspectionId} siteUuid={siteId} />
            </MapContainer>
        </PopoverCard>
    );
};

export default DiagnosticResultsMap;

const MapContainer = styled.div({
    width: '100%',
});
