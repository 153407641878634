import { useEffect, useMemo, useState } from 'react';

import { useQuery } from 'urql';

import { USER_MANAGEMENT_ROLE } from 'lib/constants';
import { getCookie } from 'lib/cookies';
import { ACTIVE_USER_INVITES } from 'lib/queries/users';
import { WorkspaceInvite } from 'lib/types';
import { getHasuraFetchOptions } from 'lib/urqlClient';

export interface WorkspaceInvitesData {
    invites: WorkspaceInvite[];
    fetchingInvites: boolean;
    refetchInvites: () => void;
}

export const useWorkspaceInvites = (): WorkspaceInvitesData => {
    const queryContext = useMemo(() => ({ fetchOptions: () => getHasuraFetchOptions(USER_MANAGEMENT_ROLE) }), []);
    const [invites, setInvites] = useState<any>([]);
    const workspaceId = getCookie('helio-workspace')?.id;

    const [{ data, fetching: fetchingInvites }, refetchInvites] = useQuery({
        query: ACTIVE_USER_INVITES,
        variables: { workspaceId },
        requestPolicy: 'network-only',
        context: queryContext,
    });

    useEffect(() => {
        const workspaceInvites = data?.workspaceInvites;

        workspaceInvites?.forEach((invite: any, index: number) => {
            // Get the names of each permission in the invitation by their uuid
            const userPermissions = data?.permissions?.filter(
                (p: any) => data?.workspaceInvites[index].permissions.includes(p.uuid),
            );

            invite.permissions = userPermissions;
        });

        setInvites(workspaceInvites ?? []);
    }, [data?.workspaceInvites, data?.permissions]);

    return {
        invites,
        fetchingInvites,
        refetchInvites,
    };
};
