import { ChangeEvent, useCallback, useMemo } from 'react';

import { Group, Radio, Text } from '@mantine/core';
import { useParams } from 'react-router-dom';

import { DIFFERENTIAL_MODES, DIFFERENTIAL_MODES_FILTER_OPTIONS } from 'lib/constants';
import { shortFormatDate } from 'lib/helpers';
import { useDifferentialMode, useAnomalyMapData, useDifferentialAnomalyData } from 'lib/hooks';

const { CHANGED_CURRENT, CHANGED_PREVIOUS, ABSENT } = DIFFERENTIAL_MODES;
const { LATEST, PREVIOUS, CHANGED } = DIFFERENTIAL_MODES_FILTER_OPTIONS;

const DifferentialFilterOption = ({
    optionKey,
    optionValue,
}: {
    optionKey: string;
    optionValue: DIFFERENTIAL_MODES_FILTER_OPTIONS;
}) => {
    const { mode, setMode, currentInspectionDate, previousInspectionDate, setDifferentialModeInspectionId } =
        useDifferentialMode();
    const { inspectionId: currentInspectionId } = useParams();
    const { fetchingReportAnomalies } = useAnomalyMapData();
    const { getAnomalyCountForDifferentialMode, previousInspectionId } = useDifferentialAnomalyData();

    const isSelected =
        mode === optionValue || (optionValue === CHANGED && (mode === CHANGED_CURRENT || mode === CHANGED_PREVIOUS));

    const handleModeChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            if (event.target.value === CHANGED) {
                setMode(CHANGED_CURRENT);
            } else {
                setMode(event.target.value);
            }

            if (
                event.target.value === PREVIOUS ||
                event.target.value === ABSENT ||
                event.target.value === CHANGED_PREVIOUS
            ) {
                setDifferentialModeInspectionId(previousInspectionId);
            } else {
                setDifferentialModeInspectionId(currentInspectionId);
            }
        },
        [currentInspectionId, previousInspectionId, setDifferentialModeInspectionId, setMode],
    );

    const getAnomalyCountForFilterOption = useCallback(
        (option: string) => {
            if (option === CHANGED) {
                return getAnomalyCountForDifferentialMode(
                    mode === CHANGED_PREVIOUS ? CHANGED_PREVIOUS : CHANGED_CURRENT,
                );
            }

            return getAnomalyCountForDifferentialMode(option);
        },
        [getAnomalyCountForDifferentialMode, mode],
    );

    const label = useMemo(() => {
        if (optionValue === LATEST) {
            return `Full ${shortFormatDate(currentInspectionDate)}`;
        }

        if (optionValue === PREVIOUS) {
            return `Full ${shortFormatDate(previousInspectionDate)}`;
        }

        return optionValue;
    }, [optionValue, currentInspectionDate, previousInspectionDate]);

    const anomalyCount: string = useMemo(
        () => getAnomalyCountForFilterOption(optionValue).toLocaleString(),
        [getAnomalyCountForFilterOption, optionValue],
    );

    return (
        <Group key={optionKey} align="center" position="apart" noWrap>
            <Group align="center">
                <Radio
                    checked={isSelected}
                    onChange={handleModeChange}
                    value={optionValue}
                    styles={{ radio: { '&:hover': { cursor: 'pointer' } } }}
                />
                <Text pr="0.5rem" variant="body1" c="gray.1">
                    {label}
                </Text>
            </Group>
            {!fetchingReportAnomalies && (
                <Text variant="body1" c="gray.1">
                    {anomalyCount}
                </Text>
            )}
        </Group>
    );
};

export default DifferentialFilterOption;
