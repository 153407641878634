import { ComponentPropsWithRef, forwardRef } from 'react';

import { ChevronDown as IconChevronDown } from '@dronebase/shared-ui-icons';
import { Button, ButtonProps, createStyles, getStylesRef } from '@mantine/core';

const useStyles = createStyles(() => ({
    root: {
        'padingLeft': '0.2rem',
        'padingRight': '0.2rem',
        'paddingTop': '0.1rem',
        'paddingBottom': '0.1rem',
        'border': '1px solid var(--color-grey-200)',
        'color': 'var(--color-grey-900)',
        'boxShadow': '0 2px 4px rgba(0, 0, 0, 0.15)',
        '&[aria-expanded="true"]': {
            [`& .${getStylesRef('rightIcon')}`]: {
                transform: 'rotate(-0.5turn)',
            },
        },
    },
    label: {
        width: '100%',
    },
    rightIcon: {
        ref: getStylesRef('rightIcon'),
        width: 16,
        height: 16,
        transition: 'transform 0.5s',
        marginLeft: '0.1rem',
        marginRight: '-0.2rem',
    },
}));

export type GreyDropdownButtonProps = ComponentPropsWithRef<'button'> & Omit<ButtonProps, 'rightIcon'>;

const GreyDropdownButton = forwardRef<HTMLButtonElement, GreyDropdownButtonProps>(({ children, ...props }, ref) => {
    const { classes } = useStyles();

    return (
        <Button
            ref={ref}
            classNames={{
                root: classes.root,
                label: classes.label,
                rightIcon: classes.rightIcon,
            }}
            rightIcon={<IconChevronDown />}
            {...props}
        >
            {children}
        </Button>
    );
});

export default GreyDropdownButton;
