import { useCallback, useEffect, useMemo, useState } from 'react';

import { DIFFERENTIAL_MODES } from 'lib/constants';
import { getSignalCounts } from 'lib/helpers';
import createCustomHook from 'lib/helpers/hookFactory';

import { useDifferentialAnomalyData } from '.';
import { useDifferentialMode } from '../useDifferentialMode';

const ValueProvider = () => {
    const [previousSignalCounts, setPreviousSignalCounts] = useState<any>();

    const { active: isDifferentialModeActive, mode } = useDifferentialMode();
    const { previousRawMapAnomalies } = useDifferentialAnomalyData();

    useEffect(() => {
        if (isDifferentialModeActive) {
            setPreviousSignalCounts(getSignalCounts(previousRawMapAnomalies));
        }
    }, [isDifferentialModeActive, previousRawMapAnomalies]);

    const getDifferentialSignalCount = useCallback(
        (signalFilter: string) => {
            if (isDifferentialModeActive && mode === DIFFERENTIAL_MODES.LATEST) {
                return previousSignalCounts?.[signalFilter] || 0;
            }
        },
        [isDifferentialModeActive, mode, previousSignalCounts],
    );

    const value = useMemo(
        () => ({
            getDifferentialSignalCount,
        }),
        [getDifferentialSignalCount],
    );

    return value;
};

const { Provider: DifferentialSignalCountProvider, useCustomHook: useDifferentialSignalCount } = createCustomHook<any>({
    name: 'useDifferentialSignalCount',
    callback: ValueProvider,
});

export { DifferentialSignalCountProvider, useDifferentialSignalCount };
