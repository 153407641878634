import { useMemo } from 'react';

import { Loader } from '@dronebase/shared-ui-core';
import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';
import { useQuery } from 'urql';

import { GRADE_COLORS, PERFORMANCE_RANGE_TABLE_COLUMNS, QUARTILE_RANGE } from 'lib/constants';
import { formatPercentage } from 'lib/helpers';
import { GET_PERFORMACE_RANGES_FOR_INSPECTION_UUID } from 'lib/queries';

const QuartileTable = ({ siteCapacity }: { siteCapacity: number }) => {
    const { inspectionId } = useParams<{ inspectionId: string }>();

    const [{ data, fetching, error }] = useQuery({
        query: GET_PERFORMACE_RANGES_FOR_INSPECTION_UUID,
        variables: {
            superReportTaskUuid: inspectionId,
            siteCapacity,
        },
    });

    const quartiles = useMemo(
        () =>
            data?.superReportTask?.performanceRating?.ranges?.map((range: any) => ({
                quartile: QUARTILE_RANGE[range.grade],
                grade: range.grade,
                bos: `${formatPercentage(range.bosLower)} - ${formatPercentage(range.bosUpper)}`,
                major: `${formatPercentage(range.majorLower)} - ${formatPercentage(range.majorUpper)}`,
                minor: `${formatPercentage(range.minorLower)} - ${formatPercentage(range.minorUpper)}`,
                color: GRADE_COLORS[range.grade],
            })),
        [data],
    );

    if (fetching) {
        return <Loader p="4rem" />;
    }

    if (error || !quartiles || quartiles?.length === 0) {
        return null;
    }

    return (
        <TableContainer>
            <StyledTable>
                <thead>
                    <tr className="table-header">
                        <th>Quartile</th>
                        <th>Grade</th>
                        <th>BOS Range</th>
                        <th>Major Module Range</th>
                        <th>Minor Module Range</th>
                    </tr>
                </thead>
                <tbody>
                    {quartiles?.map((row: any, idx: number) => (
                        <tr style={{ backgroundColor: row.color }} key={`quartile-row-${idx}`}>
                            {PERFORMANCE_RANGE_TABLE_COLUMNS.map((c, idx) => (
                                <td key={`${row[c]}-${idx}`}>{row[c]}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </StyledTable>
        </TableContainer>
    );
};

export default QuartileTable;

const TableContainer = styled.div({
    width: '100%',
    marginTop: '30px',
    border: '2px solid #ecf7ff',
    borderRadius: '8px',
});

const StyledTable = styled.table({
    'borderCollapse': 'collapse',
    'width': '100%',
    '& th': {
        textAlign: 'left',
        padding: '1rem',
        textTransform: 'uppercase',
    },
    '& td': {
        borderTop: '1px solid #ccc',
        borderBottom: '1px solid #ccc',
        padding: '1rem',
        borderColor: 'light-gray',
        color: 'white',
        fontWeight: 'bold',
    },
});
