import Cookie from 'universal-cookie';

const createCookieObject = ({ expires, expireNow }: { expires?: boolean; expireNow?: boolean }) => {
    const cookieObject: {
        path: string;
        maxAge?: number;
    } = {
        path: '/',
    };

    if (expires) {
        cookieObject.maxAge = 60 * 60 * 8; // 8 hours
    }

    if (expireNow) {
        cookieObject.maxAge = 0;
    }

    return cookieObject;
};

const cookies = new Cookie();

export const getCookie = (key: string) => cookies.get(key);

export const setCookie = (name: string, value: any, expires = false) =>
    cookies.set(name, value, createCookieObject({ expires }));

export const removeCookie = (key: string) => cookies.set(key, '', createCookieObject({ expireNow: true }));

export const clearCookies = () => {
    const allCookies = cookies.getAll();

    return Object.keys(allCookies).forEach((cookie) => removeCookie(cookie));
};
