import { gql } from 'urql';

export const GET_WORKSPACE_SITES = gql`
    query WorkspaceSites($workspaceId: uuid) {
        inspectionYears: sites_sites(where: { workspace_uuid: { _eq: $workspaceId } }) {
            tasks: super_tasks(order_by: { super_report_task: { inspection_date: desc_nulls_last } }) {
                reports: super_report_task {
                    lastInspectionDate: inspection_date
                }
            }
        }
        allSites: tasking_site_latest_report_summary_view(where: { workspace_uuid: { _eq: $workspaceId } }) {
            siteName: name
            uuid: site_uuid
            capacity: size_dc_mw
            inspectionID: super_report_task_uuid
            inspectionType: name_external
            powerLoss: power_loss_kilowatts
            lastInspectionDate: last_inspection_date
            bos: bos_percentage
            bosGrade: bos_grade
            major: major_percentage
            majorGrade: major_grade
            minor: minor_percentage
            minorGrade: minor_grade
            city
            state: province
            country
            location
            superAnalysisTasksAggregate: super_analysis_tasks_aggregate {
                aggregate {
                    count
                }
            }
        }
        aggregate: sites_sites_aggregate(where: { workspace_uuid: { _eq: $workspaceId } }) {
            aggregate {
                count
                max {
                    capacity: size_dc_mw
                }
                min {
                    capacity: size_dc_mw
                }
            }
        }
    }
`;

export const GET_SITE = gql`
    query Site(
        $siteId: uuid!
        $workspaceId: uuid
        $orderBy: [tasking_site_report_listing_view_order_by!] = { inspection_date: asc_nulls_first }
    ) {
        sites: tasking_site_latest_report_summary_view(
            where: { site_uuid: { _eq: $siteId }, workspace_uuid: { _eq: $workspaceId } }
        ) {
            siteId: site_uuid
            name
            geometry
            capacity: size_dc_mw
            city
            province
            country
            rackingType: properties(path: "rackingType")
            moduleType: properties(path: "moduleType")
            location
            lastInspectionDate: last_inspection_date
        }
        inspections: tasking_site_report_listing_view(where: { site_uuid: { _eq: $siteId } }, order_by: $orderBy) {
            bosGrade: bos_grade
            bos: bos_percentage
            publishDate: inspection_date
            majorGrade: major_grade
            major: major_percentage
            minorGrade: minor_grade
            minor: minor_percentage
            powerLoss: power_loss_kilowatts
            inspectionID: super_report_task_uuid
            reportType: report_type
            superAnalysisTasksAggregate: super_analysis_tasks_aggregate {
                aggregate {
                    count
                }
            }
        }
    }
`;

export const GET_SITE_NAME = gql`
    query Site($siteId: uuid!) {
        site: sites_sites_by_pk(uuid: $siteId) {
            uuid
            name
            capacity: size_dc_mw
        }
    }
`;

export const GET_SITE_TRACKER_TYPE = gql`
    query Site($siteId: uuid!) {
        site: sites_sites_by_pk(uuid: $siteId) {
            trackerType: properties(path: "tracker_type")
        }
    }
`;
