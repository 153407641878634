import styled from '@emotion/styled';

export const AppLayoutContainer = styled.div({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: '100%',
});

export const FlexContainer = styled.div({
    display: 'flex',
    position: 'relative',
    overflow: 'hidden',
    flexBasis: '100%',
});

export const AppContentContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    flexBasis: '100%',
});
