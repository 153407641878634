export const PowerLossIcon = () => (
    <svg width="35" height="32" viewBox="0 0 35 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.73184 6.01201C9.98093 5.66022 10.4606 5.58234 10.8033 5.83807L21.0306 13.4707C21.2596 13.6417 21.3783 13.9283 21.3393 14.2163C21.3002 14.5043 21.1098 14.7467 20.8439 14.847L11.1104 18.5157L23.6242 26.1254L22.8924 21.8775C22.8186 21.4492 23.097 21.0406 23.5141 20.9649C23.9313 20.8891 24.3293 21.1749 24.403 21.6032L25.4258 27.5397C25.4626 27.7535 25.4118 27.9734 25.2855 28.1474C25.1592 28.3215 24.9684 28.4343 24.7584 28.4592L17.5993 29.3072C17.1785 29.3571 16.7979 29.0473 16.7494 28.6152C16.7008 28.1831 17.0026 27.7925 17.4234 27.7426L22.3197 27.1626L8.93865 19.0256C8.68383 18.8707 8.53867 18.5789 8.56572 18.2762C8.59276 17.9734 8.78718 17.7136 9.0652 17.6088L18.9659 13.877L9.90127 7.11203C9.55861 6.85631 9.48276 6.36381 9.73184 6.01201Z"
            fill="#5B4DD3"
            stroke="#5B4DD3"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M22.6625 8.46358L27.4755 2.44723C27.5594 2.34243 27.727 2.42543 27.6944 2.55564L26.5388 7.17802C26.5191 7.25691 26.5788 7.33333 26.6601 7.33333H30.2399C30.3447 7.33333 30.403 7.45458 30.3375 7.53642L25.5245 13.5528C25.4406 13.6576 25.273 13.5746 25.3056 13.4444L26.4612 8.82198C26.4809 8.74309 26.4212 8.66667 26.3399 8.66667H22.7601C22.6553 8.66667 22.597 8.54543 22.6625 8.46358Z"
            fill="#5B4DD3"
            stroke="#5B4DD3"
            strokeWidth="0.3"
        />
    </svg>
);
