import { useQuery } from 'urql';

import { GET_ANOMALIES_BY_ASSET_REGION } from 'lib/queries';

export const useAssetRegionAnomalies = ({
    assetRegionUuid,
    superAnalysisTaskUUIDs,
}: {
    assetRegionUuid?: string;
    superAnalysisTaskUUIDs?: string[];
}) => {
    const [{ data, fetching, error }, refetchAssetRegionAnomalies] = useQuery({
        variables: {
            assetRegionUuid,
            superAnalysisTaskUUIDs,
        },
        pause: !superAnalysisTaskUUIDs || superAnalysisTaskUUIDs.length === 0,
        query: GET_ANOMALIES_BY_ASSET_REGION('modal'),
        requestPolicy: 'network-only',
    });

    return {
        anomalies: data?.anomalyEntries ?? [],
        fetching,
        error,
        refetchAssetRegionAnomalies,
    };
};
