import { Stack, Text } from '@mantine/core';

import ModalSection from '../../inspections/shared/ModalSection';
import ModalTitle from '../../inspections/shared/ModalTitle';

const ModuleBasicInfoModal = () => (
    <>
        <ModalTitle title="What’s Included In The Module Basic Inspection?" />
        <ModalSection title="Description">
            <Stack spacing="0.5rem">
                <Text>
                    The Module Basic inspection identifies all anomalies with major energy impacts. It includes a module
                    level aerial scan performed by Helioytics.
                </Text>

                <Text>
                    Using our proprietary HL Insight engine, we capture a high-resolution scan of the site using a
                    combination of IR and RGB visual imagery. It allows us to precisely identify the location, category,
                    and severity of all DC faults impacting the energy loss of the site, down to the sub-module level.
                    This scope can also include data collection for warranty returns of major anomaly modes if required,
                    as well as mapping of anomalies for efficient remediation.
                </Text>
            </Stack>
        </ModalSection>

        <ModalSection title="Scope of Inspection Service">
            <Stack spacing="0.5rem">
                <Text>
                    Summary of power losses and key observations of the site attributed to balance of system (BoS)
                    faults, and module-level anomalies with significant power loss
                </Text>

                <Text>
                    Industry benchmarking of estimated power losses due to BoS faults, and module-level anomalies with
                    major power losses
                </Text>

                <Text>
                    <ul style={{ listStyleType: 'disc', margin: '0 20px' }}>
                        <li>
                            Summary of power losses and key observations of the site attributed to balance of system
                            (BoS) faults, module-level anomalies with significant power loss
                        </li>
                        <li>
                            Industry benchmarking of power losses due to BoS faults, and module-level anomalies with
                            Major Module power losses
                        </li>
                        <li>Identification of the following anomalies:</li>
                        <ul style={{ listStyleType: 'disc', margin: '0 20px' }}>
                            <li>Inverter outages</li>
                            <li>Sectional outages</li>
                            <li>All string-level anomalies</li>
                            <li>Module faults with energy loss affecting &gt;&#61;33% of a module</li>
                            <li>Sub-module faults with energy loss affecting &gt;&#61;33% of a module</li>
                            <li>Hot-spots with &gt;&#61;10°C temperature difference</li>
                        </ul>
                        <li>List of modules impacted by anomalies</li>
                        <li>Site schematic with locations of anomalies</li>
                    </ul>
                </Text>
            </Stack>
        </ModalSection>
    </>
);

export default ModuleBasicInfoModal;
