import moment from 'moment';

import { NO_INSPECTIONS } from 'lib/constants';
import { getCookie, setCookie } from 'lib/cookies';

export const getDateLocale = (date: string | number | Date | null) => {
    if (date) {
        const _date = new Date(date);

        return _date.toISOString().substring(0, 10);
    } else {
        return 'N/A';
    }
};

// TODO: Check with product if this is the correct date format
export const formatDate = (date: Date | string) => moment(date).format('MMMM D, YYYY');
export const shortFormatDate = (date: Date) => moment(date).format('MMM D, YYYY');
export const fileFormatDate = (date: Date) => moment(date).format('MMM-D-YYYY');
export const formatDateWithLocalTime = (date: Date) => moment.utc(date).local().format('h:mm a, MMMM D, YYYY');

type InspectionYears = {
    tasks: {
        reports: {
            lastInspectionDate: string;
        };
    }[];
};

export const generateInspectionYearsMap = (
    inspectionYears: InspectionYears[],
    workspaceId: string,
    sitesCount: number,
) => {
    const currentInspectionYears = getCookie('helio-inspectionYears');
    const _years: string[] = [];

    if (currentInspectionYears && currentInspectionYears.workspaceId === workspaceId) {
        return;
    }

    if (!sitesCount) {
        _years.push(NO_INSPECTIONS);
    }

    inspectionYears.forEach(({ tasks }) =>
        tasks.map(({ reports }) => {
            const _year = reports?.lastInspectionDate
                ? new Date(reports.lastInspectionDate).getFullYear().toString()
                : NO_INSPECTIONS;

            _year && !_years.includes(_year) && _years.push(_year);
        }),
    );

    setCookie('helio-inspectionYears', { years: _years.sort().reverse(), workspaceId });
};
