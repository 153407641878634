import { FirebaseOptions, getApp, getApps, initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

import { Config } from 'config';

let app;

const firebaseConfig: FirebaseOptions = {
    apiKey: String(Config.get('FIREBASE_PUBLIC_API_KEY')),
    authDomain: String(Config.get('FIREBASE_AUTH_DOMAIN')),
    projectId: String(Config.get('FIREBASE_PROJECT_ID')),
};

if (getApps().length) {
    app = getApp();
} else {
    app = initializeApp(firebaseConfig);
}

const auth = getAuth(app);

export default auth;
