import { useMemo } from 'react';

import { Pagination } from '@dronebase/shared-ui-core';
import styled from '@emotion/styled';
import { Group, Stack, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useAtom, useAtomValue } from 'jotai';

import NoSitesFoundIcon from 'components/common/Icons/NoSitesFoundIcon';
import Table from 'components/common/Table';
import { Pill } from 'components/pill/Pill';
import { LOCAL_STORAGE_KEYS, SORT_DIRECTION } from 'lib/constants';
import { ROUTES } from 'lib/constants/routes';
import { getDateLocale } from 'lib/helpers';
import { get } from 'lib/helpers/localStorage';
import { useSitesPagePagination } from 'lib/hooks/useManualPagination';
import { sortedByFilterWithResetAtom } from 'state/sites/filters/siteFiltersStorage';
import { useActiveSiteFilters } from 'state/sites/filters/utils/useActiveSiteFilters';
import { sitesWithFiltersAtom } from 'state/sites/sites';

import {
    generateFaultTypeContent,
    generateLinkTypeContent,
    generateTextTypeContent,
    getInspectionPagePath,
    tableColumns,
} from '../SitesTableCols';
import { Site } from '../types';

const SitesListView = () => {
    const sitesToDisplayInTable = useAtomValue(sitesWithFiltersAtom);
    const activeFilters = useActiveSiteFilters();
    const [sortedBy, setSortedBy] = useAtom(sortedByFilterWithResetAtom);

    const tableSort = useMemo(() => {
        const { key, direction } = sortedBy;

        return {
            id: key,
            desc: direction === SORT_DIRECTION.DESC,
        };
    }, [sortedBy]);

    const sortedFilteredSites = useMemo(
        () =>
            sitesToDisplayInTable.toSorted((a, b) => {
                const key = tableSort.id as keyof Site;

                if (a[key] === b[key]) {
                    return 0;
                }

                if (a[key] === null) {
                    return tableSort.desc ? 1 : -1;
                }

                if (b[key] === null) {
                    return tableSort.desc ? -1 : 1;
                }

                // TS is yelling that object may be null
                // even though the check for null is already
                // happening above. Need "!" so it can
                // stop complaining.
                if (tableSort.desc) {
                    // if descending, highest sorts first
                    return a[key]! < b[key]! ? 1 : -1;
                } else {
                    // if ascending, lowest sorts first
                    return a[key]! < b[key]! ? -1 : 1;
                }
            }),
        [sitesToDisplayInTable, tableSort.id, tableSort.desc],
    );

    const sitesData = useMemo(
        () =>
            sortedFilteredSites.map((site) => {
                const inspectionPagePath = getInspectionPagePath(
                    site.inspectionID,
                    site.inspectionType,
                    site.superAnalysisTasksAggregate.aggregate.count,
                );

                const siteDetails = {
                    siteName: generateLinkTypeContent(`/sites/${site.uuid}`, site.siteName),
                    lastInspectionDate: generateLinkTypeContent(
                        `${ROUTES.sites.index}/${site.uuid}/${inspectionPagePath}`,
                        getDateLocale(site.lastInspectionDate),
                    ),
                    inspectionType: generateTextTypeContent(site.inspectionType),
                    capacity: generateTextTypeContent(parseFloat(site.capacity.toFixed(2)).toLocaleString()),
                    city: generateTextTypeContent(site.city),
                    state: generateTextTypeContent(site.state),
                    country: generateTextTypeContent(site.country),
                };

                const bos = { value: site.bos?.toFixed(2) ?? null, grade: site.bosGrade };
                const major = {
                    value: site.major?.toFixed(2) ?? null,
                    grade: site.majorGrade,
                };
                const minor = {
                    value: site.minor?.toFixed(2) ?? null,
                    grade: site.minorGrade,
                };

                return {
                    powerLoss: generateTextTypeContent(site.powerLoss?.toString()),
                    bos: generateFaultTypeContent('bos', bos, site.inspectionType),
                    major: generateFaultTypeContent('major', major, site.inspectionType),
                    minor: generateFaultTypeContent('minor', minor, site.inspectionType),
                    ...siteDetails,
                };
            }),
        [sortedFilteredSites],
    );

    const { paginatedData: paginatedSitesData, paginationProps: sitesPaginationProps } =
        useSitesPagePagination(sitesData);

    const [isTableView] = useDisclosure(get(LOCAL_STORAGE_KEYS.SITES_TABLE_VIEW) !== 'false');

    const hasNoSites = useMemo(() => !sitesToDisplayInTable.length, [sitesToDisplayInTable]);

    return (
        <SitesListViewContainer>
            <Group align="flex-start" position="apart" noWrap>
                <Group>
                    {activeFilters.map((activeFilter, i) => (
                        <Pill {...activeFilter} key={i} />
                    ))}
                </Group>
                <Pagination {...sitesPaginationProps} withTotal withPageSize withDoubleArrows />
            </Group>

            {hasNoSites ? (
                <Stack align="center" spacing="1rem" h="100%" justify="center">
                    <NoSitesFoundIcon />

                    <Text variant="subtitle1" c="gray.9">
                        {activeFilters.length ? 'No sites match search criteria' : 'No sites found with inspections'}
                    </Text>
                </Stack>
            ) : isTableView ? (
                <>
                    <Table
                        data={paginatedSitesData}
                        columns={tableColumns}
                        handleSort={(key, direction) => setSortedBy({ key, direction })}
                        sort={tableSort}
                    />
                    <Text fz="0.875rem" c="gray.4">
                        <Text component="span" c="var(--color-purple-400)">
                            *
                        </Text>{' '}
                        Power Loss = BoS, Major Module and Minor Module represent the values from the recent inspection{' '}
                    </Text>
                </>
            ) : null}
        </SitesListViewContainer>
    );
};

export default SitesListView;

const SitesListViewContainer = styled(Stack)({
    overflowY: 'auto',
    overflowX: 'hidden',
    height: '100%',
});
