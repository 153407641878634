const UsersIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            className="controlled-fill"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.46364 7.38462C6.46364 5.69706 7.77606 4.38462 9.32727 4.38462C10.8785 4.38462 12.1909 5.69706 12.1909 7.38462C12.1909 9.07217 10.8785 10.3846 9.32727 10.3846C7.77606 10.3846 6.46364 9.07217 6.46364 7.38462ZM9.32727 3C6.9422 3 5.06364 4.99376 5.06364 7.38462C5.06364 9.77547 6.9422 11.7692 9.32727 11.7692C11.7123 11.7692 13.5909 9.77547 13.5909 7.38462C13.5909 4.99376 11.7123 3 9.32727 3ZM15.745 3.14364C15.3716 3.04458 14.9877 3.26365 14.8875 3.63295C14.7874 4.00224 15.0089 4.38192 15.3823 4.48098C15.9918 4.64267 16.5385 5.01167 16.9324 5.53736C17.3266 6.06357 17.5434 6.71506 17.5434 7.38923C17.5434 8.0634 17.3266 8.71489 16.9324 9.2411C16.5385 9.76679 15.9918 10.1358 15.3823 10.2975C15.0089 10.3965 14.7874 10.7762 14.8875 11.1455C14.9877 11.5148 15.3716 11.7339 15.745 11.6348C16.6686 11.3898 17.4807 10.835 18.0572 10.0654C18.6334 9.29633 18.9434 8.35476 18.9434 7.38923C18.9434 6.4237 18.6334 5.48213 18.0572 4.71306C17.4807 3.94347 16.6686 3.38866 15.745 3.14364ZM5.76364 14.0769C4.62206 14.0769 3.53422 14.5471 2.73742 15.3727C1.94167 16.1971 1.5 17.3088 1.5 18.4615V20.3077C1.5 20.69 1.8134 21 2.2 21C2.5866 21 2.9 20.69 2.9 20.3077V18.4615C2.9 17.6558 3.20924 16.8891 3.75011 16.3287C4.28993 15.7694 5.01494 15.4615 5.76364 15.4615H12.8909C13.6396 15.4615 14.3646 15.7694 14.9044 16.3287C15.4453 16.8891 15.7545 17.6558 15.7545 18.4615V20.3077C15.7545 20.69 16.0679 21 16.4545 21C16.8411 21 17.1545 20.69 17.1545 20.3077V18.4615C17.1545 17.3088 16.7129 16.1971 15.9171 15.3727C15.1203 14.5471 14.0325 14.0769 12.8909 14.0769H5.76364ZM19.31 14.2209C18.9369 14.1211 18.5525 14.3394 18.4516 14.7085C18.3506 15.0775 18.5713 15.4577 18.9445 15.5575C19.5524 15.7201 20.0975 16.0891 20.4902 16.6139C20.8832 17.1393 21.0995 17.7893 21.1 18.462V20.3077C21.1 20.69 21.4134 21 21.8 21C22.1866 21 22.5 20.69 22.5 20.3077V18.461C22.4993 17.4976 22.19 16.5581 21.6156 15.7903C21.0407 15.022 20.2312 14.4674 19.31 14.2209Z"
        />
    </svg>
);

export default UsersIcon;
