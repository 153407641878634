import { useCallback, useEffect, useMemo, useState } from 'react';

import { MapView, WebMercatorViewport } from '@deck.gl/core/typed';
import DeckGL from '@deck.gl/react/typed';
import styled from '@emotion/styled';
import { ActionIcon, Button, Group, Text } from '@mantine/core';
import { multiPoint } from '@turf/turf';
import { useAtom, useSetAtom } from 'jotai';
import { Map } from 'react-map-gl';
import { useNavigate, useParams } from 'react-router-dom';

import ExpandIcon from 'components/common/Icons/ExpandIcon';
import CollapseIcon from 'core/Icons/CollapseIcon';
import { AssetTypes, MAPBOX_TOKEN, MODULE_LAYER_ID, MIN_ANOMALY_ZOOM_LEVEL, MIN_ZOOM_PICKABLE } from 'lib/constants';
import { ROUTES } from 'lib/constants/routes';
import { STADIA_STYLE } from 'lib/constants/stadiaStyle';
import { generateLayerFilter, getRightBorderMidpoint } from 'lib/helpers';
import { useAnomalyMapData, useMapLayers, useSiteGeometry } from 'lib/hooks/map';
import { useHighlightedAssets } from 'lib/hooks/map/useHighlightedAssets';
import { useMiniMapLayers } from 'lib/hooks/map/useMiniMapLayers';
import { useSelectedAsset } from 'lib/hooks/map/useSelectedAsset';
import { viewStateAtom, hoverInfoAtom, isAssetSelectedAtom } from 'state/inspections/anomaliesMap';

import AnomalyMapTooltip from './AnomalyMapTooltip';
import MapControls from './MapControls';
import { getBBoxFromGeometry } from '../../../lib/helpers/deckgl';

const [minimapHeight, minimapWidth] = [0.2, 0.2];

const AnomaliesMap = () => {
    const setIsAssetSelected = useSetAtom(isAssetSelectedAtom);
    const [mainMapDimensions, setMainMapDimensions] = useState({ width: null, height: null });
    const setHoverInfo = useSetAtom(hoverInfoAtom);
    const { filteredMapAnomalies } = useAnomalyMapData();
    const [viewState, setViewState] = useAtom(viewStateAtom);
    const [miniMapViewState, setMiniMapViewState] = useState(null);
    const { siteGeometry, siteBounds } = useSiteGeometry();
    const { assetLayer, siteOutlineLayer, combinerLayer, inverterLayer, stackLayer, setLayer, scatterplotLayer } =
        useMapLayers();
    const { minimapLayers, minimapVisible, setMinimapVisible } = useMiniMapLayers();
    const { setHighlightedInverterUuid, setHighlightedCombinerUuid, setHighlightedStackLayerUuid } =
        useHighlightedAssets();
    const { onAssetHover, getAssetAndAnomalies } = useSelectedAsset();
    const [isDragging, setIsDragging] = useState(false);
    const [isPickable, setIsPickable] = useState(true);
    const navigate = useNavigate();
    const { siteId, inspectionId } = useParams();

    useEffect(() => {
        setIsPickable(!isDragging && viewState.zoom >= MIN_ZOOM_PICKABLE);

        if (isDragging) {
            setHoverInfo(undefined);
        }
    }, [isDragging, setHoverInfo, viewState.zoom]);

    const handleAssetHover = useCallback(
        (info) => {
            if (info?.object?.geometry) {
                const coordinates = getRightBorderMidpoint(info.object.geometry);

                let [x, y] = [info.x, info.y];

                if (info.layer) {
                    const { viewport } = info.layer.context;

                    [x, y] = viewport.project(coordinates);
                }

                const assetRegion = filteredMapAnomalies[info?.object?.properties?.uuid];

                setHoverInfo({
                    x,
                    y,
                    longitude: coordinates[0],
                    latitude: coordinates[1],
                    assetId: info.object.properties.uuid,
                    combinerId: info.object.properties.nom_combiner,
                    inverterId: info.object.properties.nom_inverter,
                    positionId: info.object.properties.position_id,
                    stack: info.object.properties.nom_stack,
                    species: assetRegion?.species,
                    name: assetRegion?.species?.map((species) => species.name).join(', '),
                    assetRegion,
                });
            } else {
                setHoverInfo();
            }
        },
        [filteredMapAnomalies, setHoverInfo],
    );

    // TODO: Remove this once the decision is made not to show the hover state on inverters & combiners.
    const handleLayerHover = useCallback((info, setUuid) => {
        const geometry = info.object?.geometry;
        const properties = info.object?.properties;

        const uuid = geometry ? properties.uuid : null;

        setUuid(uuid);
    }, []);

    const handleViewStateChange = useCallback(
        ({ viewState: newViewState }) => {
            if (siteBounds) {
                newViewState.longitude = Math.min(
                    siteBounds.maxLon,
                    Math.max(siteBounds.minLon, newViewState.longitude),
                );
                newViewState.latitude = Math.min(siteBounds.maxLat, Math.max(siteBounds.minLat, newViewState.latitude));
                newViewState.minZoom = 12; // calculated from zoomToBoundingBox
            }

            if (mainMapDimensions.width !== newViewState.width || mainMapDimensions.height !== newViewState.height) {
                setMainMapDimensions({ width: newViewState.width, height: newViewState.height });
            }

            setViewState(newViewState);
        },
        [mainMapDimensions.height, mainMapDimensions.width, setViewState, siteBounds],
    );

    const [mainView, minimapView] = useMemo(
        () => [
            new MapView({ id: 'main', controller: true }),
            new MapView({
                id: 'miniMap',
                x: 25,
                y: 25,
                width: `calc(${minimapWidth * 100}%-10px)`,
                height: `calc(${minimapHeight * 100}%-10px)`,
                clear: true,
            }),
        ],
        [],
    );

    const layerFilter = useMemo(
        () => generateLayerFilter(minimapView.id, ['minimap-site-outline', 'viewport-bounds', 'viewport-shade']),
        [minimapView],
    );

    const updateMainMapViewState = useCallback(
        (geometry) => {
            const { width, height } = mainMapDimensions;
            const mainMercView = new WebMercatorViewport({ width, height });
            const bounds = getBBoxFromGeometry(geometry);
            const { longitude, latitude, zoom } = mainMercView.fitBounds(bounds, { padding: 60 });

            setViewState((prevState) => ({
                ...prevState,
                longitude,
                latitude,
                zoom,
            }));
        },
        [mainMapDimensions, setViewState],
    );

    const updateMiniMapViewState = useCallback(
        (geometry) => {
            const bounds = getBBoxFromGeometry(geometry);
            const miniMapMercView = new WebMercatorViewport({
                width: mainMapDimensions.width * 0.2,
                height: mainMapDimensions.height * 0.2,
            });

            const { latitude, longitude, zoom, height, width } = miniMapMercView.fitBounds(bounds, {
                padding: 14,
            });

            setMiniMapViewState({ latitude, longitude, zoom, height, width });
        },
        [mainMapDimensions.height, mainMapDimensions.width],
    );

    useEffect(() => {
        if (siteGeometry && !!mainMapDimensions.width && !!mainMapDimensions.height) {
            updateMainMapViewState(siteGeometry);
            updateMiniMapViewState(siteGeometry);
        }
    }, [
        mainMapDimensions.height,
        mainMapDimensions.width,
        siteGeometry,
        updateMainMapViewState,
        updateMiniMapViewState,
    ]);

    const layerHandlers = useMemo(
        () => ({
            [AssetTypes.inverter.layerId]: {
                onHover: (info) => handleLayerHover(info, setHighlightedInverterUuid),
                offHover: () => setHighlightedInverterUuid(null),
            },
            [AssetTypes.combiner.layerId]: {
                onHover: (info) => handleLayerHover(info, setHighlightedCombinerUuid),
                offHover: () => setHighlightedCombinerUuid(null),
            },
            [AssetTypes.stack.layerId]: {
                onHover: (info) => handleLayerHover(info, setHighlightedStackLayerUuid),
                offHover: () => setHighlightedStackLayerUuid(null),
            },
            [MODULE_LAYER_ID]: { onHover: handleAssetHover, offHover: () => handleAssetHover(null) },
        }),
        [
            handleAssetHover,
            handleLayerHover,
            setHighlightedInverterUuid,
            setHighlightedCombinerUuid,
            setHighlightedStackLayerUuid,
        ],
    );

    const buildTooltip = useCallback(
        (object) => {
            if (object.picked) {
                const { x, y } = object;

                Object.keys(layerHandlers).forEach((layerID) => {
                    const layerInfo = object.layer.context.deck.pickObject({
                        x,
                        y,
                        layerIds: [layerID],
                    });

                    if (layerInfo) {
                        layerHandlers[layerID].onHover(layerInfo);
                        onAssetHover(layerInfo);
                    } else {
                        layerHandlers[layerID].offHover();
                    }
                });
            } else {
                Object.values(layerHandlers).forEach((value) => value.offHover());
            }
        },
        [layerHandlers, onAssetHover],
    );

    const handleClick = useCallback(
        (object) => {
            if (object.picked && viewState.zoom > MIN_ANOMALY_ZOOM_LEVEL) {
                const { x, y } = object;

                const assetLayerInfo = object.layer.context.deck.pickObject({
                    x,
                    y,
                    layerIds: [MODULE_LAYER_ID],
                });

                if (assetLayerInfo && assetLayerInfo.object?.properties?.uuid && assetLayerInfo.coordinate) {
                    setIsAssetSelected(true);
                    const { anomalies } = getAssetAndAnomalies({
                        assetRegionUuid: assetLayerInfo.object.properties.uuid,
                        inverterId: assetLayerInfo.object.properties.nom_inverter,
                        combinerId: assetLayerInfo.object.properties.nom_combiner,
                        positionId: assetLayerInfo.object.properties.position_id,
                        stack: assetLayerInfo.object.properties.nom_stack,
                        coordinates: assetLayerInfo.coordinate,
                    });

                    navigate(
                        `${assetLayerInfo.object.properties.uuid}/${
                            anomalies?.species?.length > 0
                                ? `${ROUTES.sites.inspection.map.anomaly}/1`
                                : ROUTES.sites.inspection.map.module
                        }`,
                    );
                }
            } else if (!object.picked) {
                navigate(`${ROUTES.sites.index}/${siteId}/${inspectionId}/${ROUTES.sites.inspection.map.index}`);
            }
        },
        [getAssetAndAnomalies, inspectionId, navigate, setIsAssetSelected, siteId, viewState.zoom],
    );

    const zoomToHighlightedModules = useCallback(() => {
        const anomalyPositions = Object.values(filteredMapAnomalies)
            .flat(1)
            .map((anom) => anom.position);

        const anomalyPoints = multiPoint(anomalyPositions);

        updateMainMapViewState(anomalyPoints);
    }, [filteredMapAnomalies, updateMainMapViewState]);

    const zoomToHighlightEntireSite = useCallback(
        () => updateMainMapViewState(siteGeometry),
        [siteGeometry, updateMainMapViewState],
    );

    const layers = useMemo(
        () => [
            assetLayer,
            setLayer,
            scatterplotLayer,
            stackLayer,
            siteOutlineLayer,
            combinerLayer,
            inverterLayer,
            ...minimapLayers,
        ],
        [
            assetLayer,
            setLayer,
            scatterplotLayer,
            stackLayer,
            siteOutlineLayer,
            combinerLayer,
            inverterLayer,
            minimapLayers,
        ],
    );

    return (
        <>
            <DeckGL
                controller={{
                    doubleClickZoom: false,
                    touchRotate: false,
                    touchZoom: true,
                    dragRotate: false,
                }}
                viewState={{ main: viewState, miniMap: miniMapViewState }}
                layers={layers}
                style={{ zIndex: 1, overflow: 'hidden' }}
                onViewStateChange={handleViewStateChange}
                views={minimapVisible ? [mainView, minimapView] : [mainView]}
                parameters={{ depthTest: false }}
                layerFilter={layerFilter}
                getTooltip={buildTooltip}
                onClick={handleClick}
                onDragStart={() => setIsDragging(true)}
                onDragEnd={() => setIsDragging(false)}
                _pickable={isPickable}
                getCursor={() => 'default'}
            >
                <MapView id="main" />
                {minimapVisible && (
                    <MapView id="miniMap">
                        <MinimapContainer>
                            <MinimapBackgroundContainer />
                            <StyledActionIcon onClick={() => setMinimapVisible(false)} m="0.3125rem">
                                <CollapseIcon />
                            </StyledActionIcon>
                        </MinimapContainer>
                    </MapView>
                )}
                <Map mapStyle={STADIA_STYLE} mapboxAccessToken={MAPBOX_TOKEN} />
                <MapControls
                    zoomToHighlightEntireSite={zoomToHighlightEntireSite}
                    zoomToHighlightedModules={zoomToHighlightedModules}
                />
                <AnomalyMapTooltip />
            </DeckGL>

            {!minimapVisible && (
                <StyledMinimapCloseButton onClick={() => setMinimapVisible(true)} size="md">
                    <Group spacing="0.5rem" c="currentColor">
                        <Text c="gray.1" variant="body2medium">
                            Minimap
                        </Text>
                        <ExpandIcon />
                    </Group>
                </StyledMinimapCloseButton>
            )}
        </>
    );
};

export default AnomaliesMap;

const StyledActionIcon = styled(ActionIcon)({
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: 'var(--color-grey-700)',
});

const StyledMinimapCloseButton = styled(Button)({
    'backgroundColor': 'var(--color-grey-700)',
    'position': 'absolute',
    'left': '1.25rem',
    'top': '1.25rem',
    'zIndex': 3,
    'color': 'var(--color-grey-300)',
    ':hover': {
        backgroundColor: 'var(--color-grey-900)',
    },
    ':active': {
        color: 'var(--color-purple-300)',
    },
});

const MinimapContainer = styled.div({
    boxSizing: 'border-box',
    border: '6px solid white',
    borderRadius: '8px',
    width: 'calc(100% + 10px)',
    height: 'calc(100% + 10px)',
    position: 'absolute',
    top: '-5px',
    left: '-5px',
});

const MinimapBackgroundContainer = styled.div({
    position: 'relative',
    zIndex: '-1',
    width: '100%',
    height: '100%',
    background: 'var(--color-grey-100)',
    boxShadow: '0 0 8px 2px rgba(0, 0, 0, 0.15)',
    boxSizing: 'border-box',
});
