import { Cross } from '@dronebase/shared-ui-icons';
import styled from '@emotion/styled';
import { Stack, Image, Text, Center } from '@mantine/core';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

import DownloadImageButton from './DownloadImageButton';

const CrossIcon = () => (
    <Center>
        <Cross height={20} />
    </Center>
);

type AnomalyThumbnailProps = {
    src: string;
    alt: string;
    isSampleImage: boolean;
};

const AnomalyThumbnail = ({ src, alt, isSampleImage }: AnomalyThumbnailProps) => (
    <Stack align="center" spacing="0.25rem">
        <ImageContainer>
            <Zoom zoomMargin={10} IconUnzoom={CrossIcon} zoomImg={{ src }}>
                <Image
                    src={src}
                    alt={alt}
                    height="7.5rem"
                    width="9rem"
                    fit="contain"
                    bg="var(--color-grey-900)"
                    styles={{ root: { borderRadius: '0.25rem' } }}
                    withPlaceholder
                />
            </Zoom>
            <DownloadImageButtonContainer>
                <DownloadImageButton className="downloadImage" url={src} />
            </DownloadImageButtonContainer>
        </ImageContainer>
        {isSampleImage && (
            <Text variant="caption2" c="gray.0">
                Sample Image
            </Text>
        )}
    </Stack>
);

export default AnomalyThumbnail;

const ImageContainer = styled.div({
    'height': '100%',
    'width': '100%',
    'position': 'relative',
    '&:hover': {
        outline: '3px solid var(--color-purple-300)',
        borderRadius: '0.25rem',
    },
    '&:hover .downloadImage': {
        display: 'block',
    },
    '& .downloadImage': {
        display: 'none',
    },
    '[data-rmiz-content="found"] img': {
        cursor: 'pointer',
    },
});

const DownloadImageButtonContainer = styled.div({
    position: 'absolute',
    bottom: '0.25rem',
    right: '0.25rem',
});
