const InverterIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.25 4.25H3.75L3.75 16.75H16.25V4.25ZM3.75 3C3.05964 3 2.5 3.55964 2.5 4.25V16.75C2.5 17.4404 3.05964 18 3.75 18H16.25C16.9404 18 17.5 17.4404 17.5 16.75V4.25C17.5 3.55964 16.9404 3 16.25 3H3.75Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.096 3.84597L3.34597 17.596L2.90403 17.154L16.654 3.40403L17.096 3.84597Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.75721 7.58394C5.66264 7.71529 5.625 7.89168 5.625 8H4.375C4.375 7.69165 4.46236 7.24304 4.74279 6.85356C5.04616 6.43221 5.5463 6.125 6.25 6.125C7.13456 6.125 7.60672 6.59756 7.92559 6.9167C7.93108 6.9222 7.93654 6.92765 7.94194 6.93306C8.24788 7.23899 8.3998 7.375 8.75 7.375C9.0463 7.375 9.17116 7.26554 9.24279 7.16606C9.33736 7.03471 9.375 6.85832 9.375 6.75H10.625C10.625 7.05835 10.5376 7.50696 10.2572 7.89644C9.95384 8.31779 9.4537 8.625 8.75 8.625C7.86544 8.625 7.39328 8.15244 7.07441 7.8333C7.06892 7.8278 7.06346 7.82235 7.05806 7.81694C6.75212 7.51101 6.6002 7.375 6.25 7.375C5.9537 7.375 5.82884 7.48446 5.75721 7.58394Z"
            fill="currentColor"
        />
        <path fillRule="evenodd" clipRule="evenodd" d="M15 14.25H8.75V13H15V14.25Z" fill="currentColor" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.25 15.8125H8.75V15.1875H11.25V15.8125Z"
            fill="currentColor"
        />
        <path fillRule="evenodd" clipRule="evenodd" d="M15 15.8125H12.5V15.1875H15V15.8125Z" fill="currentColor" />
    </svg>
);

export default InverterIcon;
