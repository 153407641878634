import { useMemo } from 'react';

import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider, Navigate } from 'react-router-dom';

import LoginPage, { loginLoader } from 'components/Auth';
import ProtectedRoutes from 'components/Auth/ProtectedRoutes';
import ResetPassword from 'components/Auth/ResetPassword';
import ResetPasswordSuccess from 'components/Auth/ResetPassword/Success';
import UpdatePassword from 'components/Auth/ResetPassword/UpdatePassword';
import SignInLinkSent from 'components/Auth/SignInLinkSent';
import SignInLinkVerification from 'components/Auth/SignInLinkVerification';
import SelectWorkspace, { selectWorkspaceLoader } from 'components/Auth/Workspace/SelectWorkspace';
import AnomalyData from 'components/inspections/AnomalyData';
import PivotTable from 'components/inspections/AnomalyData/PivotTable';
import TabularTable from 'components/inspections/AnomalyData/TabularTable';
import AnomalyMap from 'components/inspections/AnomalyMap';
import AnomalyTabContent from 'components/inspections/AnomalyMap/ModuleDetailsPanel/AnomalyTabContent';
import Module from 'components/inspections/AnomalyMap/ModuleDetailsPanel/Module';
import { ModuleTabContent } from 'components/inspections/AnomalyMap/ModuleDetailsPanel/ModuleTabContent';
import SiteAnomaliesAndObservationsPanel from 'components/inspections/AnomalyMap/SiteAnomaliesAndObservationsPanel';
import DiagnosticReport from 'components/inspections/DiagnosticReport';
import Files from 'components/inspections/Files';
import Findings from 'components/inspections/Findings';
import { InspectionPageLayout } from 'components/inspections/InspectionPageLayout';
import Invite, { inviteLoader } from 'components/Invites';
import AppLayout from 'components/Layout';
import { AuthPagesLayout } from 'components/Layout/AuthPagesLayout';
import Error from 'components/Layout/Error';
import { NoWorkspacesFound, noWorkspacesFoundLoader } from 'components/Layout/NoWorkspaceFound';
import { PageLayout } from 'components/Layout/PageLayout';
import { siteLayoutLoader } from 'components/Layout/SiteLayout';
import { SitesPageLayout } from 'components/Layout/SitesPageLayout';
import { UsersPageLayout } from 'components/Layout/UsersPageLayout';
import SiteDetails, { siteDetailsLoader } from 'components/Sites/SiteDetails';
import Sites, { sitesLoader } from 'components/Sites/Sites';
import WorkspaceUsers from 'components/Users/WorkspaceUsers';
import ViewInvites from 'components/Users/WorkspaceUsers/ViewInvites';
import { editAssetAction } from 'lib/actions/editAssetAction';
import { ROUTES } from 'lib/constants/routes';
import { UsersProvider } from 'lib/context/UsersContext';
import { AnomalyMapDataProvider } from 'lib/hooks';
import { assetLoader } from 'lib/loaders/assetLoader';
import { inspectionLoader } from 'lib/loaders/inspectionLoader';
import { usersLoader } from 'lib/loaders/usersLoader';

const AppRoutes = () => {
    const router = useMemo(
        () =>
            createBrowserRouter(
                createRoutesFromElements(
                    <Route id="root-route">
                        <Route path="/auth" errorElement={<Error checkAuth />}>
                            <Route element={<AuthPagesLayout />}>
                                <Route index element={<LoginPage />} loader={loginLoader} />
                                <Route path="confirm" element={<SignInLinkVerification />} />

                                <Route
                                    path="select-workspace"
                                    element={<SelectWorkspace />}
                                    loader={selectWorkspaceLoader}
                                />

                                <Route path="reset-password" loader={loginLoader}>
                                    <Route index element={<ResetPassword />} />
                                    <Route path="success" element={<ResetPasswordSuccess />} />
                                </Route>

                                <Route path="password-reset/:resetId" element={<UpdatePassword />} />
                                <Route path="invite/:inviteId" element={<Invite />} loader={inviteLoader} />
                                <Route path="sign-in-link-sent" element={<SignInLinkSent />} />
                            </Route>

                            <Route path="404-ws" element={<NoWorkspacesFound />} loader={noWorkspacesFoundLoader} />
                            <Route path="*" element={<Error type="404" />} />
                        </Route>

                        <Route element={<ProtectedRoutes />}>
                            <Route element={<AppLayout />}>
                                <Route element={<SitesPageLayout />}>
                                    <Route path="/sites" errorElement={<Error />}>
                                        <Route
                                            index
                                            element={<Sites />}
                                            errorElement={<Error />}
                                            loader={sitesLoader}
                                        />

                                        <Route
                                            path=":siteId"
                                            errorElement={<Error />}
                                            id="site-details"
                                            loader={siteLayoutLoader}
                                        >
                                            <Route index element={<SiteDetails />} loader={siteDetailsLoader} />

                                            <Route
                                                path=":inspectionId"
                                                element={<InspectionPageLayout />}
                                                id="inspection"
                                                loader={inspectionLoader}
                                                errorElement={<Error />}
                                            >
                                                <Route
                                                    path={ROUTES.sites.inspection.diagnostic}
                                                    id="diagnostic-page"
                                                    element={<DiagnosticReport />}
                                                />
                                                <Route
                                                    path={ROUTES.sites.inspection.findings}
                                                    id="findings-page"
                                                    element={<Findings />}
                                                />
                                                <Route
                                                    path={ROUTES.sites.inspection.anomalies}
                                                    id="anomalies-table-page"
                                                    element={<AnomalyData />}
                                                >
                                                    <Route path="pivot" element={<PivotTable />} />
                                                    <Route path="tabular" element={<TabularTable />} />
                                                </Route>
                                                <Route
                                                    path={ROUTES.sites.inspection.map.index}
                                                    id="anomaly-map-page"
                                                    element={
                                                        <AnomalyMapDataProvider>
                                                            <AnomalyMap />
                                                        </AnomalyMapDataProvider>
                                                    }
                                                >
                                                    <Route index element={<SiteAnomaliesAndObservationsPanel />} />

                                                    <Route
                                                        path=":assetRegionUUID"
                                                        id="module-details"
                                                        element={<Module />}
                                                        loader={assetLoader}
                                                    >
                                                        <Route
                                                            path={
                                                                `${ROUTES.sites.inspection.map.anomaly}` +
                                                                `/:anomalyTabNumber`
                                                            }
                                                            id="anomaly"
                                                            element={<AnomalyTabContent />}
                                                        />
                                                        <Route
                                                            path={ROUTES.sites.inspection.map.module}
                                                            id="module"
                                                            element={<ModuleTabContent />}
                                                            action={editAssetAction}
                                                        />
                                                    </Route>
                                                </Route>
                                                <Route
                                                    path={ROUTES.sites.inspection.files}
                                                    id="files-page"
                                                    element={<Files />}
                                                />
                                                <Route
                                                    path="*"
                                                    element={
                                                        <PageLayout>
                                                            <Error
                                                                type="404"
                                                                label="No report found for current report type!"
                                                                showRedirect={false}
                                                            />
                                                        </PageLayout>
                                                    }
                                                />
                                            </Route>
                                        </Route>
                                        <Route path="*" element={<Error type="404" />} />
                                    </Route>
                                </Route>

                                <Route path="/users" errorElement={<Error />}>
                                    <Route
                                        element={
                                            <UsersProvider>
                                                <UsersPageLayout />
                                            </UsersProvider>
                                        }
                                        loader={usersLoader}
                                    >
                                        <Route index element={<WorkspaceUsers />} errorElement={<Error />} />
                                        <Route path="invites" element={<ViewInvites />} errorElement={<Error />} />
                                        <Route id="users-404" path="404" element={<Error type="404" />} />
                                        <Route id="users-*-404" path="*" element={<Error type="404" />} />
                                    </Route>
                                </Route>
                            </Route>
                        </Route>
                        <Route path="*" element={<Navigate to="/sites" />} />
                    </Route>,
                ),
            ),
        [],
    );

    return <RouterProvider router={router} />;
};

export default AppRoutes;
