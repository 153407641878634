const CollapseIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.33333 1.93333C2.56013 1.93333 1.93333 2.56013 1.93333 3.33333V12.6667C1.93333 13.4399 2.56013 14.0667 3.33333 14.0667H12.6667C13.4399 14.0667 14.0667 13.4399 14.0667 12.6667V6.91111C14.0667 6.65338 14.2756 6.44444 14.5333 6.44444C14.7911 6.44444 15 6.65338 15 6.91111V12.6667C15 13.9553 13.9553 15 12.6667 15H3.33333C2.04467 15 1 13.9553 1 12.6667V3.33333C1 2.04467 2.04467 1 3.33333 1H9.08889C9.34662 1 9.55556 1.20893 9.55556 1.46667C9.55556 1.7244 9.34662 1.93333 9.08889 1.93333H3.33333ZM13.7744 2.22557C13.9567 2.40782 13.9567 2.70329 13.7744 2.88554L8.03774 8.62222H11.2667C11.5244 8.62222 11.7333 8.83116 11.7333 9.08889C11.7333 9.34662 11.5244 9.55556 11.2667 9.55556H6.91111C6.65338 9.55556 6.44444 9.34662 6.44444 9.08889V4.73333C6.44444 4.4756 6.65338 4.26667 6.91111 4.26667C7.16884 4.26667 7.37778 4.4756 7.37778 4.73333V7.96226L13.1145 2.22557C13.2967 2.04333 13.5922 2.04333 13.7744 2.22557Z"
            fill="currentColor"
        />
    </svg>
);

export default CollapseIcon;
