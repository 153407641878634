import { useMemo, useState } from 'react';

import { DropdownButton } from '@dronebase/shared-ui-core';
import { Menu, Checkbox, Overlay } from '@mantine/core';

interface UserPermissions {
    permissions: string;
    setPermissions: (permissions: UserPermissions['permissions']) => void;
}

const formatOptions = (permissions: string[]) => {
    // TODO: Fetch this list from BE
    const options = [{ key: 'user_management.editor', text: 'Manage Users' }];

    return options.map((opt) => ({
        ...opt,
        checked: permissions.includes(opt.key),
    }));
};

const FilterPermissionsDropdown = ({ permissions, setPermissions }: UserPermissions) => {
    const [selectedPermissions, setSelectedPermissions] = useState(permissions?.split(',') || []);
    const options = useMemo(() => formatOptions(selectedPermissions), [selectedPermissions]);

    const handlePermissionChange = (key: string) => {
        const newFilters = selectedPermissions.includes(key)
            ? selectedPermissions.filter((existingKey) => existingKey !== key)
            : [...selectedPermissions, key];

        setSelectedPermissions(newFilters);
        setPermissions(newFilters.length ? newFilters.join(',') : '');
    };

    return (
        <Menu closeOnItemClick={false}>
            <Menu.Target>
                <DropdownButton>Select Permissions</DropdownButton>
            </Menu.Target>
            <Menu.Dropdown>
                {options.map((option) => (
                    <Menu.Item key={option.key} w="10rem" pos="relative">
                        {/** Overlay here allows any part of the menu item to clicked to toggle the option */}
                        <Overlay opacity={0} zIndex={1} onClick={() => handlePermissionChange(option.key)} />
                        <Checkbox label={option.text} checked={option.checked} />
                    </Menu.Item>
                ))}
            </Menu.Dropdown>
        </Menu>
    );
};

export default FilterPermissionsDropdown;
