import { ActionFunctionArgs, json } from 'react-router-dom';

import { changeSerialNumber } from 'lib/mutations/changeSerialNumber';

export const editAssetAction = async ({ request, params }: ActionFunctionArgs) => {
    const formData = await request.formData();
    const serialNumber = formData.get('serialNumber') as string;
    const { siteId, assetRegionUUID } = params;

    if (siteId && assetRegionUUID) {
        await changeSerialNumber(siteId, assetRegionUUID, serialNumber);
    }

    return json({ serialNumber });
};
