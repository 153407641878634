import { Stack, Text } from '@mantine/core';

import ModalSection from '../../inspections/shared/ModalSection';
import ModalTitle from '../../inspections/shared/ModalTitle';

const ModuleAdvancedInfoModal = () => (
    <>
        <ModalTitle title="What’s Included In The Module Advanced Inspection?" />
        <ModalSection title="Description">
            <Stack spacing="0.5rem">
                <Text>
                    The Module Advanced inspection identifies all anomalies with major energy impacts, as well as all
                    thermal anomalies onsite, including the detection of subtle performance or site concerns such as
                    cell cracking, potential induced degradation (PID), vegetation, surface fouling, and installation
                    defects.
                </Text>

                <Text>
                    Using our proprietary HL Insight engine, we capture a high-resolution scan of the site using a
                    combination of IR and RGB visual imagery. It allows us to precisely identify the location, category,
                    and severity of all DC faults impacting the energy loss of the site down to the sub-module level.
                    This scope can also include data collection for warranty returns of major anomaly modes if required,
                    as well as mapping of anomalies for efficient remediation.
                </Text>
            </Stack>
        </ModalSection>

        <ModalSection title="Scope of Inspection Service">
            <Stack spacing="0.5rem">
                <Text>
                    <ul style={{ listStyleType: 'disc', margin: '0 20px' }}>
                        <li>
                            Summary of power losses and key observations of the site attributed to balance of system
                            (BoS) faults, module-level anomalies, and other potential issues that may impact the site
                        </li>
                        <li>
                            Industry benchmarking of power losses due to BoS faults, and module-level anomalies with
                            Major Module and Minor Module power losses
                        </li>
                        <li>Identification of the following anomalies:</li>
                        <ul style={{ listStyleType: 'disc', margin: '0 20px' }}>
                            <li>Inverter outages</li>
                            <li>Sectional outages</li>
                            <li>All string-level anomalies</li>
                            <li>All module faults</li>
                            <li>All sub-module faults</li>
                            <li>All hot-spots with &gt;1°C temperature difference</li>
                            <li>
                                Other site issues: junction box heating, missing modules, acute soiling (bird droppings,
                                debris), potential induced degradation (PID), major racking shifts, misaligned trackers,
                                systemic shading
                            </li>
                        </ul>
                        <li>List of modules impacted by anomalies</li>
                        <li>Site schematic with locations of anomalies</li>
                    </ul>
                </Text>
            </Stack>
        </ModalSection>
    </>
);

export default ModuleAdvancedInfoModal;
