import { Stack, Text } from '@mantine/core';

import ModalSection from '../shared/ModalSection';
import ModalTitle from '../shared/ModalTitle';

const AnomalyOverviewInfoModal = () => (
    <>
        <ModalTitle title="Overview Of Anomalies" />
        <ModalSection title="Description">
            <Text variant="body2">
                The Sankey chart provides a visual overview of the distribution of anomalies on the site. It shows what
                proportion of anomalies to either remediate or monitor, as well as which anomalies are contributing the
                greatest power loss and affecting the most modules.
            </Text>
        </ModalSection>
        <ModalSection title="Using The Sankey Chart">
            <Stack spacing="0.5rem">
                <Text variant="body2">
                    <strong>Sankey Columns</strong> - The Sankey chart is sub-divided into three columns: IR Signals,
                    RGB Signals and Actions to Take. IR Signals refer to thermal issues uncovered during the inspection.
                    RGB Signals refer to visible issues that caused thermal issues to occur. And, Actions to Take refer
                    to types of actions that are recommended for anomalies.
                </Text>

                <Text variant="body2">
                    <strong>View of Anomalies</strong> - To view anomaly details, hover the mouse over a segment of
                    interest in the Sankey chart. A tooltip will be shown that includes the number and percentage of
                    modules impacted, and the power loss attributed to the anomaly.
                </Text>

                <Text variant="body2">
                    <strong>View Locations of Anomalies</strong> - Select a segment of interest in the Sankey chart, and
                    you will automatically be navigated to the Map of Anomalies, where you can view the locations of the
                    the selected anomaly.
                </Text>
            </Stack>
        </ModalSection>
    </>
);

export default AnomalyOverviewInfoModal;
