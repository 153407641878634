import styled from '@emotion/styled';
import { Text } from '@mantine/core';

// TODO - We need better typing for this
const ANOMALY_PRIORITY_COLOR_DEFINITION_MAP = {
    '1': { color: '#BC1742', definition: 'Remediation Recommended' },
    '2': { color: '#F06D05', definition: 'Monitor & Remediate' },
    '3': { color: '#005F69', definition: 'Long Term Monitoring' },
};

const AnomalyPriority = ({ anomalyPriority }: { anomalyPriority: '1' | '2' | '3' }) => (
    <AnomalyPriorityContainer backgroundColor={ANOMALY_PRIORITY_COLOR_DEFINITION_MAP[anomalyPriority].color}>
        <Text c="white" variant="body2medium">
            {`${anomalyPriority} - ${ANOMALY_PRIORITY_COLOR_DEFINITION_MAP[anomalyPriority].definition}`}
        </Text>
    </AnomalyPriorityContainer>
);

export default AnomalyPriority;

const AnomalyPriorityContainer = styled.div(({ backgroundColor }: { backgroundColor: string }) => ({
    borderRadius: '0.25rem',
    backgroundColor,
    width: 'fit-content',
    padding: '0.1rem 0.5rem',
    color: 'var(--color-white)',
}));
