import { Text } from '@mantine/core';

import ModalSection from '../shared/ModalSection';
import ModalTitle from '../shared/ModalTitle';

const KeyObservationsInfoModal = () => (
    <>
        <ModalTitle title="Key Observations" />
        <ModalSection title="Description">
            <Text variant="body2">
                Zeitview&apos; solar experts will highlight noteworthy issues, observations or recommendations for many
                types of inspections. These key observations will include text and images for clarification when
                available and appropriate.
            </Text>
        </ModalSection>
    </>
);

export default KeyObservationsInfoModal;
