import styled from '@emotion/styled';
import { Box, Group, Image } from '@mantine/core';

interface KeyObservationImageGalleryProps {
    images: string[];
}

const KeyObservationGallery = ({ images }: KeyObservationImageGalleryProps) => (
    <tr>
        <StyledTableData colSpan={3}>
            <StyledGroup>
                {images.map((src, index: number) => (
                    <Box key={`key-observation-box-${index}`}>
                        <Image height="12.5rem" src={src} key={`observation-image-${index}`} />
                    </Box>
                ))}
            </StyledGroup>
        </StyledTableData>
    </tr>
);

const StyledGroup = styled(Group)({
    gap: '1.25rem',
    padding: '1.25rem',
});

const StyledTableData = styled.td({
    backgroundColor: 'var(--color-purple-50)',
});

export default KeyObservationGallery;
