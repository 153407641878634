import styled from '@emotion/styled';
import { Paper, PaperProps } from '@mantine/core';

export const PageLayout = ({ children }: React.PropsWithChildren) => (
    <PaperContainer>
        <PageContent>{children}</PageContent>
    </PaperContainer>
);

const PaperContainer = styled(Paper)<PaperProps>({
    padding: '1.5rem',
    backgroundColor: 'var(--color-grey-50)',
    flexBasis: '100%',
    display: 'flex',
    overflow: 'hidden',
});

const PageContent = styled.div({
    overflow: 'auto',
    padding: '1.5rem',
    borderRadius: '0.25rem',
    backgroundColor: 'var(--color-white)',
    flexBasis: '100%',
    display: 'flex',
    flexDirection: 'column',
});
