import { useMemo, useState } from 'react';

import { SortedBy } from 'components/Sites/types';
import { SORT_DIRECTION } from 'lib/constants';
import { User } from 'lib/context/UsersContext';

export interface WorkspaceUsersData {
    emailFilter: string;
    setEmailFilter: React.Dispatch<React.SetStateAction<string>>;
    permissionsFilter: string;
    setPermissionsFilter: React.Dispatch<React.SetStateAction<string>>;
    sortedFilteredUsers: User[];
    tableSort: {
        id: string;
        desc: boolean;
    };
    setSortedBy: React.Dispatch<
        React.SetStateAction<{
            key: string;
            direction: SortedBy['direction'];
        }>
    >;
}

const useWorkspaceUsers = ({ workspaceUsers }: { workspaceUsers: User[] }): WorkspaceUsersData => {
    const [emailFilter, setEmailFilter] = useState('');
    const [permissionsFilter, setPermissionsFilter] = useState('');

    const filteredWorkspaceUsers = useMemo(
        () =>
            workspaceUsers.filter(
                (workspaceUser) =>
                    (!emailFilter || workspaceUser.email?.includes(emailFilter)) &&
                    (!permissionsFilter ||
                        workspaceUser.permissions?.some((permission) =>
                            permissionsFilter.includes(permission.permission.name),
                        )),
            ),
        [workspaceUsers, emailFilter, permissionsFilter],
    );

    // TODO: The `table` component this data is fed into is built too heavily on the assumption you're doing server-side filtering
    //       This means that you need to feed it filter/paginated data, rather than feeding it everything and letting TanStack handle it
    const [sortedBy, setSortedBy] = useState<{
        key: string;
        direction: SortedBy['direction'];
    }>({
        key: 'email',
        direction: 'desc_nulls_last',
    });

    const tableSort = useMemo(() => {
        const { key, direction } = sortedBy;

        return {
            id: key,
            desc: direction === SORT_DIRECTION.DESC,
        };
    }, [sortedBy]);

    const sortedFilteredUsers = useMemo(
        () =>
            filteredWorkspaceUsers.toSorted((a, b) => {
                const key = tableSort.id as keyof User;

                if (key === 'permissions') {
                    return 0;
                } else {
                    return a[key].localeCompare(b[key]) * (tableSort.desc ? 1 : -1);
                }
            }),
        [tableSort, filteredWorkspaceUsers],
    );

    return {
        // Filters
        emailFilter,
        setEmailFilter,
        permissionsFilter,
        setPermissionsFilter,

        setSortedBy,
        tableSort,
        // Table Data
        sortedFilteredUsers,
    };
};

export default useWorkspaceUsers;
