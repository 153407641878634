import { useCallback, useEffect, useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';

import { AnomalyMapFilters } from 'components/inspections/types';
import { LOCAL_STORAGE_KEYS } from 'lib/constants';
import { ROUTES } from 'lib/constants/routes';
import { getSavedFilters, getSavedLayerFilters } from 'lib/helpers';
import createCustomHook from 'lib/helpers/hookFactory';
import { getAsBoolean, getAsJSON, set } from 'lib/helpers/localStorage';

import { useAssetLabels } from './map';
import { useDifferentialMode } from './useDifferentialMode';

const ValueProvider = () => {
    const { siteId, inspectionId } = useParams();
    const { active: isDifferentialModeActive } = useDifferentialMode();
    const filterKey = useMemo(() => `${ROUTES.sites.index}/${siteId}/${inspectionId}`, [inspectionId, siteId]);
    const { defaultFilteredAssetLayers, fetchingInverterAssetData } = useAssetLabels();
    const [filter, setFilter] = useState<AnomalyMapFilters>(() =>
        getSavedFilters(defaultFilteredAssetLayers, filterKey),
    );
    const [showObservationsPanel, setShowObservationsPanel] = useState(
        getAsBoolean(LOCAL_STORAGE_KEYS.SHOW_OBSERVATIONS_PANEL),
    );

    const setAnomalyMapFilters = useCallback(
        (filter: AnomalyMapFilters) => {
            setFilter(filter);

            if (!isDifferentialModeActive) {
                const prevFilters = getAsJSON(LOCAL_STORAGE_KEYS.ANOMALY_MAP_FILTERS);

                set(LOCAL_STORAGE_KEYS.ANOMALY_MAP_FILTERS, JSON.stringify({ ...prevFilters, [filterKey]: filter }));
            }
        },
        [filterKey, isDifferentialModeActive],
    );

    useEffect(() => {
        set(LOCAL_STORAGE_KEYS.SHOW_OBSERVATIONS_PANEL, showObservationsPanel);
    }, [showObservationsPanel]);

    useEffect(() => {
        if (!fetchingInverterAssetData) {
            if (isDifferentialModeActive) {
                setShowObservationsPanel(false);
                setAnomalyMapFilters(getSavedLayerFilters(defaultFilteredAssetLayers, filterKey));
            } else {
                setAnomalyMapFilters(getSavedFilters(defaultFilteredAssetLayers, filterKey));
            }
        }
    }, [
        defaultFilteredAssetLayers,
        fetchingInverterAssetData,
        filterKey,
        isDifferentialModeActive,
        setAnomalyMapFilters,
    ]);

    const toggleFilter = useCallback(
        (categoryKey: string, filterUuid: string) => {
            const newFilters = { ...filter };
            const isListOfOptions = typeof newFilters[categoryKey as keyof AnomalyMapFilters] !== 'string';

            if (isListOfOptions && filter[categoryKey as keyof AnomalyMapFilters].includes(filterUuid)) {
                newFilters[categoryKey as keyof AnomalyMapFilters] = filter[
                    categoryKey as keyof AnomalyMapFilters
                ].filter((layerName: string) => layerName !== filterUuid);
            } else {
                newFilters[categoryKey as keyof AnomalyMapFilters] = isListOfOptions
                    ? [...filter[categoryKey as keyof AnomalyMapFilters], filterUuid]
                    : [filterUuid];
            }

            setAnomalyMapFilters(newFilters);
        },
        [filter, setAnomalyMapFilters],
    );

    const clearAnomalyFilters = useCallback(() => {
        setAnomalyMapFilters({
            ...filter,
            rgb: [],
            ir: [],
            remediationFilters: [],
            statusIds: [],
        });
    }, [filter, setAnomalyMapFilters]);

    const clearObservationFilters = useCallback(() => {
        setAnomalyMapFilters({
            ...filter,
            observationIds: [],
        });
    }, [filter, setAnomalyMapFilters]);

    const areAnomalyFiltersApplied = useMemo(
        () =>
            filter.ir.length > 0 ||
            filter.rgb.length > 0 ||
            filter.remediationFilters.length > 0 ||
            filter.statusIds.length > 0,
        [filter.ir.length, filter.remediationFilters.length, filter.rgb.length, filter.statusIds.length],
    );

    const areObservationFiltersApplied = useMemo(
        () => filter.observationIds.length > 0,
        [filter.observationIds.length],
    );

    const value = useMemo(
        () => ({
            filter,
            setAnomalyMapFilters,
            toggleFilter,
            showObservationsPanel,
            setShowObservationsPanel,
            clearAnomalyFilters,
            clearObservationFilters,
            areAnomalyFiltersApplied,
            areObservationFiltersApplied,
        }),
        [
            filter,
            setAnomalyMapFilters,
            toggleFilter,
            showObservationsPanel,
            setShowObservationsPanel,
            clearAnomalyFilters,
            clearObservationFilters,
            areAnomalyFiltersApplied,
            areObservationFiltersApplied,
        ],
    );

    return value;
};

const { Provider: AnomalyMapFilterProvider, useCustomHook: useAnomalyMapFilters } = createCustomHook<any>({
    name: 'useAnomalyMapFilters',
    callback: ValueProvider,
});

export { AnomalyMapFilterProvider, useAnomalyMapFilters };
