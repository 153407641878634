import styled from '@emotion/styled';

import { POPOVER_CARD_MAX_WIDTH } from 'lib/constants';

const InspectionContainerStyled = styled.div({
    gap: '3.25rem',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    minWidth: POPOVER_CARD_MAX_WIDTH,
    margin: '0 auto',
});

export default InspectionContainerStyled;
