import { Notifications } from '@dronebase/shared-ui-core';
import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';

import { themeConfig } from 'theme/themeConfig';

export const MantineUIProvider = ({ children }: React.PropsWithChildren) => (
    <MantineProvider theme={themeConfig} withGlobalStyles>
        <ModalsProvider>
            <Notifications />
            {children}
        </ModalsProvider>
    </MantineProvider>
);
