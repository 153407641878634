import { useEffect, useMemo, useState } from 'react';

import { useQuery } from 'urql';

import { Species } from 'components/inspections/types';
import { EMPTY_SIGNAL_OR_MODIFIER, NO_IR_SIGNAL, NO_RGB_SIGNAL } from 'lib/constants';
import { buildSignalFilter } from 'lib/helpers';
import createCustomHook from 'lib/helpers/hookFactory';
import { GET_INDEPENDENT_ANOMALY_SPECIES } from 'lib/queries';

const ValueProvider = () => {
    const [anomalySpecies, setAnomalySpecies] = useState<Species[]>([]);

    const [{ data }] = useQuery({
        query: GET_INDEPENDENT_ANOMALY_SPECIES,
    });

    useEffect(() => {
        if (data) {
            setAnomalySpecies(
                data?.anomalySpecies.reduce(
                    (accumulator: any, species: Species) => {
                        if (species.irSignal === EMPTY_SIGNAL_OR_MODIFIER) {
                            const rgbFilter = buildSignalFilter(species.rgbSignal, species.rgbModifier);

                            accumulator.rgb[rgbFilter] = species.nameExternal;
                        } else {
                            const irFilter = buildSignalFilter(species.irSignal, species.irModifier);

                            accumulator.ir[irFilter] = species.nameExternal;
                        }

                        return accumulator;
                    },
                    {
                        ir: { [EMPTY_SIGNAL_OR_MODIFIER]: NO_IR_SIGNAL },
                        rgb: { [EMPTY_SIGNAL_OR_MODIFIER]: NO_RGB_SIGNAL },
                    },
                ),
            );
        }
    }, [data]);

    const value = useMemo(
        () => ({
            anomalySpecies,
        }),
        [anomalySpecies],
    );

    return value;
};

const { Provider: AnomalySpeciesProvider, useCustomHook: useAnomalySpecies } = createCustomHook<{
    anomalySpecies: Species[];
}>({
    name: 'useAnomalySpecies',
    callback: ValueProvider,
});

export { AnomalySpeciesProvider, useAnomalySpecies };
