const NoResultsIcon = () => (
    <svg width="340" height="245" viewBox="0 0 340 245" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M281.52 74.8613C286.778 74.8613 291.04 79.1271 291.04 84.3891C291.04 89.6512 286.778 93.9169 281.52 93.9169H227.12C232.378 93.9169 236.64 98.1826 236.64 103.445C236.64 108.707 232.378 112.972 227.12 112.972H257.04C262.298 112.972 266.56 117.238 266.56 122.5C266.56 127.762 262.298 132.028 257.04 132.028H243.204C236.574 132.028 231.2 136.294 231.2 141.556C231.2 145.064 233.92 148.24 239.36 151.084C244.618 151.084 248.88 155.349 248.88 160.611C248.88 165.873 244.618 170.139 239.36 170.139H126.48C121.222 170.139 116.96 165.873 116.96 160.611C116.96 155.349 121.222 151.084 126.48 151.084H73.44C68.1823 151.084 63.92 146.818 63.92 141.556C63.92 136.294 68.1823 132.028 73.44 132.028H127.84C133.098 132.028 137.36 127.762 137.36 122.5C137.36 117.238 133.098 112.972 127.84 112.972H93.84C88.5823 112.972 84.32 108.707 84.32 103.445C84.32 98.1826 88.5823 93.9169 93.84 93.9169H148.24C142.982 93.9169 138.72 89.6512 138.72 84.3891C138.72 79.1271 142.982 74.8613 148.24 74.8613H281.52ZM281.52 112.972C286.778 112.972 291.04 117.238 291.04 122.5C291.04 127.762 286.778 132.028 281.52 132.028C276.262 132.028 272 127.762 272 122.5C272 117.238 276.262 112.972 281.52 112.972Z"
            fill="#F8F9FA"
        />
        <path
            d="M111.773 168.616L141.241 120.532C141.637 119.885 142.586 119.887 142.985 120.532C145.896 125.235 155.761 140.196 166.6 145.639C178.21 151.469 200.642 151.202 204.717 151.102C205.109 151.092 205.473 151.309 205.648 151.66L214.157 168.692C214.489 169.357 214.006 170.139 213.263 170.139H112.626C111.844 170.139 111.365 169.283 111.773 168.616Z"
            fill="#EFECFF"
        />
        <path
            d="M161.985 81.5607L104.997 169.592C103.705 171.588 105.138 174.222 107.516 174.222H219.014C221.364 174.222 222.802 171.642 221.564 169.643L167.054 81.6116C165.895 79.7398 163.182 79.7125 161.985 81.5607Z"
            stroke="#9C91FC"
            strokeWidth="2.5"
        />
        <path
            d="M160.48 140.195V108.889C160.48 108.889 160.48 104.806 164.56 104.806C168.64 104.806 168.64 108.889 168.64 108.889V140.195C168.64 140.195 168.64 144.278 164.56 144.278C160.48 144.278 160.48 140.195 160.48 140.195Z"
            stroke="#9C91FC"
            strokeWidth="2.5"
        />
        <path
            d="M161.239 153.046L162.438 151.846C163.61 150.673 165.51 150.673 166.682 151.846L167.881 153.046C169.052 154.218 169.052 156.116 167.881 157.287L166.682 158.487C165.51 159.66 163.61 159.66 162.438 158.487L161.239 157.287C160.068 156.116 160.068 154.218 161.239 153.046Z"
            stroke="#9C91FC"
            strokeWidth="2.5"
        />
        <path
            d="M203.399 90.4353L204.598 89.2352C205.77 88.0625 207.67 88.0625 208.842 89.2352L210.041 90.4353C211.212 91.6067 211.212 93.5048 210.041 94.6762L208.842 95.8763C207.67 97.049 205.77 97.049 204.598 95.8763L203.399 94.6762C202.228 93.5049 202.228 91.6067 203.399 90.4353Z"
            stroke="#9C91FC"
            strokeWidth="2.5"
        />
        <path d="M223.04 115.694L212.16 125.222" stroke="#9C91FC" strokeWidth="2.5" strokeLinecap="round" />
        <path d="M110.16 122.5L99.28 132.028" stroke="#9C91FC" strokeWidth="2.5" strokeLinecap="round" />
        <path d="M212.16 115.694L223.04 125.222" stroke="#9C91FC" strokeWidth="2.5" strokeLinecap="round" />
        <path d="M99.28 122.5L110.16 132.028" stroke="#9C91FC" strokeWidth="2.5" strokeLinecap="round" />
        <path
            d="M243.8 108.889C243.8 110.593 242.42 111.972 240.72 111.972C239.02 111.972 237.64 110.593 237.64 108.889C237.64 107.185 239.02 105.806 240.72 105.806C242.42 105.806 243.8 107.185 243.8 108.889Z"
            fill="#9C91FC"
            stroke="#9C91FC"
            strokeWidth="2"
        />
        <path
            d="M121.4 108.889C121.4 110.593 120.02 111.972 118.32 111.972C116.62 111.972 115.24 110.593 115.24 108.889C115.24 107.185 116.62 105.806 118.32 105.806C120.02 105.806 121.4 107.185 121.4 108.889Z"
            fill="#9C91FC"
            stroke="#9C91FC"
            strokeWidth="2"
        />
    </svg>
);

export default NoResultsIcon;
