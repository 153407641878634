import { Info } from '@dronebase/shared-ui-icons';
import styled from '@emotion/styled';
import { ActionIcon, Group, Text, Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import { POPOVER_CARD_MAX_WIDTH } from 'lib/constants';

import InfoModal from './InfoModal';

interface PopoverCardProps {
    title?: string;
    modalContent?: React.ReactNode;
    children: React.ReactNode;
    useUnpaddedContainer?: boolean;
    hasBorder?: boolean;
}

const PopoverCard = ({ title, children, modalContent, useUnpaddedContainer, hasBorder = true }: PopoverCardProps) => {
    const [opened, { open, close }] = useDisclosure(false);

    return (
        <StyledPopOverContainer>
            <Group>
                {title && (
                    <Text variant="subtitle1" c="gray.9">
                        {title}
                    </Text>
                )}
                {modalContent && (
                    <>
                        <ActionIcon aria-label="Info" ml="auto" onClick={open}>
                            <Info color="var(--color-purple-600)" height="1.75rem" width="1.75rem" />
                        </ActionIcon>
                        <InfoModal opened={opened} onClose={close}>
                            {modalContent}
                        </InfoModal>
                    </>
                )}
            </Group>
            <StyledCardContainer hasBorder={hasBorder} unpadded={useUnpaddedContainer}>
                {children}
            </StyledCardContainer>
        </StyledPopOverContainer>
    );
};

const StyledCardContainer = styled.div<{
    unpadded?: boolean;
    hasBorder: boolean;
}>(({ unpadded, hasBorder = true }) => ({
    padding: unpadded ? '0' : '1.5rem 2rem',
    border: hasBorder ? '1px solid var(--color-grey-100)' : 'none',
    borderRadius: 'var(--Radius-small, 4px)',
    backgroundColor: 'var(--color-white-100)',
}));

const StyledPopOverContainer = styled(Stack)({
    gap: '0.625rem',
    width: '100%',
    maxWidth: POPOVER_CARD_MAX_WIDTH,
});

export default PopoverCard;
