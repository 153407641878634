import styled from '@emotion/styled';
import { Box, Group, Text, Select } from '@mantine/core';

import { SANKEY_AGGREGATION } from 'lib/constants/sankey';
import { useAnomalyOverviewFilters } from 'lib/hooks';

const SANKEY_AGGREGATION_DROPDOWN_OPTIONS = [
    { value: SANKEY_AGGREGATION.NUMBER_OF_MODULES, label: 'Number of Modules' },
    { value: SANKEY_AGGREGATION.POWER_LOSS, label: 'Power Loss' },
];

const AggregationFilter = () => {
    const { aggregation, setAggregation } = useAnomalyOverviewFilters();

    return (
        <StyledFilterGroup>
            <Text fz="0.75rem">Summarize By</Text>
            <Box style={{ width: '15rem' }}>
                <Select
                    value={aggregation}
                    fz="0.875rem"
                    onChange={(value) => setAggregation(value)}
                    placeholder="Select an aggregation"
                    data={SANKEY_AGGREGATION_DROPDOWN_OPTIONS}
                />
            </Box>
        </StyledFilterGroup>
    );
};

const StyledFilterGroup = styled(Group)({
    alignContent: 'center',
    justifyContent: 'center',
    gap: '0.625rem',
    flexGrow: 1,
});

export default AggregationFilter;
