import { getAuth, onAuthStateChanged, User } from 'firebase/auth';

import auth from 'lib/firebase';
import { WorkspacePermissionV1, WorkspacePermissionV2, Workspace, WorkspaceUser } from 'lib/types';

import { getCookie, setCookie } from '../cookies';

export const getAppPermissions = (role: string, returnData = true) => {
    const appPermissions: WorkspacePermissionV1['permissions'] = getCookie('helio-permissions')?.permissions || [];

    const permission = appPermissions.find((p) => p.permissionName === role);

    return returnData ? permission : !!permission;
};

export const processPermissions = (workspaceUser: WorkspaceUser[]): WorkspacePermissionV1[] => {
    const userPermissions = workspaceUser
        ?.filter(({ workspace }) => workspace)
        .map(({ workspace_user_permissions: workspaceUserPermissions, workspace }) => {
            const permissions = {
                workspaceId: workspace.uuid,
                permissions: workspaceUserPermissions.map(({ permission }) => ({
                    permissionId: permission.uuid,
                    permissionName: permission.name,
                    applicationName: permission.application.name,
                })),
            };

            return permissions;
        });

    return userPermissions;
};

export const processPermissionsV2 = (workspaceUser: WorkspaceUser[]): WorkspacePermissionV2[] => {
    const userPermissions = workspaceUser
        ?.filter(({ workspace }) => workspace)
        .map(({ workspace_user_permissions: workspaceUserPermissions, workspace }) => {
            const permissions = {
                workspaceId: workspace.uuid,
                permissions: workspaceUserPermissions.map(({ permission }) => ({
                    id: permission.uuid,
                    name: permission.name,
                    applicationName: permission.application.name,
                })),
            };

            return permissions;
        });

    return userPermissions;
};

export const getAuthToken = async (): Promise<string | undefined> => {
    await auth.authStateReady();

    return await auth.currentUser?.getIdToken();
};

export const checkLoggedIn = async () => {
    try {
        const auth = getAuth();
        const user = await new Promise<User | null>((resolve, reject) => {
            const unsubscribe = onAuthStateChanged(
                auth,
                (user) => {
                    unsubscribe();
                    resolve(user);
                },
                reject,
            );
        });

        return !!user;
    } catch (error) {
        return false;
    }
};

export const sortWorkspaces = (a: Workspace, b: Workspace) => {
    const x = a.name.toLowerCase();
    const y = b.name.toLowerCase();

    if (x > y) {
        return 1;
    } else if (x < y) {
        return -1;
    }

    return 0;
};

export const switchWorkspace = (
    permissions: WorkspacePermissionV1[],
    permissionsV2: WorkspacePermissionV2[],
    id: string,
    name: string,
) => {
    const _permissionsV1 = permissions.find((wp) => wp.workspaceId === id);
    const _permissionsV2 = permissionsV2.find((wp) => wp.workspaceId === id)?.permissions;

    setCookie('helio-workspace', { id, name });
    setCookie('helio-permissions', _permissionsV1);
    setCookie('helio-permissionsV2', _permissionsV2);
};

export const isInternalUser = (email: string | null): boolean =>
    !!email && /@heliolytics\.com$|@dronebase\.com$|@zeitview\.com$/.test(email);
