export const FinancialLossIcon = () => (
    <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.23184 6.01201C9.48093 5.66022 9.96064 5.58234 10.3033 5.83807L20.5306 13.4707C20.7596 13.6417 20.8783 13.9283 20.8393 14.2163C20.8002 14.5043 20.6098 14.7467 20.3439 14.847L10.6104 18.5157L23.1242 26.1254L22.3924 21.8775C22.3186 21.4492 22.597 21.0406 23.0141 20.9649C23.4313 20.8891 23.8293 21.1749 23.903 21.6032L24.9258 27.5397C24.9626 27.7535 24.9118 27.9734 24.7855 28.1474C24.6592 28.3215 24.4684 28.4343 24.2584 28.4592L17.0993 29.3072C16.6785 29.3571 16.2979 29.0473 16.2494 28.6152C16.2008 28.1831 16.5026 27.7925 16.9234 27.7426L21.8197 27.1626L8.43865 19.0256C8.18383 18.8707 8.03867 18.5789 8.06572 18.2762C8.09276 17.9734 8.28718 17.7136 8.5652 17.6088L18.4659 13.877L9.40127 7.11203C9.05861 6.85631 8.98276 6.36381 9.23184 6.01201Z"
            fill="#5B4DD3"
            stroke="#5B4DD3"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.1159 4.8697H26.793C26.225 4.8697 25.7625 5.3205 25.7625 5.87078C25.7625 6.42105 26.225 6.87185 26.793 6.87185H30.2127C31.7469 6.87185 33 8.1071 33 9.62922C33 11.1514 31.7527 12.3866 30.2127 12.3866H29.3812V13.6218C29.3812 14.1078 28.9889 14.5 28.5029 14.5C28.0169 14.5 27.6245 14.1077 27.6245 13.6218V12.3866H24.8841C24.3981 12.3866 24.0058 11.9944 24.0058 11.5084C24.0058 11.0225 24.3981 10.6303 24.8841 10.6303H30.2069C30.775 10.6303 31.2375 10.1795 31.2375 9.62922C31.2375 9.07895 30.775 8.62815 30.2069 8.62815H26.7873C25.2531 8.62815 24 7.3929 24 5.87078C24 4.34865 25.2531 3.1134 26.7873 3.1134H27.6188V1.87815C27.6188 1.39222 28.0111 1 28.4971 1C28.9831 1 29.3755 1.39226 29.3755 1.87815V3.1134H32.1159C32.6019 3.1134 32.9942 3.50562 32.9942 3.99155C32.9942 4.47747 32.6019 4.8697 32.1159 4.8697Z"
            fill="#5B4DD3"
            stroke="#5B4DD3"
            strokeWidth="0.2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
