export const SIZES = {
    xs: '5px',
    s: '10px',
    m: '20px',
    l: '30px',
    xl: '40px',
    xl2: '50px',
};

export const ELEVATIONS = {
    elevation25: '0px 4px 4px rgba(0, 0, 0, 0.25)',
};

export const DEFAULT_OFFSET = '0';

export const NON_SAT_MAP_STYLE = 'mapbox://styles/dronebase-eng/cl5r9omjq000b14nn7xrfulem';
export const SAT_MAP_STYLE = 'mapbox://styles/mapbox/satellite-v9';

export const DEFAULT_SITES_SORT_BY = 'powerLoss';

export const SITES_SORT_MAP = {
    siteName: { key: 'name', value: 'Site Name' },
    capacity: { key: 'size_dc_mw', value: 'Capacity' },
    bos: { key: 'bos_percentage', value: 'BOS' },
    major: { key: 'major_percentage', value: 'Major Module' },
    minor: { key: 'minor_percentage', value: 'Minor Module' },
    powerLoss: { key: 'power_loss_kilowatts', value: 'Power Loss' },
    lastInspectionDate: {
        key: 'last_inspection_date',
        value: 'Last Inspection Date',
    },
    lastInspectionType: { key: 'name_external', value: 'Last Inspection Type' },
    city: { key: 'city', value: 'City' },
    state: { key: 'province', value: 'Province' },
    country: { key: 'country', value: 'Country' },
};

export const SITE_INSPECTIONS_SORT_MAP = {
    publishDate: 'inspection_date',
    bos: 'bos_percentage',
    major: 'major_percentage',
    minor: 'minor_percentage',
    inspectionType: 'report_type',
    powerLoss: 'power_loss_kilowatts',
};

export const SITE_IMAGE_URL = (uuid: string | undefined) =>
    `https://storage.googleapis.com/re-plus-demo-data/site-images/${uuid}.png`;

export const SITES_PARAMS_KEYS = ['name', 'capacityRange', 'boundary', 'year', 'offset', 'limit', 'sort'];

export const USER_MGMT_PERMISSION = 'user_management.editor';

export const NO_INSPECTIONS = 'No inspections';
export const ALL_TIME = 'All Time';
