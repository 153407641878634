import { Loader } from '@dronebase/shared-ui-core';
import { Center, Modal, Text } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

import { WorkspaceListContainer } from 'components/common/Auth/Workspace.styled';
import { ROUTES } from 'lib/constants/routes';
import { useSwitchWorkspaceModalContext } from 'lib/context/SwitchWorkspaceModalContext';
import { getCookie } from 'lib/cookies';
import { switchWorkspace } from 'lib/helpers';
import useWorkspaceDetails from 'lib/hooks/useWorkspaceDetails';
import { useResetSiteFiltersStorage } from 'state/sites/filters/siteFiltersStorage';

import WorkspaceRow from '../../Workspace/WorkspaceRow';

const SelectWorkspaceModal = () => {
    const currentWorkspace = getCookie('helio-workspace');
    const { allWorkspaces, allPermissions, allPermissionsV2 } = useWorkspaceDetails();

    const resetAllSiteFilters = useResetSiteFiltersStorage();

    const { isModalOpen, closeModal } = useSwitchWorkspaceModalContext();

    const navigate = useNavigate();

    const handleWorkspaceChange = (newWorkspaceUuid: string) => {
        const newWorkspace = allWorkspaces?.find((w) => w.uuid === newWorkspaceUuid);

        if (newWorkspace && newWorkspace.uuid !== currentWorkspace.id) {
            switchWorkspace(allPermissions, allPermissionsV2, newWorkspace.uuid, newWorkspace.name);
            navigate(ROUTES.auth.index);
            resetAllSiteFilters();
        }

        closeModal();
    };

    return (
        <Modal size="sm" opened={isModalOpen} onClose={closeModal} title={<Text color="gray.9">Change Workspace</Text>}>
            <Text color="gray.4" mb="1rem">
                Please select a workspace to use
            </Text>
            <WorkspaceListContainer>
                {allWorkspaces ? (
                    allWorkspaces.map((workspace) => (
                        <WorkspaceRow
                            onClick={() => handleWorkspaceChange(workspace.uuid)}
                            workspace={workspace}
                            key={workspace.uuid}
                        />
                    ))
                ) : (
                    <Center style={{ height: 80 }}>
                        <Loader />
                    </Center>
                )}
            </WorkspaceListContainer>
        </Modal>
    );
};

export default SelectWorkspaceModal;
