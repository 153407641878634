import { Badge, Divider, Grid, Group, Stack, Text } from '@mantine/core';

import { FAULT_TYPE_INSPECTION_TYPE_MAP } from 'lib/constants';
import { useDifferentialMode } from 'lib/hooks';

export interface Grade {
    grade: string;
    value: string;
    disabled?: boolean;
}
export interface PowerSummaryRowsProps {
    date?: string;
    powerLossPercent: number;
    powerLossKw: number;
    reportType?: string;
}

export interface PowerSummaryData {
    [inspection: string]: PowerSummaryRowsProps;
}

export interface ModuleInspection {
    bos: Grade;
    major: Grade;
    minor: Grade;
}

export interface ModuleSummaryData {
    [inspection: string]: ModuleInspection;
}

const GRADE_COLORS: { [grade: string]: string } = {
    A: 'green.7',
    B: 'yellow.5',
    C: 'orange.5',
    D: 'red.5',
    disabled: 'gray.3',
};

export const PowerSummary = ({
    previousInspection,
    currentInspection,
    previousReportType,
    currentReportType,
}: {
    previousInspection: PowerSummaryRowsProps;
    currentInspection: PowerSummaryRowsProps;
    previousReportType: string;
    currentReportType: string;
}) => (
    <Grid columns={3}>
        <Grid.Col span={1} offset={1}>
            <Text variant="caption1" color="var(--color-grey-300)">
                {previousInspection.date}
            </Text>
        </Grid.Col>
        <Grid.Col span={1}>
            <Text variant="caption1" color="var(--color-grey-300)">
                {currentInspection.date}
            </Text>
        </Grid.Col>

        <Grid.Col span={3}>
            <Divider size="xs" color="gray.1" />
        </Grid.Col>

        <Grid.Col span={1} offset={1}>
            <Text variant="body2light" color="var(--color-grey-900)">
                {previousReportType}
            </Text>
        </Grid.Col>
        <Grid.Col span={1}>
            <Text variant="body2light" color="var(--color-grey-900)">
                {currentReportType}
            </Text>
        </Grid.Col>

        <Grid.Col span={1}>
            <Text variant="body2light" color="var(--color-grey-900)">
                Power Loss
            </Text>
        </Grid.Col>
        <Grid.Col span={1}>
            <Text variant="body2" color="var(--color-grey-900)">
                {previousInspection.powerLossPercent.toFixed(2)}%
            </Text>
        </Grid.Col>
        <Grid.Col span={1}>
            <Text variant="body2" color="var(--color-grey-900)">
                {currentInspection.powerLossPercent.toFixed(2)}%
            </Text>
        </Grid.Col>

        <Grid.Col span={1}>
            <Text variant="body2light" color="var(--color-grey-900)">
                Power Loss
            </Text>
        </Grid.Col>
        <Grid.Col span={1}>
            <Text variant="body2" color="var(--color-grey-900)">
                {previousInspection.powerLossKw.toFixed(2)} KW
            </Text>
        </Grid.Col>
        <Grid.Col span={1}>
            <Text variant="body2" color="var(--color-grey-900)">
                {currentInspection.powerLossKw.toFixed(2)} KW
            </Text>
        </Grid.Col>
    </Grid>
);

const ModuleSummaryItem = ({ grade, value, disabled }: Grade) => (
    <Grid.Col span={1}>
        <Group align="baseline" spacing="xs">
            <Badge
                radius="sm"
                color={GRADE_COLORS[disabled ? 'disabled' : grade]}
                size="xs"
                variant="filled"
                styles={{
                    inner: {
                        color: 'white',
                        fontWeight: 'var(--caption1-font-weight)' as unknown as number,
                        fontSize: 'var(--caption1-font-size)',
                    },
                }}
            >
                {disabled ? 'N/A' : grade}
            </Badge>
            {!disabled && (
                <Stack spacing={0}>
                    <Text variant="body2" color="var(--color-grey-900)">
                        {value}
                    </Text>
                    <Text variant="caption1" ta="end">
                        of modules
                    </Text>
                </Stack>
            )}
        </Group>
    </Grid.Col>
);

const ModuleSummaryGridRow = ({
    summaryCategory,
    faultType,
    previousInspection,
    currentInspection,
    previousReportType,
    currentReportType,
}: {
    summaryCategory: string;
    faultType: keyof ModuleInspection;
    previousInspection: ModuleInspection;
    currentInspection: ModuleInspection;
    previousReportType: string;
    currentReportType: string;
}) => (
    <>
        <Grid.Col span={1}>
            <Text variant="body2light" color="var(--color-grey-900)">
                {summaryCategory}
            </Text>
        </Grid.Col>
        <ModuleSummaryItem
            grade={previousInspection[faultType].grade}
            value={previousInspection[faultType].value}
            disabled={FAULT_TYPE_INSPECTION_TYPE_MAP[faultType].includes(previousReportType)}
        />
        <ModuleSummaryItem
            grade={currentInspection[faultType].grade}
            value={currentInspection[faultType].value}
            disabled={FAULT_TYPE_INSPECTION_TYPE_MAP[faultType].includes(currentReportType)}
        />
    </>
);

export const ModuleSummary = () => {
    const {
        modulesSummaryData: { previousInspection, currentInspection },
        previousReportType,
        currentReportType,
    } = useDifferentialMode();

    const summaryCategory = ['Balance of System', 'Major Module', 'Minor Module'];

    return (
        <Grid columns={3}>
            {Object.keys(previousInspection).map((faultType, key) => (
                <ModuleSummaryGridRow
                    key={faultType}
                    faultType={faultType as keyof ModuleInspection}
                    previousInspection={previousInspection}
                    currentInspection={currentInspection}
                    summaryCategory={summaryCategory[key]}
                    previousReportType={previousReportType}
                    currentReportType={currentReportType}
                />
            ))}
        </Grid>
    );
};
