import { ConfirmModal } from '@dronebase/shared-ui-core';
import { Text } from '@mantine/core';

import { LightThemeProvider } from 'providers/LightThemeProvider';

interface DiscardModalProps {
    opened: boolean;
    onClose: () => void;
    onDiscard: () => void;
}

const DiscardChangesModal = ({ opened, onClose, onDiscard }: DiscardModalProps) => (
    <LightThemeProvider>
        <ConfirmModal
            opened={opened}
            onClose={onClose}
            title="Discard unsaved changes?"
            buttonPosition="end"
            confirmLabel="Discard"
            cancelLabel="Cancel"
            onConfirm={onDiscard}
        >
            <Text size="sm" variant="body2">
                Any changes will be lost.
            </Text>
        </ConfirmModal>
    </LightThemeProvider>
);

export default DiscardChangesModal;
