import React from 'react';

import { IconProps } from './icon_definition';

export const Google = ({ width = 16, height = 16, ...rest }: IconProps) => (
        <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <g clipPath="url(#clip0_4879_92677)">
                <path
                    d="M15.83 8.17974C15.83 7.65307 15.7833 7.15307 15.7033 6.6664H8.17001V9.67307H12.4833C12.29 10.6597 11.7233 11.4931 10.8833 12.0597V14.0597H13.4567C14.9633 12.6664 15.83 10.6131 15.83 8.17974Z"
                    fill="#4285F4"
                />
                <path
                    d="M8.17 16C10.33 16 12.1367 15.28 13.4567 14.06L10.8833 12.06C10.1633 12.54 9.25 12.8333 8.17 12.8333C6.08333 12.8333 4.31667 11.4267 3.68333 9.52666H1.03V11.5867C2.34333 14.2 5.04333 16 8.17 16Z"
                    fill="#34A853"
                />
                <path
                    d="M3.68335 9.52667C3.51668 9.04667 3.43001 8.53334 3.43001 8C3.43001 7.46667 3.52335 6.95334 3.68335 6.47334V4.41334H1.03001C0.483346 5.49334 0.170013 6.70667 0.170013 8C0.170013 9.29334 0.483346 10.5067 1.03001 11.5867L3.68335 9.52667Z"
                    fill="#FBBC05"
                />
                <path
                    d="M8.17 3.16667C9.35 3.16667 10.4033 3.57334 11.2367 4.36667L13.5167 2.08667C12.1367 0.793334 10.33 0 8.17 0C5.04333 0 2.34333 1.8 1.03 4.41334L3.68333 6.47334C4.31667 4.57334 6.08333 3.16667 8.17 3.16667Z"
                    fill="#EA4335"
                />
            </g>
            <defs>
                <clipPath id="clip0_4879_92677">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
