import { useCallback, useEffect, useMemo } from 'react';

import { Cross } from '@dronebase/shared-ui-icons';
import { ActionIcon, Divider, Flex, Group, Select, Stack, Tabs, Text } from '@mantine/core';
import { useAtom, useAtomValue } from 'jotai';
import { Link, useParams } from 'react-router-dom';

import { MODULE_DETAILS_TABS, MODULE_TABS } from 'lib/constants';
import { ROUTES } from 'lib/constants/routes';
import { shortFormatDate } from 'lib/helpers';
import { useAnomalyMapData, useDifferentialAnomalyData, useDifferentialMode } from 'lib/hooks';
import { useTabularNavigation } from 'lib/hooks/useTabularNavigation';
import { Asset, InspectionSummary } from 'lib/types/inspections/anomaliesMap';
import { anomalyTabDropdownInspectionIdAtom, selectedAnomaliesAtom } from 'state/inspections/anomaliesMap';

import { PositionIDPopover } from './PositionIDPopover';
import { SingleDetail } from './SingleDetail';
import { PanelHeaderContainer } from '../TabContainers.styled';

const ModuleDetails = ({ asset }: { asset: Asset }) => {
    const { assetRegionUuid, stack, positionId } = asset;
    const selectedAnomalies = useAtomValue(selectedAnomaliesAtom);
    const { siteId, inspectionId, assetRegionUUID } = useParams();

    const {
        active: differentialModeIsActive,
        previousInspectionSummary,
        currentInspectionSummary,
        differentialModeInspectionId,
    } = useDifferentialMode();
    const { previousRawMapAnomalies, previousInspectionId } = useDifferentialAnomalyData();
    const { currentInspectionMapAnomalies, currentInspectionId } = useAnomalyMapData();

    const [anomalyTabDropdownInspectionId, setAnomalyTabDropdownInspectionId] = useAtom(
        anomalyTabDropdownInspectionIdAtom,
    );

    useEffect(() => {
        setAnomalyTabDropdownInspectionId(null);
    }, [assetRegionUUID, setAnomalyTabDropdownInspectionId]);

    const currentInspectionSelectedAnomaly = useMemo(
        () =>
            currentInspectionMapAnomalies[assetRegionUuid]
                ? {
                      ...currentInspectionMapAnomalies[assetRegionUuid],
                      inspectionId: currentInspectionId,
                  }
                : undefined,
        [currentInspectionMapAnomalies, assetRegionUuid, currentInspectionId],
    );

    const previousInspectionSelectedAnomaly = useMemo(
        () =>
            previousRawMapAnomalies[assetRegionUuid]
                ? {
                      ...previousRawMapAnomalies[assetRegionUuid],
                      inspectionId: previousInspectionId,
                  }
                : undefined,
        [previousRawMapAnomalies, assetRegionUuid, previousInspectionId],
    );

    const inspections: InspectionSummary[] = [previousInspectionSummary, currentInspectionSummary].filter(Boolean);

    const selectInspectionData: {
        value: string;
        label: string;
    }[] = inspections.map((inspection) => ({
        value: inspection.inspectionID,
        label: shortFormatDate(inspection.publishDate),
    }));

    const createNavPath = useCallback((tab: MODULE_TABS) => {
        const isAnomalyTab = tab.toLowerCase() === MODULE_TABS.ANOMALY.toLowerCase();

        return `${tab.toLowerCase()}${isAnomalyTab ? `/1` : ''}`;
    }, []);

    const { currentTab, setAndNavigateToTab } = useTabularNavigation<MODULE_TABS>(
        MODULE_DETAILS_TABS,
        MODULE_TABS.MODULE,
        createNavPath,
    );

    const handleInspectionSelect = useCallback(
        (selectedInspectionID: string) => {
            const inspection = inspections.find((item) => item.inspectionID === selectedInspectionID);

            if (inspection) {
                const selectedInspection =
                    inspection.inspectionID === previousInspectionSummary.inspectionID
                        ? previousInspectionSummary
                        : currentInspectionSummary;

                setAnomalyTabDropdownInspectionId(selectedInspection.inspectionID);
            }
        },
        [inspections, previousInspectionSummary, currentInspectionSummary, setAnomalyTabDropdownInspectionId],
    );

    return (
        <Tabs value={currentTab.toLowerCase()} onTabChange={setAndNavigateToTab} h="auto">
            <PanelHeaderContainer>
                <ActionIcon
                    component={Link}
                    size="md"
                    to={`${ROUTES.sites.index}/${siteId}/${inspectionId}/${ROUTES.sites.inspection.map.index}`}
                    style={{ position: 'absolute', top: '0.5rem', right: '0.5rem' }}
                >
                    <Cross />
                </ActionIcon>
                <Group align="center" spacing="0.75rem" pb="1rem" pl="0rem">
                    <Text variant="subtitle2" c="gray.0" w="100%">
                        Panel Details
                    </Text>
                    <Group spacing="0.5rem" position="apart" align="flex-start" w="100%">
                        <Group spacing="0.75rem" align="flex-start">
                            <Stack spacing="0.12rem" align="flex-start">
                                <Text variant="caption1" c="gray.3">
                                    Stack
                                </Text>
                                <Text variant="body2light" c="gray.0">
                                    {stack || '-'}
                                </Text>
                            </Stack>
                            <Divider orientation="vertical" />
                            <Stack spacing="0.12rem" align="flex-start">
                                <Text variant="caption1" c="gray.3">
                                    Position in Stack
                                </Text>
                                <Flex columnGap="0.25rem">
                                    <Text variant="body2light" c="gray.0">
                                        {positionId || '-'}
                                    </Text>
                                    {positionId && <PositionIDPopover positionId={positionId} />}
                                </Flex>
                            </Stack>
                        </Group>
                        {(currentInspectionSelectedAnomaly || previousInspectionSelectedAnomaly) &&
                            differentialModeIsActive && (
                                <SingleDetail label="Inspection Date">
                                    {currentInspectionSelectedAnomaly && previousInspectionSelectedAnomaly ? (
                                        <Select
                                            w="9rem"
                                            data={selectInspectionData}
                                            value={anomalyTabDropdownInspectionId || differentialModeInspectionId}
                                            onChange={handleInspectionSelect}
                                        />
                                    ) : (
                                        <Text variant="body2light" c="gray.0">
                                            {shortFormatDate(
                                                inspections.filter(
                                                    (item) =>
                                                        item.inspectionID ===
                                                            currentInspectionSelectedAnomaly?.inspectionId ||
                                                        item.inspectionID ===
                                                            previousInspectionSelectedAnomaly?.inspectionId,
                                                )[0].publishDate,
                                            )}
                                        </Text>
                                    )}
                                </SingleDetail>
                            )}
                    </Group>
                </Group>

                <Tabs.List>
                    {selectedAnomalies && selectedAnomalies.species.length > 0 && (
                        <Tabs.Tab value={MODULE_TABS.ANOMALY.toLowerCase()}>Anomaly</Tabs.Tab>
                    )}
                    <Tabs.Tab value={MODULE_TABS.MODULE.toLowerCase()}>Module</Tabs.Tab>
                </Tabs.List>
            </PanelHeaderContainer>
        </Tabs>
    );
};

export default ModuleDetails;
