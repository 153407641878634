import { useCallback, useEffect, useMemo, useState } from 'react';

import _ from 'lodash';
import { useLocation, useNavigate, useParams, useRouteLoaderData } from 'react-router-dom';

import { InspectionLoaderData } from 'components/inspections/types';
import {
    DIFFERENTIAL_REPORT_TABS,
    FULL_REPORT_TABS,
    GLASS_CRACK_REPORT_TABS,
    REPORT_TABS,
    REPORT_TYPES,
} from 'lib/constants';
import { ROUTES } from 'lib/constants/routes';
import { getTabFromPath } from 'lib/helpers/routing';

import { useDifferentialMode } from './useDifferentialMode';

export const useInspectionTabs = () => {
    const { pathname } = useLocation();
    const { siteId, inspectionId } = useParams();
    const { active: isDifferentialModeActive } = useDifferentialMode();
    const { reportType } = useRouteLoaderData('inspection') as InspectionLoaderData;

    const tabs =
        reportType === REPORT_TYPES.GLASS_CRACK
            ? GLASS_CRACK_REPORT_TABS
            : isDifferentialModeActive
              ? DIFFERENTIAL_REPORT_TABS
              : FULL_REPORT_TABS;
    const navigate = useNavigate();

    const [currentTab, setCurrentTab] = useState<REPORT_TABS>(() =>
        getTabFromPath<REPORT_TABS>(pathname, tabs, REPORT_TABS.FINDINGS),
    );

    const setAndNavigateToTab = useCallback(
        (tab: REPORT_TABS) => {
            setCurrentTab(tab);
            navigate(`${ROUTES.sites.index}/${siteId}/${inspectionId}/${tab.toLowerCase()}`);
        },
        [inspectionId, navigate, siteId],
    );

    const value = useMemo(
        () => ({
            currentTab,
            setAndNavigateToTab,
            tabs,
        }),
        [currentTab, setAndNavigateToTab, tabs],
    );

    // Update current tab when path changes from a blocking event
    useEffect(() => {
        const newTab = getTabFromPath<REPORT_TABS>(pathname, tabs, REPORT_TABS.FINDINGS);

        if (newTab !== currentTab) {
            setCurrentTab(newTab);
        }
    }, [currentTab, pathname, tabs]);

    return value;
};
