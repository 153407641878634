import { authExchange } from '@urql/exchange-auth';
import { cacheExchange, createClient, fetchExchange } from 'urql';

import { Config } from 'config';
import { clearCookies, getCookie } from 'lib/cookies';
import { getAuthToken } from 'lib/helpers';

interface FetchOptions {
    headers: {
        'x-user-workspace': string;
        'x-user-permission': string;
        'content-type': string;
    };
}

export const getHasuraFetchOptions = (permission: string): FetchOptions => ({
    headers: {
        'x-user-workspace': getCookie('helio-workspace')?.id || '',
        'x-user-permission': permission,
        'content-type': 'application/json',
    },
});

const client = createClient({
    url: String(Config.get('GRAPHQL_API_URL')),
    exchanges: [
        cacheExchange,
        authExchange(async (utils) => {
            const token = await getAuthToken();

            if (token) {
                localStorage.setItem('token', token);
            }

            return {
                addAuthToOperation(operation) {
                    const { token } = localStorage;

                    if (!token) {
                        return operation;
                    }

                    return utils.appendHeaders(operation, {
                        'x-user-token': token,
                    });
                },
                didAuthError(error) {
                    return error.message === '[GraphQL] Authentication hook unauthorized this request';
                },
                async refreshAuth() {
                    const token = await getAuthToken();

                    if (token) {
                        localStorage.setItem('token', token);
                    } else {
                        localStorage.removeItem('token');
                        clearCookies();
                    }
                },
            };
        }),
        fetchExchange,
    ],
    fetchOptions: () => getHasuraFetchOptions('reports.viewer'),
});

export { client };
