const SiteIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            className="controlled-fill"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 1.5C7.88275 1.5 3.75 4.52687 3.75 10.1432C3.75 12.4218 5.04169 14.8961 6.48083 16.9729C7.94049 19.0793 9.64636 20.9083 10.6501 21.9206C11.4039 22.681 12.5878 22.6961 13.3576 21.9475C14.3681 20.9647 16.073 19.1904 17.5301 17.0987C18.9709 15.0304 20.25 12.5393 20.25 10.1432C20.25 4.52687 16.1173 1.5 12 1.5ZM5.13696 10.1432C5.13696 5.3855 8.56068 2.89909 12 2.89909C15.4393 2.89909 18.863 5.3855 18.863 10.1432C18.863 12.0905 17.7972 14.2819 16.3953 16.2943C15.0099 18.2831 13.3747 19.9875 12.3949 20.9402C12.1721 21.1569 11.8525 21.1549 11.6308 20.9313C10.6539 19.9459 9.01125 18.1825 7.6176 16.1713C6.20342 14.1306 5.13696 11.9625 5.13696 10.1432ZM9.45497 9.82212C9.45497 8.40425 10.5944 7.25485 12 7.25485C13.4056 7.25485 14.545 8.40425 14.545 9.82212C14.545 11.24 13.4056 12.3894 12 12.3894C10.5944 12.3894 9.45497 11.24 9.45497 9.82212ZM12 5.85576C9.82842 5.85576 8.06801 7.63156 8.06801 9.82212C8.06801 12.0127 9.82842 13.7885 12 13.7885C14.1716 13.7885 15.932 12.0127 15.932 9.82212C15.932 7.63156 14.1716 5.85576 12 5.85576Z"
        />
    </svg>
);

export default SiteIcon;
