import { cloneDeep } from 'lodash';

import {
    AggregatedAnomaly,
    FinancialLossCalculator,
    InspectionReportType,
    MapFilterCategory,
    ReportDefinitionAggregation,
    Species,
} from 'components/inspections/types';
import { MapAnomalies } from 'components/Sites/types';
import { InspectionAnomaly } from 'lib/types/inspections/anomaliesMap';

import {
    DEFAULT_SITE_LOSS_VALUES,
    EMPTY_SIGNAL_OR_MODIFIER,
    FILTERED_EMPTY_ANOMALY_ATTRIBUTES,
    HIDDEN_CSV_FIELDS,
    NO_IR_SIGNAL,
    NO_RGB_SIGNAL,
    PivotViewOptions,
    PRIORITY_LEVEL_TO_REMEDIATION_CATEGORY,
    REPORT_TYPE_CTA_TEXT,
} from '../constants';

const { APR, HURDLE_FACTOR, NPV_DURATION, PPA_RATE } = DEFAULT_SITE_LOSS_VALUES;

export const formatDollarAmount = (amount: number) => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2,
    });

    return formatter.format(amount);
};

export const formatPercentage = (value: number) => `${(value * 100).toFixed(2)}%`;

export const mapKeyObservationImages = (data: any): any => [
    ...(data?.keyObservationImageResponse[0]?.aoi?.legacy?.default?.map((image: any) => image.public_gcp_url) ?? []),
    ...(data?.keyObservationImageResponse[0]?.aoi?.legacy?.ir?.map((image: any) => image.public_gcp_url) ?? []),
    ...(data?.keyObservationImageResponse[0]?.aoi?.legacy?.viz?.map((image: any) => image.public_gcp_url) ?? []),
];

export const flattenAnomalies = (
    anomalySummary: ReportDefinitionAggregation[],
    anomalySpecies: [Species],
): AggregatedAnomaly[] => {
    const totalAffectedModulesCount = getTotalAffectedModuleCount(anomalySummary);
    const flattened = anomalySummary?.map((anomalyData: ReportDefinitionAggregation) => {
        const { irSignal, irModifier, rgbSignal, rgbModifier } = anomalyData.definition.species;
        const irFilter = irSignal + (irModifier !== EMPTY_SIGNAL_OR_MODIFIER ? `-${irModifier}` : '');
        const rgbFilter = rgbSignal + (rgbModifier !== EMPTY_SIGNAL_OR_MODIFIER ? `-${rgbModifier}` : '');
        const isIRAnomaly = anomalyData.definition.species.irSignal !== EMPTY_SIGNAL_OR_MODIFIER;
        const isRGBAnomaly = anomalyData.definition.species.rgbSignal !== EMPTY_SIGNAL_OR_MODIFIER;
        const showAsOther = isIRAnomaly && anomalyData.affectedModuleCount / totalAffectedModulesCount < 0.01;

        return {
            definitionName: anomalyData.definition.globalDefinition.nameExternal,
            showAsOther,
            priorityLevel: anomalyData.definition.priority,
            remediationCategory: PRIORITY_LEVEL_TO_REMEDIATION_CATEGORY[anomalyData.definition.priority],
            modulesImpacted: anomalyData.affectedModuleCount,
            anomalyCount: anomalyData.anomalyCount,
            powerLoss: anomalyData.definition.energyLossMid,
            irAnomalyName: getIRAnomalyName(anomalySpecies, irSignal, irModifier),
            rgbAnomalyName: getRGBAnomalyName(anomalySpecies, rgbSignal, rgbModifier),
            isIRAnomaly,
            isRGBAnomaly,
            irSignal,
            irModifier,
            irFilter,
            rgbSignal,
            rgbModifier,
            rgbFilter,
        };
    });

    return flattened;
};

export const getIRAnomalyName = (anomalySpecies: Species[], irSignal: string, irModifier: string) => {
    if (irSignal === EMPTY_SIGNAL_OR_MODIFIER) {
        return NO_IR_SIGNAL;
    }

    return (
        anomalySpecies.find((species) => species.irSignal === irSignal && species.irModifier === irModifier)
            ?.nameExternal ?? NO_IR_SIGNAL
    );
};

export const getRGBAnomalyName = (anomalySpecies: Species[], rgbSignal: string, rgbModifier: string) => {
    if (rgbSignal === EMPTY_SIGNAL_OR_MODIFIER) {
        return NO_RGB_SIGNAL;
    }

    return (
        anomalySpecies.find((species) => species.rgbSignal === rgbSignal && species.rgbModifier === rgbModifier)
            ?.nameExternal ?? NO_RGB_SIGNAL
    );
};

export const buildSignalFilterSections = (
    staticAnomalyFilters: any,
    mapAnomalies: Record<string, any>,
    anomalySpecies: any,
) => {
    const sections = cloneDeep(staticAnomalyFilters);

    Object.values(mapAnomalies).forEach(({ species }: { species: Array<{ irFilter: string; rgbFilter: string }> }) => {
        species.forEach(({ irFilter, rgbFilter }) => {
            const irFilterName = anomalySpecies.ir[irFilter];

            sections.ir.filterOptions[irFilterName] = {
                name: irFilterName,
                databaseValue: irFilter,
                count: sections.ir.filterOptions[irFilterName]?.count + 1 || 1,
            };

            const rgbFilterName = anomalySpecies.rgb[rgbFilter];

            sections.rgb.filterOptions[rgbFilterName] = {
                name: rgbFilterName,
                databaseValue: rgbFilter,
                count: sections.rgb.filterOptions[rgbFilterName]?.count + 1 || 1,
            };
        });
    });

    return sections;
};

export const buildStatusIdsFilterSection = (
    staticStatusIdsFilter: MapFilterCategory,
    mapAnomalies: MapAnomalies,
): MapFilterCategory => {
    const statusIdsSection = cloneDeep(staticStatusIdsFilter);

    Object.values(mapAnomalies).forEach(({ species }) => {
        species.forEach(({ statusId }) => {
            statusIdsSection.filterOptions[statusId].count! += 1;
        });
    });

    return statusIdsSection;
};

export const getTotalAnomalyCount = (mapAnomalies: any) =>
    Object.values(mapAnomalies).reduce((acc, { species }: any) => acc + species?.length, 0);

export const getSignalCounts = (mapAnomalies: Record<string, any>) => {
    const signalCounts: any = {};

    Object.values(mapAnomalies).forEach(({ species }: any) =>
        species.forEach(({ irFilter, rgbFilter }: { irFilter: string; rgbFilter: string }) => {
            signalCounts[irFilter] = signalCounts[irFilter] + 1 || 1;
            signalCounts[rgbFilter] = signalCounts[rgbFilter] + 1 || 1;
        }),
    );

    return signalCounts;
};

export const getTotalAffectedModuleCount = (anomalySummary: any) =>
    anomalySummary.reduce((count: number, summaryEntry: any) => count + summaryEntry.affectedModuleCount, 0);

export const generateSignalFilterData = (anomalySummary: AggregatedAnomaly[]) => {
    const uniqueIRSignals: any = {};
    const uniqueRGBSignals: any = {};

    anomalySummary?.forEach((anomaly: any) => {
        const irSignalCount = anomaly.modulesImpacted + (uniqueIRSignals[anomaly.irFilter]?.count || 0);
        const rgbSignalCount = anomaly.modulesImpacted + (uniqueRGBSignals[anomaly.rgbFilter]?.count || 0);

        uniqueIRSignals[anomaly.irFilter] = {
            name: anomaly.irAnomalyName || NO_IR_SIGNAL,
            count: irSignalCount,
        };
        uniqueRGBSignals[anomaly.rgbFilter] = {
            name: anomaly.rgbAnomalyName || NO_RGB_SIGNAL,
            count: rgbSignalCount,
        };
    });

    const flatIrSignals = Object.keys(uniqueIRSignals).map((irFilter) => ({
        name: uniqueIRSignals[irFilter].name,
        count: uniqueIRSignals[irFilter].count,
        filter: irFilter,
    }));

    const flatRGBSignals = Object.keys(uniqueRGBSignals).map((rgbFilter) => ({
        name: uniqueRGBSignals[rgbFilter].name,
        count: uniqueRGBSignals[rgbFilter].count,
        filter: rgbFilter,
    }));

    return {
        irSignals: flatIrSignals.sort((a, b) => b.count - a.count),
        rgbSignals: flatRGBSignals.sort((a, b) => b.count - a.count),
    };
};

export const generateSignalColorsFromSignalFilters = (signalFilters: any) =>
    getAggregatedSignalColors(
        signalFilters?.irSignals.map((irSignal: any) => irSignal.filter),
        signalFilters?.rgbSignals.map((rgbSignal: any) => rgbSignal.filter),
    );

export const filterAnomalyAttributes = (data: any) => {
    const [header, ...rows] = data;

    FILTERED_EMPTY_ANOMALY_ATTRIBUTES.concat(HIDDEN_CSV_FIELDS).forEach((attribute: string) => {
        if (header.includes(attribute)) {
            const index = header.indexOf(attribute);
            // Hidden fields are always removed, filtered empty are only removed if some entries are empty
            const shouldRemove = HIDDEN_CSV_FIELDS.includes(attribute) || !rows.some((row: any) => row[index] !== '-');

            if (shouldRemove) {
                header.splice(index, 1);
                rows.forEach((row: any) => row.splice(index, 1));
            }
        }
    });

    return [header, ...rows];
};

export const buildAOIObservations = (observations: {
    inspectionsReportObservations: Array<{
        uuid: string;
        reportObservationAois: Array<{ aoiUuid: string }>;
    }>;
}) => {
    const aoiObservations: { [key: string]: Array<string> } = {};

    observations?.inspectionsReportObservations?.forEach((observation) => {
        observation?.reportObservationAois?.forEach((reportObservation) => {
            if (aoiObservations[reportObservation.aoiUuid]) {
                aoiObservations[reportObservation.aoiUuid] = [
                    ...aoiObservations[reportObservation.aoiUuid],
                    observation.uuid,
                ];
            } else {
                aoiObservations[reportObservation.aoiUuid] = [observation.uuid];
            }
        });
    });

    return aoiObservations;
};

function generateColorGradient(colors: number[][], count: number) {
    if (count === 1) {
        // If there is only one color needed return the last color of the gradient
        return colors.slice(-1);
    }

    const colorPositions = colors.map((value, index) => index / (colors.length - 1));
    const step = 1 / (count - 1);

    let currentColorPosition = 0;
    let lowerBound = colorPositions[0];
    let upperBound = colorPositions[1];

    const delta = upperBound - lowerBound;
    const colorGradient: number[][] = [];

    for (let i = 0; i < count; i++) {
        const position = i * step;

        while (position < lowerBound || position > upperBound) {
            currentColorPosition++;
            lowerBound = colorPositions[currentColorPosition];
            upperBound = colorPositions[currentColorPosition + 1];
        }

        const distance = (position - lowerBound) / delta;
        const color = colors[currentColorPosition].map((lowerColorComponent, index) =>
            Math.round(
                lowerColorComponent + (colors[currentColorPosition + 1][index] - lowerColorComponent) * distance,
            ),
        );

        colorGradient.push(color);
    }

    return colorGradient;
}

const IR_COLORS = [
    [253, 197, 0],
    [255, 45, 45],
    [161, 26, 156],
];

const RGB_COLORS = [
    [45, 17, 216],
    [2, 157, 129],
    [107, 186, 28],
];

const getAggregatedSignalColor = (signals: string[], colors: number[][]) => {
    const generatedColors = generateColorGradient(colors, signals.length);

    return Object.fromEntries(signals.map((signal, index) => [signal, generatedColors[index]]));
};

export const getAggregatedSignalColors = (irSignals: string[], rgbSignals: string[]) => ({
    ir: getAggregatedSignalColor(irSignals, IR_COLORS),
    rgb: getAggregatedSignalColor(rgbSignals, RGB_COLORS),
});

export const calculateFinancialLoss = ({
    powerLossBreakdown,
    ppaRate = PPA_RATE,
    apr = APR,
    hurdleRate = HURDLE_FACTOR,
    npvDuration = NPV_DURATION,
}: FinancialLossCalculator) => {
    const getFaultLossValue = (powerLoss: number) =>
        (((powerLoss / 1000) * apr * ppaRate) / (hurdleRate / 100)) * (1 - 1 / (1 + hurdleRate / 100) ** npvDuration);

    const bosFinancialLoss = (powerLossBreakdown.bos / 1000) * apr * ppaRate;
    const majorFinancialLoss = getFaultLossValue(powerLossBreakdown.major);
    const minorFinancialLoss = getFaultLossValue(powerLossBreakdown.minor);

    return {
        totalFinancialLoss: formatDollarAmount(bosFinancialLoss + minorFinancialLoss + majorFinancialLoss),
        bosFinancialLoss: formatDollarAmount(bosFinancialLoss),
        majorFinancialLoss: formatDollarAmount(majorFinancialLoss),
        minorFinancialLoss: formatDollarAmount(minorFinancialLoss),
        majorAndMinorFinancialLoss: formatDollarAmount(minorFinancialLoss + majorFinancialLoss),
        moduleBasicRecoverableLoss: formatDollarAmount(bosFinancialLoss + majorFinancialLoss),
    };
};

export const isReportUpgradeExpired = (upgradeExpiresAt?: string | number, inspectionDate?: string | number) => {
    if (!upgradeExpiresAt) {
        if (inspectionDate) {
            upgradeExpiresAt = new Date(inspectionDate).getTime() + 12 * 30 * 24 * 60 * 60 * 1000;
        } else {
            return false;
        }
    }

    const now = new Date().getTime();
    const expiryDate = new Date(upgradeExpiresAt).getTime();

    return now >= expiryDate;
};

export const upgradeReportMatch = (inspectionReportTypes: InspectionReportType[], reportUpgrade: any, type: string) => {
    const existingReport = inspectionReportTypes.find(
        (report: InspectionReportType) => report.uuid === reportUpgrade?.reportTypeUuid,
    );

    return existingReport?.nameExternal === REPORT_TYPE_CTA_TEXT[type];
};

export const pickDifferentialInspection = (previousInspections: any) =>
    // TODO: Add the picking logic for the differential inspection. For now, just return the first inspection
    previousInspections && previousInspections[0];

export const buildPivotViewDropdownOptions = (
    viewOptions: PivotViewOptions[],
    disabledViewOptions?: PivotViewOptions[],
) =>
    viewOptions.map((option) => ({
        value: option,
        label: option,
        disabled: disabledViewOptions?.includes(option) || false,
    }));
