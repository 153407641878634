/* eslint-disable complexity */
import { CompositeLayer, MVTLayer, TextLayer } from 'deck.gl';

import {
    AssetTypes,
    MAX_LABEL_ZOOM,
    MIN_COMBINER_LABEL_ZOOM,
    MIN_COMBINER_OUTLINE_ZOOM,
    SELECTED_LAYER_OUTLINE_COLOR,
    MIN_ZOOM_PICKABLE,
} from 'lib/constants';

import { getAssetMVTURL } from '../deckglLayers';

class ElectricalRegionLayer extends CompositeLayer {
    renderLayers() {
        const isCombinerLayer = this.props.id === AssetTypes.combiner.layerId;
        const isStackOrInverterLayer =
            this.props.id === AssetTypes.stack.layerId || this.props.id === AssetTypes.inverter.layerId;

        const shouldShowOutlines =
            isStackOrInverterLayer || (isCombinerLayer && this.props.zoom >= MIN_COMBINER_OUTLINE_ZOOM);

        const shouldShowLabels =
            ((isCombinerLayer && this.props.zoom >= MIN_COMBINER_LABEL_ZOOM) ||
                (isStackOrInverterLayer && this.props.zoom >= MIN_ZOOM_PICKABLE)) &&
            this.props.zoom < MAX_LABEL_ZOOM;

        return [
            new MVTLayer(
                this.getSubLayerProps({
                    id: 'mvt',
                    data: getAssetMVTURL(this.props.draftVersionUUID, AssetTypes[this.props.assetType].uuid),
                    getLineWidth: (d) => (d.properties.uuid === this.props.highlightedUuid ? 0.75 : 0.5),
                    lineWidthMinPixels: 1,
                    getLineColor: (d) =>
                        d.properties.uuid && d.properties.uuid === this.props.highlightedUuid
                            ? SELECTED_LAYER_OUTLINE_COLOR
                            : AssetTypes[this.props.assetType].color,
                    visible: this.props.visible && shouldShowOutlines,
                    pickable: shouldShowLabels,
                    highlightedUuid: this.props.highlightedUuid,
                    getFillColor: (d) => {
                        const alpha = d.properties.uuid === this.props.highlightedUuid ? 25 : 0;

                        return [...AssetTypes[this.props.assetType].color, alpha];
                    },
                    updateTriggers: {
                        getFillColor: this.props.highlightedUuid,
                        getLineWidth: this.props.highlightedUuid,
                        getLineColor: this.props.highlightedUuid,
                        visible: [this.props.visible, shouldShowOutlines],
                        pickable: shouldShowLabels,
                    },
                }),
            ),
            new TextLayer(
                this.getSubLayerProps({
                    id: 'label',
                    data: this.props.data,
                    visible: this.props.visible && shouldShowLabels,
                    getPosition: (d) => d.position,
                    getText: (d) => d.label,
                    getColor: [255, 255, 255],
                    getSize: (d) => (String(d.uuid) === String(this.props.highlightedUuid) ? 22 : 16),
                    getAngle: 0,
                    getTextAnchor: 'middle',
                    getAlignmentBaseline: 'center',
                    getBackgroundColor: AssetTypes[this.props.assetType].color,
                    background: true,
                    updateTriggers: {
                        getSize: this.props.highlightedUuid,
                        visible: [this.props.visible, shouldShowLabels],
                    },
                }),
            ),
        ];
    }
}

ElectricalRegionLayer.layerName = 'ElectricalRegionLayer';

// Define default props:
ElectricalRegionLayer.defaultProps = {
    // Shared accessors
    getPosition: { type: 'accessor', value: (x) => x.position },
    // Shared properties
    assetType: 'inverter',
    visible: { type: 'boolean', value: 'false' },
    // Text accessors
    getText: { type: 'accessor', value: (x) => x.label },
    getTextSize: { type: 'accessor', value: 12 },
    getTextColor: { type: 'accessor', value: [255, 255, 255] },
    data: { type: 'array', value: [] },
    highlightedUuid: { type: 'accessor', value: null },
};

export { ElectricalRegionLayer };
