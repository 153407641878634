import { theme } from '@dronebase/shared-ui-core';
import { MantineTheme, MantineThemeOverride, rem } from '@mantine/core';

import { PAGE_HEADER_HEIGHT } from 'lib/constants/layout';

export const isDark = (t: MantineTheme) => t.colorScheme === 'dark';

export const themeConfig: MantineThemeOverride = {
    ...theme,
    colors: {
        ...theme.colors,
        anomalyPriority: [
            '#000', // placeholder for 0 index
            'var(--color-anomaly-priority-1)',
            'var(--color-anomaly-priority-2)',
            'var(--color-anomaly-priority-3)',
        ],
    },
    components: {
        ...theme.components,
        ActionIcon: {
            defaultProps: {
                size: 'md',
            },
            sizes: {
                xs: () => ({
                    root: {
                        padding: 4,
                        svg: {
                            width: rem(14),
                            minWidth: rem(14),
                            height: rem(14),
                            minHeight: rem(14),
                        },
                    },
                }),
                sm: () => ({
                    root: {
                        padding: 4,
                        svg: {
                            width: rem(18),
                            minWidth: rem(18),
                            height: rem(18),
                            minHeight: rem(18),
                        },
                    },
                }),
                md: () => ({
                    root: {
                        padding: 7,
                        svg: {
                            width: rem(20),
                            minWidth: rem(20),
                            height: rem(20),
                            minHeight: rem(20),
                        },
                    },
                }),
                lg: () => ({
                    root: {
                        padding: 8,
                        svg: {
                            width: rem(24),
                            minWidth: rem(24),
                            height: rem(24),
                            minHeight: rem(24),
                        },
                    },
                }),
            },
            styles: (theme) => ({
                root: {
                    'border': 'none',
                    'width': 'min-content',
                    'height': 'min-content',
                    'svg': {
                        zIndex: 100,
                        fill: 'var(--color-grey-300)',
                    },
                    'color': 'var(--color-grey-300)',
                    '&:active': {
                        // this is applied during the press event on the button
                        svg: {
                            fill: isDark(theme) ? 'var(--color-purple-300)' : 'var(--color-purple-400)',
                        },
                        color: isDark(theme) ? 'var(--color-purple-300)' : 'var(--color-purple-400)',
                    },
                    '&[type="button"]:focus-visible': {
                        outline: `4px solid ${isDark(theme) ? 'var(--color-grey-700)' : 'var(--color-purple-200)'}`,
                    },
                    '&:hover': {
                        svg: {
                            fill: isDark(theme) ? 'var(--color-purple-300)' : 'var(--color-purple-400)',
                        },
                        color: isDark(theme) ? 'var(--color-purple-300)' : 'var(--color-purple-400)',
                    },
                    '&[data-loading="true"]': {
                        backgroundColor: 'transparent',
                    },
                    '&[data-disabled="true"], &:disabled': {
                        color: isDark(theme) ? 'var(--color-grey-400)' : 'var(--color-grey-200)',
                        svg: {
                            fill: isDark(theme) ? 'var(--color-grey-400)' : 'var(--color-grey-200)',
                        },
                    },
                    '&[aria-pressed="true"]': {
                        // active styles when the button is toggled on
                        color: isDark(theme) ? 'var(--color-purple-300)' : 'var(--color-purple-400)',
                        svg: {
                            fill: isDark(theme) ? 'var(--color-purple-300)' : 'var(--color-purple-400)',
                        },
                    },
                    '&[aria-pressed="true"][data-disabled="true"], &:disabled[aria-pressed="true"]': {
                        color: isDark(theme) ? 'var(--color-grey-400)' : 'var(--color-grey-200)',
                        svg: {
                            fill: isDark(theme) ? 'var(--color-grey-400)' : 'var(--color-grey-200)',
                        },
                    },
                },
            }),
            variants: {
                subtle: () => ({
                    root: {
                        'backgroundColor': 'transparent',
                        '&:hover, &[data-disabled="true"], &:disabled': {
                            backgroundColor: 'transparent',
                        },
                    },
                }),
                filled: (theme) => ({
                    root: {
                        'backgroundColor': 'transparent',
                        '&:active': {
                            // this is applied during the press event on the button
                            backgroundColor: isDark(theme) ? 'var(--color-grey-900)' : 'var(--color-purple-100)',
                        },
                        '&:hover': {
                            backgroundColor: isDark(theme) ? 'var(--color-grey-900)' : 'var(--color-purple-50)',
                        },
                        '&[aria-pressed="true"]': {
                            // active styles when the button is toggled on
                            backgroundColor: isDark(theme) ? 'var(--color-grey-900)' : 'var(--color-purple-100)',
                            svg: {
                                fill: isDark(theme) ? 'var(--color-purple-300)' : 'var(--color-purple-400)',
                                color: isDark(theme) ? 'var(--color-purple-300)' : 'var(--color-purple-400)',
                            },
                        },
                        '&[aria-pressed="true"][data-disabled="true"], &:disabled[aria-pressed="true"]': {
                            backgroundColor: 'transparent',
                            svg: {
                                fill: isDark(theme) ? 'var(--color-grey-400)' : 'var(--color-grey-200)',
                            },
                        },
                    },
                }),
            },
        },
        Avatar: {
            styles: {
                root: {
                    ':hover': { cursor: 'pointer' },
                },
            },
        },
        Badge: {
            styles: {
                root: {
                    color: 'black',
                },
                leftSection: {
                    height: '100%',
                    marginRight: 0,
                },
            },
        },
        Button: {
            ...theme.components?.Button,
            variants: {
                ...theme.components?.Button.variants,
                filledGrey: () => ({
                    root: {
                        'boxShadow': 'var(--btn-box-shadow)',
                        'backgroundColor': 'var(--color-grey-100)',
                        'border': '1px solid var(--color-grey-200)',
                        '&:hover': {
                            backgroundColor: 'var(--color-grey-100)',
                        },
                    },
                }),
                outlineGrey: () => ({
                    root: {
                        'color': 'var(--color-grey-900)',
                        'border': '1px solid var(--color-grey-200)',
                        'boxShadow': 'var(--btn-box-shadow)',
                        '&:hover': {
                            backgroundColor: 'var(--color-grey-100)',
                        },
                        'backgroundColor': 'var(--color-white)',
                    },
                }),
            },
        },
        Drawer: {
            styles: {
                content: {
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                },
                body: {
                    display: 'flex',
                    flexGrow: 1,
                },
            },
        },
        NavLink: {
            styles: {
                root: {
                    'padding': '0.5rem',
                    ':hover': {
                        'backgroundColor': 'var(--color-purple-700)',
                        'color': 'var(--color-white)',
                        '.controlled-fill': {
                            fill: 'var(--color-white)',
                        },
                    },
                    'height': PAGE_HEADER_HEIGHT,
                },
                label: {
                    fontSize: 'var(--typography-fz-xs)',
                },
            },
            variants: {
                inactive: () => ({
                    root: {
                        'color': 'var(--color-grey-600)',
                        'backgroundColor': 'var(--color-white)',
                        '.controlled-fill': {
                            fill: 'var(--color-grey-600)',
                        },
                    },
                }),
                active: () => ({
                    root: {
                        'color': 'var(--color-white)',
                        'backgroundColor': 'var(--color-purple-600)',
                        '.controlled-fill': {
                            fill: 'var(--color-white)',
                        },
                    },
                }),
            },
        },
        PasswordInput: {
            styles: { rightSection: { pointerEvents: 'auto' } },
        },
        Tabs: {
            ...theme.components?.Tabs,
            styles: {
                root: {
                    height: '100%',
                },
                tab: {
                    height: '1.75rem',
                    borderWidth: '0.25rem',
                },
                panel: {
                    height: '100%',
                },
            },
            variants: {
                ...theme.components?.Tabs.variants,
                default: () => ({
                    tabsList: {
                        border: 0,
                    },
                }),
            },
        },
        Text: {
            ...theme.components?.Text,
            variants: {
                ...theme.components?.Text.variants,
                caption1medium: () => ({
                    root: {
                        fontSize: 'var(--typography-fz-xs)',
                        fontWeight: 'var(--typography-fw-medium)' as unknown as number,
                        lineHeight: '120%', // there is no typography variable for this in shared-ui
                    },
                }),
                body1semibold: () => ({
                    root: {
                        fontSize: 'var(--body1-font-size)',
                        fontWeight: 'var(--typography-fw-semi-bold)' as unknown as number,
                        lineHeight: 'var(--body1-line-height)',
                    },
                }),
                body2medium: () => ({
                    root: {
                        fontSize: 'var(--body2-font-size)',
                        fontWeight: 'var(--typography-fw-medium)' as unknown as number,
                        lineHeight: 'var(--body2-line-height)',
                    },
                }),
                body3light: () => ({
                    root: {
                        fontSize: 'var(--typography-fz-xs)',
                        fontWeight: 'var(--typography-fw-light)' as unknown as number,
                        lineHeight: 'var(--caption2-line-height)',
                    },
                }),
            },
        },
    },
};
