import { Button, Center, Stack, Text } from '@mantine/core';
import { redirect, useNavigate } from 'react-router-dom';

import NoWorkspaceIcon from 'core/Icons/NoWorkspaceIcon';
import { ROUTES } from 'lib/constants/routes';
import useFirebaseAuth from 'lib/hooks/useFirebaseAuth';

export const NoWorkspacesFound = () => {
    const { logoutUser } = useFirebaseAuth();
    const navigate = useNavigate();

    const backToSignInClick = async () => {
        await logoutUser();
        navigate(ROUTES.auth.index, { replace: true });
    };

    return (
        <Center h="100vh">
            <Stack spacing="0.25rem" align="center">
                <NoWorkspaceIcon />

                <Text variant="subtitle1" c="gray.9">
                    You do not have access to any Workspaces.
                </Text>
                <Text variant="subtitle1" c="gray.9">
                    Please contact your administrator to gain access
                </Text>

                <Button variant="link" size="xl" onClick={backToSignInClick}>
                    Back to Sign In Screen
                </Button>
            </Stack>
        </Center>
    );
};

export const noWorkspacesFoundLoader = ({ request }: { request: any }) => {
    const urlParams = new URL(request.url).searchParams;

    // IF USER ENTERS THIS URL MANUALLY, REDIRECT THEM TO THE AUTH PAGE.
    // IF THEY ARE LOGGED IN AND DON'T HAVE A WORKSPACE, THEY'D COME BACK HERE.
    // OTHERWISE, THEY SHOULDN'T SEE THIS PAGE
    if (!urlParams.get('no_ws')) {
        return redirect(ROUTES.auth.index);
    }

    return null;
};
