import { ReactNode } from 'react';

import { Cross } from '@dronebase/shared-ui-icons';
import styled from '@emotion/styled';
import { Modal, ActionIcon, Stack } from '@mantine/core';

import { DarkThemeProvider } from 'providers/DarkThemeProvider';
import { LightThemeProvider } from 'providers/LightThemeProvider';

interface InfoModalProps {
    opened: boolean;
    onClose: () => void;
    children: React.ReactNode;
    padding?: string;
    themeType?: 'light' | 'dark';
    fullWidth?: boolean;
}

const InfoModal = ({ opened, onClose, children, themeType, fullWidth = false }: InfoModalProps) => {
    const renderWithOptionalThemeProvider = (content: ReactNode) => {
        switch (themeType) {
            case 'light':
                return <LightThemeProvider>{content}</LightThemeProvider>;
            case 'dark':
                return <DarkThemeProvider>{content}</DarkThemeProvider>;
            default:
                return content;
        }
    };

    const modalContent = (
        <Modal opened={opened} onClose={onClose} padding="1.5rem" size="auto" withCloseButton={false}>
            <ActionIcon
                aria-label="Close popup modal"
                onClick={onClose}
                style={{
                    position: 'absolute',
                    top: '0.5rem',
                    right: '0.5rem',
                    zIndex: '10',
                }}
            >
                <Cross />
            </ActionIcon>
            <StyledInfoModalContainer fullWidth={fullWidth}>{children}</StyledInfoModalContainer>
        </Modal>
    );

    return renderWithOptionalThemeProvider(modalContent);
};

const StyledInfoModalContainer = styled(Stack)(({ fullWidth }: { fullWidth: boolean }) => ({
    backgroundColor: '#FFFFFF',
    borderRadius: 4,
    maxWidth: fullWidth ? '100%' : '40rem',
    gap: '1rem',
    padding: '0.5rem',
}));

export default InfoModal;
