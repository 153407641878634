import { useCallback } from 'react';

import { Button } from '@mantine/core';

import { ANOMALY_TABLE_TYPES } from 'lib/constants';
import { useAnomalyData, useDownloadCSV } from 'lib/hooks';

const DownloadCSV = ({ tableType }: { tableType: ANOMALY_TABLE_TYPES }) => {
    const { download } = useDownloadCSV();
    const { loading, isEmpty, error } = useAnomalyData();

    const handleDownload = useCallback(() => {
        download(tableType);
    }, [download, tableType]);

    if (loading || error || isEmpty) {
        return null;
    }

    return (
        <Button onClick={handleDownload} size="lg">
            Download CSV
        </Button>
    );
};

export default DownloadCSV;
