export const NoPendingInvites = () => (
    <svg width="340" height="245" viewBox="0 0 340 245" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M281.52 63.9722C286.778 63.9722 291.04 68.2379 291.04 73.4999C291.04 78.762 286.778 83.0277 281.52 83.0277H227.12C232.378 83.0277 236.64 87.2935 236.64 92.5555C236.64 97.8176 232.378 102.083 227.12 102.083H257.04C262.298 102.083 266.56 106.349 266.56 111.611C266.56 116.873 262.298 121.139 257.04 121.139H243.203C236.574 121.139 231.2 125.405 231.2 130.667C231.2 134.175 233.92 137.351 239.36 140.194C244.618 140.194 248.88 144.46 248.88 149.722C248.88 154.984 244.618 159.25 239.36 159.25H126.48C121.222 159.25 116.96 154.984 116.96 149.722C116.96 144.46 121.222 140.194 126.48 140.194H73.4399C68.1822 140.194 63.9199 135.929 63.9199 130.667C63.9199 125.405 68.1822 121.139 73.4399 121.139H127.84C133.098 121.139 137.36 116.873 137.36 111.611C137.36 106.349 133.098 102.083 127.84 102.083H93.8399C88.5822 102.083 84.3199 97.8176 84.3199 92.5555C84.3199 87.2935 88.5822 83.0277 93.8399 83.0277H148.24C142.982 83.0277 138.72 78.762 138.72 73.4999C138.72 68.2379 142.982 63.9722 148.24 63.9722H281.52ZM281.52 102.083C286.778 102.083 291.04 106.349 291.04 111.611C291.04 116.873 286.778 121.139 281.52 121.139C276.262 121.139 272 116.873 272 111.611C272 106.349 276.262 102.083 281.52 102.083Z"
            fill="#F8F9FA"
        />
        <path
            d="M85.54 182.086H74.421M79.181 170.139H55.8219H79.181ZM47.901 170.139H42.1602H47.901Z"
            stroke="#9C91FC"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M255.68 182.086H266.799M262.039 170.139H285.398H262.039ZM293.319 170.139H299.06H293.319Z"
            stroke="#9C91FC"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M118.322 185.485C112.722 185.485 108.182 180.891 108.182 175.239L108.182 109.478C108.182 104.51 110.636 99.8678 114.724 97.1005L167.727 61.2296C169.223 60.2167 171.179 60.2196 172.672 61.237L225.313 97.0934C229.383 99.866 231.821 104.5 231.818 109.456L231.816 177.7C231.816 181.999 228.362 185.485 224.102 185.485L118.322 185.485Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M171.776 62.5552C170.822 61.9053 169.575 61.9034 168.619 62.5504L115.617 98.4214C111.969 100.89 109.775 105.035 109.775 109.478L109.776 175.239C109.776 180.026 113.617 183.89 118.322 183.89L224.102 183.89C227.47 183.89 230.222 181.131 230.222 177.7L230.225 109.456C230.228 105.024 228.047 100.885 224.416 98.4116L171.776 62.5552ZM166.834 59.9087C168.871 58.5299 171.536 58.534 173.569 59.9188L226.209 95.7752C230.719 98.847 233.415 103.976 233.412 109.457L233.409 177.7C233.409 182.868 229.255 187.079 224.102 187.079L118.322 187.079C111.827 187.079 106.589 181.757 106.589 175.239L106.589 109.478C106.589 103.984 109.302 98.8455 113.832 95.7796L166.834 59.9087Z"
            fill="#9C91FC"
        />
        <path
            d="M163.02 142.037L162.908 142.119L127.803 115.401V74.612C127.803 66.4179 134.43 59.7753 142.605 59.7753H197.561C205.661 59.7753 212.257 66.3027 212.361 74.422L212.865 113.839L176.896 142.119C172.793 139.035 167.16 139.001 163.02 142.037Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M142.605 61.6298C135.452 61.6298 129.654 67.4422 129.654 74.612V114.48L162.977 139.843C167.235 137.292 172.555 137.292 176.809 139.831L211.003 112.946L210.511 74.4458C210.42 67.3413 204.649 61.6298 197.561 61.6298H142.605ZM214.667 110.065L214.211 74.3983C214.095 65.264 206.674 57.9207 197.561 57.9207H142.605C133.409 57.9207 125.953 65.3936 125.953 74.612V111.664L110.42 99.8412L108.182 102.795L159.813 142.092L108.208 179.94L110.394 182.934L164.113 143.534C167.595 140.98 172.334 141.008 175.786 143.603L228.086 182.92L230.306 179.953L179.936 142.086L231.181 101.93L229.269 98.7412L214.667 110.065Z"
            fill="#9C91FC"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M164.068 116.526C156.915 116.526 151.116 122.338 151.116 129.508V130.815L147.461 128.034C147.73 121.354 154.711 112.817 164.068 112.817H174.429C183.675 112.817 191.049 119.474 191.049 128.635L187.381 131.519V129.508C187.381 122.338 181.582 116.526 174.429 116.526H164.068Z"
            fill="#9C91FC"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M176.173 89.0089C172.458 85.1356 166.268 85.1356 162.553 89.0089C159.046 92.665 159.046 98.4392 162.553 102.095C166.268 105.969 172.458 105.969 176.173 102.095C179.68 98.4392 179.68 92.665 176.173 89.0089ZM160.254 86.8004C165.223 81.6196 173.502 81.6196 178.472 86.8004C183.163 91.6905 183.163 99.4137 178.472 104.304C173.502 109.485 165.223 109.485 160.254 104.304C155.563 99.4137 155.563 91.6905 160.254 86.8004Z"
            fill="#9C91FC"
        />
        <path
            d="M119.015 179.349L150.591 156.465C151.967 155.468 153.749 155.222 155.343 155.81L218.739 179.185C220.074 179.677 219.721 181.658 218.298 181.658H119.763C118.527 181.658 118.014 180.074 119.015 179.349Z"
            fill="#EFECFF"
        />
        <path
            d="M112.005 170.271V120.584C112.005 119.08 115.695 118.255 116.454 119.554C118.657 123.322 121.905 128.268 126.663 134.459C131.493 140.743 140.35 145.562 144.355 147.527C145.251 147.966 145.368 149.232 144.552 149.806L114.013 171.314C113.169 171.909 112.005 171.304 112.005 170.271Z"
            fill="#EFECFF"
        />
    </svg>
);
