const ResourcesIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            className="controlled-fill"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.90686 1.5H19.7108C19.8538 1.49991 19.991 1.5581 20.0922 1.66171C20.1933 1.76543 20.2501 1.906 20.25 2.05262V21.9474C20.2501 22.094 20.1933 22.2346 20.0922 22.3383C19.991 22.4419 19.8538 22.5001 19.7108 22.5H5.90686C5.33503 22.4994 4.78672 22.2663 4.38231 21.852C3.97798 21.4375 3.75059 20.8755 3.75 20.2895V3.71053C3.75058 3.12447 3.97798 2.56251 4.38231 2.14804C4.78672 1.73365 5.33503 1.5006 5.90686 1.5ZM5.90686 21.2836L19.1387 21.2836V19.1842L5.90686 19.1842C5.52158 19.1842 5.16552 19.3949 4.97292 19.7368C4.78023 20.0788 4.78023 20.389 4.97292 20.7309C5.16553 21.0729 5.52158 21.2836 5.90686 21.2836ZM5.90686 17.9926L19.1387 17.9926V2.6702H12.9625V11.512C12.9621 11.7055 12.9078 11.8952 12.8073 12.0568C12.7069 12.2183 12.5648 12.3459 12.3988 12.4266C12.2329 12.5074 12.0487 12.5386 11.8672 12.5176C11.6856 12.4965 11.5122 12.4238 11.3673 12.3061C11.3487 12.291 11.3312 12.2746 11.3148 12.257L9.9375 10.7732L8.56022 12.257C8.54431 12.2741 8.52729 12.2901 8.5093 12.3048C8.36444 12.4234 8.19083 12.4969 8.00879 12.5184C7.82676 12.5399 7.64205 12.5087 7.47568 12.4278C7.3092 12.3468 7.16684 12.2187 7.06647 12.0565C6.96611 11.8944 6.91227 11.7055 6.9125 11.5117V2.6702H5.90686C5.62099 2.67055 5.34683 2.78712 5.14471 2.99435C4.94251 3.2015 4.82877 3.48248 4.82843 3.77546V18.3C5.15474 18.101 5.52706 17.9948 5.90686 17.9926ZM8.01504 2.6702L8.00879 11.435L9.575 9.65292C9.66999 9.55059 9.80082 9.4928 9.9375 9.4928C10.0742 9.4928 10.205 9.55059 10.3 9.65292L11.8672 11.4007L11.8503 2.6702H8.01504Z"
            fill="#8D95A2"
        />
    </svg>
);

export default ResourcesIcon;
