import { Loader } from '@dronebase/shared-ui-core';
import styled from '@emotion/styled';
import { Box, Checkbox, Flex, Text, Stack, Button } from '@mantine/core';
import { useToggle } from '@mantine/hooks';

import { FILTER_OPTION_DEFAULT_SHOWN_LIMIT } from 'lib/constants/inspections';
import { useAnomalyMapData, useAnomalyMapFilters } from 'lib/hooks';

interface FilterCategorySectionProps {
    options: {
        [key: string]: {
            name: string;
            databaseValue: string;
            color: [number, number, number];
            count?: number;
        };
    };
    filterKey: string;
    title?: string;
    loading: boolean;
    disabledFilterKeys?: string[];
    containerClassName?: string;
}

const FilterCategorySection = ({
    options,
    filterKey,
    title,
    loading = false,
    disabledFilterKeys,
}: FilterCategorySectionProps) => {
    const { filter, toggleFilter } = useAnomalyMapFilters();
    const { fetchingReportAnomalies } = useAnomalyMapData();
    const [showAll, toggleShowAll] = useToggle();

    return (
        <FilterCategorySectionContainer>
            {title && (
                <Text variant="body1" color="gray.0" pb="0.75rem">
                    {title}
                    {filter[filterKey].length > 0 && ` (${filter[filterKey].length})`}
                </Text>
            )}
            <Stack spacing="0.5rem">
                {loading || fetchingReportAnomalies ? (
                    <Loader />
                ) : (
                    <>
                        {Object.entries(options)
                            .slice(0, showAll ? undefined : FILTER_OPTION_DEFAULT_SHOWN_LIMIT)
                            .map(([key, option]) => (
                                <Flex key={key} align="start" justify="space-between">
                                    <Flex gap="0.75rem">
                                        <Checkbox
                                            disabled={disabledFilterKeys ? disabledFilterKeys.includes(key) : false}
                                            onChange={() => toggleFilter(filterKey, option.databaseValue)}
                                            checked={filter[filterKey].includes(option.databaseValue)}
                                        />
                                        <Flex align="start" gap="0.75rem" mt="-0.1rem" pr="0.5rem">
                                            {Array.isArray(option?.color) && (
                                                <AnomalyColorLegendEntry color={`rgb(${option.color.join(',')})`} />
                                            )}
                                            <Text variant="body2" color="gray.1">
                                                {option.name}
                                            </Text>
                                        </Flex>
                                    </Flex>
                                    <Text variant="body2" mt="-0.13rem" color="gray.1">
                                        {option.count && option.count.toLocaleString()}
                                    </Text>
                                </Flex>
                            ))}
                        <Flex>
                            {Object.keys(options).length > FILTER_OPTION_DEFAULT_SHOWN_LIMIT && (
                                <Button onClick={() => toggleShowAll()} variant="text" size="md" px={0}>
                                    {showAll ? 'Show Less' : 'Show More'}
                                </Button>
                            )}
                        </Flex>
                    </>
                )}
            </Stack>
        </FilterCategorySectionContainer>
    );
};

export default FilterCategorySection;

const AnomalyColorLegendEntry = ({ color }: { color: string }) => (
    <Box>
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="5" viewBox="0 0 25 5" fill="none">
            <path
                d="M23 0H2C0.895431 0 0 0.89543 0 2V3C0 4.10457 0.89543 5 2 5H23C24.1046 5 25 4.10457 25 3V2C25 0.895431 24.1046 0 23 0Z"
                fill={color}
            />
        </svg>
    </Box>
);

const FilterCategorySectionContainer = styled.div({
    'paddingBottom': '1rem',
    ':not(:last-child)': {
        borderBottom: '1px solid var(--color-grey-500)',
    },
});
