export type CapacityRangeFilter = {
    low: number;
    high: number;
    checked: boolean;
};

export const createCapacityRangeCriterion = (selectedCapacity: CapacityRangeFilter) => (site: any) => {
    if (!selectedCapacity.checked) {
        return true;
    }

    return selectedCapacity.low <= site.capacity && site.capacity <= selectedCapacity.high;
};
