import { atom } from 'jotai';

import { Site } from 'components/Sites/types';
import { filterByCriteria } from 'lib/filters/filterByCriteria';
import { createCapacityRangeCriterion } from 'lib/filters/sites/createCapacityRangeCriterion';
import { createInspectionCriterion } from 'lib/filters/sites/createInspectionYearCriterion';
import { createMapBoundsCriterion } from 'lib/filters/sites/createMapBoundsCriterion';
import { createSearchCriterion } from 'lib/filters/sites/createSearchCriterion';

import {
    capacityRangeFilterWithResetAtom,
    inspectionYearFilterWithResetAtom,
    mapBoundsFilterWithResetAtom,
    searchFilterWithResetAtom,
} from './filters/siteFiltersStorage';

// Atom to hold all of the Sites BEFORE the filtering
export const allSitesAtom = atom<Site[]>([]);

export const maxSiteCapacityAtom = atom<number | undefined>(undefined);

// Atom to hold all of the Sites AFTER the filtering
export const sitesWithFiltersAtom = atom((get) => {
    const allSites = get(allSitesAtom);

    const criteria = [
        createMapBoundsCriterion(get(mapBoundsFilterWithResetAtom)),
        createSearchCriterion(get(searchFilterWithResetAtom)),
        createCapacityRangeCriterion(get(capacityRangeFilterWithResetAtom)),
        createInspectionCriterion(get(inspectionYearFilterWithResetAtom)),
    ];

    const results = filterByCriteria<Site>(criteria)(allSites);

    return results;
});
