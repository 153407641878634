import { SetStateAction, useCallback } from 'react';

import { atom, useSetAtom, WritableAtom } from 'jotai';
import { RESET } from 'jotai/utils';

export const APPLY = Symbol('apply');

/**
 * A function that creates an atom from an existing one.
 * The newly created atom defaults to the value of the base atom.
 *
 * You can set the value of this atom to `RESET` to make it reset to
 * the base atom current value
 *
 * You can set the value of this atom to `APPLY` in order to set the value
 * of the base atom to the current value of this atom.
 *
 * @example Reset the value
 * const atom1 = atom(1);
 * const atom2 = atomWithValidation(atom1)
 *
 * const [value1, setValue1] = useAtom(atom1)
 * const [value2, setValue2] = useAtom(atom2)
 *
 * console.log(value1) // 1
 * console.log(value2) // 1
 *
 * setValue1(2)
 * setValue2(3)
 *
 * console.log(value1) // 2
 * console.log(value2) // 3
 *
 * setValue2(RESET)

 * console.log(value1) // 2
 * console.log(value2) // 2
 *
 * @example Apply the value
 * const atom1 = atom(1);
 * const atom2 = atomWithValidation(atom1)
 *
 * const [value1, setValue1] = useAtom(atom1)
 * const [value2, setValue2] = useAtom(atom2)
 *
 * console.log(value1) // 1
 * console.log(value2) // 1
 *
 * setValue2(3)
 *
 * console.log(value1) // 1
 * console.log(value2) // 3
 *
 * setValue2(APPLY)
 *
 * console.log(value1) // 3
 * console.log(value2) // 3
 *
 * @param aWritableAtom the base that will be used as default value and for which we can set a new value
 * @returns another writable atom with a value of the same type as `aWritableAtom` 
 */
export const atomWithValidation = <Value>(aWritableAtom: WritableAtom<Value, [Value], void>) => {
    // Implementation inspired from the code of `atomWithDefault`
    const EMPTY = Symbol();

    // An atom to hold the value of the temporary changes
    const temporaryValueAtom = atom<Value | typeof EMPTY>(EMPTY);

    const atomToReturn = atom<Value, [SetStateAction<Value> | typeof RESET | typeof APPLY], void>(
        (get) => {
            const temporaryValue = get(temporaryValueAtom);

            if (temporaryValue !== EMPTY) {
                return temporaryValue;
            }

            return get(aWritableAtom);
        },
        (get, set, update) => {
            if (update === RESET) {
                return set(temporaryValueAtom, EMPTY);
            }

            if (update === APPLY) {
                const temporaryValue = get(temporaryValueAtom);

                if (temporaryValue === EMPTY) {
                    return;
                }

                set(aWritableAtom, temporaryValue);

                return set(temporaryValueAtom, EMPTY);
            }

            // https://github.com/microsoft/TypeScript/issues/37663#issuecomment-759728342
            if (update instanceof Function) {
                const temporaryValue = get(temporaryValueAtom);
                // eslint-disable-next-line no-negated-condition
                const previousValue = temporaryValue !== EMPTY ? temporaryValue : get(aWritableAtom);

                return set(temporaryValueAtom, update(previousValue));
            }

            return set(temporaryValueAtom, update);
        },
    );

    return atomToReturn;
};

/**
 * Equivalent of `useResetAtom` but to APPLY
 * @param anAtomWithValidation atom to which we need to dispatch the APPLY command
 * @returns a function that, when called, APPLY the current modification of the atom
 * with validation to the original atom
 */
export function useApplyAtom(anAtomWithValidation: WritableAtom<unknown, [typeof APPLY], void>) {
    const setAtom = useSetAtom(anAtomWithValidation);

    return useCallback(() => setAtom(APPLY), [setAtom]);
}
