import { getCenterPointFromGeometry } from 'lib/helpers';
import { GET_ASSET_REGION_INFO } from 'lib/queries/regions';
import { Asset } from 'lib/types/inspections/anomaliesMap';
import { client } from 'lib/urqlClient';

export type AssetLoader = {
    asset: Asset | null;
};

export const assetLoader = async ({
    params,
}: {
    params: Record<string, string | undefined>;
}): Promise<{ asset: Asset | null }> => {
    const { assetRegionUUID } = params;

    const {
        data: { asset },
    } = await client
        .query(
            GET_ASSET_REGION_INFO,
            {
                assetRegionUUID,
            },
            { requestPolicy: 'network-only' },
        )
        .toPromise();

    const centroidCoordinates = getCenterPointFromGeometry(asset.geometry);
    const coordinates = [Number(centroidCoordinates[1].toFixed(6)), Number(centroidCoordinates[0].toFixed(6))] as [
        number,
        number,
    ];

    const { stack, inverterId, combinerId, positionId, uuid: assetRegionUuid, serialNumber } = asset;

    return {
        asset: {
            stack,
            inverterId,
            combinerId,
            positionId,
            assetRegionUuid,
            coordinates,
            serialNumber: serialNumber ?? '',
        },
    };
};
