import { atom } from 'jotai';
import { RESET, atomWithStorage, createJSONStorage, useResetAtom } from 'jotai/utils';
import { focusAtom } from 'jotai-optics';

import { SortedBy } from 'components/Sites/types';
import { ALL_TIME, DEFAULT_PAGE_SIZE_LIMIT, LOCAL_STORAGE_KEYS, SORT_DIRECTION } from 'lib/constants';
import { CapacityRangeFilter } from 'lib/filters/sites/createCapacityRangeCriterion';
import { DEFAULT_MAP_BOUNDS } from 'state/map';
import { maxSiteCapacityAtom } from 'state/sites/sites';

type SiteFilters = {
    searchFilter: string;
    capacityRangeFilter: CapacityRangeFilter;
    inspectionYearFilter: string;
    sortedBy: SortedByFilter;
    mapBounds: number[];
    pagination: Record<string, number>;
};

type SortedByFilter = {
    key: string;
    direction: SortedBy['direction'];
};
const initialSiteFilters: SiteFilters = {
    searchFilter: '',
    capacityRangeFilter: { low: 0, high: 0, checked: false },
    inspectionYearFilter: ALL_TIME,
    sortedBy: {
        key: 'powerLoss',
        direction: SORT_DIRECTION.DESC,
    },
    mapBounds: DEFAULT_MAP_BOUNDS,
    pagination: { page: 1, size: DEFAULT_PAGE_SIZE_LIMIT },
};

const storage = createJSONStorage<SiteFilters>();

const siteFiltersStorageAtom = atomWithStorage<SiteFilters>(
    LOCAL_STORAGE_KEYS.SITE_PAGE_FILTERS,
    initialSiteFilters,
    storage,
    {
        getOnInit: true,
    },
);

const resetSiteFiltersStorageAtom = atom(null, (_, set, update: typeof RESET) => {
    set(siteFiltersStorageAtom, update);
});

export const useResetSiteFiltersStorage = () => useResetAtom(resetSiteFiltersStorageAtom);

const paginationFilterFocusAtom = focusAtom(siteFiltersStorageAtom, (optic) => optic.prop('pagination'));

export const paginationFilterWithResetAtom = atom(
    (get) => get(paginationFilterFocusAtom) ?? initialSiteFilters.pagination,
    (_, set, newValue: Record<string, number> | typeof RESET) => {
        if (newValue === RESET) {
            set(paginationFilterFocusAtom, initialSiteFilters.pagination);
        } else {
            set(paginationFilterFocusAtom, newValue);
        }
    },
);

const searchFilterFocusAtom = focusAtom(siteFiltersStorageAtom, (optic) => optic.prop('searchFilter'));

export const searchFilterWithResetAtom = atom(
    // If the local storage is incorrect for whatever reason, the get atom
    // will return "undefined" which will crash the sites page. As a fallback,
    // return the intial value of the filter in question.
    (get) => get(searchFilterFocusAtom) ?? initialSiteFilters.searchFilter,
    (_, set, newValue: string | typeof RESET) => {
        if (newValue === RESET) {
            set(searchFilterFocusAtom, initialSiteFilters.searchFilter);
        } else {
            set(searchFilterFocusAtom, newValue);
        }
    },
);

const capacityRangeFilterFocusAtom = focusAtom(siteFiltersStorageAtom, (optic) => optic.prop('capacityRangeFilter'));

export const capacityRangeFilterWithResetAtom = atom(
    (get) => {
        const initialCapacityFilter = get(capacityRangeFilterFocusAtom) ?? initialSiteFilters.capacityRangeFilter;
        const maxCapacity = get(maxSiteCapacityAtom);

        return maxCapacity
            ? {
                  ...initialCapacityFilter,
                  high: initialCapacityFilter.high === 0 ? maxCapacity : initialCapacityFilter.high,
              }
            : initialCapacityFilter;
    },
    (get, set, newValue: CapacityRangeFilter | typeof RESET) => {
        if (newValue === RESET) {
            const maxCapacity = get(maxSiteCapacityAtom);

            set(capacityRangeFilterFocusAtom, { ...initialSiteFilters.capacityRangeFilter, high: maxCapacity! });
        } else {
            set(capacityRangeFilterFocusAtom, newValue);
        }
    },
);

const inspectionYearFilterFocusAtom = focusAtom(siteFiltersStorageAtom, (optic) => optic.prop('inspectionYearFilter'));

export const inspectionYearFilterWithResetAtom = atom(
    (get) => get(inspectionYearFilterFocusAtom) ?? initialSiteFilters.inspectionYearFilter,
    (_, set, newValue: string | typeof RESET) => {
        if (newValue === RESET) {
            set(inspectionYearFilterFocusAtom, initialSiteFilters.inspectionYearFilter);
        } else {
            set(inspectionYearFilterFocusAtom, newValue);
        }
    },
);

const sortedByFilterFocusAtom = focusAtom(siteFiltersStorageAtom, (optic) => optic.prop('sortedBy'));

export const sortedByFilterWithResetAtom = atom(
    (get) => get(sortedByFilterFocusAtom) ?? initialSiteFilters.sortedBy,
    (_, set, newValue: SortedByFilter | typeof RESET) => {
        if (newValue === RESET) {
            set(sortedByFilterFocusAtom, initialSiteFilters.sortedBy);
        } else {
            set(sortedByFilterFocusAtom, newValue);
        }
    },
);

const mapBoundsFilterFocusAtom = focusAtom(siteFiltersStorageAtom, (optic) => optic.prop('mapBounds'));

export const mapBoundsFilterWithResetAtom = atom(
    (get) => get(mapBoundsFilterFocusAtom) ?? initialSiteFilters.mapBounds,
    (_, set, newValue: number[] | typeof RESET) => {
        if (newValue === RESET) {
            set(mapBoundsFilterFocusAtom, initialSiteFilters.mapBounds);
        } else {
            set(mapBoundsFilterFocusAtom, newValue);
        }
    },
);
