import { Checkbox } from '@mantine/core';

type CheckboxGroupFilterProps = {
    label: string;
    options: { label: string; value: string }[];
    onChange: React.Dispatch<React.SetStateAction<string[]>>;
    value: string[];
};

export const CheckboxGroup = ({ label, onChange, options, value }: CheckboxGroupFilterProps) => {
    const createOnChange = (changedCheckboxValue: string) => () => {
        onChange((previousSelectionArray) =>
            previousSelectionArray.includes(changedCheckboxValue)
                ? previousSelectionArray.filter((value) => value !== changedCheckboxValue)
                : [...previousSelectionArray, changedCheckboxValue],
        );
    };

    return (
        <Checkbox.Group value={value} label={label} onChange={onChange}>
            {options.map((option) => (
                <Checkbox
                    key={option.label}
                    value={option.value}
                    label={option.label}
                    py="xs"
                    onChange={() => createOnChange(option.value)}
                    checked={value.includes(option.value)}
                />
            ))}
        </Checkbox.Group>
    );
};
