import { gql } from 'urql';

export const GET_ASSET_REGION_INFO = gql`
    query AssetRegion($assetRegionUUID: uuid!) {
        asset: regions_asset_regions_by_pk(uuid: $assetRegionUUID) {
            stack: nom_stack
            uuid
            geometry
            inverterId: nom_properties(path: "nom_inverter")
            combinerId: nom_properties(path: "nom_combiner")
            positionId: nom_properties(path: "['Position ID']")
            serialNumber: nom_properties(path: "serial_number")
        }
    }
`;
