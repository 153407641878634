import { Tabs } from '@mantine/core';

import { REPORT_TABS } from 'lib/constants';
import { useInspectionTabs } from 'lib/hooks';

export const SitesInpectionPageTabs = () => {
    const { currentTab, tabs, setAndNavigateToTab } = useInspectionTabs();

    return (
        <Tabs variant="segmented" value={currentTab} onTabChange={setAndNavigateToTab} w="100%">
            <Tabs.List grow w="100%">
                {tabs.map((tab: REPORT_TABS) => (
                    <Tabs.Tab key={tab} value={tab}>
                        {tab}
                    </Tabs.Tab>
                ))}
            </Tabs.List>
        </Tabs>
    );
};
