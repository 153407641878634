import styled from '@emotion/styled';
import { Checkbox, Stack, Text } from '@mantine/core';

import { APPS_TO_EXCLUDE } from 'lib/constants';
import { WorkspaceApp } from 'lib/types';

interface WorkspacePermisionsListProps {
    workspaceName: string;
    workspaceApps: WorkspaceApp[];
    selectedPermissions: string[];
    handlePermissionSelect: (permission: string) => void;
}

const WorkspacePermisionsList = ({
    workspaceName,
    workspaceApps,
    selectedPermissions,
    handlePermissionSelect,
}: WorkspacePermisionsListProps) => (
    <>
        <Text pt="1rem">Permissions</Text>
        <StyledPermissionsInfoContainer>
            <Text variant="body2">Access To {workspaceName}</Text>
            <Text variant="body2light" color="var(--color-grey-400)">
                Users invited to this workspace will have access to all inspections.
            </Text>
        </StyledPermissionsInfoContainer>

        <Stack p="1rem 0">
            {workspaceApps
                .filter((app) => !APPS_TO_EXCLUDE.includes(app.group))
                .map((app) => (
                    <Stack key={app.group} spacing="0.5rem">
                        <Text>{app.name}</Text>

                        <Stack ml="1rem" spacing="0.5rem">
                            {app.permissions.map((p) => (
                                <Checkbox
                                    key={p.key}
                                    label={<Text variant="body2light">{p.label}</Text>}
                                    onChange={() => handlePermissionSelect(p.id)}
                                    checked={selectedPermissions.includes(p.id)}
                                />
                            ))}
                        </Stack>
                    </Stack>
                ))}
        </Stack>
    </>
);

export default WorkspacePermisionsList;

const StyledPermissionsInfoContainer = styled(Stack)({
    padding: '1rem 0',
    borderBottom: '1px solid var(--color-grey-100)',
    gap: 0,
});
