import { useCallback } from 'react';

import { notifications } from '@dronebase/shared-ui-core';
import styled from '@emotion/styled';
import { Button, Text } from '@mantine/core';

import { Google } from 'core/Icons/Google';
import { Microsoft } from 'core/Icons/Microsoft';
import useFirebaseAuth from 'lib/hooks/useFirebaseAuth';

interface SingleSignOnProps {
    onSuccessCallback: () => void | Promise<void>;
}

export const SingleSignOn = ({ onSuccessCallback }: SingleSignOnProps) => {
    const { loginWithGoogle, loginWithMicrosoft } = useFirebaseAuth();

    const handleSSOLogin = useCallback(
        async (ssoLoginFunc: () => Promise<void>) => {
            try {
                await ssoLoginFunc();
                await onSuccessCallback();
            } catch (error) {
                notifications.error({
                    title: 'Something went wrong. Please try again',
                    withIcon: true,
                    withCloseButton: true,
                });
            }
        },
        [onSuccessCallback],
    );

    return (
        <LayoutContainer>
            <Text variant="subtitle2">Log in with</Text>
            <ButtonContainer>
                {[
                    { label: 'Microsoft', ssoLoginFunc: loginWithMicrosoft, icon: <Microsoft /> },
                    { label: 'Google', ssoLoginFunc: loginWithGoogle, icon: <Google /> },
                ].map(({ label, icon, ssoLoginFunc }) => (
                    <Button
                        key={label}
                        variant="outline"
                        leftIcon={icon}
                        size="xl"
                        w="100%"
                        onClick={() => handleSSOLogin(ssoLoginFunc)}
                        p="0.75rem 2rem 0.75rem 1.75rem"
                    >
                        {label}
                    </Button>
                ))}
            </ButtonContainer>
        </LayoutContainer>
    );
};

const LayoutContainer = styled.div({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    gap: '1.5rem',
    alignItems: 'center',
});

const ButtonContainer = styled.div({
    display: 'flex',
    width: '100%',
    gap: '1.5rem',
    justifyContent: 'center',
});
