import styled from '@emotion/styled';
import { Box, LoadingOverlay, Text, Stack, BoxProps } from '@mantine/core';

const LoaderOverlay = () => (
    <LoaderOverlayContainer>
        <LoaderContainer>
            <Text size="1.75rem" fw="bold" c="white">
                Loading Anomalies
            </Text>
            <LoadingOverlay pos="relative" visible overlayBlur={0} overlayOpacity={0} />
        </LoaderContainer>
    </LoaderOverlayContainer>
);

export default LoaderOverlay;

const LoaderOverlayContainer = styled(Box)<BoxProps>({
    position: 'absolute',
    top: 0,
    background: 'rgba(0, 0, 0, 0.5)',
    width: '100%',
    height: '100%',
    display: 'flex',
    zIndex: 4,
});

const LoaderContainer = styled(Stack)({
    margin: 'auto',
    verticalAlign: 'middle',
    padding: '5rem',
    height: 'auto',
    position: 'relative',
});
