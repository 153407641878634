import { redirect } from 'react-router-dom';

import { InspectionLoaderData, InspectionReportTiers } from 'components/inspections/types';
import { DIFFERENTIALS_APPLICATION_NAME, REPORT_TYPES } from 'lib/constants';
import { getCookie } from 'lib/cookies';
import { pickDifferentialInspection } from 'lib/helpers';
import {
    GET_INSPECTION_SUMMARY,
    GET_REPORT_TYPES,
    GET_ANALYSIS_CLIENT_STATUS,
    GET_PREVIOUS_SITE_INSPECTIONS,
    GET_WORKSPACE_APPLICATIONS,
    GET_SITE_TRACKER_TYPE,
} from 'lib/queries';
import { client } from 'lib/urqlClient';

export const inspectionLoader = async ({
    params,
}: {
    params: { [key: string]: string | undefined };
}): Promise<InspectionLoaderData | undefined> => {
    const workspaceID = getCookie('helio-workspace')?.id;
    const [trackerTypeRes, inspectionSummaryRes, inspectionReportTypesRes, anomalyStatusesRes, applicationDataRes] =
        await Promise.all([
            client.query(GET_SITE_TRACKER_TYPE, { siteId: params.siteId }).toPromise(),
            client
                .query(
                    GET_INSPECTION_SUMMARY,
                    {
                        inspectionID: params.inspectionId,
                        workspaceID,
                    },
                    {
                        requestPolicy: 'network-only',
                    },
                )
                .toPromise(),
            client.query(GET_REPORT_TYPES, {}).toPromise(),
            client.query(GET_ANALYSIS_CLIENT_STATUS, {}).toPromise(),
            client.query(GET_WORKSPACE_APPLICATIONS, { workspaceId: workspaceID }).toPromise(),
        ]);

    const trackerType = trackerTypeRes.data?.site?.trackerType;
    const report = inspectionSummaryRes.data?.superReportTasks[0];
    const currentSummary = {
        ...(report?.inspectionSummary ?? {}),
        publishDate: report?.inspectionDate,
        inspectionID: params.inspectionId,
        draftVersionUUID: report?.superReportSuperAnalysisTasks[0]?.superAnalysisTask.superTask.draftVersionUUID,
    };

    const upgradeRequests = {
        inspectionDate: report?.inspectionDate,
        upgradeExpiresAt: report?.upgradeExpiresAt,
        reportUpgrades: report?.reportUpgrades,
    };

    if (!currentSummary) {
        redirect('/404');

        return;
    }

    const { data: previousInspections } = await client
        .query(GET_PREVIOUS_SITE_INSPECTIONS, {
            siteId: params.siteId,
            inspectionDate: report?.inspectionDate,
        })
        .toPromise();

    const hasDifferentialApplication = Boolean(
        applicationDataRes?.data?.applications?.find(
            ({ application }: any) => application?.name === DIFFERENTIALS_APPLICATION_NAME,
        ),
    );

    const currentInspectionAnomalyCount = report?.superAnalysisTaskCount.aggregate.count;
    const currentReportType = report?.reportType?.nameExternal;
    const reportTypeUuid = report?.reportType?.uuid;
    const powerLossBreakdown = {
        bos: currentSummary.bosPowerLoss,
        major: currentSummary.majorPowerLoss,
        minor: currentSummary.minorPowerLoss,
    };
    const upgradeCosts = report?.upgrade;

    const previousInspection = previousInspections?.inspections[0];
    const previousInspectionAnomalyCount = previousInspection?.superAnalysisTasksCount.aggregate.count;
    const previousReportType = previousInspection?.reportType;

    return {
        inspectionDate: report?.inspectionDate,
        reportType: currentReportType,
        trackerType,
        summary: currentSummary,
        description: report?.description,
        reportTier: getReportTier(currentReportType, currentInspectionAnomalyCount),
        powerLossBreakdown,
        upgradeCosts,
        previousInspection: pickDifferentialInspection(previousInspections?.inspections),
        previousInspectionReportTier:
            previousInspection && getReportTier(previousReportType, previousInspectionAnomalyCount),
        reportTypeUuid,
        upgradeRequests,
        inspectionReportTypes: inspectionReportTypesRes?.data?.inspectionReportTypes,
        hasDifferentialApplication,
        anomalyStatuses: anomalyStatusesRes?.data?.analysis_client_status.map((status: any) => ({
            id: status.id,
            name: status.name,
        })),
    };
};

export const getReportTier = (reportType: string, hasAnomalyData: number): InspectionReportTiers => {
    if (reportType === REPORT_TYPES.DIAGNOSTIC_REPORT) {
        return 0;
    } else if (hasAnomalyData) {
        return 3;
    } else {
        return 1;
    }
};
