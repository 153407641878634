import styled from '@emotion/styled';
import { Divider, Group, Stack, Text } from '@mantine/core';
import { useAtomValue } from 'jotai';

import CombinerIcon from 'components/common/Icons/CombinerIcon';
import InverterIcon from 'components/common/Icons/InverterIcon';
import LocationIcon from 'components/common/Icons/LocationIcon';
import StackIcon from 'components/common/Icons/StackIcon';
import { useDifferentialMode } from 'lib/hooks';
import { HoverInfo } from 'lib/types/inspections/anomaliesMap';
import { hoverInfoAtom } from 'state/inspections/anomaliesMap';

import AnomalyMapTooltipDifferential from './AnomalyMapTooltipDifferential';

const AnomalyMapTooltip = () => {
    const hoverInfo = useAtomValue(hoverInfoAtom);
    const { active: isDifferentialModeActive } = useDifferentialMode();

    return (
        <MapTooltip hoverInfo={hoverInfo}>
            <TooltipArrow hoverInfo={hoverInfo} />
            <Stack spacing="0.25rem">
                {hoverInfo && hoverInfo?.species?.length > 0 && (
                    <>
                        {isDifferentialModeActive ? (
                            <AnomalyMapTooltipDifferential />
                        ) : (
                            <Text variant="body2">{hoverInfo.name}</Text>
                        )}

                        <Divider color="gray.1" my="0.25rem" />
                    </>
                )}
                {hoverInfo?.positionId && (
                    <>
                        <Group w="100%" noWrap spacing="0.25rem" align="center">
                            <LocationIcon />
                            <Group position="apart" w="100%" noWrap>
                                <Text variant="body2">Position in Stack</Text>
                                <Text variant="body2light" c="gray.9">
                                    {hoverInfo.positionId.toUpperCase()}
                                </Text>
                            </Group>
                        </Group>
                        <Divider color="gray.1" my="0.25rem" />
                    </>
                )}
                <Stack spacing="0.5rem">
                    {hoverInfo?.combinerId && (
                        <Group w="100%" noWrap spacing="0.25rem" align="center">
                            <CombinerIcon />
                            <Group position="apart" w="100%" noWrap>
                                <Text variant="body2">Combiner</Text>
                                <Text variant="body2light" c="gray.9">
                                    {hoverInfo.combinerId.toUpperCase()}
                                </Text>
                            </Group>
                        </Group>
                    )}
                    {hoverInfo?.inverterId && (
                        <Group w="100%" noWrap spacing="0.25rem" align="center">
                            <InverterIcon />
                            <Group position="apart" w="100%" noWrap>
                                <Text variant="body2">Inverter</Text>
                                <Text variant="body2light" c="gray.9">
                                    {hoverInfo.inverterId.toUpperCase()}
                                </Text>
                            </Group>
                        </Group>
                    )}
                    {hoverInfo?.stack && (
                        <Group w="100%" noWrap spacing="0.25rem" align="center">
                            <StackIcon />
                            <Group position="apart" w="100%" noWrap>
                                <Text variant="body2">Stack</Text>
                                <Text variant="body2light" c="gray.9">
                                    {hoverInfo.stack.toUpperCase()}
                                </Text>
                            </Group>
                        </Group>
                    )}
                </Stack>
            </Stack>
        </MapTooltip>
    );
};

export default AnomalyMapTooltip;

const MapTooltip = styled.div<{ hoverInfo: HoverInfo | undefined }>(({ hoverInfo }) => ({
    position: 'absolute',
    zIndex: '1',
    left: `${(hoverInfo?.x || 0) + 12}px`,
    top: `${(hoverInfo?.y || 0) - 34}px`,
    backgroundColor: 'var(--color-white)',
    color: 'var(--color-grey-400)',
    padding: '0.75rem',
    borderRadius: '5px',
    minWidth: '14.3rem',
    maxWidth: '21.8rem',
    display: hoverInfo ? 'block' : 'none',
}));

const TooltipArrow = styled.div<{ hoverInfo: HoverInfo | undefined }>(({ hoverInfo }) => ({
    content: '" "',
    position: 'absolute',
    top: '1.5rem',
    left: '3.5rem',
    rotate: '270deg',
    marginLeft: '-4.7rem',
    borderWidth: '10px',
    borderStyle: 'solid',
    borderColor: 'transparent transparent var(--color-white) transparent',
    display: hoverInfo ? 'block' : 'none',
}));
