import { useState, useCallback, useMemo, useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { getTabFromPath } from 'lib/helpers/routing';

export const useTabularNavigation = <T>(tabs: T[], defaultTab: T, createNavPath: (tab: T) => string) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const [currentTab, setCurrentTab] = useState<T>(() => getTabFromPath<T>(pathname, tabs, defaultTab));

    const setAndNavigateToTab = useCallback(
        (tab: T) => {
            const navigationPath = createNavPath(tab);

            setCurrentTab(tab);
            navigate(navigationPath);
        },
        [createNavPath, navigate],
    );

    const tabularNavigation = useMemo(
        () => ({
            currentTab,
            setAndNavigateToTab,
            tabs,
        }),
        [currentTab, setAndNavigateToTab, tabs],
    );

    // Update current tab when path changes from a blocking event
    useEffect(() => {
        const newTab = getTabFromPath<T>(pathname, tabs, defaultTab);

        if (newTab !== currentTab) {
            setCurrentTab(newTab);
        }
    }, [pathname, currentTab, tabs, defaultTab]);

    return tabularNavigation;
};
