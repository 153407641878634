import { useState } from 'react';

import { notifications } from '@dronebase/shared-ui-core';
import { Drawer, TextInput, Text, Box, Button, Group } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

import { Config } from 'config';
import { ROUTES } from 'lib/constants/routes';
import { useUsersContext } from 'lib/context/UsersContext';
import { getCookie } from 'lib/cookies';
import { validateEmail } from 'lib/helpers';
import { fetchData } from 'lib/hooks';

import { StyledUserForm } from './UserForm.styled';
import WorkspacePermisionsList from './WorkspacePermissionsList';

const AUTH_API_URL = Config.get('AUTH_API_URL');

const InviteUserDrawer = ({ isOpen, close }: { isOpen: boolean; close: () => void }) => {
    const { workspaceApps, defaultPermissionIDs, refetchInvites } = useUsersContext();

    const navigate = useNavigate();
    const [email, setEmail] = useState<string>('');
    const [emailError, setEmailError] = useState<string>();
    const [selectedPermissions, setSelectedPermissions] = useState(defaultPermissionIDs);
    const workspace = getCookie('helio-workspace');

    const handlePermissionSelect = (pId: any) => {
        const perms = [...selectedPermissions];
        const existingPID = perms.indexOf(pId);

        existingPID >= 0 ? perms.splice(existingPID, 1) : perms.push(pId);

        setSelectedPermissions(perms);
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        const { error: createInviteError } = await fetchData({
            url: `${AUTH_API_URL}/invite/create`,
            method: 'POST',
            body: {
                email,
                workspace_uuid: workspace?.id,
                permissions: selectedPermissions,
            },
        });

        if (createInviteError) {
            notifications.error({ title: createInviteError, withCloseButton: true, withIcon: true });
        } else {
            notifications.info({ title: 'Successfully invited user', withCloseButton: true, withIcon: true });
            close();
            refetchInvites ? refetchInvites() : navigate(ROUTES.users.invites);
        }
    };

    const closeDrawer = () => {
        setEmail('');
        close();
    };

    return (
        <Drawer
            size="33.75rem"
            position="right"
            opened={isOpen}
            onClose={closeDrawer}
            title={<Text fz="1.25rem">Invite User</Text>}
        >
            <StyledUserForm onSubmit={handleSubmit}>
                <Box>
                    <TextInput
                        label="Email"
                        placeholder="name@company.com"
                        name="email"
                        autoComplete="email"
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                            setEmailError('');
                        }}
                        onBlur={(e) => setEmailError(validateEmail(e.target.value))}
                        error={email && emailError}
                        autoFocus
                        required
                    />
                    <WorkspacePermisionsList
                        workspaceName={workspace.name}
                        workspaceApps={workspaceApps}
                        selectedPermissions={selectedPermissions}
                        handlePermissionSelect={handlePermissionSelect}
                    />
                </Box>
                <Group m="0.5rem">
                    <Button size="lg" type="submit" disabled={!email || !!validateEmail(email)}>
                        Invite
                    </Button>
                    <Button size="lg" variant="text" onClick={closeDrawer}>
                        Cancel
                    </Button>
                </Group>
            </StyledUserForm>
        </Drawer>
    );
};

export default InviteUserDrawer;
