export const DecreaseIcon = () => (
    <svg
        fill="none"
        height="32"
        viewBox="0 0 32 32"
        width="32"
        xmlns="http://www.w3.org/2000/svg"
        transform="rotate(180)"
    >
        <rect fill="#eb5757" height="32" rx="16" width="32" />
        <g clipRule="evenodd" fill="#fff" fillRule="evenodd">
            <path d="m16 24.25c-.4142 0-.75-.3358-.75-.75v-15c0-.41422.3358-.75.75-.75s.75.33578.75.75v15c0 .4142-.3358.75-.75.75z" />
            <path d="m10.9697 13.5303c-.2929-.2929-.2929-.7677 0-1.0606l4.5-4.50003c.2929-.29289.7677-.29289 1.0606 0s.2929.76777 0 1.06066l-4.5 4.49997c-.2929.2929-.7677.2929-1.0606 0z" />
            <path d="m21.0303 13.5303c-.2929.2929-.7677.2929-1.0606 0l-4.5-4.49997c-.2929-.29289-.2929-.76777 0-1.06066s.7677-.29289 1.0606 0l4.5 4.50003c.2929.2929.2929.7677 0 1.0606z" />
        </g>
    </svg>
);
