import { ArrowRight } from '@dronebase/shared-ui-icons';
import { Group, Text } from '@mantine/core';
import { useAtomValue } from 'jotai';

import { useAnomalyMapData, useDifferentialAnomalyData } from 'lib/hooks';
import { hoveringAssetAtom } from 'state/inspections/anomaliesMap';

const AnomalyMapTooltipDifferential = () => {
    const hoveringAsset = useAtomValue(hoveringAssetAtom);
    const { previousRawMapAnomalies } = useDifferentialAnomalyData();
    const { currentInspectionMapAnomalies } = useAnomalyMapData();

    const current =
        (hoveringAsset?.assetRegionUuid &&
            currentInspectionMapAnomalies[hoveringAsset.assetRegionUuid]?.species?.[0]?.name) ??
        'None';

    const previous =
        (hoveringAsset?.assetRegionUuid &&
            previousRawMapAnomalies[hoveringAsset.assetRegionUuid]?.species?.[0]?.name) ??
        'None';

    return (
        <Group align="flex-start" position="left" spacing="0.75rem" noWrap>
            {current === previous ? (
                <>
                    <Text
                        c="gray.4"
                        variant="body2"
                        style={{ wordBreak: 'break-word', padding: 0, maxWidth: '10rem', minWidth: '2.5rem' }}
                    >
                        {current}
                    </Text>
                    <Text pt="0.2rem" variant="caption1medium" c="gray.9">
                        Present in Both
                    </Text>
                </>
            ) : (
                <>
                    <Text
                        c="gray.4"
                        variant="body2"
                        style={{ wordBreak: 'break-word', padding: 0, maxWidth: '10rem', minWidth: '2.5rem' }}
                    >
                        {previous}
                    </Text>
                    <ArrowRight
                        style={{ marginTop: '0.2rem', flexShrink: 0 }}
                        color="var(--color-grey-300)"
                        width="1rem"
                        height="1rem"
                    />
                    <Text
                        c="gray.4"
                        variant="body2"
                        style={{ wordBreak: 'break-word', padding: 0, maxWidth: '10rem', minWidth: '2.5rem' }}
                    >
                        {current}
                    </Text>
                </>
            )}
        </Group>
    );
};

export default AnomalyMapTooltipDifferential;
