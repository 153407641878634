import styled from '@emotion/styled';
import { NavLink, Text, Stack } from '@mantine/core';
import { useLocation, useNavigate } from 'react-router-dom';

import ResourcesIcon from 'components/common/Icons/ResourcesIcon';
import SiteIcon from 'components/common/Icons/SiteIcon';
import UsersIcon from 'components/common/Icons/UsersIcon';
import { LEFT_NAVBAR_WIDTH } from 'lib/constants/layout';
import { getCookie } from 'lib/cookies';
import { PermissionV1 } from 'lib/types';

const AVAILABLE_ROUTES = [
    { path: '/sites', label: 'Sites', icon: <SiteIcon />, applicationName: 'Reports' },
    { path: '/users', label: 'Users', icon: <UsersIcon />, applicationName: 'User Management' },
];

const LeftNavBar = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const permissions: PermissionV1[] = getCookie('helio-permissions')?.permissions ?? [];
    const userApps = permissions.map((permission) => permission.applicationName);
    // Routes are shown based on the user's permissions
    const userRoutes = AVAILABLE_ROUTES.filter((route) => userApps.includes(route.applicationName));

    return (
        <LeftNavBarContainer>
            {userRoutes.map(({ path, label, icon }) => (
                <NavLink
                    key={label}
                    label={
                        <Stack align="center" spacing={0}>
                            {icon}
                            <Text size="xs">{label}</Text>
                        </Stack>
                    }
                    active={pathname.includes(path)}
                    onClick={() => navigate(path)}
                    variant={pathname.includes(path) ? 'active' : 'inactive'}
                />
            ))}
            <NavLink
                label={
                    <Stack align="center" spacing={0}>
                        <ResourcesIcon />
                        <Text size="xs">Resources</Text>
                    </Stack>
                }
                onClick={() =>
                    window.open(
                        'https://dronebaseinsights.zendesk.com/hc/en-us/articles/360049714951-Get-Started',
                        '_blank',
                    )
                }
                variant="inactive"
            />
        </LeftNavBarContainer>
    );
};

export default LeftNavBar;

export const LeftNavBarContainer = styled.div({
    width: LEFT_NAVBAR_WIDTH,
    backgroundColor: 'var(--color-white)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    boxShadow: '0px 4px 48px 0px rgba(141, 149, 162, 0.20)',
    zIndex: 101,
});
