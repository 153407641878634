import _ from 'lodash';

import {
    Grade,
    ModuleInspection,
    ModuleSummaryData,
    PowerSummaryData,
    PowerSummaryRowsProps,
} from 'components/inspections/AnomalyMap/DifferentialsModalContent';
import { InspectionLoaderData } from 'components/inspections/types';

import { formatPercentage } from './inspections';
import { formatDate } from './sites';

export const getPowerLossPercent = (powerLoss: number, siteCapacity: number) =>
    // powerLoss: W
    // siteCapacity: MW
    (Math.abs(powerLoss / (siteCapacity * 1000000)) * 100).toFixed(2);

const buildPowerSummaryObject = (
    inspectionData: InspectionLoaderData | any,
    inspectionDate: Date | undefined,
    siteCapacity: number,
): PowerSummaryRowsProps => ({
    date: inspectionDate ? formatDate(inspectionDate) : 'N/A',
    powerLossPercent: Number(getPowerLossPercent(inspectionData?.powerLoss, siteCapacity)),
    powerLossKw: inspectionData?.powerLoss / 1000,
});

export const buildPowerSummary = (inspectionData: InspectionLoaderData, siteCapacity: number): PowerSummaryData => ({
    previousInspection: buildPowerSummaryObject(
        inspectionData?.previousInspection,
        inspectionData?.previousInspection?.publishDate,
        siteCapacity,
    ),
    currentInspection: buildPowerSummaryObject(inspectionData?.summary, inspectionData?.inspectionDate, siteCapacity),
});

const buildModuleGradeData = (inspectionData: InspectionLoaderData | any, grade: string, module: string): Grade => ({
    grade: inspectionData?.[grade] ?? '',
    value: formatPercentage(inspectionData?.[module] / inspectionData?.activeModules),
});

const buildFaultTypeSummary = (inspectionData: InspectionLoaderData | any): ModuleInspection => ({
    bos: buildModuleGradeData(inspectionData, 'bosGrade', 'bos'),
    major: buildModuleGradeData(inspectionData, 'majorGrade', 'major'),
    minor: buildModuleGradeData(inspectionData, 'minorGrade', 'minor'),
});

export const buildModuleSummary = (inspectionData: InspectionLoaderData): ModuleSummaryData => ({
    previousInspection: buildFaultTypeSummary(inspectionData?.previousInspection),
    currentInspection: buildFaultTypeSummary(inspectionData?.summary),
});

export const extractBlobUrls = (data: any): string[] =>
    _.flatten(data?.reedling_index_blob_index?.map((blob: any) => blob?.blobInstance.url));

export const getRemoteCSVUrl = (urls: string[]) => urls?.find((url: any) => !url.includes('differential'));
export const getDifferentialRemoteCSVUrl = (urls: string[]) => urls?.find((url: any) => url.includes('differential'));
