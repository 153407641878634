import { Box, Button, Text, Title } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'lib/constants/routes';

const InviteDeclined = ({ email, workspaceName }: { email: string; workspaceName: string }) => {
    const navigate = useNavigate();

    return (
        <>
            <Title
                order={6}
                ta="center"
            >{`You've declined to join the ${workspaceName} on Zeitview Solar Insights`}</Title>

            <Box>
                <Text variant="body2" ta="center" color="gray.4">
                    {`You have declined the invitation with the account below: `}
                </Text>
                <Text ta="center" fw={500}>
                    {email}
                </Text>
            </Box>
            <Button
                variant="filled"
                w="100%"
                size="xl"
                style={{ lineHeight: '105%' }}
                onClick={() => navigate(ROUTES.auth.index, { replace: true })}
            >
                Back to Log in Screen
            </Button>
        </>
    );
};

export default InviteDeclined;
