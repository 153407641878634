import { Stack, Text } from '@mantine/core';
import { useRouteLoaderData } from 'react-router-dom';

import { SiteLayoutLoaderData } from 'components/Layout/SiteLayout';

import QuartileTable from './QuartileTable';
import ModalSection from '../shared/ModalSection';
import ModalTitle from '../shared/ModalTitle';

const SitePerformanceModal = () => {
    const siteData = useRouteLoaderData('site-details') as SiteLayoutLoaderData;

    return (
        <>
            <ModalTitle title="Performance Scorecard" />
            <ModalSection title="Description">
                <Stack spacing="0.5rem">
                    <Text variant="body2">
                        Description Performance Scorecard is an industry benchmark indicating how the site’s performance
                        compares to other sites in Zeitview’s global system performance database. Zeitview’s global
                        system performance database ranks similarly-sized sites (in terms of MW DC) by the percentage of
                        modules affected by balance of system (BoS), Major Module and Minor Module anomalies.
                    </Text>

                    <Text variant="body2">
                        Major Module anomalies are module-level anomalies with either power loss &gt;&#61;33&#37;, a
                        module sub-component failure, or temperature delta greater than or equal to
                        10&#176;C&#47;50&#176;F. Minor Module anomalies are module-level anomalies with power loss
                        &lt;33&#37;.
                    </Text>

                    <Text variant="body2">
                        Sites are then sub-divided into quartiles and assigned a letter grade according to the quartile
                        they fall in. Sites with grade A are the best performing sites, while sites with grade D are the
                        worst performing sites relative to the rest of the industry.
                    </Text>
                </Stack>
                <QuartileTable siteCapacity={siteData?.capacity} />
            </ModalSection>
        </>
    );
};

export default SitePerformanceModal;
