import { PrimitiveAtom, WritableAtom } from 'jotai';
import { RESET } from 'jotai/utils';

export type ActiveFilterDescriptor<T> = {
    atom: WritableAtom<T, [newValue: T | typeof RESET], void>;
    label: string;
    stringifyValue: (value: T) => string;
    isActive: (value: T) => boolean;
};

export type ActiveFilter = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    atom: PrimitiveAtom<any>;
    label: string;
    stringifiedValue: string;
};

export const defineActiveFilter = <T>(filter: ActiveFilterDescriptor<T>): ActiveFilterDescriptor<T> => ({
    ...filter,
});
