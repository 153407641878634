import { useEffect, useMemo, useState } from 'react';

import { staticAnomalyFilters } from 'lib/constants';
import {
    generateSignalColorsFromSignalFilters,
    buildSignalFilterSections,
    sortObjectKeysByCount,
    buildStatusIdsFilterSection,
} from 'lib/helpers';
import createCustomHook from 'lib/helpers/hookFactory';

import { useAnomalyMapData, useMapSignalColors } from './index';
import { useAnomalySpecies } from '../useAnomalySpecies';

const ValueProvider = () => {
    const [sections, setSections] = useState<any>(staticAnomalyFilters);
    const [totalAnomalyCount, setTotalAnomalyCount] = useState(0);

    const { setMapSignalColors } = useMapSignalColors();
    const { fetchingReportAnomalies, activeMapAnomalies } = useAnomalyMapData();
    const { anomalySpecies } = useAnomalySpecies();

    const addSignalColorsToFilters = (sections: any, signalColors: any) => {
        Object.values(sections.ir.filterOptions).forEach((filter: any) => {
            filter.color = signalColors?.ir[filter.databaseValue];
        });
        Object.values(sections.rgb.filterOptions).forEach((filter: any) => {
            filter.color = signalColors?.rgb[filter.databaseValue];
        });
    };

    useEffect(() => {
        if (!fetchingReportAnomalies) {
            if (Object.keys(activeMapAnomalies).length > 0) {
                const sections = buildSignalFilterSections(staticAnomalyFilters, activeMapAnomalies, anomalySpecies);
                const statusIdsSection = buildStatusIdsFilterSection(
                    staticAnomalyFilters.statusIds,
                    activeMapAnomalies,
                );

                sections.statusIds = statusIdsSection;
                sections.ir.loading = false;
                sections.rgb.loading = false;
                sections.statusIds.loading = false;

                sections.ir.filterOptions = sortObjectKeysByCount(sections.ir.filterOptions);
                sections.rgb.filterOptions = sortObjectKeysByCount(sections.rgb.filterOptions);
                sections.statusIds.filterOptions = sortObjectKeysByCount(sections.statusIds.filterOptions);

                const mapSignalColors = generateSignalColorsFromSignalFilters({
                    irSignals: Object.values(sections.ir?.filterOptions)?.map((filter: any) => ({
                        filter: filter.databaseValue,
                    })),
                    rgbSignals: Object.values(sections.rgb?.filterOptions)?.map((filter: any) => ({
                        filter: filter.databaseValue,
                    })),
                });

                setMapSignalColors(mapSignalColors);
                addSignalColorsToFilters(sections, mapSignalColors);

                setSections(sections);
                setTotalAnomalyCount(Object.values(activeMapAnomalies).length);
            } else {
                setSections([]);
                setTotalAnomalyCount(0);
            }
        }
    }, [anomalySpecies, fetchingReportAnomalies, activeMapAnomalies, setMapSignalColors]);

    const value = useMemo(
        () => ({
            sections,
            totalAnomalyCount,
            fetchingReportAnomalies,
        }),
        [sections, totalAnomalyCount, fetchingReportAnomalies],
    );

    return value;
};

const { Provider: AnomalyMapSectionsProvider, useCustomHook: useAnomalyMapSections } = createCustomHook<any>({
    name: 'useAnomalyMapData',
    callback: ValueProvider,
});

export { AnomalyMapSectionsProvider, useAnomalyMapSections };
