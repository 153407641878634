import { useAtomValue } from 'jotai';
import _ from 'lodash';

import { ALL_TIME, NO_INSPECTIONS } from 'lib/constants';
import { CapacityRangeFilter } from 'lib/filters/sites/createCapacityRangeCriterion';
import { DEFAULT_MAP_BOUNDS } from 'state/map';

import { createActiveFiltersAtom } from './createActiveFiltersAtom';
import { defineActiveFilter } from './defineActiveFilter';
import {
    searchFilterWithResetAtom,
    capacityRangeFilterWithResetAtom,
    inspectionYearFilterWithResetAtom,
    mapBoundsFilterWithResetAtom,
} from '../siteFiltersStorage';

const filters = [
    defineActiveFilter<string>({
        label: 'Name',
        isActive: (searchTerm) => !!searchTerm,
        stringifyValue: (searchTerm) => searchTerm,
        atom: searchFilterWithResetAtom,
    }),
    defineActiveFilter<CapacityRangeFilter>({
        label: 'Capacity',
        isActive: ({ checked }) => checked,
        stringifyValue: ({ low, high }) => `${low} MW - ${high} MW`,
        atom: capacityRangeFilterWithResetAtom,
    }),
    defineActiveFilter<string>({
        label: 'Inspections',
        isActive: (inspectionYear: string) => inspectionYear !== ALL_TIME,
        stringifyValue: (inspectionYear: string) => (inspectionYear === NO_INSPECTIONS ? 'None' : inspectionYear),
        atom: inspectionYearFilterWithResetAtom,
    }),
    defineActiveFilter<number[]>({
        label: '',
        isActive: (bounds: number[]) => !_.isEqual(bounds, DEFAULT_MAP_BOUNDS),
        stringifyValue: () => 'Map Filter Applied',
        atom: mapBoundsFilterWithResetAtom,
    }),
];

const activeFiltersAtom = createActiveFiltersAtom(filters);

export const useActiveSiteFilters = () => useAtomValue(activeFiltersAtom);
