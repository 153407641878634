import styled from '@emotion/styled';
import { Stack } from '@mantine/core';

export const TabContentContainer = styled(Stack)({
    flex: 1,
    overflow: 'auto',
    padding: '1.5rem',
    gap: '1rem',
});

export const PanelHeaderContainer = styled.div({
    padding: '1.5rem 1.5rem 0.1rem 1.5rem',
    boxShadow: '0px 8px 24px 8px rgba(0, 0, 0, 0.20)',
    position: 'relative',
});
