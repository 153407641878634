import { getCookie } from '../cookies';
import { errorHandlers, getAuthToken } from '../helpers';

export const fetchData = async ({
    url,
    method = 'GET',
    body = {},
    permission = null,
    externalLink = false,
}: {
    url: string;
    method: string;
    body: any;
    permission?: string | null;
    externalLink?: boolean;
}) => {
    const options: {
        method: string;
        headers?: any;
        body?: string;
    } = {
        method,
    };

    if (!externalLink) {
        const token = await getAuthToken();

        options.headers = {
            'Content-Type': 'application/json',
            'allow-control-allow-origin': '*',
            'x-user-token': token,
            'x-user-workspace': getCookie('helio-workspace')?.id,
        };
    }

    if (['POST', 'PUT', 'PATCH'].includes(method)) {
        options.body = JSON.stringify(body);
    }

    if (permission) {
        options.headers['x-user-permission'] = permission;
    }

    try {
        // eslint-disable-next-line no-undef
        const response = await fetch(url, options);

        if (!response.ok) {
            return { error: await errorHandlers(response), data: null };
        }

        const data = await response.json();

        return { error: null, data };
    } catch (error) {
        return { error: errorHandlers(error), data: null };
    }
};
