const LocationIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.125 8.95265C3.125 4.27239 6.56895 1.75 10 1.75C13.431 1.75 16.875 4.27239 16.875 8.95265C16.875 10.9494 15.8091 13.0254 14.6085 14.7489C13.3942 16.492 11.9734 17.9706 11.1313 18.7896C10.4898 19.4134 9.50325 19.4008 8.87506 18.7672C8.03863 17.9235 6.61707 16.3994 5.40069 14.6441C4.20141 12.9135 3.125 10.8515 3.125 8.95265ZM10 2.91591C7.1339 2.91591 4.2808 4.98792 4.2808 8.95265C4.2808 10.4687 5.16952 12.2755 6.348 13.9761C7.50938 15.652 8.87821 17.1216 9.69229 17.9427C9.87709 18.1291 10.1434 18.1308 10.3291 17.9502C11.1456 17.1562 12.5083 15.7359 13.6628 14.0786C14.831 12.4016 15.7192 10.5754 15.7192 8.95265C15.7192 4.98792 12.8661 2.91591 10 2.91591ZM10 6.5457C8.82868 6.5457 7.87914 7.50354 7.87914 8.6851C7.87914 9.86666 8.82868 10.8245 10 10.8245C11.1713 10.8245 12.1209 9.86666 12.1209 8.6851C12.1209 7.50354 11.1713 6.5457 10 6.5457ZM6.72334 8.6851C6.72334 6.85963 8.19035 5.3798 10 5.3798C11.8096 5.3798 13.2767 6.85963 13.2767 8.6851C13.2767 10.5106 11.8096 11.9904 10 11.9904C8.19035 11.9904 6.72334 10.5106 6.72334 8.6851Z"
            fill="currentColor"
        />
    </svg>
);

export default LocationIcon;
