import { SortedBy } from 'components/Sites/types';
import { Config } from 'config';

export const DEFAULT_PAGE_SIZE_LIMIT = 25;

export const TABLE_PAGE_SIZES = ['25', '50', '100'];

export const SORT_DIRECTION: { [key: string]: SortedBy['direction'] } = {
    DESC: 'desc_nulls_last',
    ASC: 'asc_nulls_first',
};
export const DEFAULT_SORT_DIRECTION = SORT_DIRECTION.DESC;

export const colors = {
    blackOverlay: 'rgba(79, 79, 79, 0.4)',
    white: '#ffffff',
    blackRgba50: 'rgba(0, 0, 0, 0.5)',
    blackRgba25: 'rgba(0, 0, 0, 0.25)',
    grayText: '#7d7d87',
    grayTextDark: '#4a4a4a',
    lightAccent: '#ecf7ff',
    primaryBackground: '#fafbfd',
    primaryBlue: '#02669d',
    secondaryBlue: '#2d9cdb',
    tertiaryBlue: '#1379b3',
    primaryGray: '#b9b9c3',
    secondaryGray: '#5a6771',
    tertiaryGray: '#dcdce6',
    darkGray: '#b9b9bf',
    lightGray: '#ebebf0',
    primaryOrange: '#ff6a13',
    secondaryOrange: '#ffead8',
    primaryYellow: '#ffeeb0',
    secondaryYellow: '#f7b31e',
    primaryBlack: '#303035',
    primaryRed: '#eb5757',
    primaryGreen: '#27ae60',
    secondaryWheat: '#fcdc7d',
    secondaryMarigold: '#ffc006',
    primaryPetrol: '#005f69',
    primaryPaleTeal: '#9cc4b2',
    primaryVeryLightGreen: '#e3efe0',
    secondaryMango: '#ff9829',
    redsBerry: '#800f2d',
    redsRouge: '#bc1742',
    redsPinkishRed: '#e52a4d',
    redsSalmon: '#ed8682',
    shadesBlack: '#222222',
    shadesVeryDarkGrey: '#4a4a4a',
    shadesDarkGrey: '#606060',
    shadesDarkMetal: '#7b7b7b',
    shadesMetal: '#959595',
    shadesGrey: '#b5b5b5',
    shadesLightGrey: '#dddddd',
    shadesVeryLightGrey: '#ebebeb',
    shadesOffWhite: '#f5f5f5',
    offWhite: '#fafbfd',
    shadesWhite: '#ffffff',
};

export const ENVIRONMENT = String(Config.get('ENVIRONMENT') ?? 'N/A');

export const POPOVER_CARD_MAX_WIDTH = '75rem';
