import { Pagination } from '@dronebase/shared-ui-core';
import { Group } from '@mantine/core';
import { Outlet, useLocation } from 'react-router-dom';

import { UsersPageHeader } from 'components/Header/UsersPageHeader';
import { UsersPageTabs } from 'components/tabs/UsersPageTabs';
import { ROUTES } from 'lib/constants/routes';
import { useUsersContext } from 'lib/context/UsersContext';

import { PageLayout } from './PageLayout';

export const UsersPageLayout = () => {
    const { userPaginationProps, invitesPaginationProps } = useUsersContext();
    const location = useLocation();
    const paginationProps = location.pathname === ROUTES.users.index ? userPaginationProps : invitesPaginationProps;

    return (
        <>
            <UsersPageHeader />
            <PageLayout>
                <Group position="apart">
                    <UsersPageTabs />
                    <Pagination {...paginationProps} withTotal withPageSize withDoubleArrows />
                </Group>
                <Outlet />
            </PageLayout>
        </>
    );
};
