import { useCallback, useEffect } from 'react';

import styled from '@emotion/styled';
import { Button, Tabs } from '@mantine/core';

import { ANOMALIES_TAB, OBSERVATIONS_TAB } from 'lib/constants';
import { useAnomalyMapFilters, useSelectedAsset } from 'lib/hooks';

import { AnomalyFilters } from './AnomalyFilters';
import KeyObservationsFilters from './KeyObservationsFilters';
import { PanelHeaderContainer } from './TabContainers.styled';

const SiteAnomaliesAndObservationsPanel = () => {
    const {
        showObservationsPanel,
        setShowObservationsPanel,
        areAnomalyFiltersApplied,
        areObservationFiltersApplied,
        clearAnomalyFilters,
        clearObservationFilters,
    } = useAnomalyMapFilters();

    const { clearSelectedAsset } = useSelectedAsset();

    const handleTabChange = useCallback(
        (value: string) => setShowObservationsPanel(value === OBSERVATIONS_TAB),
        [setShowObservationsPanel],
    );

    useEffect(() => {
        clearSelectedAsset();
    }, [clearSelectedAsset]);

    return (
        <Tabs
            value={showObservationsPanel ? OBSERVATIONS_TAB : ANOMALIES_TAB}
            onTabChange={handleTabChange}
            style={{ display: 'flex', flexDirection: 'column' }}
        >
            <PanelHeaderContainer>
                <Tabs.List>
                    <Tabs.Tab value={ANOMALIES_TAB}>Anomalies</Tabs.Tab>
                    <Tabs.Tab value={OBSERVATIONS_TAB}>Observations</Tabs.Tab>
                </Tabs.List>
            </PanelHeaderContainer>
            <Tabs.Panel value={ANOMALIES_TAB} style={{ overflow: 'auto' }}>
                <AnomalyFilters />
            </Tabs.Panel>
            <Tabs.Panel value={OBSERVATIONS_TAB} style={{ overflow: 'auto' }}>
                <KeyObservationsFilters />
            </Tabs.Panel>
            {(areAnomalyFiltersApplied && !showObservationsPanel) ||
            (areObservationFiltersApplied && showObservationsPanel) ? (
                <Footer>
                    <Button
                        variant="outline"
                        size="md"
                        onClick={showObservationsPanel ? clearObservationFilters : clearAnomalyFilters}
                    >
                        Clear Filters
                    </Button>
                </Footer>
            ) : null}
        </Tabs>
    );
};

export default SiteAnomaliesAndObservationsPanel;

const Footer = styled.div({
    display: 'flex',
    padding: '1rem 1.5rem',
    justifyContent: 'flex-end',
    alignItems: 'center',
    background: 'var(--color-gray-700, #2D343E)',
    boxShadow: '0px -8px 24px 8px rgba(0, 0, 0, 0.20)',
});
