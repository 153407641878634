import moment from 'moment';

import { ALL_TIME, NO_INSPECTIONS } from 'lib/constants';

export const createInspectionCriterion = (inspectionYear: string) => (site: any) => {
    if (inspectionYear === ALL_TIME) {
        return true;
    }

    if (inspectionYear === NO_INSPECTIONS) {
        return site.lastInspectionDate === null;
    }

    return site.lastInspectionDate && moment(site.lastInspectionDate).year() === Number(inspectionYear);
};
