import { Download } from '@dronebase/shared-ui-icons';
import styled from '@emotion/styled';
import { ActionIcon, ActionIconProps, Text, Tooltip } from '@mantine/core';

import useDownloadImage from 'lib/hooks/useDownloadImage';

interface DownloadImageButtonProps {
    url: string;
    className?: string;
}

interface ActionIconDownloadProps extends ActionIconProps {
    onClick: () => void;
}

const DownloadImageButton = ({ url, className = '' }: DownloadImageButtonProps) => {
    const download = useDownloadImage();

    return (
        <Tooltip label={<Text>Download</Text>} transitionProps={{ transition: 'pop', duration: 300 }}>
            <ActionIconDownload className={className} onClick={() => download(url)}>
                <Download />
            </ActionIconDownload>
        </Tooltip>
    );
};

export default DownloadImageButton;

const ActionIconDownload = styled(ActionIcon)<ActionIconDownloadProps>({
    'backgroundColor': 'var(--color-grey-700)',
    'color': 'var(--color-grey-300)',
    ':hover': {
        backgroundColor: 'var(--color-grey-900)',
        color: 'var(--color-purple-300)',
    },
    ':active': {
        color: 'var(--color-purple-300)',
        backgroundColor: 'var(--color-grey-900)',
    },
});
