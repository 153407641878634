import styled from '@emotion/styled';

import zeitviewLogoSm from 'components/common/images/zeitview-logo-sm.svg';
import { APPBAR_HEIGHT } from 'lib/constants/layout';

import SelectWorkspaceModal from './Modals/SelectWorkspaceModal/SelectWorkspaceModal';
import UserMenu from './UserMenu';

const AppBar = ({ onLogout }: { onLogout?: (redirect?: boolean) => Promise<void> }) => (
    <AppBarContainer>
        <img src={zeitviewLogoSm} alt="Zeitview Insights" width="101" height="35" />
        <UserMenu onLogout={onLogout} />
        <SelectWorkspaceModal />
    </AppBarContainer>
);

export default AppBar;

const AppBarContainer = styled.div({
    height: APPBAR_HEIGHT,
    minHeight: APPBAR_HEIGHT,
    width: '100%',
    boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.10)',
    backgroundColor: 'var(--color-white)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 1rem',
    zIndex: 100,
});
