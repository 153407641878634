import { useEffect, useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';
import { useQuery } from 'urql';

import createCustomHook from 'lib/helpers/hookFactory';
import { GET_SITE_GEOMETRY } from 'lib/queries';

const ValueProvider = () => {
    const [siteGeometry, setSiteGeometry] = useState<any>(null);
    const [siteBounds, setSiteBounds] = useState<any>(null);

    const { siteId } = useParams();

    const [{ data }] = useQuery({
        query: GET_SITE_GEOMETRY,
        variables: {
            siteUuid: siteId,
        },
    });

    useEffect(() => {
        if (data) {
            setSiteGeometry(data.site.geometry);
            setSiteBounds(
                data.site.geometry.coordinates.flat(2).reduce(
                    (bounds: any, latLon: any) => ({
                        minLon: Math.min(latLon[0], bounds.minLon),
                        maxLon: Math.max(latLon[0], bounds.maxLon),
                        minLat: Math.min(latLon[1], bounds.minLat),
                        maxLat: Math.max(latLon[1], bounds.maxLat),
                    }),
                    {
                        minLon: 180,
                        maxLon: -180,
                        minLat: 180,
                        maxLat: -180,
                    },
                ),
            );
        }
    }, [data]);

    const value = useMemo(
        () => ({
            siteGeometry,
            siteBounds,
        }),
        [siteGeometry, siteBounds],
    );

    return value;
};

const { Provider: SiteGeometryProvider, useCustomHook: useSiteGeometry } = createCustomHook<any>({
    name: 'useSelectedAsset',
    callback: ValueProvider,
});

export { SiteGeometryProvider, useSiteGeometry };
