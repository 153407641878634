import { useEffect, useState } from 'react';

import { Loader } from '@dronebase/shared-ui-core';
import styled from '@emotion/styled';
import { Center, Text, Title } from '@mantine/core';
import { isSignInWithEmailLink } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

import { CardContentContainer } from 'components/Layout/AuthPagesLayout';
import { ROUTES } from 'lib/constants/routes';
import auth from 'lib/firebase';
import useFirebaseAuth from 'lib/hooks/useFirebaseAuth';

const SignInLinkVerification = () => {
    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const { loginWithSignInLink } = useFirebaseAuth();

    useEffect(() => {
        const handleEmailLink = async () => {
            if (isSignInWithEmailLink(auth, window.location.href)) {
                const email = window.localStorage.getItem('emailForSignIn');

                if (email) {
                    try {
                        await loginWithSignInLink(email);

                        window.localStorage.removeItem('emailForSignIn');
                        navigate(ROUTES.auth.select_workspace);
                    } catch (error) {
                        console.error('Error signing in with email link', error);
                        setError(true);
                    }
                } else {
                    setError(true);
                }
            }
        };

        handleEmailLink();
    }, []);

    if (error) {
        return (
            <CardContentContainer>
                <StyledForm>
                    <Title order={6} ta="center">
                        Log in
                    </Title>
                    <Text size="sm" mb="lg" c="red">
                        The login link has expired.
                    </Text>
                    <Text
                        component="button"
                        onClick={() => navigate('/auth')}
                        size="sm"
                        c="indigo"
                        sx={{ background: 'none', border: 'none', cursor: 'pointer', padding: 0 }}
                    >
                        Back to Log in
                    </Text>
                </StyledForm>
            </CardContentContainer>
        );
    }

    return (
        <Center style={{ height: 80 }}>
            <Loader />
        </Center>
    );
};

const StyledForm = styled.div({
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
    maxWidth: '600px',
    margin: '0 auto',
    padding: '0 20px',
    textAlign: 'center',
});

export default SignInLinkVerification;
