import { useMemo } from 'react';

import { Group, Button, Flex, Stack, Text, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useRouteLoaderData } from 'react-router-dom';

import { UpgradeCosts, InspectionLoaderData } from 'components/inspections/types';
import { colors, InspectionUpgradeOptions, REPORT_TYPE_CTA_BUTTON_COLOR, REPORT_TYPE_CTA_TEXT } from 'lib/constants';
import { formatDollarAmount, isReportUpgradeExpired, upgradeReportMatch } from 'lib/helpers';

import UpgradeModal from './UpgradeModal';

export interface UpgradeOptionsCardProps {
    type: InspectionUpgradeOptions;
    upgradeCosts: UpgradeCosts;
    content?: any;
}

const UpgradeOptionCTA = ({ type, content }: UpgradeOptionsCardProps) => {
    const [opened, { open, close }] = useDisclosure(false);

    const { upgradeRequests, inspectionReportTypes } = useRouteLoaderData('inspection') as InspectionLoaderData;

    const reportUpgrades = upgradeRequests?.reportUpgrades;
    const inspectionDate = upgradeRequests?.inspectionDate;
    const upgradeExpiresAt = upgradeRequests?.upgradeExpiresAt;
    const reportUpgrade = reportUpgrades.length > 0 ? reportUpgrades[0] : null;

    const buttonDetails = useMemo(() => {
        const isMatch = upgradeReportMatch(inspectionReportTypes, reportUpgrade, type);
        const hasExpired = isReportUpgradeExpired(upgradeExpiresAt, inspectionDate);

        if (isMatch) {
            return { text: 'Requested', backgroundColor: REPORT_TYPE_CTA_BUTTON_COLOR[type] };
        }

        if (hasExpired && reportUpgrade) {
            return { text: 'Expired', backgroundColor: colors.primaryGray };
        }

        if (reportUpgrade?.reportTypeUuid) {
            return { text: 'Upgrade', backgroundColor: colors.primaryGray };
        }

        return { text: 'Upgrade', backgroundColor: REPORT_TYPE_CTA_BUTTON_COLOR[type] };
    }, [inspectionReportTypes, upgradeExpiresAt, inspectionDate, reportUpgrade, type]);

    return (
        <Flex justify="space-around">
            <Stack spacing="0" align="center">
                <Stack spacing="0.25rem">
                    <Title order={5} color="gray.9">
                        {REPORT_TYPE_CTA_TEXT[type]}
                    </Title>
                    <Group align="center" position="center" spacing="0.5rem">
                        <Text variant="subtitle2" color="gray.9">
                            Cost:
                        </Text>
                        <Text variant="body1semibold" color="gray.9">
                            {formatDollarAmount(content[type].upgradeCost)}
                        </Text>
                    </Group>
                </Stack>
                <Button
                    size="lg"
                    disabled={buttonDetails?.text === 'Expired' || !!reportUpgrade?.reportTypeUuid}
                    onClick={open}
                    color={buttonDetails?.backgroundColor}
                    mb="1.5rem"
                    mt="1rem"
                >
                    {buttonDetails?.text}
                </Button>
            </Stack>
            {opened && <UpgradeModal opened={opened} close={close} type={type} />}
        </Flex>
    );
};

export default UpgradeOptionCTA;
