import { useEffect, useMemo, useState } from 'react';

import { ConfirmModal, notifications } from '@dronebase/shared-ui-core';
import { Box, Button, Drawer, Group, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useRevalidator } from 'react-router-dom';

import { Config } from 'config';
import { User, useUsersContext } from 'lib/context/UsersContext';
import { getCookie } from 'lib/cookies';
import { fetchData } from 'lib/hooks';

import { StyledUserForm } from './UserForm.styled';
import WorkspacePermisionsList from './WorkspacePermissionsList';

const AUTH_API_URL = Config.get('AUTH_API_URL');

const editUserCallback = async (
    e: React.FormEvent<HTMLFormElement>,
    workspaceUUID: string,
    selectedUser: User,
    selectedPermissions: string[],
) => {
    e.preventDefault();
    const { error } = await fetchData({
        url: `${AUTH_API_URL}/users/${selectedUser.uuid}/permissions/update`,
        method: 'POST',
        body: {
            workspace_uuid: workspaceUUID,
            permissions: selectedPermissions,
        },
    });

    if (error) {
        notifications.error({ title: error, withCloseButton: true, withIcon: true });
    } else {
        notifications.success({
            title: 'Permissions updated successfully',
            withCloseButton: true,
            withIcon: true,
        });
    }
};

const removeUserCallback = async (selectedUser: User, workspaceUUID: string) => {
    const { error, data } = await fetchData({
        url: `${AUTH_API_URL}/users/remove`,
        method: 'POST',
        body: { users: [selectedUser.uuid], workspace: workspaceUUID },
    });

    if (error) {
        notifications.error({ title: error, withCloseButton: true, withIcon: true });
    } else {
        const removedSuccessfully = data?.removed_users?.success;

        if (removedSuccessfully?.length && removedSuccessfully.includes(selectedUser.uuid)) {
            notifications.success({
                title: 'User successfully removed from your workspace',
                withCloseButton: true,
                withIcon: true,
            });
        } else {
            notifications.error({ title: 'An error occured', withCloseButton: true, withIcon: true });
        }
    }
};

const UserDetails = ({
    isOpen,
    closeDrawer,
    selectedUser,
}: {
    isOpen: boolean;
    closeDrawer: () => void;
    selectedUser: User | null;
}) => {
    const loggedInUserId = getCookie('helio-userId');
    const workspace = getCookie('helio-workspace');
    const workspaceUUID: string = workspace.id;
    const { workspaceApps } = useUsersContext();
    const revalidator = useRevalidator();
    const existingUserPermissions = useMemo(
        () => selectedUser?.permissions.map(({ permission }) => permission.uuid) ?? [],
        [selectedUser],
    );
    const [selectedPermissions, setSelectedPermissions] = useState(existingUserPermissions);

    const [removeUserDialogOpen, { open: openRemoveUserDialog, close: closeRemoveUserDialog }] = useDisclosure(false);
    const [isUpdatingUser, setIsUpdatingUser] = useState(false);

    useEffect(() => setSelectedPermissions(existingUserPermissions), [existingUserPermissions]);

    const isEditing = useMemo(
        () =>
            JSON.stringify(selectedUser?.permissions.map(({ permission }) => permission.uuid).toSorted()) !==
            JSON.stringify(selectedPermissions.toSorted()),
        [selectedPermissions, selectedUser],
    );

    return (
        <>
            <Drawer
                size="33.75rem"
                position="right"
                opened={isOpen}
                onClose={closeDrawer}
                title={<Text fz="1.25rem">Edit User</Text>}
            >
                <StyledUserForm
                    onSubmit={async (e) => {
                        selectedUser && (await editUserCallback(e, workspaceUUID, selectedUser, selectedPermissions));
                        revalidator.revalidate();
                        closeDrawer();
                        setIsUpdatingUser(false);
                    }}
                >
                    <Box>
                        <Text>Email</Text>
                        <Text variant="body2light" color="var(--color-grey-400)">
                            {selectedUser?.email}
                        </Text>
                        <WorkspacePermisionsList
                            workspaceName={workspace.name}
                            workspaceApps={workspaceApps}
                            selectedPermissions={selectedPermissions}
                            handlePermissionSelect={(selectedPermission: string) =>
                                setSelectedPermissions((permissions) =>
                                    permissions.includes(selectedPermission)
                                        ? permissions.filter((permission) => permission !== selectedPermission)
                                        : [...permissions, selectedPermission],
                                )
                            }
                        />
                    </Box>
                    <Group m="0.5rem" position="apart">
                        <Group>
                            <Button size="lg" type="submit" disabled={!isEditing || isUpdatingUser}>
                                Update
                            </Button>
                            <Button size="lg" variant="text" onClick={closeDrawer}>
                                Cancel
                            </Button>
                        </Group>
                        <Button
                            size="lg"
                            disabled={loggedInUserId === selectedUser?.uuid || removeUserDialogOpen}
                            onClick={openRemoveUserDialog}
                            variant="text"
                        >
                            Remove User
                        </Button>
                    </Group>
                </StyledUserForm>
            </Drawer>
            <ConfirmModal
                title="Remove User from Workspace"
                onConfirm={async () => {
                    selectedUser && (await removeUserCallback(selectedUser, workspaceUUID));
                    revalidator.revalidate();
                    closeRemoveUserDialog();
                    closeDrawer();
                }}
                opened={removeUserDialogOpen}
                onClose={closeRemoveUserDialog}
            >
                <Text>Are you sure you want to remove this user? This cannot be undone.</Text>
            </ConfirmModal>
        </>
    );
};

export default UserDetails;
