import { useCallback } from 'react';

import { Button, Group, Text } from '@mantine/core';

import CaloriesIcon from 'components/common/Icons/CaloriesIcon';
import { usePivotTable } from 'lib/hooks';

import TableAggregator from './TableAggregator';

const TableSettings = () => {
    const { renderer, setRenderer } = usePivotTable();

    const handleHeatmapToggle = useCallback(
        () => (renderer === 'Table Heatmap' ? setRenderer('Table') : setRenderer('Table Heatmap')),
        [renderer, setRenderer],
    );

    return (
        <Group spacing="2rem" position="right" noWrap>
            <TableAggregator />
            <Button variant="outline" onClick={handleHeatmapToggle} size="md" leftIcon={<CaloriesIcon />}>
                <Text fw={500}>Heatmap</Text>
            </Button>
        </Group>
    );
};

export default TableSettings;
