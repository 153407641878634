import { useCallback, useEffect, useState } from 'react';

import {
    GoogleAuthProvider,
    OAuthProvider,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
    sendPasswordResetEmail,
    onAuthStateChanged,
    User,
    sendSignInLinkToEmail,
    signInWithEmailLink,
} from 'firebase/auth';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

import { SOLAR_USER_APP_ID, SOLAR_USER_ROLE } from 'lib/constants';
import { clearCookies, getCookie } from 'lib/cookies';
import auth from 'lib/firebase';
import { isInternalUser } from 'lib/helpers';
import { PermissionV1 } from 'lib/types';

import { Config } from '../../config';

const MICROSOFT_PROVIDER = 'microsoft.com';
const LOGROCKET_APP_ID = String(Config.get('LOGROCKET_APP_ID'));
const SIGN_IN_LINK_DOMAINS = String(Config.get('SIGN_IN_LINK_DOMAINS'));

const useFirebaseAuth = () => {
    const [authUser, setAuthUser] = useState<User | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const initializeLogRocket = useCallback((user: User) => {
        const workspace = getCookie('helio-workspace') || {};
        const orgId = workspace.id || '';
        const orgName = workspace.name || '';
        const { displayName, email, uid: id } = user;
        const isDronebaseUser = isInternalUser(email);
        const permissions: PermissionV1[] = getCookie('helio-permissions')?.permissions || [];
        const userApps = permissions.map((permission) => permission.applicationName);
        const [firstName = '', lastName = ''] = displayName?.split(' ') || [];
        const company = email?.match(/@([^.]+)/)?.[1] ?? 'unknown';

        if (email && !isDronebaseUser) {
            LogRocket.init(LOGROCKET_APP_ID);
            LogRocket.identify(id, {
                name: displayName || email,
                firstName,
                lastName,
                email,
                id,
                role: SOLAR_USER_ROLE,
                orgId,
                orgName,
                company,
                permissions: JSON.stringify(userApps),
                isDronebase: isDronebaseUser,
                appId: SOLAR_USER_APP_ID,
            });
            setupLogRocketReact(LogRocket);
        }
    }, []);

    const verifyCredentials = async (email: string, password: string) => {
        await signInWithEmailAndPassword(auth, email, password);
        await signOut(auth);

        return true;
    };

    const loginUser = async (email: string, password: string) => {
        try {
            const signInLinkDomains = new RegExp(SIGN_IN_LINK_DOMAINS);

            if (signInLinkDomains.test(email)) {
                if (await verifyCredentials(email, password)) {
                    await sendSignInLinkToEmail(auth, email, {
                        url: `${window.location.origin}/auth/confirm`,
                        handleCodeInApp: true,
                    });
                    window.localStorage.setItem('emailForSignIn', email);

                    return {
                        isSignInLinkDomain: true,
                    };
                }
            }

            const { user } = await signInWithEmailAndPassword(auth, email, password);

            initializeLogRocket(user);
            setAuthUser(user);
            setLoading(false);

            return {
                isSignInLinkDomain: false,
            };
        } catch (error: any) {
            setLoading(false);
            setError(error.message);

            throw error;
        }
    };

    const loginWithSignInLink = async (email: string) => {
        const { user } = await signInWithEmailLink(auth, email, window.location.href);

        initializeLogRocket(user);
        setAuthUser(user);
        setLoading(false);
    };

    const loginWithGoogle = async () => {
        const googleProvider = new GoogleAuthProvider();

        try {
            const result = await signInWithPopup(auth, googleProvider);
            const { user } = result;

            initializeLogRocket(user);
            setAuthUser(user);
            setLoading(false);
        } catch (error: any) {
            setLoading(false);
            setError(error.message);

            throw error;
        }
    };

    const loginWithMicrosoft = async () => {
        const microsoftProvider = new OAuthProvider(MICROSOFT_PROVIDER);

        try {
            const result = await signInWithPopup(auth, microsoftProvider);
            const { user } = result;

            initializeLogRocket(user);
            setAuthUser(user);
            setLoading(false);
        } catch (error: any) {
            setLoading(false);
            setError(error.message);

            throw error;
        }
    };

    const logoutUser = useCallback(async () => {
        try {
            await signOut(auth);

            clearCookies();
            setAuthUser(null);
            setLoading(false);

            window.location.assign(`${window.location.origin}/auth`);
        } catch (error: any) {
            setLoading(false);
            setError(error.message);

            throw error;
        }
    }, [setAuthUser]);

    const resetPassword = async (email: string) => {
        try {
            await sendPasswordResetEmail(auth, email);
            setLoading(false);
        } catch (error: any) {
            setLoading(false);
            setError(error.message);

            throw error;
        }
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            setLoading(true);

            if (user) {
                initializeLogRocket(user);
                setAuthUser(user);
            } else {
                setAuthUser(null);
            }

            setLoading(false);
        });

        return () => unsubscribe();
    }, [initializeLogRocket, setAuthUser]);

    return {
        authUser,
        loading,
        error,
        loginUser,
        logoutUser,
        resetPassword,
        loginWithMicrosoft,
        loginWithGoogle,
        loginWithSignInLink,
    };
};

export default useFirebaseAuth;
