import { useCallback, useEffect, useMemo, useState } from 'react';

import { useRouteLoaderData } from 'react-router-dom';

import { InspectionLoaderData } from 'components/inspections/types';
import { MapAnomalies } from 'components/Sites/types';
import { DIFFERENTIAL_MODES } from 'lib/constants';
import {
    createAnomalyDefinitionMap,
    filterDifferentialAnomalies,
    getTotalAnomalyCount,
    transformRawAnomalies,
} from 'lib/helpers';
import createCustomHook from 'lib/helpers/hookFactory';
import { GET_REPORT_ANOMALIES, GET_REPORT_ANOMALY_SUMMARY } from 'lib/queries';
import { client } from 'lib/urqlClient';

import { useAnomalyMapData } from './useAnomalyMapData';
import { useDifferentialMode } from '../useDifferentialMode';

const ValueProvider = () => {
    const [previousInspectionMapAnomalies, setPreviousInspectionMapAnomalies] = useState<MapAnomalies>({});
    const [differentialMapAnomalies, setDifferentialMapAnomalies] = useState<any>({});

    const { currentInspectionMapAnomalies, setMapAnomalies } = useAnomalyMapData();
    const { active: isDifferentialModeActive, mode } = useDifferentialMode();
    const currentInspectionData = useRouteLoaderData('inspection') as InspectionLoaderData;

    useEffect(() => {
        const fetchPreviousInspectionData = async () => {
            const previousInspection = currentInspectionData?.previousInspection;

            if (!previousInspection) {
                return;
            }

            const previousInspectionUUID = previousInspection.inspectionID;
            const previousInspectionSATUUIDs = previousInspection.superAnalysisTasks.map(
                (sat: any) => sat.superAnalysisTaskUUID,
            );

            const reportAnomaliesPromise = client
                .query(GET_REPORT_ANOMALIES, {
                    superAnalysisTaskUUIDs: previousInspectionSATUUIDs,
                })
                .toPromise();

            const reportAnomalySummaryPromise = client
                .query(GET_REPORT_ANOMALY_SUMMARY, {
                    uuid: previousInspectionUUID,
                })
                .toPromise();

            const [{ data: previousReportAnomalyData }, { data: previousReportAnomalySummary }] = await Promise.all([
                reportAnomaliesPromise,
                reportAnomalySummaryPromise,
            ]);

            setPreviousInspectionMapAnomalies(
                transformRawAnomalies(
                    previousReportAnomalyData,
                    null,
                    createAnomalyDefinitionMap(previousReportAnomalySummary?.anomalySummary),
                ),
            );
        };

        if (isDifferentialModeActive) {
            fetchPreviousInspectionData();
        }
    }, [currentInspectionData?.previousInspection, isDifferentialModeActive]);

    useEffect(() => {
        if (isDifferentialModeActive && differentialMapAnomalies[mode]) {
            setMapAnomalies(differentialMapAnomalies[mode].anomalies);
        } else {
            setMapAnomalies(currentInspectionMapAnomalies);
        }
    }, [isDifferentialModeActive, differentialMapAnomalies, mode, setMapAnomalies, currentInspectionMapAnomalies]);

    useEffect(() => {
        setDifferentialMapAnomalies(
            Object.values(DIFFERENTIAL_MODES).reduce((acc: any, mode: any) => {
                const filteredDifferentialAnomalies = filterDifferentialAnomalies(
                    currentInspectionMapAnomalies,
                    previousInspectionMapAnomalies,
                    mode,
                );

                return {
                    ...acc,
                    [mode]: {
                        anomalies: filteredDifferentialAnomalies,
                        count: getTotalAnomalyCount(filteredDifferentialAnomalies),
                    },
                };
            }, {}),
        );
    }, [currentInspectionMapAnomalies, previousInspectionMapAnomalies]);

    const getAnomalyCountForDifferentialMode = useCallback(
        (mode: DIFFERENTIAL_MODES) => differentialMapAnomalies[mode]?.count ?? 0,
        [differentialMapAnomalies],
    );

    const value = useMemo(
        () => ({
            previousRawMapAnomalies: previousInspectionMapAnomalies,
            previousInspectionId: currentInspectionData?.previousInspection?.inspectionID,
            getAnomalyCountForDifferentialMode,
        }),
        [
            previousInspectionMapAnomalies,
            currentInspectionData?.previousInspection?.inspectionID,
            getAnomalyCountForDifferentialMode,
        ],
    );

    return value;
};

const { Provider: DifferentialAnomalyDataProvider, useCustomHook: useDifferentialAnomalyData } = createCustomHook<any>({
    name: 'useDifferentialAnomalyData',
    callback: ValueProvider,
});

export { DifferentialAnomalyDataProvider, useDifferentialAnomalyData };
