/**
 * A generic type that describes a function that checks that a criterion
 * is satisfied for the given item of type T.
 */
type Criterion<T> = (item: T) => boolean;

/**
 * A function to filter an array of items by multiple criterions
 * while iterating over the array only once.
 *
 * @param criteria an array of criterions
 * @returns a function which applies every criterion to the given
 * array.
 */
export function filterByCriteria<ArrayItem>(criteria: Criterion<ArrayItem>[]) {
    // Given a single item, this function checks that every criteria is satisfied
    // for this item.
    const mergedCriteria = (arrayItem: ArrayItem) => criteria.every((criteria) => criteria(arrayItem));

    // We return a function that applies the merged criteria on every item
    return (array: ArrayItem[]) => array.filter(mergedCriteria);
}
