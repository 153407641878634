import { ActionIcon, Flex } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useRouteLoaderData } from 'react-router-dom';

import { CalculatorIcon } from 'components/common/Icons/Calculator';
import { FinancialLossIcon } from 'components/common/Icons/FinancialLossIcon';
import { PowerLossIcon } from 'components/common/Icons/PowerLossIcon';
import { calculateFinancialLoss } from 'lib/helpers';

import FinancialLossCalculator from './FinancialLossCalculator';
import SiteLossInfoModal from './SiteLossInfoModal';
import SiteLossLabel from './SiteLossLabel';
import InfoModal from '../shared/InfoModal';
import PopoverCard from '../shared/PopoverCard';
import { InspectionLoaderData, ReportTypeMap } from '../types';

const SiteLoss = () => {
    const { summary, powerLossBreakdown, reportType } = useRouteLoaderData('inspection') as InspectionLoaderData;
    const [opened, { open, close }] = useDisclosure(false);

    const { totalFinancialLoss, bosFinancialLoss, moduleBasicRecoverableLoss } = calculateFinancialLoss({
        powerLossBreakdown,
    });

    const reportTypeMap: ReportTypeMap = {
        'String Level': {
            financialLoss: bosFinancialLoss,
            powerLoss: powerLossBreakdown.bos / 1000,
        },
        'Module Basic': {
            financialLoss: moduleBasicRecoverableLoss,
            powerLoss: (powerLossBreakdown.bos + powerLossBreakdown.major) / 1000,
        },
        'default': {
            financialLoss: totalFinancialLoss,
            powerLoss: summary.powerLoss / 1000,
        },
    };
    const { financialLoss, powerLoss } = reportTypeMap[reportType] || reportTypeMap.default;

    return (
        <PopoverCard title="DC Losses at Site" modalContent={<SiteLossInfoModal />}>
            <Flex justify="space-around">
                <SiteLossLabel
                    title={`${Number(powerLoss.toFixed(2)).toLocaleString()} kW`}
                    subtitle="power loss"
                    icon={<PowerLossIcon />}
                />
                <SiteLossLabel
                    title={financialLoss}
                    subtitle="financial loss over lifetime of project"
                    icon={<FinancialLossIcon />}
                    calculatorIcon={
                        <ActionIcon onClick={open} size="xl" mih="fit-content" miw="fit-content">
                            <CalculatorIcon />
                        </ActionIcon>
                    }
                />
            </Flex>

            <InfoModal opened={opened} onClose={close}>
                <FinancialLossCalculator powerLossBreakdown={powerLossBreakdown} />
            </InfoModal>
        </PopoverCard>
    );
};

export default SiteLoss;
