import { useEffect, useMemo, useState } from 'react';

import { usePaginationProps } from '@dronebase/shared-ui-core';
import { useAtom } from 'jotai';

import { DEFAULT_PAGE_SIZE_LIMIT } from 'lib/constants';
import { paginationFilterWithResetAtom } from 'state/sites/filters/siteFiltersStorage';

const usePagination = <T>(
    items: T[],
    paginationState: Record<string, number>,
    setPaginationState: (value: Record<string, number>) => void,
    currentPageKey: string,
    pageSizeKey: string,
) => {
    const paginationProps = usePaginationProps({
        prefix: '',
        currentPage: paginationState[currentPageKey],
        totalItems: items.length,
        itemsPerPage: paginationState[pageSizeKey],
        setQueryParams: (values) => setPaginationState({ ...paginationState, ...values }),
    });

    const paginatedData = useMemo(
        () =>
            items.slice(
                (paginationProps.currentPage - 1) * paginationProps.itemsPerPage,
                paginationProps.currentPage * paginationProps.itemsPerPage,
            ),
        [items, paginationProps],
    );

    useEffect(() => {
        const maxPage = Math.max(Math.ceil(items.length / paginationProps.itemsPerPage), 1);

        if (maxPage < paginationProps.currentPage) {
            setPaginationState({ ...paginationState, [currentPageKey]: maxPage });
        }
    }, [paginationProps, items, paginationState, currentPageKey, setPaginationState]);

    return {
        paginationProps,
        paginatedData,
    };
};

export const useManualPagination = <T>(items: T[], prefix = '') => {
    const currentPageKey = `${prefix}page`;
    const pageSizeKey = `${prefix}size`;

    const [paginationState, setPaginationState] = useState<Record<string, number>>({
        [currentPageKey]: 1,
        [pageSizeKey]: DEFAULT_PAGE_SIZE_LIMIT,
    });

    return usePagination(items, paginationState, setPaginationState, currentPageKey, pageSizeKey);
};

export const useSitesPagePagination = <T>(items: T[], prefix = '') => {
    const currentPageKey = `${prefix}page`;
    const pageSizeKey = `${prefix}size`;

    const [paginationFilter, setPaginationFilter] = useAtom(paginationFilterWithResetAtom);

    return usePagination(items, paginationFilter, setPaginationFilter, currentPageKey, pageSizeKey);
};
