import { useMemo } from 'react';

import { Loader } from '@dronebase/shared-ui-core';
import { Text } from '@mantine/core';

import { REPORT_TABS } from 'lib/constants';
import { SANKEY_AGGREGATION } from 'lib/constants/sankey';
import { generateSankeyData } from 'lib/helpers/sankey';
import { useAnomalyMapFilters, useAnomalyOverviewFilters, useInspectionTabs } from 'lib/hooks';

import RechartsSankeyChart from './RechartsSankey';

interface SankeyChartProps {
    totalModules: number;
    siteCapacity: number;
    powerLossPerModule: number;
}

const SankeyChart = ({ totalModules, siteCapacity, powerLossPerModule }: SankeyChartProps) => {
    const { setAnomalyMapFilters, setShowObservationsPanel, filter: currentFilters } = useAnomalyMapFilters();
    const { filteredAnomalies, aggregation, fetching: fetchingAnomalies, signalColors } = useAnomalyOverviewFilters();
    const { setAndNavigateToTab } = useInspectionTabs();

    const { nodes, linkData, powerLossMapping } = useMemo(
        () => generateSankeyData(filteredAnomalies, powerLossPerModule, signalColors),
        [filteredAnomalies, signalColors, powerLossPerModule],
    );

    const rechartsSankeyData = useMemo(
        () => ({
            nodes,
            links: aggregation === SANKEY_AGGREGATION.NUMBER_OF_MODULES ? linkData.numberOfModules : linkData.powerLoss,
        }),
        [nodes, linkData, aggregation],
    );

    const handleLinkClick = (sankeyFilters: Record<string, string[]>) => {
        const newFilters = { ...currentFilters, ...sankeyFilters };

        setAnomalyMapFilters(newFilters);
        setShowObservationsPanel(false);

        setAndNavigateToTab(REPORT_TABS.MAP);
    };

    if (fetchingAnomalies) {
        return <Loader />;
    }

    if (!filteredAnomalies?.length) {
        return <Text>There are no anomalies selected!</Text>;
    }

    return (
        <RechartsSankeyChart
            sankeyData={rechartsSankeyData}
            onLinkClick={handleLinkClick}
            siteCapacity={siteCapacity}
            totalModules={totalModules}
            linkData={linkData}
            powerLossPerModule={powerLossPerModule}
            powerLossMapping={powerLossMapping}
            aggregation={aggregation}
        />
    );
};

export default SankeyChart;
