import { useEffect } from 'react';

import { Group, Tabs } from '@mantine/core';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { PageLayout } from 'components/Layout/PageLayout';
import { ANOMALIES_PAGE_TABLE_TABS, ANOMALY_TABLE_TYPES } from 'lib/constants';
import { getTabFromPath } from 'lib/helpers/routing';
import { useTabularNavigation } from 'lib/hooks/useTabularNavigation';

import DownloadCSV from './DownloadCSV';

const AnomalyData = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const { currentTab, setAndNavigateToTab } = useTabularNavigation<ANOMALY_TABLE_TYPES>(
        ANOMALIES_PAGE_TABLE_TABS,
        ANOMALY_TABLE_TYPES.PIVOT,
        (tab: ANOMALY_TABLE_TYPES) => tab.toLowerCase(),
    );

    useEffect(() => {
        const tab = getTabFromPath<ANOMALY_TABLE_TYPES>(pathname, ANOMALIES_PAGE_TABLE_TABS, ANOMALY_TABLE_TYPES.PIVOT);

        if (tab === ANOMALY_TABLE_TYPES.PIVOT) {
            navigate('pivot', { replace: true });
        }
    }, [navigate, pathname]);

    return (
        <PageLayout>
            <Group position="apart" pb="1rem">
                <Tabs value={currentTab.toLowerCase()} onTabChange={setAndNavigateToTab}>
                    <Tabs.List>
                        <Tabs.Tab value={ANOMALY_TABLE_TYPES.PIVOT.toLowerCase()}>{ANOMALY_TABLE_TYPES.PIVOT}</Tabs.Tab>
                        <Tabs.Tab value={ANOMALY_TABLE_TYPES.TABULAR.toLowerCase()}>
                            {ANOMALY_TABLE_TYPES.TABULAR}
                        </Tabs.Tab>
                    </Tabs.List>
                </Tabs>
                <DownloadCSV tableType={currentTab} />
            </Group>
            <Outlet />
        </PageLayout>
    );
};

export default AnomalyData;
