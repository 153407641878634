import { createContext, useContext, ReactNode } from 'react';

interface CustomHookProps<T> {
    name: string;
    callback: () => T;
}

export default function createCustomHook<T>(props: CustomHookProps<T>) {
    const Context = createContext<T | undefined>(undefined);

    const Provider = ({ children }: { children: ReactNode }) => {
        const value = props.callback();

        return <Context.Provider value={value}>{children}</Context.Provider>;
    };

    const useCustomHook = () => {
        const context = useContext(Context);

        if (!context) {
            throw new Error(`${props.name} must be used within a ${props.name}'s Provider`);
        }

        return context;
    };

    return { Provider, Context, useCustomHook };
}
