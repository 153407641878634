import moment from 'moment';

/**
 * Formats an ISO date string to either include or exclude time based on the provided option.
 *
 * For tabular data, it's helpful to show the full date and time (hours, minutes, etc.) to provide
 * detailed information. In contrast, the Pivot Table view only requires the date without time to
 * simplify the display.
 *
 * @param {string} isoString - The ISO date string to format.
 * @param {boolean} [includeTime=false] - Whether to include time in the formatted output.
 * @returns {string} The formatted date, with or without time.
 *
 * @example
 * // With time (for tabular data)
 * formatISODate('2024-10-10T03:12:00-04:00', true); // "3:12 am, October 10, 2024"
 *
 * @example
 * // Without time (for pivot data)
 * formatISODate('2024-10-10T03:12:00-04:00'); // "October 10, 2024"
 */
export const formatISODate = (isoString: string, includeTime = false): string => {
    if (!isoString) {
        // can be empty string on initial load
        return isoString;
    }

    const date = moment(isoString, moment.ISO_8601, true);

    if (!date.isValid()) {
        // eslint-disable-next-line no-console
        console.warn(`Invalid date encountered: ${isoString}`);

        return isoString;
    }

    return includeTime ? date.format('h:mm a, MMMM D, YYYY') : date.format('MMMM D, YYYY');
};
