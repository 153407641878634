export const SANKEY_AGGREGATION = {
    NUMBER_OF_MODULES: 'number_of_modules',
    POWER_LOSS: 'power_loss',
};

export const DEFAULT_OTHER_NODE_COLOR = [178, 190, 181];

export const DEFAULT_REMEDIATION_LEVEL_COLORS: any = {
    1: [178, 34, 34],
    2: [189, 183, 107],
    3: [154, 205, 50],
};

export const DEFAULT_FALLBACK_NODE_COLOR = [0, 0, 0];
