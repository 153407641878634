import { useEffect, useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';
import { useQuery } from 'urql';

import { AggregatedAnomaly, Observation } from 'components/inspections/types';
import { AnomalyDefinitionMap } from 'components/Sites/types';
import { ALL_REMEDIATION_LEVELS } from 'lib/constants';
import { SANKEY_AGGREGATION } from 'lib/constants/sankey';
import {
    createAnomalyDefinitionMap,
    flattenAnomalies,
    generateSignalColorsFromSignalFilters,
    generateSignalFilterData,
} from 'lib/helpers';
import createCustomHook from 'lib/helpers/hookFactory';
import {
    GET_INDEPENDENT_ANOMALY_SPECIES,
    GET_INSPECTION_OBSERVATIONS,
    GET_REPORT_ANOMALY_SUMMARY_WITH_SATS,
} from 'lib/queries';

const ValueProvider = () => {
    const [anomalySummary, setAnomalySummary] = useState<AggregatedAnomaly[]>([]);
    const [superAnalysisTaskUUIDs, setSuperAnalysisTaskUUIDs] = useState<string[]>([]);
    const [signalFilters, setSignalFilters] = useState<any>(null);
    const [signalColors, setSignalColors] = useState<any>(null);
    const [filteredAnomalies, setFilteredAnomalies] = useState<any>([]);
    const [aggregation, setAggregation] = useState(SANKEY_AGGREGATION.NUMBER_OF_MODULES);
    const [remediationCategoryFilter, setRemediationCategoryFilter] = useState<string[]>(ALL_REMEDIATION_LEVELS);
    const [irSignalFilter, setIrSignalFilter] = useState<string[]>([]);
    const [rgbSignalFilter, setRgbSignalFilter] = useState<string[]>([]);
    const [observations, setObservations] = useState<Observation[]>([]);
    const [anomalyDefinitions, setAnomalyDefinitions] = useState<AnomalyDefinitionMap>();

    const { inspectionId } = useParams();
    const [{ data: reportAnomalySummary, fetching: fetchingAnomalySummary }] = useQuery({
        query: GET_REPORT_ANOMALY_SUMMARY_WITH_SATS,
        variables: { uuid: inspectionId },
    });

    // TODO: Consolidate with useAnomalySpecies
    const [{ data: getAnomalySpeciesResponse, fetching: fetchingAnomalySpecies }] = useQuery({
        query: GET_INDEPENDENT_ANOMALY_SPECIES,
    });

    const [{ data: observationsData, fetching: fetchingObservations }] = useQuery({
        query: GET_INSPECTION_OBSERVATIONS,
        variables: {
            superReportTaskUUID: inspectionId,
        },
    });

    useEffect(() => {
        if (!fetchingAnomalySummary && !fetchingAnomalySpecies) {
            setAnomalySummary(
                flattenAnomalies(reportAnomalySummary?.anomalySummary, getAnomalySpeciesResponse?.anomalySpecies),
            );
            setSuperAnalysisTaskUUIDs(
                reportAnomalySummary?.superAnalysisTasks?.map((task: any) => task.superAnalysisTaskUUID) ?? [],
            );
        }
    }, [fetchingAnomalySummary, reportAnomalySummary, getAnomalySpeciesResponse, fetchingAnomalySpecies]);

    useEffect(() => {
        if (reportAnomalySummary) {
            setAnomalyDefinitions(createAnomalyDefinitionMap(reportAnomalySummary.anomalySummary));
        }
    }, [reportAnomalySummary]);

    useEffect(() => {
        setFilteredAnomalies(anomalySummary);
    }, [anomalySummary]);

    useEffect(() => {
        setSignalFilters(generateSignalFilterData(anomalySummary));
    }, [anomalySummary]);

    useEffect(() => {
        if (signalFilters) {
            setSignalColors(generateSignalColorsFromSignalFilters(signalFilters));
        }
    }, [signalFilters]);

    useEffect(() => {
        if (anomalySummary?.length && remediationCategoryFilter) {
            const signalFilters = [...irSignalFilter, ...rgbSignalFilter];

            setFilteredAnomalies(
                anomalySummary
                    .filter((anomaly) => remediationCategoryFilter.includes(anomaly.remediationCategory))
                    .filter(
                        (anomaly) =>
                            !(signalFilters.includes(anomaly.irFilter) || signalFilters.includes(anomaly.rgbFilter)),
                    ),
            );
        }
    }, [remediationCategoryFilter, irSignalFilter, rgbSignalFilter, anomalySummary]);

    useEffect(() => {
        if (observationsData) {
            setObservations(
                observationsData.observations?.map((observationEntry: any, index: number) => ({
                    id: `KO-${index + 1}`,
                    images: observationEntry.observationBlobs?.map(({ blob }: any) => blob?.blobInstance.url),
                    description: observationEntry.description,
                    observationID: observationEntry.uuid,
                    isDisabled: !observationEntry.observationAOIs.length,
                })),
            );
        }
    }, [observationsData]);

    const value = useMemo(
        () => ({
            fetching: fetchingAnomalySummary || fetchingAnomalySpecies,
            signalFilters,
            signalColors,
            anomalySummary,
            filteredAnomalies,
            observations,
            aggregation,
            remediationCategoryFilter,
            irSignalFilter,
            rgbSignalFilter,
            superAnalysisTaskUUIDs,
            anomalyDefinitions,
            setAggregation,
            setIrSignalFilter,
            setRgbSignalFilter,
            setRemediationCategoryFilter,
            fetchingObservations,
        }),
        [
            signalFilters,
            signalColors,
            fetchingAnomalySummary,
            fetchingAnomalySpecies,
            anomalySummary,
            filteredAnomalies,
            observations,
            aggregation,
            remediationCategoryFilter,
            irSignalFilter,
            rgbSignalFilter,
            superAnalysisTaskUUIDs,
            anomalyDefinitions,
            setAggregation,
            setIrSignalFilter,
            setRgbSignalFilter,
            setRemediationCategoryFilter,
            fetchingObservations,
        ],
    );

    return value;
};

const { Provider: AnomalyOverviewFilterProvider, useCustomHook: useAnomalyOverviewFilters } = createCustomHook<any>({
    name: 'useAnomalyOverviewFilters',
    callback: ValueProvider,
});

export { AnomalyOverviewFilterProvider, useAnomalyOverviewFilters };
