import { useEffect } from 'react';

import styled from '@emotion/styled';
import { Text, Flex, Box, BoxProps } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useAtom, useSetAtom } from 'jotai';
import { useLoaderData } from 'react-router-dom';

import NoSitesFoundIcon from 'components/common/Icons/NoSitesFoundIcon';
import { PageHeader } from 'components/Header/PageHeader';
import { PageLayout } from 'components/Layout/PageLayout';
import { LOCAL_STORAGE_KEYS } from 'lib/constants';
import { getCookie } from 'lib/cookies';
import { generateInspectionYearsMap } from 'lib/helpers';
import { get } from 'lib/helpers/localStorage';
import { useDocumentTitle } from 'lib/hooks/useDocumentTitle';
import { GET_WORKSPACE_SITES } from 'lib/queries';
import { client } from 'lib/urqlClient';
import { allSitesAtom, maxSiteCapacityAtom } from 'state/sites/sites';

import SitesListView from './ListView/SitesListView';
import MapView from './MapView';
import { Site } from './types';

const Sites = () => {
    useDocumentTitle('Zeitview | Sites');

    const { capacityRange, allSites: allSitesFromLoader } = useLoaderData() as SiteLoader;

    /**
     * We need the allSites atom to be populated to drive the filtering in local storage
     * for <SitesListView>. That is why that sites list is conditionally rendered once
     * allSitesAtom is populated.
     */
    const [sites, setAllSites] = useAtom(allSitesAtom);
    const setMaxSiteCapacity = useSetAtom(maxSiteCapacityAtom);

    useEffect(() => {
        setAllSites(allSitesFromLoader);
        setMaxSiteCapacity(Math.ceil(capacityRange[1]));
    }, [allSitesFromLoader, capacityRange, setAllSites, setMaxSiteCapacity]);

    const [collapsed, { toggle: toggleCollapsed }] = useDisclosure(
        get(LOCAL_STORAGE_KEYS.SITES_MAP_COLLAPSED) === 'true',
    );

    if (!allSitesFromLoader.length) {
        return (
            <Flex direction="column" gap="0.5rem" align="center" justify="center" w="100%" h="100%">
                <NoSitesFoundIcon />
                <Text
                    style={{
                        fontWeight: 'var(--typography-fw-semi-bold)',
                        fontSize: 'var(--h6-font-size)',
                        verticalAlign: 'middle',
                    }}
                >
                    Your workspace does not contain any sites.
                </Text>
            </Flex>
        );
    }

    return (
        <>
            <PageHeader title="Sites" />
            <PageLayout>
                <Flex h="100%" w="100%" justify="space-between" style={{ overflow: 'hidden' }}>
                    <MapView collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
                    {sites.length > 0 && (
                        <SitesListViewContainer collapsed={collapsed}>
                            <SitesListView />
                        </SitesListViewContainer>
                    )}
                </Flex>
            </PageLayout>
        </>
    );
};

export default Sites;

const SitesListViewContainer = styled(Box)<BoxProps & { collapsed: boolean }>(({ collapsed }) => ({
    height: '100%',
    overflow: 'auto',
    width: collapsed ? 'calc(100% - 40px)' : '50%',
    paddingLeft: '1.5rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 20,
}));

interface SiteLoader {
    allSites: Site[];
    capacityRange: [number, number];
}

export const sitesLoader = async (): Promise<SiteLoader> => {
    const workspaceId = getCookie('helio-workspace')?.id;

    const {
        data: { aggregate, inspectionYears, allSites },
    } = await client
        .query(GET_WORKSPACE_SITES, {
            workspaceId,
        })
        .toPromise();

    const {
        min: { capacity: minCapacity },
        max: { capacity: maxCapacity },
    } = aggregate.aggregate;

    generateInspectionYearsMap(inspectionYears, workspaceId, allSites.length);
    const capacityRange: [number, number] = [minCapacity ?? 0, maxCapacity ?? 100];

    return {
        allSites,
        capacityRange,
    };
};
