import { useEffect } from 'react';

import { Loader } from '@dronebase/shared-ui-core';
import { LinearInterpolator } from 'deck.gl/typed';
import { useAtomValue, useSetAtom } from 'jotai';
import { NavigationType, Outlet, useLoaderData, useNavigationType } from 'react-router-dom';

import { useAnomalyMapData, useSelectedAsset } from 'lib/hooks';
import { AssetLoader } from 'lib/loaders/assetLoader';
import { isAssetSelectedAtom, viewStateAtom } from 'state/inspections/anomaliesMap';

import ModuleDetails from './ModuleDetails';

const Module = () => {
    const { asset } = useLoaderData() as AssetLoader;
    const isAssetSelected = useAtomValue(isAssetSelectedAtom);
    const setViewState = useSetAtom(viewStateAtom);
    const { getAssetAndAnomalies, setAssetAndAnomalies } = useSelectedAsset();
    const { fetchingReportAnomalies, fetchingAnomalyOverview } = useAnomalyMapData();
    const navigationType = useNavigationType();

    useEffect(() => {
        if (asset) {
            const result = getAssetAndAnomalies(asset);

            if (result) {
                setAssetAndAnomalies(result, asset.assetRegionUuid);
            }

            /**
             * If going directly to asset where it hasn't been selected (link share) or
             * hitting the browser back button (history pop) then update the view state
             * to bring the module in view
             */
            if (!isAssetSelected || navigationType === NavigationType.Pop) {
                setViewState((prevState) => ({
                    ...prevState,
                    longitude: asset.coordinates[1],
                    latitude: asset.coordinates[0],
                    zoom: 20,
                    transitionDuration: 1000,
                    transitionInterpolator: new LinearInterpolator(),
                }));
            }
        }
    }, [asset, getAssetAndAnomalies, isAssetSelected, navigationType, setAssetAndAnomalies, setViewState]);

    if (fetchingReportAnomalies || fetchingAnomalyOverview || !asset) {
        return <Loader />;
    }

    return (
        <>
            <ModuleDetails asset={asset} />
            <Outlet context={asset} />
        </>
    );
};

export default Module;
