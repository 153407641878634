import styled from '@emotion/styled';
import { Button } from '@mantine/core';

interface SaveCancelFooterProps {
    onSave: (() => void) | undefined;
    onCancel: () => void;
    disabled: boolean;
}

const ModuleTabFooter = ({ onCancel, disabled, onSave }: SaveCancelFooterProps) => (
    <Footer>
        <Button variant="filled" size="md" type="submit" onClick={onSave} disabled={disabled}>
            Save
        </Button>
        <Button variant="outline" size="md" onClick={onCancel} disabled={disabled}>
            Cancel
        </Button>
    </Footer>
);

export default ModuleTabFooter;

const Footer = styled.div({
    display: 'flex',
    padding: '1rem 1.5rem',
    gap: '1rem',
    justifyContent: 'flex-start',
    alignItems: 'center',
    background: 'var(--color-gray-700, #2D343E)',
    boxShadow: '0px -8px 24px 8px rgba(0, 0, 0, 0.20)',
});
