import { useMemo } from 'react';

import { Text, Stack, Flex, Title, Group } from '@mantine/core';
import { useRouteLoaderData } from 'react-router-dom';

import { BreakdownArrowsIcon } from 'components/common/Icons/BreakdownArrows';
import { InspectionUpgradeOptions } from 'lib/constants';
import { calculateFinancialLoss } from 'lib/helpers';

import { InspectionLoaderData } from '../types';

interface RecoverableEnergyBreakdownProps {
    reportType: string;
}

const RecoverableEnergyBreakdown = ({ reportType }: RecoverableEnergyBreakdownProps) => {
    const { powerLossBreakdown } = useRouteLoaderData('inspection') as InspectionLoaderData;

    const {
        bosFinancialLoss,
        majorFinancialLoss,
        majorAndMinorFinancialLoss,
        moduleBasicRecoverableLoss,
        totalFinancialLoss,
    } = calculateFinancialLoss({
        powerLossBreakdown,
    });

    const costBreakdown = useMemo<any>(
        () => ({
            string_level: {
                total: bosFinancialLoss,
                bos: bosFinancialLoss,
                majorAndMinor: 0,
            },
            module_basic: {
                total: moduleBasicRecoverableLoss,
                bos: bosFinancialLoss,
                major: majorFinancialLoss,
            },
            module_advanced: {
                total: totalFinancialLoss,
                bos: bosFinancialLoss,
                majorAndMinor: majorAndMinorFinancialLoss,
            },
        }),
        [
            bosFinancialLoss,
            majorAndMinorFinancialLoss,
            majorFinancialLoss,
            moduleBasicRecoverableLoss,
            totalFinancialLoss,
        ],
    );

    return (
        <Stack spacing={0}>
            <Title m="0.25rem 0" order={5} color="gray.9">
                {costBreakdown[reportType].total}
            </Title>
            <Group position="center">
                <BreakdownArrowsIcon />
            </Group>

            <Flex justify="space-evenly">
                <Stack spacing={0}>
                    <Text variant="body1" fw={500} color="gray.9">
                        {costBreakdown[reportType].bos}
                    </Text>
                    <Text pt="0.2rem" variant="body3light" color="gray.4">
                        BOS
                    </Text>
                </Stack>
                <Stack spacing={0}>
                    <Text variant="body1" fw={500} color="gray.9">
                        {reportType === InspectionUpgradeOptions.ModuleBasic
                            ? costBreakdown[reportType].major
                            : costBreakdown[reportType].majorAndMinor}
                    </Text>
                    <Text pt="0.2rem" variant="body3light" color="gray.4">
                        {reportType === InspectionUpgradeOptions.ModuleBasic ? 'Major Module' : 'Major & Minor Module'}
                    </Text>
                </Stack>
            </Flex>
        </Stack>
    );
};

export default RecoverableEnergyBreakdown;
