import { useCallback, useState } from 'react';

import { ResponsiveContainer, Sankey, Tooltip } from 'recharts';

import { getConnectedNodes } from 'lib/helpers/sankey';

import CustomTooltip from './CustomTooltip';
import SankeyLink from './SankeyLink';
import SankeyNode from './SankeyNode';

const RechartsSankey = ({
    sankeyData,
    onLinkClick,
    totalModules,
    siteCapacity,
    powerLossMapping,
    powerLossPerModule,
    aggregation,
    linkData,
}: any) => {
    const [backwardLinks, setBackwardLinks] = useState<any[]>([]);
    const [hoveredLinkIndex, setHoveredLinkIndex] = useState<number | null>(null);

    const handleLinkClick = useCallback(() => {
        const { currentSourceNode, connectedSourceNode } = getConnectedNodes(sankeyData, hoveredLinkIndex);

        onLinkClick({
            ...currentSourceNode?.filter,
            ...connectedSourceNode?.filter,
        });
    }, [hoveredLinkIndex, onLinkClick, sankeyData]);

    return (
        <ResponsiveContainer minWidth={500} minHeight={500}>
            <Sankey
                width={960}
                height={600}
                data={sankeyData}
                node={<SankeyNode containerWidth={500} setBackwardLinks={setBackwardLinks} />}
                margin={{
                    right: 20,
                    left: 20,
                    bottom: 10,
                    top: 20,
                }}
                link={
                    <SankeyLink
                        backwardLinks={backwardLinks}
                        hoveredLinkIndex={hoveredLinkIndex}
                        setHoveredLinkIndex={setHoveredLinkIndex}
                        onLinkClick={handleLinkClick}
                    />
                }
                nodePadding={15}
            >
                <Tooltip
                    content={
                        <CustomTooltip
                            aggregation={aggregation}
                            linkData={linkData}
                            totalModules={totalModules}
                            siteCapacity={siteCapacity}
                            powerLossMapping={powerLossMapping}
                            powerLossPerModule={powerLossPerModule}
                        />
                    }
                    animationDuration={0}
                />
            </Sankey>
        </ResponsiveContainer>
    );
};

export default RechartsSankey;
