import { useCallback } from 'react';

import { useAtomValue } from 'jotai';
import fileDownload from 'js-file-download';
import { jsonToCSV } from 'react-papaparse';
import { useRouteLoaderData } from 'react-router-dom';

import { SiteLayoutLoaderData } from 'components/Layout/SiteLayout';
import { ANOMALY_TABLE_TYPES } from 'lib/constants';
import { fileFormatDate } from 'lib/helpers';
import { tabularTableCSVDataAtom } from 'state/inspections/anomalyTabularTable';

import { useDifferentialMode } from './useDifferentialMode';
import { usePivotTable } from './usePivotTable';

export const useDownloadCSV = () => {
    const tabularTableCsvData = useAtomValue(tabularTableCSVDataAtom);
    const { view: pivotView, pivotDownloadData, hasFilters } = usePivotTable();
    const { active: isDifferentialModeActive, currentInspectionDate, previousInspectionDate } = useDifferentialMode();
    const { name: siteName } = useRouteLoaderData('site-details') as SiteLayoutLoaderData;

    const buildFilename = useCallback(
        (tableType: ANOMALY_TABLE_TYPES) => {
            let filename;

            if (tableType === ANOMALY_TABLE_TYPES.TABULAR) {
                filename = hasFilters ? `Tabular-Filtered.csv` : `Tabular.csv`;
            } else {
                filename = `Pivot-${pivotView.replaceAll(' ', '')}.csv`;
            }

            filename = isDifferentialModeActive
                ? `Differentials-${fileFormatDate(previousInspectionDate)}-${fileFormatDate(
                      currentInspectionDate,
                  )}-${filename}`
                : `${fileFormatDate(currentInspectionDate)}-${filename}`;
            filename = `${siteName.replaceAll(' ', '')}-${filename}`;

            return filename;
        },
        [hasFilters, isDifferentialModeActive, siteName, pivotView, previousInspectionDate, currentInspectionDate],
    );

    const download = useCallback(
        (tableType: ANOMALY_TABLE_TYPES) => {
            const csvString =
                tableType === ANOMALY_TABLE_TYPES.TABULAR ? jsonToCSV(tabularTableCsvData) : pivotDownloadData;
            const filename = buildFilename(tableType);

            fileDownload(new Blob([csvString], { type: 'text/csv' }), filename);
        },
        [tabularTableCsvData, pivotDownloadData, buildFilename],
    );

    return { download };
};
