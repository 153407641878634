import { useState } from 'react';

import styled from '@emotion/styled';
import { Group, Stack, Text } from '@mantine/core';
import { redirect, useLoaderData, useSearchParams } from 'react-router-dom';

import { NoInspectionsFoundIcon } from 'components/common/Icons/NoInspectionsFound';
import Table from 'components/common/Table';
import { PageHeader } from 'components/Header/PageHeader';
import { PageLayout } from 'components/Layout/PageLayout';
import { SITE_INSPECTIONS_SORT_MAP } from 'lib/constants';
import { ROUTES } from 'lib/constants/routes';
import { getCookie } from 'lib/cookies';
import { getDateLocale, getUrlParams } from 'lib/helpers';
import { GET_SITE } from 'lib/queries';
import { client } from 'lib/urqlClient';

import { tableColumns } from './InspectionTableCols';
import SiteInformation from './SiteInformation';
import { SiteMap } from '../SiteMap';
import {
    generateFaultTypeContent,
    generateLinkTypeContent,
    generateTextTypeContent,
    getInspectionPagePath,
} from '../SitesTableCols';
import { SiteDetailsLoader, SiteInspectionsRequestData, SortedBy } from '../types';

const SiteDetails = () => {
    const { sort: sortedBy, siteData, reports } = useLoaderData() as SiteDetailsLoader;
    const [, setParams] = useSearchParams();
    const [sort, setSort] = useState({
        id: sortedBy[0],
        desc: sortedBy[1] === 'desc',
    });

    const handleSort = (key: string, direction: SortedBy['direction']) => {
        setSort({ id: key, desc: direction === 'desc_nulls_last' });
        setParams({ sort: `${key}, ${direction}` });
    };

    return (
        <>
            <PageHeader title={siteData.name} backTo={ROUTES.sites.index} />
            <PageLayout>
                <StyledSiteDetailsContainer grow={true}>
                    <StyledSiteMapAndInfoContainer>
                        <SiteMap siteOutline={siteData.geometry} />
                        <SiteInformation site={siteData} />
                    </StyledSiteMapAndInfoContainer>

                    {reports.length ? (
                        <StyledInspectionContainer>
                            <Table data={reports} columns={tableColumns} handleSort={handleSort} sort={sort} />
                        </StyledInspectionContainer>
                    ) : (
                        <StyledInspectionContainer justify="center">
                            <NoInspectionsFoundIcon />
                            <Text align="center">This site does not have any inspections</Text>
                        </StyledInspectionContainer>
                    )}
                </StyledSiteDetailsContainer>
            </PageLayout>
        </>
    );
};

const StyledInspectionContainer = styled(Stack)({
    flex: '50%',
    height: '100%',
});

const StyledSiteDetailsContainer = styled(Group)({
    width: '100%',
    height: '100%',
});

const StyledSiteMapAndInfoContainer = styled(Stack)({
    borderRight: `1px solid var(--color-grey-100)`,
    paddingRight: '1.5rem',
    flex: '50%',
    height: '100%',
});

export default SiteDetails;

export const siteDetailsLoader = async ({
    request,
    params,
}: {
    request: Request;
    params: { [key: string]: string | undefined };
}) => {
    const uriParams = getUrlParams(request.url, ['sort']);
    const sort = (uriParams?.sort as string)?.split(', ') || ['publishDate', 'desc'];
    const workspaceId = getCookie('helio-workspace')?.id;

    const res = await client
        .query(GET_SITE, {
            siteId: params.siteId,
            workspaceId,
            orderBy: (() => ({
                [SITE_INSPECTIONS_SORT_MAP[sort[0] as keyof typeof SITE_INSPECTIONS_SORT_MAP]]: sort[1],
            }))(),
        })
        .toPromise();

    const siteInfo = res.data.sites[0];

    if (!siteInfo) {
        return redirect('/404');
    }

    const reports = res.data.inspections.map((report: SiteInspectionsRequestData) => {
        const bos = {
            value: report.bos?.toFixed(2) ?? null,
            grade: report.bosGrade,
        };
        const major = {
            value: report.major?.toFixed(2) ?? null,
            grade: report.majorGrade,
        };
        const minor = {
            value: report.minor?.toFixed(2) ?? null,
            grade: report.minorGrade,
        };

        const inspectionPagePath = getInspectionPagePath(
            report.inspectionID,
            report.reportType,
            report.superAnalysisTasksAggregate.aggregate.count,
        );

        return {
            publishDate: generateLinkTypeContent(inspectionPagePath, getDateLocale(report.publishDate)),
            inspectionType: generateTextTypeContent(report.reportType),
            powerLoss: generateTextTypeContent(report.powerLoss?.toString()),
            bos: generateFaultTypeContent('bos', bos, report.reportType),
            major: generateFaultTypeContent('major', major, report.reportType),
            minor: generateFaultTypeContent('minor', minor, report.reportType),
        };
    });

    return { siteData: siteInfo, reports, sort };
};
