import { useCallback } from 'react';

import { Box, Group, SegmentedControl, Select } from '@mantine/core';

import { usePivotTable } from 'lib/hooks';

const TABLE_PIVOT_AGGREGATIONS = [
    { value: 'sum', label: 'Anomalies' },
    { value: 'power_loss', label: 'Power Loss' },
];

const TableAggregator = () => {
    const { aggregator, setAggregator } = usePivotTable();

    const toggleAggregator = useCallback(
        (targetAggregationType: string) => {
            if (aggregator === 'PowerLossSum' || aggregator === 'PowerLossPercentage') {
                setAggregator(targetAggregationType === 'Modules' ? 'PowerLossSum' : 'PowerLossPercentage');
            } else {
                setAggregator(targetAggregationType);
            }
        },
        [aggregator, setAggregator],
    );

    const handlePivotAggregationChange = useCallback(
        (value: string) => {
            if (value === 'sum') {
                if (aggregator === 'PowerLossPercentage') {
                    setAggregator('Percentage');
                } else if (aggregator === 'PowerLossSum') {
                    setAggregator('Modules');
                }
            } else {
                if (aggregator === 'Modules') {
                    setAggregator('PowerLossSum');
                } else if (aggregator === 'Percentage') {
                    setAggregator('PowerLossPercentage');
                }
            }
        },
        [toggleAggregator],
    );

    return (
        <Group noWrap spacing="1rem">
            <Box w="14.375rem">
                <SegmentedControl
                    fullWidth
                    fz="1rem"
                    data={[
                        { label: 'Sum', value: 'Modules' },
                        { label: 'Percentage', value: 'Percentage' },
                    ]}
                    onChange={(value) => toggleAggregator(value)}
                />
            </Box>
            <Box w="12.5rem">
                <Select
                    data={TABLE_PIVOT_AGGREGATIONS}
                    onChange={handlePivotAggregationChange}
                    placeholder="Select an aggregation"
                    value={aggregator === 'Modules' || aggregator === 'Percentage' ? 'sum' : 'power_loss'}
                />
            </Box>
        </Group>
    );
};

export default TableAggregator;
