import { findKey } from 'lodash';

import { TRACKER_TYPES } from 'lib/constants';

export const getOrdinal = (n: number) => {
    let ord = `th`;

    if (n % 10 === 1 && n % 100 !== 11) {
        ord = `st`;
    } else if (n % 10 === 2 && n % 100 !== 12) {
        ord = `nd`;
    } else if (n % 10 === 3 && n % 100 !== 13) {
        ord = `rd`;
    }

    return ord;
};

const getModuleFromBottomOfRow = (char: string | undefined) => {
    if (char === '' || !char) {
        return null;
    }

    return char.charCodeAt(0) - 64;
};

const buildPositionIdLocationInstructions = (
    trackerType: keyof typeof TRACKER_TYPES,
    steps: string[],
    moduleFromBottomOfRow: number | null,
) => {
    const instructions = steps.map((position, idx) => ({
        value: Number(position),
        direction: TRACKER_TYPES[trackerType].directions[idx],
    }));

    if (moduleFromBottomOfRow) {
        instructions.push({
            value: Number(moduleFromBottomOfRow),
            direction: TRACKER_TYPES[trackerType].directions[TRACKER_TYPES[trackerType].directions.length - 1],
        });
    }

    return instructions;
};

export const getPositionIdLocationInstructions = (positionId: string) => {
    const trackerType = findKey(TRACKER_TYPES, (type) => positionId.match(type.regex)) as keyof typeof TRACKER_TYPES;

    const matches = positionId.match(TRACKER_TYPES[trackerType].regex);

    if (!matches) {
        return [];
    }

    switch (trackerType) {
        case 'GROUND_MOUNT_VISIBLE': {
            // # Example: 4[3-1W,5-60E]A
            const rowFromSouthEnd = matches[1];
            const setFromWest = matches[2];
            const columnInSetFromWest = matches[3];
            const setFromEast = matches[4];
            const columnInSetFromEast = matches[5];
            const moduleFromBottomOfRow = getModuleFromBottomOfRow(matches[6]);

            return buildPositionIdLocationInstructions(
                trackerType,
                [rowFromSouthEnd, setFromWest, columnInSetFromWest, setFromEast, columnInSetFromEast],
                moduleFromBottomOfRow,
            );
        }

        case 'GROUND_MOUNT_NON_VISIBLE': {
            // # Example: 2[1W,31E]D
            const rowFromSouthEnd = matches[1];
            const moduleFromWest = matches[2];
            const moduleFromEast = matches[3];
            const moduleFromBottomOfRow = getModuleFromBottomOfRow(matches[4]);

            return buildPositionIdLocationInstructions(
                trackerType,
                [rowFromSouthEnd, moduleFromWest, moduleFromEast],
                moduleFromBottomOfRow,
            );
        }

        case 'TRACKER_N_S_VISIBLE': {
            // # Example: 18[4-6S,1-5N]A
            const rowFromWesternEnd = matches[1];
            const setFromSouth = matches[2];
            const moduleInSetFromSouth = matches[3];
            const setFromNorth = matches[4];
            const moduleInSetFromNorth = matches[5];
            const moduleFromBottomOfRow = getModuleFromBottomOfRow(matches[6]);

            return buildPositionIdLocationInstructions(
                trackerType,
                [rowFromWesternEnd, setFromSouth, moduleInSetFromSouth, setFromNorth, moduleInSetFromNorth],
                moduleFromBottomOfRow,
            );
        }

        case 'TRACKER_N_S_NON_VISIBLE': {
            // # Example: 19[2N]C & 6[4S]A
            const instructions = [];
            const row = matches[1];
            const module = matches[2];
            const moduleFromBottomOfRow = getModuleFromBottomOfRow(matches[3]);
            const isNorth = positionId.includes('N');

            instructions.push({
                value: Number(row),
                direction: TRACKER_TYPES.TRACKER_N_S_NON_VISIBLE.directions[0],
            });

            instructions.push({
                value: Number(module),
                direction: isNorth
                    ? TRACKER_TYPES.TRACKER_N_S_NON_VISIBLE.directions[1]
                    : TRACKER_TYPES.TRACKER_N_S_NON_VISIBLE.directions[2],
            });

            moduleFromBottomOfRow &&
                instructions.push({
                    value: Number(moduleFromBottomOfRow),
                    direction: TRACKER_TYPES.TRACKER_N_S_NON_VISIBLE.directions[3],
                });

            return instructions;
        }

        case 'TRACKER_VISIBLE': {
            // # Example: 2[7-4S,3-1N]B
            const rowFromWesternEnd = matches[1];
            const setFromSouth = matches[2];
            const moduleInSetFromSouth = matches[3];
            const setFromNorth = matches[4];
            const moduleInSetFromNorth = matches[5];
            const moduleFromBottomOfRow = getModuleFromBottomOfRow(matches[6]);

            return buildPositionIdLocationInstructions(
                trackerType,
                [rowFromWesternEnd, setFromSouth, moduleInSetFromSouth, setFromNorth, moduleInSetFromNorth],
                moduleFromBottomOfRow,
            );
        }

        case 'TRACKER_NON_VISIBLE': {
            // # Example: 1[4S,8N]B
            const row = matches[1];
            const moduleSouth = matches[2].replace(/\D/g, '');
            const moduleNorth = matches[3].replace(/\D/g, '');
            const moduleFromBottomOfRow = getModuleFromBottomOfRow(matches[4]);

            return buildPositionIdLocationInstructions(
                trackerType,
                [row, moduleSouth, moduleNorth],
                moduleFromBottomOfRow,
            );
        }

        case 'CARPORT_OR_ROOFTOP': {
            // # Example: 2,6
            const row = matches[1];
            const module = matches[2];

            return buildPositionIdLocationInstructions(trackerType, [row, module], null);
        }

        default:
            return [];
    }
};
