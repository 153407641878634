import { Pill } from '@dronebase/shared-ui-core';
import { ColumnFilter, ColumnFiltersState } from '@tanstack/react-table';
import { useAtomValue, useSetAtom } from 'jotai';

import {
    tabularTableFilterAtoms,
    translatedTabularTableFiltersAtom,
    useRemoveTabularTableFilter,
} from 'state/inspections/anomaliesTabularTableFilters';

export const FilterPill = ({
    filter,
    setTabularFilters,
}: {
    filter: ColumnFilter;
    setTabularFilters: React.Dispatch<React.SetStateAction<ColumnFiltersState>>;
}) => {
    const tabularFilters = useAtomValue(translatedTabularTableFiltersAtom);

    const { atom, defaultAtom } = tabularTableFilterAtoms[filter.id as keyof typeof tabularTableFilterAtoms];
    const setAtom = useSetAtom(atom);
    const defaultValue = useAtomValue(defaultAtom);
    const applyRemovalOfFilter = useRemoveTabularTableFilter(filter.id);

    const onRemoveFilter = (filterId: string) => {
        const newFilters = tabularFilters.filter((filter) => filter.id !== filterId);

        setTabularFilters(newFilters);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setAtom(defaultValue);
        applyRemovalOfFilter();
    };

    return (
        <Pill key={filter.id} title={filter.id} withRemoveButton h="1.6rem" onRemove={() => onRemoveFilter(filter.id)}>
            {(filter.value as string[]).join(', ')}
        </Pill>
    );
};
