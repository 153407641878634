import styled from '@emotion/styled';
import { Group } from '@mantine/core';
import { CellContext } from '@tanstack/react-table';
import { Link } from 'react-router-dom';

import { GradeIcon } from 'components/common/Icons/GradeIcon';
import { colors, FAULT_TYPE_INSPECTION_TYPE_MAP, GRADE_COLORS, REPORT_TYPES } from 'lib/constants';
import { ROUTES } from 'lib/constants/routes';
import { formatRowHeader } from 'lib/helpers';

import { FaultType, FaultTypeData } from './types';

export const tableColumns = [
    {
        header: formatRowHeader('Name'),
        accessorKey: 'siteName',
        cell: (info: CellContext<string, unknown>) => info.getValue(),
    },
    {
        header: formatRowHeader('Last Inspection Date'),
        accessorKey: 'lastInspectionDate',
        cell: (info: CellContext<string, unknown>) => info.getValue(),
    },
    {
        header: formatRowHeader('Last Inspection Type'),
        accessorKey: 'inspectionType',
        cell: (info: CellContext<string, unknown>) => info.getValue(),
    },
    {
        header: formatRowHeader('Power Loss (KW)'),
        accessorKey: 'powerLoss',
        cell: (info: CellContext<string, unknown>) => info.getValue(),
    },
    {
        header: formatRowHeader('BoS (%)'),
        accessorKey: 'bos',
        cell: (info: CellContext<string, unknown>) => info.getValue(),
    },
    {
        header: formatRowHeader('Major Module (%)'),
        accessorKey: 'major',
        cell: (info: CellContext<string, unknown>) => info.getValue(),
    },
    {
        header: formatRowHeader('Minor Module (%)'),
        accessorKey: 'minor',
        cell: (info: CellContext<string, unknown>) => info.getValue(),
    },
    {
        header: formatRowHeader('Capacity (MW DC)'),
        accessorKey: 'capacity',
        cell: (info: CellContext<string, unknown>) => info.getValue(),
    },
    {
        header: formatRowHeader('City'),
        accessorKey: 'city',
        cell: (info: CellContext<string, unknown>) => info.getValue(),
    },
    {
        header: formatRowHeader('State'),
        accessorKey: 'state',
        cell: (info: CellContext<string, unknown>) => info.getValue(),
    },
    {
        header: formatRowHeader('Country'),
        accessorKey: 'country',
        cell: (info: CellContext<string, unknown>) => info.getValue(),
    },
];

export const generateLinkTypeContent = (href: string, label: string) =>
    label ? <StyledLink to={href}>{label}</StyledLink> : <StyledSpan>N/A</StyledSpan>;

export const generateFaultTypeContent = (faultType: FaultType, data: FaultTypeData, inspectionType: string | null) => {
    if (data?.value && inspectionType && !FAULT_TYPE_INSPECTION_TYPE_MAP[faultType].includes(inspectionType)) {
        return (
            <Group spacing="0.4rem" align="center">
                {data.grade && <GradeIcon fill={GRADE_COLORS[data.grade]} />}
                <StyledSpan>{data.value}</StyledSpan>
            </Group>
        );
    }

    return <StyledSpan>N/A</StyledSpan>;
};

export const generateTextTypeContent = (label?: string | null) => <StyledSpan>{label || 'N/A'}</StyledSpan>;

export const getInspectionPagePath = (
    inspectionId: string | null,
    reportType: string | null,
    superAnalysisTasksAggregateCount: number,
) => {
    if (reportType === REPORT_TYPES.DIAGNOSTIC_REPORT) {
        return `${inspectionId}/${ROUTES.sites.inspection.diagnostic}`;
    }

    if (reportType === REPORT_TYPES.GLASS_CRACK) {
        return `${inspectionId}/${ROUTES.sites.inspection.map.index}`;
    }

    if (superAnalysisTasksAggregateCount >= 1) {
        return `${inspectionId}/${ROUTES.sites.inspection.findings}`;
    } else {
        return `${inspectionId}/${ROUTES.sites.inspection.files}`;
    }
};

const StyledSpan = styled.span({
    color: colors.secondaryGray,
    fontSize: '14px',
    letterSpacing: '0.01em',
});

const StyledLink = styled(Link)({
    fontSize: '0.875rem',
});
