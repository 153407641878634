import { Group, Text } from '@mantine/core';

import { LegacyAnomaly } from 'components/inspections/types';
import { getImageUrlsFromLegacyAnomaly } from 'lib/helpers';

import AnomalyThumbnail from './AnomalyThumbnail';

const LegacyAnomalyImages = ({
    anomaly,
    showSampleImages = false,
}: {
    anomaly: LegacyAnomaly;
    showSampleImages: boolean;
}) => {
    const { irUrl, vizUrl, isIrSample, isVizSample } = getImageUrlsFromLegacyAnomaly(anomaly, showSampleImages);

    if (!irUrl && !vizUrl && !isIrSample && !isVizSample) {
        return (
            <Text variant="body2light" c="gray.0">
                -
            </Text>
        );
    }

    return (
        <Group>
            {irUrl && <AnomalyThumbnail src={irUrl} alt="IR" isSampleImage={isIrSample} />}
            {vizUrl && <AnomalyThumbnail src={vizUrl} alt="RGB" isSampleImage={isVizSample} />}
        </Group>
    );
};

export default LegacyAnomalyImages;
