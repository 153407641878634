import { Layers } from '@dronebase/shared-ui-icons';
import styled from '@emotion/styled';
import { ActionIcon, ActionIconProps, Box, Checkbox, Flex, Menu, Text, Tooltip } from '@mantine/core';
import { useAtomValue } from 'jotai';

import { MIN_COMBINER_OUTLINE_ZOOM, MapLayerFilterOptions, mapLayerFilterOptions } from 'lib/constants';
import { useAnomalyMapFilters } from 'lib/hooks';
import { useAssetLabels } from 'lib/hooks/map/useAssetLabels';
import { viewStateAtom } from 'state/inspections/anomaliesMap';

const MapLayerFilters = () => {
    const { disabledFilterKeys } = useAssetLabels();
    const { filter, toggleFilter } = useAnomalyMapFilters();
    const { zoom } = useAtomValue(viewStateAtom);

    return (
        <StyledMapLayerFilterContainer>
            <Menu closeOnItemClick={false} position="top-start" width="15.625rem">
                <Menu.Target>
                    <StyledActionIcon h="3.125rem" w="3.125rem">
                        <Layers />
                    </StyledActionIcon>
                </Menu.Target>
                <Menu.Dropdown p="1rem" style={{ minWidth: '18rem' }}>
                    <Menu.Label fz="1rem" fw={400} c="var(--color-grey-50)">
                        Display Layers {filter.layerFilters.length > 0 && ` (${filter.layerFilters.length})`}
                    </Menu.Label>
                    <Menu.Divider />
                    {Object.keys(mapLayerFilterOptions).map((filterKey) => {
                        const option = mapLayerFilterOptions[filterKey as MapLayerFilterOptions];
                        const disabled = disabledFilterKeys.includes(filterKey);

                        return (
                            <Tooltip
                                key={filterKey}
                                disabled={filterKey !== 'combiner' || zoom > MIN_COMBINER_OUTLINE_ZOOM}
                                label="Zoom in to display combiner boundaries"
                            >
                                <Menu.Item key={filterKey} disabled={disabled} color="gray.6">
                                    <Checkbox
                                        disabled={disabled}
                                        onChange={() => toggleFilter('layerFilters', option.databaseValue)}
                                        checked={filter.layerFilters.includes(option.databaseValue)}
                                        size="md"
                                        label={
                                            <Flex align="start" gap="0.75rem" mt="-0.1rem" pr="0.5rem">
                                                {option.color && <ColorLegend color={option.color} />}
                                                <Text
                                                    variant="body2"
                                                    color={disabled ? 'gray.3' : 'gray.1'}
                                                    pt="0.3rem"
                                                >
                                                    {option.name}
                                                </Text>
                                            </Flex>
                                        }
                                    />
                                </Menu.Item>
                            </Tooltip>
                        );
                    })}
                </Menu.Dropdown>
            </Menu>
        </StyledMapLayerFilterContainer>
    );
};

export default MapLayerFilters;

const ColorLegend = ({ color }: { color: number[] }) => (
    <Box>
        <svg width="27" height="7" viewBox="0 0 27 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M24 0.5H3C1.61929 0.5 0.5 1.61929 0.5 3V4C0.5 5.38071 1.61929 6.5 3 6.5H24C25.3807 6.5 26.5 5.38071 26.5 4V3C26.5 1.61929 25.3807 0.5 24 0.5Z"
                fill={`rgb(${color.join(',')})`}
                stroke="#6A7380"
            />
        </svg>
    </Box>
);

const StyledActionIcon = styled(ActionIcon)<ActionIconProps>({
    'backgroundColor': 'var(--color-grey-700)',
    'color': 'var(--color-grey-300)',
    ':hover': {
        backgroundColor: 'var(--color-grey-900)',
        color: 'var(--color-grey-300)',
    },
    '&[data-expanded="true"]': {
        'color': 'var(--color-purple-300)',
        ':hover': {
            backgroundColor: 'var(--color-grey-900)',
            color: 'var(--color-purple-300)',
        },
    },
    ':active': {
        color: 'var(--color-purple-300)',
    },
});

const StyledMapLayerFilterContainer = styled.div({
    zIndex: '3',
    position: 'absolute',
    left: '1.875rem',
    bottom: '1.875rem',
    display: 'inline-block',
});
