import 'mapbox-gl/dist/mapbox-gl.css';
import './App.css';
import './colors.css';
import React, { useEffect } from 'react';

import * as Sentry from '@sentry/react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { Provider as URQLProvider } from 'urql';

import { ENVIRONMENT } from 'lib/constants';
import { SwitchWorkspaceModalProvider } from 'lib/context/SwitchWorkspaceModalContext';
import { client } from 'lib/urqlClient';
import { MantineUIProvider } from 'providers/MantineProvider';
import '@dronebase/shared-ui-styles/index.css';
import Routes from 'Routes';

const shouldUseSentry = ENVIRONMENT === 'production';

const SentryRoutes = shouldUseSentry ? Sentry.withSentryReactRouterV6Routing(Routes) : Routes;

if (shouldUseSentry) {
    Sentry.init({
        dsn: 'https://c69991e2e4b5763e4ee985681ed66719@o324603.ingest.sentry.io/4506587186135054',
        environment: 'production',
        integrations: [
            // TODO - BrowserTracing & Sentry.reactRouterV6Instrumentation are now depricated
            // Need to update this to the new way of doing things
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                    useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes,
                ),
            }),
        ],
        tracesSampleRate: 0.5,
    });
}

function App(): React.ReactElement {
    return (
        <MantineUIProvider>
            <URQLProvider value={client}>
                <SwitchWorkspaceModalProvider>
                    <SentryRoutes />
                </SwitchWorkspaceModalProvider>
            </URQLProvider>
        </MantineUIProvider>
    );
}

export default App;
