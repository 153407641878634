import { ChangeEvent, FormEvent, useState } from 'react';

import { notifications } from '@dronebase/shared-ui-core';
import styled from '@emotion/styled';
import { Box, Button, Text, TextInput, Title } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

import { LinkButton } from 'components/common/Buttons/LinkButton';
import { Config } from 'config';
import { ROUTES } from 'lib/constants/routes';
import { debounce, validateEmail } from 'lib/helpers';
import { fetchData } from 'lib/hooks';

const AUTH_API_URL = Config.get('AUTH_API_URL');

const ResetPassword = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState<string>('');
    const [formErrors, setFormErrors] = useState<string>();
    const [submitting, setSubmitting] = useState<boolean>(false);

    const setFormError = debounce((value: string) => {
        setFormErrors(validateEmail(value));
    }, 1000);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        setEmail(value);
        setFormError(value);
    };

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();

        try {
            setSubmitting(true);
            const { error } = await fetchData({
                url: `${AUTH_API_URL}/password-reset`,
                method: 'POST',
                body: {
                    email,
                },
            });

            setSubmitting(false);

            if (error) {
                throw error;
            }

            localStorage.setItem('passwordResetSuccess', 'true');
            navigate(ROUTES.auth.reset_password_success);
        } catch (error) {
            notifications.error({
                title: 'Something went wrong. Please try again later.',
                withCloseButton: true,
                withIcon: true,
            });
        }
    };

    return (
        <>
            <Title order={6} ta="center">
                Reset password
            </Title>
            <Box>
                <Text variant="body2" ta="center" color="gray.4">
                    Please enter your email address.
                </Text>
                <Text variant="body2" ta="center" color="gray.4">
                    We will send you an email to reset your password.
                </Text>
            </Box>
            <StyledForm onSubmit={onSubmit} noValidate>
                <TextInput
                    label="Email"
                    aria-label="Email"
                    type="email"
                    name="email"
                    autoComplete="email"
                    value={email}
                    error={formErrors}
                    onChange={handleChange}
                    autoFocus
                />
                <Button type="submit" variant="filled" size="xl" disabled={submitting || !!formErrors || !email}>
                    Send Reset Link
                </Button>
                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                    <LinkButton size="lg" to="/auth">
                        Back to Log in Screen
                    </LinkButton>
                </Box>
            </StyledForm>
        </>
    );
};

export default ResetPassword;

const StyledForm = styled('form')({
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
});
