import styled from '@emotion/styled';

export const CalculationContainer = styled.div({
    margin: '10px auto',
    marginVertical: 20,
});

export const VariablesContainer = styled.div({
    display: 'grid',
    gap: '5px',
});

export const VariableReferenceContainer = styled.div({
    display: 'grid',
    gridTemplateColumns: '110px auto',
    gridGap: '1rem',
});
