import { useEffect, useState } from 'react';

import { AUTH_API_URL } from 'lib/constants';
import { processPermissions, processPermissionsV2, sortWorkspaces } from 'lib/helpers';
import { Workspace, WorkspacePermissionV1, WorkspacePermissionV2 } from 'lib/types';

import { fetchData } from './fetchData';

const useWorkspaceDetails = () => {
    const [allWorkspaces, setAllWorkspaces] = useState<Workspace[] | null>(null);
    const [allPermissions, setAllPermissions] = useState<WorkspacePermissionV1[]>([]);
    const [allPermissionsV2, setAllPermissionsV2] = useState<WorkspacePermissionV2[]>([]);

    useEffect(() => {
        const fetchUserDetails = async () => {
            const { error, data } = await fetchData({
                url: `${AUTH_API_URL}/detail`,
                method: 'GET',
                body: {},
            });

            if (!error) {
                const fetchedWorkspaces = data[0].workspace_users
                    ?.filter(({ workspace }: { workspace: Workspace }) => workspace)
                    .map(({ workspace }: { workspace: Workspace }) => workspace)
                    .sort(sortWorkspaces);
                const userPermissions = processPermissions(data[0].workspace_users);
                const userPermissionsV2 = processPermissionsV2(data[0].workspace_users);

                setAllWorkspaces(fetchedWorkspaces);
                setAllPermissions(userPermissions);
                setAllPermissionsV2(userPermissionsV2);
            }
        };

        fetchUserDetails();
    }, []);

    return {
        allWorkspaces,
        allPermissions,
        allPermissionsV2,
    };
};

export default useWorkspaceDetails;
