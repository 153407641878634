const ExpandIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.33333 1.93333C2.56013 1.93333 1.93333 2.56013 1.93333 3.33333V12.6667C1.93333 13.4399 2.56013 14.0667 3.33333 14.0667H12.6667C13.4399 14.0667 14.0667 13.4399 14.0667 12.6667V10.1778C14.0667 9.92004 14.2756 9.71111 14.5333 9.71111C14.7911 9.71111 15 9.92004 15 10.1778V12.6667C15 13.9553 13.9553 15 12.6667 15H3.33333C2.04467 15 1 13.9553 1 12.6667V3.33333C1 2.04467 2.04467 1 3.33333 1H5.82222C6.07996 1 6.28889 1.20893 6.28889 1.46667C6.28889 1.7244 6.07996 1.93333 5.82222 1.93333H3.33333ZM9.71111 1.46667C9.71111 1.20893 9.92004 1 10.1778 1H14.5333C14.7911 1 15 1.20893 15 1.46667V5.82222C15 6.07996 14.7911 6.28889 14.5333 6.28889C14.2756 6.28889 14.0667 6.07996 14.0667 5.82222V2.5933L8.32998 8.32998C8.14774 8.51223 7.85226 8.51223 7.67002 8.32998C7.48777 8.14774 7.48777 7.85226 7.67002 7.67002L13.4067 1.93333H10.1778C9.92004 1.93333 9.71111 1.7244 9.71111 1.46667Z"
            fill="currentColor"
        />
    </svg>
);

export default ExpandIcon;
