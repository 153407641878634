import React from 'react';

import { IconProps } from './icon_definition';

export const Microsoft = ({ width = 16, height = 16, ...rest }: IconProps) => (
        <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <g clipPath="url(#clip0_4879_92676)">
                <path d="M0 0H16V16H0V0Z" fill="#F3F3F3" />
                <path d="M0.695709 0.695312H7.65223V7.65183H0.695709V0.695312Z" fill="#F35325" />
                <path d="M8.34766 0.695312H15.3042V7.65183H8.34766V0.695312Z" fill="#81BC06" />
                <path d="M0.695648 8.34782H7.65217V15.3043H0.695648V8.34782Z" fill="#05A6F0" />
                <path d="M8.34784 8.34782H15.3044V15.3043H8.34784V8.34782Z" fill="#FFBA08" />
            </g>
            <defs>
                <clipPath id="clip0_4879_92676">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
