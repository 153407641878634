import { atom } from 'jotai';

import { ActiveFilter, ActiveFilterDescriptor } from './defineActiveFilter';

export const createActiveFiltersAtom = <
    // Here, literally any FilterDescriptor would work, and we can't really narrow it down
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    T extends readonly ActiveFilterDescriptor<any>[],
>(
    filters: T,
) =>
    atom((get) =>
        filters.reduce<ActiveFilter[]>(
            (accumulator, { atom, isActive, stringifyValue, label }: ActiveFilterDescriptor<unknown>) => {
                const value = get(atom);

                if (!isActive(value)) {
                    return accumulator;
                }

                return [
                    ...accumulator,
                    {
                        atom,
                        label,
                        stringifiedValue: stringifyValue(value),
                    } satisfies ActiveFilter,
                ];
            },
            [],
        ),
    );
