import { useMemo } from 'react';

import styled from '@emotion/styled';
import { Button, Group, Stack, Text } from '@mantine/core';

import { useAnomalyMapData, useSiteGeometry } from 'lib/hooks/map';

interface MapControlsProps {
    zoomToHighlightEntireSite: () => void;
    zoomToHighlightedModules: () => void;
}

const MapControls: React.FC<MapControlsProps> = ({ zoomToHighlightEntireSite, zoomToHighlightedModules }) => {
    const { siteGeometry } = useSiteGeometry();
    const { filteredMapAnomalies, moduleCount } = useAnomalyMapData();

    const totalFilteredAffectedModules = useMemo(
        () => Object.values(filteredMapAnomalies).length,
        [filteredMapAnomalies],
    );

    const percentageModules = useMemo(() => {
        if (totalFilteredAffectedModules === 0) {
            return '0%';
        }

        const percentage = 100 * (totalFilteredAffectedModules / moduleCount);

        return percentage < 0.01 ? '< 0.01%' : `${percentage.toFixed(2)}%`;
    }, [totalFilteredAffectedModules, moduleCount]);

    return (
        <StyledMapControlContainer>
            <StyledButtonContainer bg="#181A1CCC" p="0.25rem 1rem">
                {/* //TODO: the number of anomalies is no longer the length, because mulitple 
                            anomalies could be represented by one entry. We have to flatten the anomalyUuids
                            array in each value. */}
                {moduleCount > 0 && (
                    <>
                        <Button
                            onClick={zoomToHighlightEntireSite}
                            disabled={!siteGeometry}
                            variant="outline"
                            size="md"
                        >
                            Entire site
                        </Button>
                        <Stack spacing={0}>
                            <Text fz="0.875rem" color="var(--color-grey-25)">
                                {totalFilteredAffectedModules.toLocaleString()} Modules
                            </Text>
                            <Text fz="0.75rem" color="var(--color-grey-200)">
                                {percentageModules}
                            </Text>
                        </Stack>
                        <Button
                            onClick={zoomToHighlightedModules}
                            disabled={totalFilteredAffectedModules === 0}
                            variant="outline"
                            size="md"
                        >
                            Selected
                        </Button>
                    </>
                )}
            </StyledButtonContainer>
        </StyledMapControlContainer>
    );
};

const StyledMapControlContainer = styled.div({
    position: 'absolute',
    textAlign: 'center',
    bottom: '1.875rem',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    zIndex: 3,
});

const StyledButtonContainer = styled(Group)({
    borderRadius: '9px',
});

export default MapControls;
