import { Text } from '@mantine/core';

interface ModalTitleProps {
    title: string;
}

const ModalTitle = ({ title }: ModalTitleProps) => (
    <Text color="var(--color-grey-900)" fz="1.25rem" align="left" style={{ paddingBottom: '0.5rem' }}>
        {title}
    </Text>
);

export default ModalTitle;
