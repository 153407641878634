const NoWorkspaceIcon = () => (
    <svg width="340" height="245" viewBox="0 0 340 245" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M247 39.4722C250.866 39.4722 254 43.7379 254 48.9999C254 54.262 250.866 58.5277 247 58.5277H207C210.866 58.5277 214 62.7935 214 68.0555C214 73.3175 210.866 77.5833 207 77.5833H229C232.866 77.5833 236 81.849 236 87.1111C236 92.3731 232.866 96.6388 229 96.6388H218.826C213.952 96.6388 210 100.905 210 106.167C210 109.675 212 112.851 216 115.694C219.866 115.694 223 119.96 223 125.222C223 130.484 219.866 134.75 216 134.75H133C129.134 134.75 126 130.484 126 125.222C126 119.96 129.134 115.694 133 115.694H94C90.134 115.694 87 111.429 87 106.167C87 100.905 90.134 96.6388 94 96.6388H134C137.866 96.6388 141 92.3731 141 87.1111C141 81.849 137.866 77.5833 134 77.5833H109C105.134 77.5833 102 73.3175 102 68.0555C102 62.7935 105.134 58.5277 109 58.5277H149C145.134 58.5277 142 54.262 142 48.9999C142 43.7379 145.134 39.4722 149 39.4722H247ZM247 77.5833C250.866 77.5833 254 81.849 254 87.1111C254 92.3731 250.866 96.6388 247 96.6388C243.134 96.6388 240 92.3731 240 87.1111C240 81.849 243.134 77.5833 247 77.5833Z"
            fill="#F8F9FA"
        />
        <path
            d="M213.887 172.861H101.819C96.6612 172.861 92.48 168.68 92.48 163.522V163.381V156.271V81.6184V81.4777C92.48 76.3199 96.6612 72.1387 101.819 72.1387H213.887H214.394C219.919 72.1387 224.397 76.6197 224.394 82.1451L224.393 82.8034V156.271V165.751C224.393 169.678 221.21 172.861 217.283 172.861H213.887Z"
            fill="white"
        />
        <path
            d="M92.48 156.271C92.48 128.623 92.48 111.101 92.48 81.6184V81.4777C92.48 76.3199 96.6612 72.1387 101.819 72.1387V72.1387H213.887H214.394C219.919 72.1387 224.397 76.6197 224.394 82.1451L224.393 82.8034V165.751V165.751C224.393 169.678 221.21 172.861 217.283 172.861H213.887H101.819V172.861C96.6612 172.861 92.48 168.68 92.48 163.522L92.48 163.381C92.48 160.933 92.48 158.567 92.48 156.271ZM92.48 156.271H224.4"
            stroke="#9C91FC"
            strokeWidth="2.5"
            strokeLinejoin="round"
        />
        <path
            d="M213.438 77.5835H176.633C176.236 77.5835 175.878 77.8163 175.715 78.1783C174.01 81.9659 164.344 102.946 153.981 115.135C140.772 130.67 114.186 144.142 103.688 149.078C102.705 149.54 103.055 151.083 104.142 151.083H214.6C216.257 151.083 217.6 149.74 217.6 148.083V82.2774V80.5835C217.6 78.9266 216.257 77.5835 214.6 77.5835H213.438Z"
            fill="#EFECFF"
        />
        <path
            d="M144.84 172.861L142.46 188.871M173.4 172.861L175.78 188.871M136.439 198.722H181.801C184.25 198.722 185.667 195.945 184.23 193.962L181.438 190.11C180.874 189.332 179.971 188.871 179.009 188.871H139.231C138.269 188.871 137.366 189.332 136.802 190.11L134.01 193.962C132.573 195.945 133.99 198.722 136.439 198.722Z"
            stroke="#9C91FC"
            strokeWidth="2.5"
            strokeLinejoin="round"
        />
        <ellipse cx="158.44" cy="165.375" rx="3.4" ry="3.40277" stroke="#9C91FC" strokeWidth="2.5" />
        <path
            d="M38.0801 168.778H45.8022M54.8114 168.778H93.4221M236.701 168.778L286.31 168.778M294.841 168.778H301.92"
            stroke="#9C91FC"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <rect x="198.56" y="144.278" width="51.68" height="51.7222" rx="25.84" fill="white" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M198.434 170.139C198.434 155.787 210.059 144.152 224.4 144.152C231.286 144.152 237.891 146.89 242.76 151.763C247.63 156.637 250.366 163.247 250.366 170.139C250.366 184.491 238.74 196.126 224.4 196.126C210.059 196.126 198.434 184.491 198.434 170.139ZM234.243 176.763C234.243 175.909 233.902 175.09 233.296 174.488L229.136 170.357L233.296 166.225C234.551 164.969 234.551 162.933 233.296 161.677C232.041 160.421 230.007 160.421 228.752 161.677L224.623 165.841L220.495 161.677C219.24 160.421 217.205 160.421 215.95 161.677C214.695 162.933 214.695 164.969 215.95 166.225L220.111 170.357L215.95 174.488C215.344 175.09 215.004 175.909 215.004 176.763C215.004 177.617 215.344 178.435 215.95 179.037C216.551 179.643 217.369 179.984 218.223 179.984C219.076 179.984 219.894 179.643 220.495 179.037L224.623 174.873L228.752 179.037C229.353 179.643 230.17 179.984 231.024 179.984C231.877 179.984 232.695 179.643 233.296 179.037C233.902 178.435 234.243 177.617 234.243 176.763Z"
            fill="#9C91FC"
        />
    </svg>
);

export default NoWorkspaceIcon;
