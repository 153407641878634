export const BreakdownArrowsIcon = () => (
    <svg width="109" height="52" viewBox="0 0 109 52" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.5 39.6306L8.81008 34.9002L3.05838 34.3988L5.5 39.6306ZM54.5 17.1306C43.3964 17.1306 31.9917 17.1276 22.9566 19.3349C18.4326 20.4401 14.4643 22.1075 11.4132 24.6368C8.35167 27.1748 6.23521 30.5634 5.399 35.0589L6.38214 35.2418C7.17536 30.9774 9.16908 27.7961 12.0514 25.4067C14.9443 23.0085 18.7536 21.391 23.1939 20.3063C32.0874 18.1336 43.3579 18.1306 54.5 18.1306V17.1306Z"
            fill="#7D7D87"
        />
        <line x1="55" y1="7.6814" x2="55" y2="17.6814" stroke="#7D7D87" />
        <path
            d="M103.5 39.6306L100.19 34.9002L105.942 34.3988L103.5 39.6306ZM54.5 17.1306C65.6036 17.1306 77.0083 17.1276 86.0434 19.3349C90.5674 20.4401 94.5357 22.1075 97.5868 24.6368C100.648 27.1748 102.765 30.5634 103.601 35.0589L102.618 35.2418C101.825 30.9774 99.8309 27.7961 96.9486 25.4067C94.0557 23.0085 90.2464 21.391 85.8061 20.3063C76.9126 18.1336 65.6421 18.1306 54.5 18.1306V17.1306Z"
            fill="#7D7D87"
        />
    </svg>
);
