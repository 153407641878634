import styled from '@emotion/styled';

export const StyledTable = styled.table({
    borderCollapse: 'collapse',
});

export const StyledRow = styled.tr({
    'borderBottom': 'solid',
    'borderWidth': `0.1rem`,
    'borderColor': 'var(--color-grey-100)',
    ':last-child': {
        borderBottom: 'none',
    },
});

export const StyledOrdinalCell = styled.td({
    padding: '0.5rem',
    borderRight: 'solid',
    borderWidth: `0.1rem`,
    borderColor: 'var(--color-grey-100)',
});

export const StyledPositionDescriptionCell = styled.td({
    padding: '0.5rem',
});
