import { Badge, Text } from '@mantine/core';

interface PriorityBadgeProps {
    priorityNumber: string;
}

const PriorityBadge = ({ priorityNumber }: PriorityBadgeProps) => (
    <Badge
        p="0rem 0.25rem"
        radius="0.25rem"
        color={`anomalyPriority.${priorityNumber}`}
        variant="filled"
        style={{ cursor: 'pointer' }}
    >
        <Text c="white">{priorityNumber}</Text>
    </Badge>
);

export default PriorityBadge;
