import { ReactNode } from 'react';

import styled from '@emotion/styled';
import { Text, MantineColor, Divider as MantineDivider, MantineNumberSize } from '@mantine/core';

interface HorizontalDividerWithLabelProps {
    label: ReactNode;
    size?: MantineNumberSize;
    labelColor: string;
    color: MantineColor;
}

export const HorizontalDividerWithLabel = ({
    label,
    size = 'sm',
    color,
    labelColor,
}: HorizontalDividerWithLabelProps) => (
    <BoxContainer>
        <StyledMantineDivider size={size} color={color} />
        <Text color={labelColor} fz="1rem">
            {label}
        </Text>
        <StyledMantineDivider size={size} color={color} />
    </BoxContainer>
);

const BoxContainer = styled.div({
    display: 'flex',
    width: '100%',
    gap: '1rem',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
});

const StyledMantineDivider = styled(MantineDivider)({
    width: '100%',
    alignSelf: 'center',
});
