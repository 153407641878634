import { ReactNode } from 'react';

import { Stack, Text } from '@mantine/core';

export const SingleDetail = ({
    label,
    value,
    children,
}: {
    label: string;
    value?: string | undefined | null;
    children?: ReactNode | null;
}) => {
    const formattedValue = value ?? '-';

    return (
        <Stack spacing="0.125rem">
            <Text variant="caption1" c="gray.3">
                {label}
            </Text>
            {children ?? (
                <Text variant="body2light" c="gray.0">
                    {formattedValue}
                </Text>
            )}
        </Stack>
    );
};
