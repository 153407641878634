import { CellContext } from '@tanstack/react-table';

import { formatRowHeader } from 'lib/helpers';

export const tableColumns = [
    {
        header: formatRowHeader('Inspection Date'),
        accessorKey: 'publishDate',
        cell: (info: CellContext<string, unknown>) => info.getValue(),
    },
    {
        header: formatRowHeader('Inspection Type'),
        accessorKey: 'inspectionType',
        cell: (info: CellContext<string, unknown>) => info.getValue(),
    },
    {
        header: formatRowHeader('Power Loss (KW)'),
        accessorKey: 'powerLoss',
        cell: (info: CellContext<string, unknown>) => info.getValue(),
    },
    {
        header: formatRowHeader('BoS (%)'),
        accessorKey: 'bos',
        cell: (info: CellContext<string, unknown>) => info.getValue(),
    },
    {
        header: formatRowHeader('Major Module (%)'),
        accessorKey: 'major',
        cell: (info: CellContext<string, unknown>) => info.getValue(),
    },
    {
        header: formatRowHeader('Minor Module (%)'),
        accessorKey: 'minor',
        cell: (info: CellContext<string, unknown>) => info.getValue(),
    },
];
