const NoSitesFoundIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="167" height="88" viewBox="0 0 167 88" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M160 0.5C163.866 0.5 167 3.63401 167 7.5C167 11.366 163.866 14.5 160 14.5H120C123.866 14.5 127 17.634 127 21.5C127 25.366 123.866 28.5 120 28.5H142C145.866 28.5 149 31.634 149 35.5C149 39.366 145.866 42.5 142 42.5H131.826C126.952 42.5 123 45.634 123 49.5C123 52.0773 125 54.4107 129 56.5C132.866 56.5 136 59.634 136 63.5C136 67.366 132.866 70.5 129 70.5H46C42.134 70.5 39 67.366 39 63.5C39 59.634 42.134 56.5 46 56.5H7C3.13401 56.5 0 53.366 0 49.5C0 45.634 3.13401 42.5 7 42.5H47C50.866 42.5 54 39.366 54 35.5C54 31.634 50.866 28.5 47 28.5H22C18.134 28.5 15 25.366 15 21.5C15 17.634 18.134 14.5 22 14.5H62C58.134 14.5 55 11.366 55 7.5C55 3.63401 58.134 0.5 62 0.5H160ZM160 28.5C163.866 28.5 167 31.634 167 35.5C167 39.366 163.866 42.5 160 42.5C156.134 42.5 153 39.366 153 35.5C153 31.634 156.134 28.5 160 28.5Z"
            fill="#F0F3F7"
        />
        <path
            d="M73.5 68.5C92.0015 68.5 107 53.5015 107 35C107 16.4985 92.0015 1.5 73.5 1.5C54.9985 1.5 40 16.4985 40 35C40 53.5015 54.9985 68.5 73.5 68.5Z"
            fill="#F8F9FA"
            stroke="#9C91FC"
            strokeWidth="2.5"
        />
        <path
            d="M73.75 61.25C88.2475 61.25 100 49.4975 100 35C100 20.5025 88.2475 8.75 73.75 8.75C59.2525 8.75 47.5 20.5025 47.5 35C47.5 49.4975 59.2525 61.25 73.75 61.25Z"
            fill="#F8F9FA"
            stroke="#9C91FC"
            strokeWidth="2.5"
        />
        <path
            d="M101.884 60.6161C101.396 60.128 100.604 60.128 100.116 60.6161C99.628 61.1043 99.628 61.8957 100.116 62.3839L101.884 60.6161ZM100.116 62.3839L106.116 68.3839L107.884 66.6161L101.884 60.6161L100.116 62.3839Z"
            fill="#9C91FC"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M106.03 66.5304C104.138 68.4231 104.138 71.4918 106.03 73.3845L117.116 84.4698C119.008 86.3625 122.077 86.3625 123.97 84.4698C125.863 82.5771 125.863 79.5084 123.97 77.6157L112.885 66.5304C110.992 64.6377 107.923 64.6377 106.03 66.5304Z"
            fill="#CDC7FE"
            fillOpacity="0.3"
            stroke="#9C91FC"
            strokeWidth="2.5"
        />
        <path d="M111 68.5L122 79.5" stroke="white" strokeWidth="2.5" strokeLinecap="round" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M67 23.5C67 35.098 76.4021 44.5 88 44.5C90.2784 44.5 92.472 44.1372 94.5262 43.4662C91.1732 51.7871 83.0225 57.6607 73.5 57.6607C60.9849 57.6607 50.8394 47.5152 50.8394 35C50.8394 23.6596 59.1697 14.2648 70.045 12.6011C68.113 15.7793 67 19.5097 67 23.5Z"
            fill="#CDC7FE"
            fillOpacity="0.3"
        />
        <path
            d="M74 16.5C72.7266 16.5 71.4825 16.6253 70.2793 16.8642M66.6447 17.9761C59.8039 20.8508 55 27.6144 55 35.5"
            stroke="#9C91FC"
            strokeWidth="2.5"
            strokeLinecap="round"
        />
        <path
            d="M127.176 35.2773H119M133.5 27.5H116.324H133.5ZM140.5 27.5H138.279H140.5Z"
            stroke="#9C91FC"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M37.1758 57.2773H29M32.5 48.5H15.3242H32.5ZM9.5 48.5H5.27881H9.5Z"
            stroke="#9C91FC"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default NoSitesFoundIcon;
