export const ImageFileIcon = () => (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.75 5.13333C8.75 4.23127 9.46821 3.5 10.3542 3.5H32.8125C33.238 3.5 33.646 3.67208 33.9468 3.97839L46.7802 17.0451C47.081 17.3514 47.25 17.7668 47.25 18.2V50.8667C47.25 51.7687 46.5318 52.5 45.6458 52.5H10.3542C9.46821 52.5 8.75 51.7687 8.75 50.8667V5.13333ZM11.9583 6.76667V49.2333H44.0417V19.8333H32.8125C31.9265 19.8333 31.2083 19.1021 31.2083 18.2V6.76667H11.9583ZM34.4167 9.07655L41.773 16.5667H34.4167V9.07655Z"
            fill="#8D95A2"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.7761 41.2865C23.9413 41.3692 23.1135 41.0732 22.5203 40.48L21.431 39.3907L19.2161 42.7001H37.0549L31.3756 33.2348L26.83 40.0533C26.3646 40.7513 25.6109 41.2039 24.7761 41.2865ZM24.5002 38.5001L30.2731 29.8409C30.8432 28.9857 32.1096 29.0159 32.6384 29.8972L40.728 43.3799C41.2879 44.313 40.6158 45.5001 39.5275 45.5001H16.5945C15.4751 45.5001 14.8084 44.2517 15.431 43.3215L20.0521 36.4167C20.5429 35.6834 21.5816 35.5815 22.2055 36.2055L24.5002 38.5001Z"
            fill="#8D95A2"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.295 25.6972C25.295 28.0168 23.4146 29.8972 21.095 29.8972C18.7754 29.8972 16.895 28.0168 16.895 25.6972C16.895 23.3776 18.7754 21.4972 21.095 21.4972C23.4146 21.4972 25.295 23.3776 25.295 25.6972ZM21.095 27.4472C22.0615 27.4472 22.845 26.6637 22.845 25.6972C22.845 24.7307 22.0615 23.9472 21.095 23.9472C20.1285 23.9472 19.345 24.7307 19.345 25.6972C19.345 26.6637 20.1285 27.4472 21.095 27.4472Z"
            fill="#8D95A2"
        />
    </svg>
);
