import { MoreHor } from '@dronebase/shared-ui-icons';
import styled from '@emotion/styled';
import { ActionIcon, ActionIconProps, Badge, BadgeProps, Button, Menu, Text } from '@mantine/core';

import { Config } from 'config';
import { User } from 'lib/context/UsersContext';
import { formatRowHeader, isInvitationExpired, shortFormatDate } from 'lib/helpers';
import { WorkspaceInvite } from 'lib/types';

export const DEFAULT_PERMISSION = 'reports.viewer';
export const USER_MANAGEMENT_ROLE = String(Config.get('USER_MANAGEMENT_ROLE'));
export const PORTAL_MIGRATION_USER_EMAIL = 'portal-migration-agent@heliolytics.com';

export const INITIAL_SORT_COLUMN = 'createdAt';

export const USERS_SORT_MAP = {
    email: 'email',
    createdAt: 'created_at',
    updatedAt: 'updated_at',
};

export const APPS_TO_EXCLUDE = ['reports', 'file_management', 'differentials'];

export const generateUsersTableColumns = (actionHandler: (user: User) => void) => [
    {
        header: formatRowHeader('Email'),
        accessorKey: 'email',
        cell: (info: any) => info.getValue(),
    },
    {
        header: formatRowHeader('Invitation Accepted'),
        accessorKey: 'createdAt',
        cell: (info: any) => shortFormatDate(info.getValue()),
    },
    {
        header: formatRowHeader('Last Modified'),
        accessorKey: 'lastModified',
        cell: (info: any) => shortFormatDate(info.getValue()),
    },
    {
        header: formatRowHeader('Permissions'),
        accessorKey: 'permissions',
        enableSorting: false,
        cell: (info: any) =>
            info
                .getValue()
                .filter(({ permission }: { permission: any }) => permission.description)
                .map(({ permission }: { permission: any }) => permission.description)
                .join(', '),
    },
    {
        header: formatRowHeader('Action'),
        accessorKey: 'uuid',
        enableSorting: false,
        cell: (info: any) => (
            <Button
                styles={{
                    inner: { width: 'max-content' }, // TODO: Remove styles + p prop when text + link buttons are fixed in shared-ui
                }}
                variant="text"
                p="0"
                size="md"
                onClick={() => actionHandler(info.row.original)}
            >
                Edit
            </Button>
        ),
    },
];

export const generateViewInvitesColumns = (
    reinviteUser: (invite: WorkspaceInvite) => void,
    setInviteToCancel: (inviteUUID: string | null) => void,
) => [
    {
        header: formatRowHeader('Email'),
        accessorKey: 'email',
        enableSorting: false,
        cell: (info: any) => info.getValue(),
    },
    {
        header: formatRowHeader('Invitation Sent'),
        accessorKey: 'updatedAt',
        enableSorting: false,
        cell: (info: any) => shortFormatDate(info.getValue()),
    },
    {
        header: formatRowHeader('Expiry'),
        accessorKey: 'expiresAt',
        enableSorting: false,
        cell: (info: any) => shortFormatDate(info.getValue()),
    },
    {
        header: formatRowHeader('Invitation Status'),
        accessorKey: 'invitationStatus',
        enableSorting: false,
        cell: (info: any) =>
            isInvitationExpired(info?.row?.original?.expiresAt) ? (
                <StyledBadge color="red.2">
                    <Text color="red.6">Expired</Text>
                </StyledBadge>
            ) : (
                <StyledBadge color="gray.5">
                    {info?.row?.original?.emailSent ? 'Pending Acceptance' : 'Pending Invite'}
                </StyledBadge>
            ),
    },
    {
        header: formatRowHeader('Permissions'),
        accessorKey: 'permissions',
        enableSorting: false,
        cell: (info: any) =>
            info
                .getValue()
                .map((permissions: any) => permissions.description)
                .join(', '),
    },
    {
        header: formatRowHeader('Actions'),
        accessorKey: 'emailSent',
        enableSorting: false,
        cell: (info: any) => (
            <Menu position="bottom-end" width="10rem" shadow="2px 2px 20px 0px #0000001A">
                <Menu.Target>
                    <StyledActionIcon>
                        <MoreHor />
                    </StyledActionIcon>
                </Menu.Target>
                <Menu.Dropdown
                    style={{
                        border: 0,
                    }}
                >
                    <Menu.Item onClick={() => reinviteUser(info?.row?.original)}>
                        {info.getValue() ? 'Re-invite' : 'Invite'}
                    </Menu.Item>
                    <Menu.Item onClick={() => setInviteToCancel(info?.row?.original?.uuid)}>Remove</Menu.Item>
                </Menu.Dropdown>
            </Menu>
        ),
    },
];

const StyledBadge = styled(Badge)<BadgeProps>({
    textTransform: 'none',
});

const StyledActionIcon = styled(ActionIcon)<ActionIconProps>({
    'position': 'relative',
    'zIndex': 1,
    '&[data-expanded="true"]': {
        color: 'var(--color-purple-300)',
        backgroundColor: 'var(--color-purple-100)',
    },
    ':active': {
        color: 'var(--color-purple-300)',
    },
});
