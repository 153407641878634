import { getCookie } from 'lib/cookies';

import { getAuthToken } from './auth';
import { errorHandlers } from './common';

export const fetchData = async ({
    url,
    method = 'GET',
    permission = null,
}: {
    url: string;
    method: string;
    permission: string | null;
}) => {
    const token = await getAuthToken();
    const options: {
        method: string;
        headers: any;
    } = {
        method,
        headers: {
            'Content-Type': 'application/json',
            'allow-control-allow-origin': '*',
            'x-user-token': token,
            'x-user-workspace': getCookie('helio-workspace')?.id,
        },
    };

    if (permission) {
        options.headers['x-user-permission'] = permission;
    }

    try {
        const response = await fetch(url, options);

        if (!response.ok) {
            return {
                error: await errorHandlers(response),
                data: null,
            };
        }

        const data = await response.json();

        return { error: null, data };
    } catch (error) {
        return { error: await errorHandlers(error), data: null };
    }
};
