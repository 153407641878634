import { bbox, centroid, Geometry, getCoords, midpoint, point } from '@turf/turf';

import { AnomalyMapFilters, GeoJSONGeometry, LegacyAnomaly } from 'components/inspections/types';
import { AnomalyDefinitionMap, MapAnomalies } from 'components/Sites/types';
import {
    AssetTypes,
    DIFFERENTIAL_MODES,
    DISPLAY_MAP_LAYER_KEYS,
    EMPTY_SIGNAL_OR_MODIFIER,
    LOCAL_STORAGE_KEYS,
    MAP_LAYER_ASSET_TYPES,
} from 'lib/constants';

import { buildAOIObservations } from './inspections';
import { getAsJSON } from './localStorage';

export const getSavedFilters = (
    defaultFilteredAssetLayers: DISPLAY_MAP_LAYER_KEYS[],
    filterKey: string,
): AnomalyMapFilters => {
    const savedFilters = getAsJSON(LOCAL_STORAGE_KEYS.ANOMALY_MAP_FILTERS) ?? {};
    const appliedFilters = filterKey ? savedFilters[filterKey] ?? {} : {};

    return {
        rgb: [],
        ir: [],
        observationIds: [],
        statusIds: [],
        remediationFilters: [],
        layerFilters: defaultFilteredAssetLayers,
        ...appliedFilters,
    };
};

export const getSavedLayerFilters = (
    defaultFilteredAssetLayers: DISPLAY_MAP_LAYER_KEYS[],
    filterKey: string,
): AnomalyMapFilters => {
    const savedFilters = getSavedFilters(defaultFilteredAssetLayers, filterKey);

    return {
        rgb: [],
        ir: [],
        observationIds: [],
        statusIds: [],
        remediationFilters: [],
        layerFilters: savedFilters.layerFilters,
    };
};

export const generateLayerFilter =
    (minimapViewId: string, minimapLayers: string[]) =>
    ({ layer, viewport }: any) => {
        const shouldDrawInMinimap = minimapLayers.filter((layerName) => layer.id.startsWith(layerName)).length > 0;

        if (viewport.id === minimapViewId) {
            return shouldDrawInMinimap;
        } else {
            return !shouldDrawInMinimap;
        }
    };

export const getCenterPointFromGeometry = (geometry: GeoJSONGeometry): number[] => {
    if (['Polygon', 'MultiPolygon'].includes(geometry.type)) {
        const coords: number[] = getCoords(centroid(geometry));

        return coords;
    } else {
        return [0, 0];
    }
};

export const getRightBorderMidpoint = (geometry: GeoJSON.Geometry): number[] => {
    const feature = { type: 'Feature', properties: {}, geometry };

    const [, minLat, maxLng, maxLat] = bbox(feature);

    const topRight = point([maxLng, maxLat]);
    const bottomRight = point([maxLng, minLat]);

    const rightEdgeMidpoint = midpoint(topRight, bottomRight);

    return rightEdgeMidpoint.geometry.coordinates;
};

// Deckgl helpers
export const getBBoxFromGeometry = (geometry: Geometry) => {
    const boundingBox = bbox(geometry);
    const formatBounds = [
        [boundingBox[0], boundingBox[1]],
        [boundingBox[2], boundingBox[3]],
    ];

    return formatBounds;
};

export const getHandleViewStateChange =
    (setViewState: any, minimapHeight = 0.2, minimapWidth = 0.2) =>
    ({ viewState }: any) => {
        setViewState((prev: any) => ({
            main: viewState,
            minimap: {
                ...prev.minimap,
                longitude: viewState.longitude,
                latitude: viewState.latitude,
                // Since we don't directly manipulate the minimap viewstate,
                // we need to manage the height and width manually
                height: viewState.height * minimapHeight,
                width: viewState.width * minimapWidth,
            },
        }));
    };

export const getImageUrlsFromLegacyAnomaly = (
    legacyAnomaly: LegacyAnomaly,
    showSampleImages: boolean,
): { irUrl: string | undefined; vizUrl: string | undefined; isIrSample: boolean; isVizSample: boolean } => {
    let irUrl = legacyAnomaly?.images.find((image) => image.imageType === 'ir')?.url;
    let vizUrl = legacyAnomaly?.images.find((image) => image.imageType === 'viz')?.url;
    let isIrSample = false;
    let isVizSample = false;

    if (!irUrl && legacyAnomaly?.irSampleImages.length && showSampleImages) {
        irUrl = legacyAnomaly?.irSampleImages[0].url;
        isIrSample = true;
    }

    if (!vizUrl && legacyAnomaly?.vizSampleImages.length && showSampleImages) {
        vizUrl = legacyAnomaly?.vizSampleImages[0].url;
        isVizSample = true;
    }

    return { irUrl, vizUrl, isIrSample, isVizSample };
};

export const transformRawAnomalies = (
    rawAnomalies: any,
    reportObservationsAOIs: any,
    anomalyDefinitions: AnomalyDefinitionMap,
): MapAnomalies => {
    const rawMapAnomalies: MapAnomalies = {};

    if (rawAnomalies) {
        const aoiObservations = reportObservationsAOIs ? buildAOIObservations(reportObservationsAOIs) : {};

        // An aoi can effect multiple regions
        rawAnomalies.aois.forEach((aoi: any) => {
            const geometry = aoi?.geometry;

            aoi.aoiAssetRegions.forEach((aoiAssetRegion: any) => {
                const { assetRegionUUID } = aoiAssetRegion;

                aoi.anomalyEntries.forEach((anomalyEntry: any) => {
                    const definitionUUID = anomalyEntry.definition_uuid;
                    const {
                        species,
                        priority,
                        globalDefinition: { nameExternal },
                    } = anomalyDefinitions[definitionUUID];
                    const { uuid: speciesUuid, irSignal, irModifier, rgbSignal, rgbModifier } = species;
                    const statusId = String(anomalyEntry.statusId);
                    const irFilter = buildSignalFilter(irSignal, irModifier);
                    const rgbFilter = buildSignalFilter(rgbSignal, rgbModifier);
                    const observationUUIDs = aoiObservations[aoi.uuid];

                    if (rawMapAnomalies[assetRegionUUID]) {
                        const newAnomaly = {
                            uuid: speciesUuid,
                            name: nameExternal,
                            irFilter,
                            rgbFilter,
                            priority: priority.toString(),
                            statusId,
                        };

                        if (rawMapAnomalies[assetRegionUUID]?.species[0].priority > newAnomaly.priority) {
                            rawMapAnomalies[assetRegionUUID].species.unshift(newAnomaly);
                        } else {
                            rawMapAnomalies[assetRegionUUID].species.push(newAnomaly);
                        }

                        if (observationUUIDs) {
                            rawMapAnomalies[assetRegionUUID].observationIds.push(...observationUUIDs);
                        }
                    } else {
                        rawMapAnomalies[assetRegionUUID] = {
                            observationIds: observationUUIDs ?? [],
                            position: getCenterPointFromGeometry(geometry),
                            species: [
                                {
                                    uuid: speciesUuid,
                                    name: nameExternal,
                                    irFilter,
                                    rgbFilter,
                                    priority: priority.toString(),
                                    statusId,
                                },
                            ],
                        };
                    }
                });
            });
        });
    }

    return rawMapAnomalies;
};

export const buildSignalFilter = (signal: string, modifier: string) =>
    signal + (modifier === EMPTY_SIGNAL_OR_MODIFIER ? '' : `-${modifier}`);

const filterEntries = (object: any, filterFn: any, transformFn?: any) =>
    Object.entries(object)
        .filter(filterFn)
        .reduce((acc: any, [assetRegionUuid, anomaly]: any) => {
            acc[assetRegionUuid] = transformFn ? transformFn([assetRegionUuid, anomaly]) : anomaly;

            return acc;
        }, {});

const filterByPresent = (current: any, previous: any) =>
    filterEntries(
        current,
        ([assetRegionUuid, currentAnomaly]: any) => haveCommonSpecies(currentAnomaly, previous[assetRegionUuid]),
        ([assetRegionUuid, currentAnomaly]: any) => ({
            ...currentAnomaly,
            species:
                currentAnomaly.species.length > previous[assetRegionUuid]?.species?.length
                    ? filterSpecies(currentAnomaly, previous[assetRegionUuid])
                    : currentAnomaly.species,
        }),
    );

const filterByNew = (current: any, previous: any) =>
    filterEntries(
        current,
        ([assetRegionUuid, currentAnomaly]: any) =>
            !previous[assetRegionUuid] || !haveSameSpecies(currentAnomaly, previous[assetRegionUuid]),
        ([assetRegionUuid, currentAnomaly]: any) => ({
            ...currentAnomaly,
            species: filterSpecies(currentAnomaly, previous[assetRegionUuid]),
        }),
    );

const filterByAbsent = (current: any, previous: any) =>
    filterEntries(
        previous,
        ([assetRegionUuid, previousAnomaly]: any) =>
            !current[assetRegionUuid] || !haveSameSpecies(current[assetRegionUuid], previousAnomaly),
        ([assetRegionUuid, previousAnomaly]: any) => ({
            ...previousAnomaly,
            species: filterSpecies(previousAnomaly, current[assetRegionUuid]),
        }),
    );

export const filterDifferentialAnomalies = (
    currentMapAnomalies: any,
    previousMapAnomalies: any,
    mode: DIFFERENTIAL_MODES,
): any => {
    switch (mode) {
        case DIFFERENTIAL_MODES.PRESENT:
            return filterByPresent(currentMapAnomalies, previousMapAnomalies);

        case DIFFERENTIAL_MODES.CHANGED_CURRENT:
            return filterEntries(
                currentMapAnomalies,
                ([assetRegionUuid, currentAnomaly]: any) =>
                    previousMapAnomalies[assetRegionUuid] &&
                    !haveSameSpecies(currentAnomaly, previousMapAnomalies[assetRegionUuid]),
            );
        case DIFFERENTIAL_MODES.CHANGED_PREVIOUS:
            return filterEntries(
                previousMapAnomalies,
                ([assetRegionUuid, previousAnomaly]: any) =>
                    currentMapAnomalies[assetRegionUuid] &&
                    !haveSameSpecies(currentMapAnomalies[assetRegionUuid], previousAnomaly),
            );
        case DIFFERENTIAL_MODES.ABSENT:
            return filterByAbsent(currentMapAnomalies, previousMapAnomalies);

        case DIFFERENTIAL_MODES.NEW:
            return filterByNew(currentMapAnomalies, previousMapAnomalies);

        case DIFFERENTIAL_MODES.LATEST:
            return currentMapAnomalies;

        case DIFFERENTIAL_MODES.PREVIOUS:
            return previousMapAnomalies;

        default:
            return currentMapAnomalies;
    }
};

const haveCommonSpecies = (anomaly1: any, anomaly2: any) =>
    anomaly1?.species?.some(
        ({ uuid }: any) => anomaly2?.species?.some(({ uuid: speciesUuid }: any) => speciesUuid === uuid),
    );

const haveSameSpecies = (anomaly1: any, anomaly2: any) =>
    anomaly1?.species?.length === anomaly2?.species?.length &&
    anomaly1?.species?.every(
        ({ uuid }: any) => anomaly2?.species?.some(({ uuid: speciesUuid }: any) => speciesUuid === uuid),
    );

const filterSpecies = (anomaly1: any, anomaly2: any) =>
    anomaly1.species.filter(({ uuid }: any) => !anomaly2?.species?.map((specie: any) => specie.uuid).includes(uuid));

export const sortObjectKeysByCount = (object: any) =>
    Object.entries(object)
        .sort(([, a]: any, [, b]: any) => b.count - a.count)
        .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

export const getAssetLabels = (assetData: any, assetType: MAP_LAYER_ASSET_TYPES) => {
    if (assetData?.assetRegions) {
        const labels = assetData?.assetRegions
            .map((data: any) => ({
                uuid: data.uuid,
                position: getCenterPointFromGeometry(data.geometry),
                label:
                    AssetTypes[assetType].nom === 'nomProperties'
                        ? data.nomProperties[`cref_${assetType}_id`]
                        : data.nomStack,
            }))
            .filter((data: any) => data.label);

        return { labels, disabled: !labels.length };
    }
};

export const createAnomalyDefinitionMap = (anomalySummary: any): AnomalyDefinitionMap =>
    anomalySummary?.reduce((definitionMap: AnomalyDefinitionMap, summary: any) => {
        definitionMap[summary.definition.uuid] = summary.definition;

        return definitionMap;
    }, {});
