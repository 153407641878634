import { notifications } from '@dronebase/shared-ui-core';

import { SITE_INSPECTION_API_URL } from 'lib/constants';
import { fetchData } from 'lib/hooks';

export const changeAnomalyStatus = async (
    inspectionId: string,
    anomalyUuid: string,
    anomalyStatusId: number,
    onSuccessCallback: () => void,
) => {
    const { error } = await fetchData({
        url: `${SITE_INSPECTION_API_URL}/inspections/${inspectionId}/anomalies/update`,
        method: 'POST',
        body: {
            updates: [
                {
                    filter: {
                        anomaly_uuid: anomalyUuid,
                    },
                    data: {
                        client_status_id: anomalyStatusId,
                    },
                },
            ],
        },
    });

    if (error) {
        notifications.error({
            title: 'Something went wrong. Please try again later.',
            withCloseButton: true,
            withIcon: true,
        });
    } else {
        notifications.success({
            title: 'Anomaly status has been updated',
            withCloseButton: true,
            withIcon: true,
        });

        onSuccessCallback();
    }
};
