import { useCallback } from 'react';

import { Button, Drawer, Flex, Group } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import { TabularTableFilters } from './TabularTableFilters';

export const TabularTableFiltersDrawer = ({
    apply,
    cancel,
    clear,
}: {
    apply: () => void;
    cancel: () => void;
    clear: () => void;
}) => {
    const [opened, { close, toggle }] = useDisclosure(false);

    const applyFilterChangesAndCloseDrawer = useCallback(() => {
        apply();
        close();
    }, [apply, close]);

    const cancelFilterChangesAndCloseDrawer = useCallback(() => {
        cancel();
        close();
    }, [cancel, close]);

    return (
        <>
            <Button variant="outline" size="md" onClick={toggle}>
                Filters
            </Button>
            <Drawer opened={opened} onClose={close} position="right" size="xl" title="Filters">
                <Flex direction="column" w="100%" justify="space-between">
                    <TabularTableFilters />
                    <Group position="apart" align="baseline">
                        <Group mt="md" position="left" spacing="md" align="baseline">
                            <Button
                                variant="filled"
                                color="purple"
                                size="md"
                                onClick={applyFilterChangesAndCloseDrawer}
                            >
                                Apply
                            </Button>
                            <Button variant="text" color="gray" size="md" onClick={cancelFilterChangesAndCloseDrawer}>
                                Cancel
                            </Button>
                        </Group>
                        <Group mt="md" position="right" align="baseline">
                            <Button variant="text" color="purple" size="md" onClick={clear}>
                                Clear Filters
                            </Button>
                        </Group>
                    </Group>
                </Flex>
            </Drawer>
        </>
    );
};
