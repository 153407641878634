import { useEffect } from 'react';

import { Loader, notifications } from '@dronebase/shared-ui-core';
import { Text } from '@mantine/core';
import { redirect, useLoaderData, useNavigate } from 'react-router-dom';

import { WorkspaceListContainer } from 'components/common/Auth/Workspace.styled';
import { CardContentContainer } from 'components/Layout/AuthPagesLayout';
import { Config } from 'config';
import { ROUTES } from 'lib/constants/routes';
import { clearCookies, getCookie, setCookie } from 'lib/cookies';
import {
    checkLoggedIn,
    fetchData,
    processPermissions,
    processPermissionsV2,
    sortWorkspaces,
    switchWorkspace,
} from 'lib/helpers';
import useFirebaseAuth from 'lib/hooks/useFirebaseAuth';
import { WorkspacePermissionV1, WorkspacePermissionV2, UserDetail, Workspace } from 'lib/types';

import WorkspaceRow from './WorkspaceRow';

const AUTH_API_URL = Config.get('AUTH_API_URL');

const SelectWorkspace = () => {
    const { permissions, workspaces, permissionsV2 } = useLoaderData() as SelectWorkspaceLoader;
    const navigate = useNavigate();
    const { authUser } = useFirebaseAuth();

    useEffect(() => {
        if (workspaces.length === 1 && authUser) {
            switchWorkspace(permissions, permissionsV2, workspaces[0].uuid, workspaces[0].name);
            navigate('/sites');
        }
    }, [authUser, navigate, permissions, permissionsV2, workspaces, workspaces.length]);

    const handleClick = (w: Workspace) => {
        if (authUser) {
            switchWorkspace(permissions, permissionsV2, w.uuid, w.name);
            navigate(ROUTES.sites.index);
        }
    };

    return workspaces.length === 1 ? (
        <Loader />
    ) : (
        <CardContentContainer>
            <Text variant="h6" size="xl" color="gray.9">
                Select Workspace
            </Text>

            <WorkspaceListContainer>
                {workspaces?.map((w) => <WorkspaceRow workspace={w} onClick={() => handleClick(w)} key={w.uuid} />)}
            </WorkspaceListContainer>
        </CardContentContainer>
    );
};

export default SelectWorkspace;

export type SelectWorkspaceLoader = {
    workspaces: Workspace[];
    permissions: WorkspacePermissionV1[];
    permissionsV2: WorkspacePermissionV2[];
};

export const selectWorkspaceLoader = async () => {
    if (!(await checkLoggedIn())) {
        return redirect('/auth');
    }

    const workspaceId = getCookie('helio-workspace')?.id;

    if (workspaceId) {
        return redirect('/sites');
    }

    const { data, error } = await fetchData({ url: `${AUTH_API_URL}/detail`, method: 'GET', permission: null });

    if (error) {
        clearCookies();
        notifications.error({
            title: 'An error occured.',
            withCloseButton: true,
            withIcon: true,
        });

        return redirect('/auth');
    }

    const userData: UserDetail = data[0];
    const workspaceUsers = userData.workspace_users.filter((workspaceUser) => workspaceUser?.workspace);
    const workspaces = workspaceUsers?.map((w) => w.workspace).sort(sortWorkspaces);
    const userPermissions = processPermissions(workspaceUsers);
    const userPermissionsV2 = processPermissionsV2(workspaceUsers);

    setCookie('helio-userId', userData.uuid);

    if (workspaces.length) {
        return {
            workspaces,
            permissions: userPermissions,
            permissionsV2: userPermissionsV2,
        };
    } else {
        return redirect('/auth/404-ws?no_ws=true');
    }
};
