import { useMemo } from 'react';

import { Loader } from '@dronebase/shared-ui-core';
import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';
import { useQuery } from 'urql';

import Error from 'components/Layout/Error';
import { PageLayout } from 'components/Layout/PageLayout';
import { GET_INSPECTION_DOWNLOADS } from 'lib/queries';

import FileItem from './FileItem';

const Files = () => {
    const { inspectionId } = useParams();
    const [{ data, fetching, error }] = useQuery({
        query: GET_INSPECTION_DOWNLOADS,
        variables: {
            superReportTaskUuid: inspectionId,
        },
    });

    const downloadFiles = useMemo(
        () =>
            data?.blobs?.map((file: any) => ({
                name: file.blobIndex.objectName.split('/')[1],
                type: file.blobIndex.objectName.match(/(?:\.([^.]+))?$/)[1],
                url: file.blobIndex.blobInstance.url,
            })),
        [data],
    );

    const filesList = useMemo(
        () => (
            <PageLayout>
                <FileListContainer>
                    {downloadFiles?.map((file: any) => <FileItem key={file.name} file={file} />)}
                </FileListContainer>
            </PageLayout>
        ),
        [downloadFiles],
    );

    if (fetching) {
        return <Loader />;
    }

    if (error) {
        return <Error showRedirect={false} label="Could not fetch files" />;
    }

    if (!downloadFiles || downloadFiles.length === 0) {
        return <Error showRedirect={false} label="No files available" />;
    }

    return filesList;
};

export default Files;

const FileListContainer = styled.div({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    overflowY: 'auto',
    minWidth: 770,
    gap: 20,
    padding: 20,
});
