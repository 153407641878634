import { useMemo } from 'react';

import styled from '@emotion/styled';
import { Group, Stack, StackProps, Text, Title } from '@mantine/core';

import { colors, GRADE_COLORS } from 'lib/constants';

interface ColorGradeContainerProps extends StackProps {
    gradeColor: string;
}

interface PerformanceCardProps {
    title: string;
    percentage: number;
    powerLossKw: number;
    financialLoss: string;
    grade: string;
    disabled?: boolean;
}

const PerformanceCard = ({
    title,
    percentage,
    powerLossKw,
    financialLoss,
    grade,
    disabled = false,
}: PerformanceCardProps) => {
    const faultTypePercentage = useMemo(
        () => (percentage === 0 ? percentage : percentage < 0.01 ? '<0.01' : percentage?.toFixed(2)),
        [percentage],
    );

    const gradeColor = useMemo(() => (disabled ? colors.grayText : GRADE_COLORS[grade]), [disabled, grade]);

    return (
        <Stack spacing="1.25rem">
            <ColorGradeContainer align="center" justify="center" gradeColor={gradeColor || GRADE_COLORS.D}>
                <Title order={6} c="white">
                    {title}
                </Title>

                {disabled ? (
                    <Text c="white">NOT COVERED UNDER THIS INSPECTION SERVICE TIER</Text>
                ) : (
                    <>
                        <Group position="center" align="baseline" spacing="0.5rem">
                            <Title order={5} c="white">{`${faultTypePercentage.toString()}% `}</Title>
                            <Text variant="body1" c="white">
                                of site
                            </Text>
                        </Group>
                        <Title order={5} c="white">{`Grade: ${grade}`}</Title>
                    </>
                )}
            </ColorGradeContainer>

            {!disabled && (
                <Stack align="center" spacing="0.25rem">
                    <Text variant="body1" c="gray.4">
                        {powerLossKw.toFixed(2).toLocaleString()} KW power loss
                    </Text>
                    <Text variant="body1" c="gray.4">
                        {financialLoss} financial loss
                    </Text>
                </Stack>
            )}
        </Stack>
    );
};

export default PerformanceCard;

const ColorGradeContainer = styled(Stack, {
    shouldForwardProp: (prop) => prop !== 'gradeColor',
})<ColorGradeContainerProps>(({ gradeColor }) => ({
    backgroundColor: gradeColor,
    padding: '1rem',
    marginTop: '0.5rem',
    outline: `10px solid ${gradeColor}87`,
    borderRadius: '8px',
    width: '220px',
    height: '150px',
    boxSizing: 'content-box',
    textAlign: 'center',
    gap: '0.75rem',
}));
