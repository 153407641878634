import { useCallback, useEffect, useMemo, useState } from 'react';

import { jsonToCSV, readRemoteFile, readString } from 'react-papaparse';
import { useParams } from 'react-router-dom';
import { useQuery } from 'urql';

import { getCookie } from 'lib/cookies';
import { filterAnomalyAttributes } from 'lib/helpers';
import { extractBlobUrls, getDifferentialRemoteCSVUrl, getRemoteCSVUrl } from 'lib/helpers/differentials';
import createCustomHook from 'lib/helpers/hookFactory';
import { GET_ANOMALY_DATA_CSV_URL } from 'lib/queries';

import { useDifferentialMode } from './useDifferentialMode';

const ValueProvider = () => {
    const workspaceId = getCookie('helio-workspace')?.id;
    const [remoteCsvUrl, setRemoteCsvUrl] = useState<any>();
    const [rawData, setRawData] = useState<any>(null);
    const [rawCsv, setRawCsv] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<any>(null);

    const { inspectionId } = useParams();
    const { active: isDifferentialModeActive } = useDifferentialMode();

    const [{ data, fetching }] = useQuery({
        query: GET_ANOMALY_DATA_CSV_URL,
        variables: {
            searchString: `%${inspectionId}-anomaly-details.csv.gz`,
            altSearchString: `%${inspectionId}-anomaly-details.csv`,
            workspaceId,
        },
    });

    const sanitizeAndFilterResults = useCallback((data: any) => {
        const filteredRowData = data?.filter((row: any) => !(row.length === 1 && row[0] === ''));
        const filteredColumnData = filterAnomalyAttributes(filteredRowData);

        return filteredColumnData;
    }, []);

    useEffect(() => {
        if (data) {
            setLoading(true);
            const urls = extractBlobUrls(data);

            if (isDifferentialModeActive) {
                const differentialCSVUrl = getDifferentialRemoteCSVUrl(urls);

                if (differentialCSVUrl) {
                    setRemoteCsvUrl(differentialCSVUrl);

                    return;
                }
            }

            setRemoteCsvUrl(getRemoteCSVUrl(urls));
        }
    }, [data, isDifferentialModeActive]);

    useEffect(() => {
        if (remoteCsvUrl) {
            setLoading(true);
            setError(null);
            readRemoteFile(remoteCsvUrl, {
                download: true,
                skipEmptyLines: true,
                complete: (results: any) => {
                    const sanitizedJsonData = sanitizeAndFilterResults(results?.data);

                    if (sanitizedJsonData) {
                        setRawCsv(jsonToCSV(sanitizedJsonData));
                    } else {
                        setLoading(false);
                        setError('Could not parse the fetched anomaly data.');
                    }
                },
            });
        } else {
            setLoading(false);
            setError('Could not fetch anomaly data.');
        }
    }, [remoteCsvUrl, sanitizeAndFilterResults]);

    useEffect(() => {
        readString(rawCsv, {
            header: true,
            worker: true,
            complete: (results: any) => {
                setRawData(results.data);
                setLoading(false);
                setError(null);
            },
        });
    }, [rawCsv]);

    const value = useMemo(
        () => ({
            rawData,
            loading: fetching || loading,
            isEmpty: rawData?.length === 0,
            error,
            setRawData,
        }),
        [rawData, loading, fetching, error, setRawData],
    );

    return value;
};

const { Provider: AnomalyDataProvider, useCustomHook: useAnomalyData } = createCustomHook<any>({
    name: 'useAnomalyData',
    callback: ValueProvider,
});

export { AnomalyDataProvider, useAnomalyData };
