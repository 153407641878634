import { PageLayout } from 'components/Layout/PageLayout';

import AnomalyOverview from './AnomalyOverview';
import KeyObservationsTable from './KeyObservationsTable';
import SiteLoss from './SiteLoss';
import SitePerformance from './SitePerformance';
import InspectionContainerStyled from '../shared/InspectionContainer.styled';

const Findings = () => (
    <PageLayout>
        <InspectionContainerStyled>
            <SiteLoss />
            <SitePerformance />
            <KeyObservationsTable />
            <AnomalyOverview />
        </InspectionContainerStyled>
    </PageLayout>
);

export default Findings;
