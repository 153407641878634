import { Group, Stack, Text, Title } from '@mantine/core';

interface SiteLossLabelProps {
    title: string;
    subtitle: string;
    icon?: JSX.Element;
    calculatorIcon?: React.ReactNode;
}

const SiteLossLabel = ({ title, subtitle, icon, calculatorIcon }: SiteLossLabelProps) => (
    <Group align="start" spacing="0.5rem">
        <Stack align="center" spacing="0.5rem">
            <Title order={4} color="purple.6" data-cy="site-financial-loss">
                {title === '$NaN' ? 'N/A' : title}
            </Title>
            <Text color="gray.4" variant="body1">
                {subtitle}
            </Text>
        </Stack>
        <Group align="center" position="left">
            {icon && icon}
            {calculatorIcon && calculatorIcon}
        </Group>
    </Group>
);

export default SiteLossLabel;
