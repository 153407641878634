import styled from '@emotion/styled';
import { Select, Text } from '@mantine/core';

import { useDifferentialMode } from 'lib/hooks';

interface AnomalyStatusSelectionProps {
    currentAnomalyStatus: string;
    onStatusChange: (newStatus: string) => void;
    anomalyStatusOptions: string[];
}

export const AnomalyStatusSelection = ({
    currentAnomalyStatus,
    onStatusChange,
    anomalyStatusOptions,
}: AnomalyStatusSelectionProps) => {
    const { active: isDifferentialModeActive } = useDifferentialMode();

    return isDifferentialModeActive ? (
        <Text variant="body2light" c="gray.0">
            {currentAnomalyStatus}
        </Text>
    ) : (
        <StyledSelect value={currentAnomalyStatus} data={anomalyStatusOptions} onChange={onStatusChange} />
    );
};

export const StyledSelect = styled(Select)({
    display: 'flex',
    flexDirection: 'column',
    width: 'fit-content',
});
