import { useCallback, useMemo } from 'react';

import { useBlocker } from 'react-router-dom';

export const useNavigationBlocker = (fieldHasChanged: boolean) => {
    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) => fieldHasChanged && currentLocation.pathname !== nextLocation.pathname,
    );

    const isNavigationBlocked = useMemo(() => blocker.state === 'blocked', [blocker.state]);

    const proceed = useCallback(() => {
        if (blocker.state === 'blocked') {
            blocker.proceed();
        }
    }, [blocker]);

    const halt = useCallback(() => {
        if (blocker.state === 'blocked') {
            blocker.reset();
        }
    }, [blocker]);

    return { isNavigationBlocked, proceed, halt };
};
