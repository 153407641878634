import { useCallback, useState } from 'react';

import { Search } from '@dronebase/shared-ui-icons';
import styled from '@emotion/styled';
import { Group, Stack, Text, TextInput } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import Table from 'components/common/Table';
import { generateUsersTableColumns } from 'lib/constants';
import { User, useUsersContext } from 'lib/context/UsersContext';
import { useDocumentTitle } from 'lib/hooks/useDocumentTitle';

import FilterPermissionsDropdown from './FilterPermissionsDropdown';
import UserDetails from './UserDetails';

const WorkspaceUsers = () => {
    useDocumentTitle('Zeitview | Users');

    const [userDetailsOpen, { open: openDrawer, close: closeDrawer }] = useDisclosure(false);
    const [selectedUser, setSelectedUser] = useState<User | null>(null);

    const {
        emailFilter,
        setEmailFilter,
        permissionsFilter,
        setPermissionsFilter,
        tableSort,
        setSortedBy,
        sortedFilteredPaginatedUsers,
    } = useUsersContext();

    const actionHandler = useCallback(
        (user: User) => {
            setSelectedUser(user);
            openDrawer();
        },
        [selectedUser, openDrawer],
    );

    return (
        <StyledStack spacing="sm">
            <Group position="left">
                <TextInput
                    type="text"
                    name="email"
                    value={emailFilter}
                    w="21.875rem"
                    placeholder="Search for user by email address"
                    onChange={(event) => setEmailFilter(event.currentTarget.value)}
                    icon={<Search height="1.125rem" width="1.125rem" />}
                />
                <FilterPermissionsDropdown setPermissions={setPermissionsFilter} permissions={permissionsFilter} />
            </Group>

            {sortedFilteredPaginatedUsers.length ? (
                <Table
                    data={sortedFilteredPaginatedUsers}
                    columns={generateUsersTableColumns(actionHandler)}
                    handleSort={(key, direction) => setSortedBy({ key, direction })}
                    sort={tableSort}
                    hasBorder={false}
                />
            ) : (
                <Text>No users found matching search query.</Text>
            )}
            <UserDetails isOpen={userDetailsOpen} closeDrawer={closeDrawer} selectedUser={selectedUser} />
        </StyledStack>
    );
};

export default WorkspaceUsers;

const StyledStack = styled(Stack)({
    flexBasis: '100%',
    overflow: 'hidden',
});
