import { Stack, Text } from '@mantine/core';

import ModalSection from '../../inspections/shared/ModalSection';
import ModalTitle from '../../inspections/shared/ModalTitle';

const DiagnosticReportInfoModal = () => (
    <>
        <ModalTitle title="What’s Included In The Diagnostic Inspection?" />
        <ModalSection title="Description">
            <Stack spacing="0.5rem">
                <Text>
                    An initial benchmark inspection of the site. High-resolution data from the full site is collected
                    prior to inspection, and a summary report of the DC health and performance of the site is provided.
                    It includes the frequency and economic impact of anomalies detected and is used to inform a decision
                    regarding inspection service tier upgrades and any warranted remediation.
                </Text>

                <Text>
                    Using our proprietary HL Insight engine, we capture a high-resolution scan of the site using a
                    combination of IR and RGB visual imagery. It allows the inspection to be upgraded to any appropriate
                    inspection service tier at any time, as needed.
                </Text>
            </Stack>
        </ModalSection>

        <ModalSection title="Scope of Inspection Service">
            <Stack spacing="0.5rem">
                <ul style={{ listStyleType: 'disc', margin: '0 20px' }}>
                    <li>
                        Estimated summary of power losses of site based on balance of system (BoS) faults, and a
                        sub-sampling of module-level anomalies
                    </li>
                    <li>
                        Industry benchmarking of estimated power losses due to BoS faults, and module-level anomalies
                        with Major Module or Minor Module power losses
                    </li>
                    <li>
                        A map of the site sub-divided into sections indicating which portions of the site are most
                        impacted by BoS, Major Module and Minor Module power losses
                    </li>
                </ul>
            </Stack>
        </ModalSection>
    </>
);

export default DiagnosticReportInfoModal;
