import { ConfirmModal } from '@dronebase/shared-ui-core';
import { Box, Button, Stack, Text, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

const InviteLanding = ({
    email,
    workspaceName,
    setIsInvitationAccepted,
    onSubmit,
}: {
    email: string;
    workspaceName: string;
    setIsInvitationAccepted: React.Dispatch<React.SetStateAction<boolean | null>>;
    onSubmit: (password?: string | null) => Promise<void>;
}) => {
    const [opened, { open, close }] = useDisclosure(false);

    const onDeclineInvite = () => {
        setIsInvitationAccepted(false);
        onSubmit();
    };

    return (
        <>
            <Title order={6} ta="center">
                {`You've been invited to join ${workspaceName} on Zeiview Solar Insights`}
            </Title>
            <Box>
                <Text variant="body2" ta="center" color="gray.4">
                    You have received an invitation{' '}
                    <Text span fw={500}>
                        {email}
                    </Text>
                </Text>
                <Text variant="body2" ta="center" color="gray.4">
                    To get started, accept the invite below.
                </Text>
            </Box>
            <Stack align="center">
                <Button variant="filled" w="100%" size="xl" onClick={() => setIsInvitationAccepted(true)}>
                    Accept
                </Button>
                <Button variant="text" w="fit-content" size="xl" onClick={open}>
                    Decline
                </Button>
            </Stack>

            <ConfirmModal
                opened={opened}
                onClose={close}
                title="Confirm invite decline"
                buttonPosition="end"
                confirmLabel="Yes"
                cancelLabel="No"
                onConfirm={onDeclineInvite}
            >
                <Text size="sm" variant="body2">
                    Are you sure you want to decline this invite? This cannot be undone.
                </Text>
            </ConfirmModal>
        </>
    );
};

export default InviteLanding;
