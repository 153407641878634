import { useEffect } from 'react';

import { TextInput } from '@mantine/core';
import { useForm } from 'react-hook-form';
import { useOutletContext, useParams, useFetcher } from 'react-router-dom';

import { ROUTES } from 'lib/constants/routes';
import { useNavigationBlocker } from 'lib/hooks/useNavigationBlocker';
import { Asset } from 'lib/types/inspections/anomaliesMap';

import DiscardChangesModal from './DiscardChangesModal';
import ModuleTabFooter from './ModuleTabFooter';
import { SingleDetail } from './SingleDetail';
import { TabContentContainer } from '../TabContainers.styled';

export const ModuleTabContent = () => {
    const { assetRegionUuid, combinerId, inverterId, coordinates, serialNumber } = useOutletContext() as Asset;
    const { siteId, inspectionId } = useParams();
    const fetcher = useFetcher();

    const {
        register,
        reset,
        formState: { isDirty },
    } = useForm({
        defaultValues: { serialNumber },
    });

    const navigationBlocker = useNavigationBlocker(isDirty);

    useEffect(() => {
        reset({ serialNumber });
    }, [serialNumber, reset]);

    const handleDiscard = () => {
        reset({ serialNumber });
        navigationBlocker.proceed();
    };

    return (
        <>
            <DiscardChangesModal
                opened={navigationBlocker.isNavigationBlocked}
                onClose={navigationBlocker.halt}
                onDiscard={handleDiscard}
            />
            <fetcher.Form
                method="post"
                action={
                    `${ROUTES.sites.index}/${siteId}/${inspectionId}/${ROUTES.sites.inspection.map.index}/` +
                    `${assetRegionUuid}/${ROUTES.sites.inspection.map.module}`
                }
                style={{ display: 'flex', flexDirection: 'column', flex: 1 }}
            >
                <TabContentContainer>
                    <SingleDetail label="Combiner ID" value={combinerId} />
                    <SingleDetail label="Inverter ID" value={inverterId} />
                    <SingleDetail label="Lat, Long" value={`${coordinates[0]}, ${coordinates[1]}`} />
                    <SingleDetail label="Serial Number">
                        <TextInput {...register('serialNumber')} placeholder="Enter serial number" w="max-content" />
                    </SingleDetail>
                </TabContentContainer>

                <ModuleTabFooter disabled={!isDirty} onCancel={handleDiscard} onSave={undefined} />
            </fetcher.Form>
        </>
    );
};
