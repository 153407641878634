import { Loader } from '@dronebase/shared-ui-core';
import { Navigate, Outlet } from 'react-router-dom';

import useFirebaseAuth from 'lib/hooks/useFirebaseAuth';

const ProtectedRoutes = () => {
    const { authUser, loading } = useFirebaseAuth();

    if (loading) {
        return <Loader />;
    }

    return authUser ? <Outlet context={authUser} /> : <Navigate to="/auth" replace />;
};

export default ProtectedRoutes;
