import { Button, Text } from '@mantine/core';
import { useParams } from 'react-router-dom';

import { REPORT_TABS } from 'lib/constants';
import { shortFormatDate } from 'lib/helpers';
import { useInspectionTabs } from 'lib/hooks';
import { useDifferentialMode } from 'lib/hooks/useDifferentialMode';

const DifferentialToggle = () => {
    const { inspectionId } = useParams();
    const { setAndNavigateToTab } = useInspectionTabs();
    const {
        activateDifferentialMode,
        active,
        hasDifferentialReports,
        currentInspectionDate,
        setDifferentialModeInspectionId,
    } = useDifferentialMode();

    const handleClick = () => {
        setAndNavigateToTab(REPORT_TABS.MAP);
        setDifferentialModeInspectionId(inspectionId);
        activateDifferentialMode();
    };

    return inspectionId ? (
        <>
            <Text fz="0.875rem" color="gray.2">
                Inspection: {shortFormatDate(currentInspectionDate)}
            </Text>
            {hasDifferentialReports && !active ? (
                <Button size="md" onClick={handleClick}>
                    Comparison View
                </Button>
            ) : null}
        </>
    ) : null;
};

export default DifferentialToggle;
