import { ReactNode } from 'react';

import styled from '@emotion/styled';
import { Button, MantineSize } from '@mantine/core';
import { Link, To } from 'react-router-dom';

export const LinkButton = ({ children, to, size }: { children: ReactNode; to: To; size: MantineSize }) => (
    <Button variant="text" size={size} p={0} styles={{ root: { minHeight: 'fit-content', width: 'fit-content' } }}>
        <StyledRouterLink to={to}>{children}</StyledRouterLink>
    </Button>
);

const StyledRouterLink = styled(Link)({
    'textDecoration': 'none',
    ':hover': {
        textDecoration: 'underline',
    },
    'color': 'var(--color-purple-400)',
    'lineHeight': '150%',
});
