import { MIN_PASSWORD_LENGTH } from 'lib/constants';

export const validateEmail = (email: string) => {
    const re = /\S+@\S+\.\S+/;

    if (!re.test(email)) {
        return 'Enter a valid email address';
    }
};

export const validateNewPassword = (password: string, confirmPassword: string) => {
    const pattern = new RegExp(`^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{${MIN_PASSWORD_LENGTH},}$`);

    if (!pattern.test(password)) {
        return `Password must contain at least one number, one lowercase and one uppercase letter,
        and be at least ${MIN_PASSWORD_LENGTH} or more characters. Password can contain special characters`;
    }

    if (confirmPassword && password !== confirmPassword) {
        return 'Passwords must match';
    }
};

export const validatePassword = (password: string) => {
    if (!password || password.length < MIN_PASSWORD_LENGTH) {
        return `Password must be at least ${MIN_PASSWORD_LENGTH} characters long`;
    }
};

export const validateRequired = (value: string, name: string, len: number) => {
    if (!value) {
        return `${name} is requried`;
    } else if (value.length < len) {
        return `${name} must be at least ${MIN_PASSWORD_LENGTH} characters long`;
    }
};
