import { useState } from 'react';

import { Tabs } from '@mantine/core';
import { useLocation, useNavigate } from 'react-router-dom';

import { USERS_TABS_HEIGHT } from 'lib/constants/layout';
import { ROUTES } from 'lib/constants/routes';

export const UsersPageTabs = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const USER_TABS_CONTROLS: Record<string, { label: string; path: string }> = {
        users: {
            label: 'Users',
            path: ROUTES.users.index,
        },
        invites: {
            label: 'Invites',
            path: ROUTES.users.invites,
        },
    };

    const [activeTab, setActiveTab] = useState<string | null>(() => {
        const tab = Object.keys(USER_TABS_CONTROLS).find((key) => USER_TABS_CONTROLS[key].path === location.pathname);

        return tab ?? Object.keys(USER_TABS_CONTROLS)[0];
    });

    const onTabChange = (value: string) => {
        setActiveTab(value);
        navigate(USER_TABS_CONTROLS[value].path);
    };

    return (
        <Tabs h={USERS_TABS_HEIGHT} value={activeTab} onTabChange={onTabChange}>
            <Tabs.List>
                {Object.keys(USER_TABS_CONTROLS).map((key) => (
                    <Tabs.Tab key={key} value={key}>
                        {USER_TABS_CONTROLS[key].label}
                    </Tabs.Tab>
                ))}
            </Tabs.List>
        </Tabs>
    );
};
