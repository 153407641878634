import { Flex } from '@mantine/core';
import { useRouteLoaderData } from 'react-router-dom';

import { FAULT_TYPE_INSPECTION_TYPE_MAP } from 'lib/constants';
import { calculateFinancialLoss } from 'lib/helpers';

import PerformanceCard from './PerformanceCard';
import SitePerformanceModal from './SitePerformanceModal';
import PopoverCard from '../shared/PopoverCard';
import { InspectionLoaderData } from '../types';

const SitePerformance = () => {
    const { summary, powerLossBreakdown, reportType } = useRouteLoaderData('inspection') as InspectionLoaderData;
    const { bosFinancialLoss, majorFinancialLoss, minorFinancialLoss } = calculateFinancialLoss({
        powerLossBreakdown,
    });

    return (
        <PopoverCard title="Performance Scorecard" modalContent={<SitePerformanceModal />}>
            <Flex justify="space-around">
                <PerformanceCard
                    title="Balance of System"
                    percentage={(summary?.bos / summary?.activeModules) * 100}
                    powerLossKw={summary?.bosPowerLoss / 1000}
                    financialLoss={bosFinancialLoss}
                    grade={summary?.bosGrade}
                    disabled={!summary?.bosGrade}
                />

                <PerformanceCard
                    title="Major Module"
                    percentage={(summary?.major / summary?.activeModules) * 100}
                    powerLossKw={summary?.majorPowerLoss / 1000}
                    financialLoss={majorFinancialLoss}
                    grade={summary?.majorGrade}
                    disabled={FAULT_TYPE_INSPECTION_TYPE_MAP.major.includes(reportType)}
                />

                <PerformanceCard
                    title="Minor Module"
                    percentage={(summary?.minor / summary?.activeModules) * 100}
                    powerLossKw={summary?.minorPowerLoss / 1000}
                    financialLoss={minorFinancialLoss}
                    grade={summary?.minorGrade}
                    disabled={FAULT_TYPE_INSPECTION_TYPE_MAP.minor.includes(reportType)}
                />
            </Flex>
        </PopoverCard>
    );
};

export default SitePerformance;
