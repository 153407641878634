import styled from '@emotion/styled';
import { Group, Stack, Text } from '@mantine/core';

import { colors } from 'lib/constants';
import { getDateLocale } from 'lib/helpers';

import { SiteDetails } from '../types';

export interface SiteInformationProps {
    site: SiteDetails;
}

const SiteInformation = ({ site }: SiteInformationProps) => (
    <StyledSiteInformationContainer>
        <Text size="1.125rem">Site Information</Text>
        <Group align="start" grow={true} spacing="0.625rem">
            <Text>Address: {[site.city, site.province, site.country].filter((val) => val).join(', ') || 'N/A'}</Text>
            <Text>Site Capacity: {site.capacity ? `${parseFloat(site.capacity.toFixed(2))} MW` : 'N/A'}</Text>
        </Group>

        <Group align="start" grow={true} spacing="0.625rem">
            <Text>Module Type(s): {site.moduleType ?? 'N/A'}</Text>
            <Text>Racking Type(s): {site.rackingType ?? 'N/A'}</Text>
        </Group>

        <Text>Last Inspection Date: {getDateLocale(site.lastInspectionDate) ?? 'N/A'}</Text>

        <Text>
            Geographic Coordinates(Lat/Lon):{' '}
            {`${site.location.coordinates[1].toFixed(5)}, ${site.location.coordinates[0].toFixed(5)}`}
        </Text>
    </StyledSiteInformationContainer>
);

// These colors were not specified in design so haven't been updated
const StyledSiteInformationContainer = styled(Stack)({
    background: colors.white,
    padding: '0.9375rem 1.5rem',
    borderRadius: '0.625rem',
    border: `1px solid ${colors.lightGray}`,
    fontSize: '0.875rem',
    gap: '1rem',
});

export default SiteInformation;
