import { Stack, Text } from '@mantine/core';

import ModalSection from '../../inspections/shared/ModalSection';
import ModalTitle from '../../inspections/shared/ModalTitle';

const FullMappingScopeInfoModal = () => (
    <>
        <ModalTitle title="What’s Included In The Full Mapping Scope Inspection?" />
        <ModalSection title="Description">
            <Stack spacing="0.5rem">
                <Text>
                    The Full Mapping Scope inspection is proprietary to NovaSource Power Services and identifies all
                    balance of system (BoS) level outages and all broken modules with a significant IR signature. Using
                    our proprietary HL Insight engine, we capture a high-resolution scan of the site using a combination
                    of IR and RGB visual imagery. It allows us to precisely identify the location, category, and
                    severity of all DC faults impacting the energy loss of the site down to the sub-module level. This
                    scope also includes mapping of anomalies for efficient remediation.
                </Text>
            </Stack>
        </ModalSection>

        <ModalSection title="Scope of Inspection Service">
            <Stack spacing="0.5rem">
                <Text>
                    <ul style={{ listStyleType: 'disc', margin: '0 20px' }}>
                        <li>
                            Summary of power losses and key observations of the site attributed to BoS anomalies and
                            broken modules with a significant IR signature
                        </li>
                        <li>Industry benchmarking of power losses due to BoS faults</li>
                        <li>Identification of the following anomalies:</li>
                        <ul style={{ listStyleType: 'disc', margin: '0 20px' }}>
                            <li>Inverter outages</li>
                            <li>Sectional outages</li>
                            <li>All string-level anomalies</li>
                            <li>All broken modules with a significant IR signature</li>
                        </ul>
                        <li>List of modules impacted by anomalies</li>
                        <li>Site schematic with locations of anomalies</li>
                    </ul>
                </Text>
            </Stack>
        </ModalSection>
    </>
);

export default FullMappingScopeInfoModal;
