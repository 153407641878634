import { useCallback, useEffect, useState } from 'react';

import { notifications } from '@dronebase/shared-ui-core';
import { Params, redirect, useLoaderData, useNavigate } from 'react-router-dom';

import { Config } from 'config';
import { ROUTES } from 'lib/constants/routes';
import { fetchData } from 'lib/hooks/fetchData';
import useFirebaseAuth from 'lib/hooks/useFirebaseAuth';

import InviteAcceptForm from './InviteAcceptForm';
import InviteDeclined from './InviteDeclined';
import InviteLanding from './InviteLanding';

const AUTH_API_URL = Config.get('AUTH_API_URL');

const Invite = () => {
    const { logoutUser } = useFirebaseAuth();
    const { workspaceName, email, needsPassword, inviteId } = useLoaderData() as InviteLoaderData;
    const navigate = useNavigate();
    const [isInvitationAccepted, setIsInvitationAccepted] = useState<boolean | null>(null);

    const onSubmit = useCallback(
        async (password: string | null = null) => {
            try {
                const { error } = await fetchData({
                    url: `${AUTH_API_URL}/invite`,
                    method: 'POST',
                    body: {
                        invite_uuid: inviteId,
                        accept: !!isInvitationAccepted,
                        password,
                    },
                });

                if (error) {
                    throw error;
                }

                if (isInvitationAccepted) {
                    notifications.success({
                        title: `You have successfully been added to the ${workspaceName} workspace.`,
                        withIcon: true,
                        withCloseButton: true,
                    });

                    await logoutUser();
                    navigate(ROUTES.auth.index);
                }
            } catch (error) {
                notifications.error({
                    title: <>{error}</>,
                    withIcon: true,
                    withCloseButton: true,
                });
            }
        },
        [isInvitationAccepted, inviteId, logoutUser, navigate, workspaceName],
    );

    useEffect(() => {
        if (isInvitationAccepted && !needsPassword) {
            onSubmit();
        }
    }, [isInvitationAccepted, needsPassword, onSubmit]);

    return (
        <>
            {isInvitationAccepted === null && (
                <InviteLanding
                    email={email}
                    workspaceName={workspaceName}
                    setIsInvitationAccepted={setIsInvitationAccepted}
                    onSubmit={onSubmit}
                />
            )}

            {isInvitationAccepted === false && <InviteDeclined email={email} workspaceName={workspaceName} />}

            {isInvitationAccepted === true && needsPassword && <InviteAcceptForm onSubmit={onSubmit} />}
        </>
    );
};

export default Invite;

interface InviteLoaderData {
    workspaceName: string;
    needsPassword: boolean;
    email: string;
    inviteId: string | undefined;
}

export const inviteLoader = async ({ params }: { params: Params }): Promise<Response | InviteLoaderData> => {
    const { data, error } = await fetchData({
        url: `${AUTH_API_URL}/invite?invite_uuid=${params.inviteId}`,
        method: 'GET',
        body: {},
    });

    if (error) {
        notifications.error({
            title: error,
            withIcon: true,
            withCloseButton: true,
        });

        return redirect('/auth');
    }

    return {
        workspaceName: data.workspace_name,
        needsPassword: data.needs_password,
        email: data.email,
        inviteId: params.inviteId,
    };
};
