import { LayerProps } from 'react-map-gl';

type LayerPropsWithID = LayerProps & {
    id: string;
};

export const clusterLayer: LayerPropsWithID = {
    id: 'sites-circle',
    type: 'circle',
    source: 'sites',
    filter: ['has', 'point_count'],
    paint: {
        'circle-color': ['step', ['get', 'point_count'], '#7363FB', 100, '#7363FB', 750, '#7363FB'],
        'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
        'circle-stroke-width': 7,
        'circle-stroke-color': '#9C91FC',
        'circle-stroke-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0.5],
        'circle-opacity-transition': {
            duration: 0,
            delay: 0,
        },
    },
};

export const clusterCountLayer: LayerPropsWithID = {
    id: 'sites-cluster-count',
    type: 'symbol',
    source: 'sites',
    filter: ['has', 'point_count'],
    layout: {
        'text-field': ['get', 'point_count'],
        'text-offset': [0, 0],
        'text-size': 14,
    },
    paint: {
        'text-color': '#FFF',
        'text-halo-width': 0.1,
        'text-halo-color': '#FFF',
        'text-opacity-transition': {
            duration: 0,
            delay: 0,
        },
    },
};

export const unclusteredPointLayer: LayerPropsWithID = {
    id: 'unclustered-point',
    type: 'circle',
    source: 'sites',
    filter: ['!', ['has', 'point_count']],
    paint: {
        'circle-color': '#7363FB',
        'circle-radius': 8,
        'circle-opacity': 0.75,
        'circle-stroke-opacity': 0.75,
        'circle-stroke-width': 2,
        'circle-stroke-color': '#FFF',
    },
};
