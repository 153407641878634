import { Group, Tabs, Text } from '@mantine/core';

import { useMapSignalColors } from 'lib/hooks/map';

const AnomalyColorPalette = () => {
    const { colorPalette, setColorPalette } = useMapSignalColors();

    return (
        <Group pt="1rem" align="center" position="left">
            <Text variant="body1" color="gray.0">
                Color Modules
            </Text>
            <Tabs variant="segmented" value={colorPalette} onTabChange={setColorPalette}>
                <Tabs.List>
                    <Tabs.Tab value="IR">IR</Tabs.Tab>
                    <Tabs.Tab value="RGB">RGB</Tabs.Tab>
                </Tabs.List>
            </Tabs>
        </Group>
    );
};

export default AnomalyColorPalette;
