import { Loader } from '@dronebase/shared-ui-core';
import { Box, Group, Select, Text } from '@mantine/core';

import { buildPivotViewDropdownOptions } from 'lib/helpers';
import { usePivotTable, usePivotViewOptions } from 'lib/hooks';

const PivotViewDropdown = () => {
    const { view, setView } = usePivotTable();
    const { viewOptions, disabledViewOptions, loading } = usePivotViewOptions();

    return (
        <Group spacing="0.625rem" noWrap>
            <Text variant="mediumPlus">View</Text>
            {loading && <Loader />}
            {!loading && (
                <Box w="17.5rem">
                    <Select
                        value={view}
                        onChange={setView}
                        data={buildPivotViewDropdownOptions(viewOptions, disabledViewOptions)}
                    />
                </Box>
            )}
        </Group>
    );
};

export default PivotViewDropdown;
