import { FormEvent, useCallback } from 'react';

import styled from '@emotion/styled';
import { Text, Button, Title } from '@mantine/core';
import { sendSignInLinkToEmail } from 'firebase/auth';
import { useLocation, useNavigate } from 'react-router-dom';

import { CardContentContainer } from 'components/Layout/AuthPagesLayout';
import auth from 'lib/firebase';

const SignInLinkSent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const email = location.state?.email || '';

    const handleResendLink = useCallback(
        async (e: FormEvent) => {
            e.preventDefault();

            try {
                await sendSignInLinkToEmail(auth, email, {
                    url: `${window.location.origin}/auth/confirm`,
                    handleCodeInApp: true,
                });
            } catch (error) {
                console.error('Error resending link:', error);
            }
        },
        [email],
    );

    return (
        <CardContentContainer>
            <StyledForm onSubmit={handleResendLink}>
                <Title order={6} ta="center" mb={8}>
                    Log in
                </Title>
                <Text size="sm" c="#6A7380" mb={12}>
                    A one-time login link has been sent to your email. Click on the link to access Solar Insights. Check
                    your spam and promotions folder if it doesn&apos;t appear in your main mailbox.
                </Text>
                <Text size="sm" c="#6A7380" mb={22}>
                    Link is valid for 5 minutes.
                </Text>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                    <Button type="submit" fullWidth size="xl" mb="md" style={{ width: '100%' }}>
                        Resend the Link
                    </Button>
                    <Text
                        component="button"
                        onClick={() => navigate('/auth')}
                        style={{ fontSize: '16px' }}
                        c="#7363FB"
                        sx={{
                            'background': 'none',
                            'border': 'none',
                            'cursor': 'pointer',
                            'padding': 0,
                            '&:hover': {
                                textDecoration: 'underline',
                            },
                        }}
                    >
                        Back to Log in
                    </Text>
                </div>
            </StyledForm>
        </CardContentContainer>
    );
};

const StyledForm = styled.form({
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '600px',
    margin: '0 auto',
    textAlign: 'center',
});

export default SignInLinkSent;
