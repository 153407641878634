import './pivot-table.css';

import { Loader } from '@dronebase/shared-ui-core';
import { InfoSolid } from '@dronebase/shared-ui-icons';
import styled from '@emotion/styled';
import { Button, Group, GroupProps, Stack, Text } from '@mantine/core';
import PivotTableUI from 'react-pivottable';

import Error from 'components/Layout/Error';
import { useAnomalyData, usePivotTable } from 'lib/hooks';

import PivotViewDropdown from './PivotViewDropdown';
import TableSettings from './TableSettings';

const PivotTable = () => {
    const { data, loading: loadingPivotTable, settings, setSettings, clearFilters, hasFilters } = usePivotTable();
    const { error, loading: loadingAnomalyData } = useAnomalyData();

    if (loadingAnomalyData || loadingPivotTable) {
        return <Loader />;
    }

    if (error || !data?.length) {
        return <Error showRedirect={false} label={error ?? 'No records found.'} />;
    }

    return (
        <>
            <PivotTableInfoGroup>
                <InfoSolid color="var(--color-purple-400)" />
                <Text fz="0.875rem" fw={500}>
                    The pivot table calculates counts using anomalies and may differ from the spreadsheet within the
                    Files section which calculates counts using affected modules.
                </Text>
            </PivotTableInfoGroup>
            <Group p="0.625rem" position="apart">
                <Group position="left">
                    <PivotViewDropdown />
                    {hasFilters && (
                        <Button size="md" variant="text" onClick={clearFilters}>
                            Clear all filters
                        </Button>
                    )}
                </Group>
                <TableSettings />
            </Group>
            <OverflowStack>
                <PivotTableUI data={data} onChange={setSettings} {...settings} />
            </OverflowStack>
        </>
    );
};

export default PivotTable;

const OverflowStack = styled(Stack)({
    overflow: 'auto',
    fontSize: '0.875rem',
});

const PivotTableInfoGroup = styled(Group)<GroupProps>({
    gap: '1rem',
    backgroundColor: 'var(--color-purple-50)',
    padding: '0.75rem 1rem 0.75rem 1rem',
    borderRadius: '4px',
});
