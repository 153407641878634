import { Flex } from '@mantine/core';

import { LOCAL_STORAGE_KEYS } from 'lib/constants';
import { toggle } from 'lib/helpers/localStorage';

import CollapsedMapView from './CollapsedView';
import Map from './Map';
import Search from './Search';

export interface MapViewProps {
    collapsed: boolean;
    toggleCollapsed: () => void;
}

const MapView = ({ collapsed, toggleCollapsed }: MapViewProps) => {
    const handleToggling = () => {
        toggleCollapsed();
        toggle(LOCAL_STORAGE_KEYS.SITES_MAP_COLLAPSED);
    };

    if (collapsed) {
        return <CollapsedMapView handleToggling={handleToggling} />;
    }

    return (
        <Flex
            h="100%"
            w="50%"
            gap="1.5rem"
            direction="column"
            style={{
                borderRight: '1px solid var(--color-grey-100)',
            }}
        >
            <Search handleToggling={handleToggling} />
            <Map />
        </Flex>
    );
};

export default MapView;
