export const PDFFileIcon = () => (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.7996 41.7364H16.9528V45.573H14.5252V34.5333H18.7996C19.6635 34.5333 20.4032 34.6926 21.0188 35.011C21.6393 35.3295 22.1135 35.7667 22.4411 36.3227C22.7737 36.8737 22.94 37.5056 22.94 38.2183C22.94 38.931 22.7737 39.5502 22.4411 40.0759C22.1135 40.6016 21.6393 41.0111 21.0188 41.3042C20.4032 41.5924 19.6635 41.7364 18.7996 41.7364ZM16.9528 36.4516V39.8181H18.7996C19.2018 39.8181 19.5244 39.7499 19.7677 39.6134C20.0159 39.4769 20.1971 39.2899 20.3113 39.0523C20.4305 38.8097 20.4901 38.5367 20.4901 38.2334C20.4901 37.9201 20.4305 37.6294 20.3113 37.3615C20.1971 37.0885 20.0159 36.8687 19.7677 36.7018C19.5244 36.535 19.2018 36.4516 18.7996 36.4516H16.9528Z"
            fill="#8D95A2"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.8475 45.573H24.4145V34.5333H27.8326C28.5674 34.5333 29.2376 34.6597 29.8432 34.9124C30.4539 35.1652 30.9801 35.5266 31.422 35.9967C31.8688 36.4668 32.2138 37.0228 32.4571 37.6648C32.7003 38.3067 32.822 39.022 32.822 39.8105V40.3034C32.822 41.0869 32.7003 41.8021 32.4571 42.4492C32.2138 43.0911 31.8688 43.6471 31.422 44.1172C30.9801 44.5823 30.4564 44.9412 29.8507 45.1939C29.245 45.4467 28.5773 45.573 27.8475 45.573ZM27.8475 43.6623H26.8422V36.4516H27.8326C28.2397 36.4516 28.5996 36.5224 28.9124 36.6639C29.2301 36.8055 29.4957 37.0178 29.7092 37.3008C29.9227 37.5839 30.084 37.9352 30.1932 38.3548C30.3025 38.7693 30.3571 39.2495 30.3571 39.7954V40.3034C30.3571 41.0262 30.2603 41.6404 30.0666 42.1459C29.873 42.6463 29.59 43.0254 29.2177 43.2832C28.8454 43.5359 28.3886 43.6623 27.8475 43.6623Z"
            fill="#8D95A2"
        />
        <path
            d="M36.7315 39.1888V36.4516H41.4752V34.5333H34.3039V45.573H36.7315V41.0995H40.9986V39.1888H36.7315Z"
            fill="#8D95A2"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.3542 3.5C9.46821 3.5 8.75 4.23127 8.75 5.13333V50.8667C8.75 51.7687 9.46821 52.5 10.3542 52.5H45.6458C46.5318 52.5 47.25 51.7687 47.25 50.8667V18.2C47.25 17.7668 47.081 17.3514 46.7802 17.0451L33.9468 3.97839C33.646 3.67208 33.238 3.5 32.8125 3.5H10.3542ZM11.9583 49.2333V6.76667H31.2083V18.2C31.2083 19.1021 31.9265 19.8333 32.8125 19.8333H44.0417V49.2333H11.9583ZM41.773 16.5667L34.4167 9.07655V16.5667H41.773Z"
            fill="#8D95A2"
        />
    </svg>
);
