export const CalculatorIcon = () => (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M19.75 3H5.75C4.65 3 3.75 3.9 3.75 5V19C3.75 20.1 4.65 21 5.75 21H19.75C20.85 21 21.75 20.1 21.75 19V5C21.75 3.9 20.85 3 19.75 3ZM19.75 19H5.75V5H19.75V19Z"
            fill="#8D8D8D"
        />
        <path d="M12 7.72H7V9.22H12V7.72Z" fill="#8D8D8D" />
        <path d="M18.75 15.75H13.75V17.25H18.75V15.75Z" fill="#8D8D8D" />
        <path d="M18.75 13.25H13.75V14.75H18.75V13.25Z" fill="#8D8D8D" />
        <path d="M8.75 18H10.25V16H12.25V14.5H10.25V12.5H8.75V14.5H6.75V16H8.75V18Z" fill="#8D8D8D" />
        <path
            d="M14.84 10.95L16.25 9.54L17.66 10.95L18.72 9.89L17.31 8.47L18.72 7.06L17.66 6L16.25 7.41L14.84 6L13.78 7.06L15.19 8.47L13.78 9.89L14.84 10.95Z"
            fill="#8D8D8D"
        />
    </svg>
);
