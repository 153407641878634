import { ReactElement } from 'react';

import styled from '@emotion/styled';
import { SimpleGrid, Stack, Text, TextProps } from '@mantine/core';

import { BackButton } from 'components/buttons/BackButton';
import { DarkThemeProvider } from 'providers/DarkThemeProvider';

import {
    HeaderContainer,
    TitleSection,
    DualPageTitles,
    SegmentedTabsSection,
    FlexEndContentSection,
} from './PageHeader.styled';

interface PageHeaderProps {
    title: string;
    backTo?: string;
    onBackCallback?: () => void;
    subtitle?: ReactElement | string;
    segmentedPageTabs?: ReactElement;
    flexEndContent?: ReactElement;
}

export const PageHeader = ({
    title,
    subtitle,
    backTo,
    segmentedPageTabs,
    flexEndContent,
    onBackCallback,
}: PageHeaderProps) => (
    <DarkThemeProvider>
        <HeaderContainer>
            <SimpleGrid cols={3} h="100%">
                <TitleSection>
                    {backTo && (
                        <Stack justify="center">
                            <BackButton to={backTo} onClick={onBackCallback} />
                        </Stack>
                    )}
                    <DualPageTitles>
                        <StyledOverflowText variant="subtitle1" color="gray.1">
                            {title}
                        </StyledOverflowText>
                        {subtitle && subtitle}
                    </DualPageTitles>
                </TitleSection>
                <SegmentedTabsSection>{segmentedPageTabs && segmentedPageTabs}</SegmentedTabsSection>
                <FlexEndContentSection>{flexEndContent && flexEndContent}</FlexEndContentSection>
            </SimpleGrid>
        </HeaderContainer>
    </DarkThemeProvider>
);

const StyledOverflowText = styled(Text)<TextProps>({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
});
